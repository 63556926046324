import { ISimulationResponse } from 'app/components/CreditSimulatorV2/types';
import { BaseHttpService } from './baseHttpService';

export type TSimulatorV2Request = {
  requestedAmount?: number;
  totalAmountOwed?: number;
  paymentValue?: number;
  financedAmount?: number;
  financialIndicatorCobransaas?: ISimulationResponse;
  numPayments: number;
  tacAmount?: number;
  tacRate?: number;
  interestRate: number;
  financeFee: number;
  discountRate?: number;
  iofType: string;
  scheduleType: string;
  firstPaymentDate: string;
  disbursementDate: string;
  additionalInstallmentFee?: number | null;
  additionalInstallmentDescription?: string | null;
};

export type TSimulatorV2Response = {
  total_processing_cost: number;
  annual_interest_rate: number;
  annual_effective_interest_rate: number;
  monthly_effective_interest_rate: number;
  iof_amount: number;
  finance_fee: number;
  interest_rate: number;
  tac_amount: number;
  discount_amount: number;
  discount_rate: number;
  total_amount_owed: number;
  financed_amount: number;
  iof_daily_rate: number;
  iof_base_rate: number;
  requested_amount: number;
  payment_amount: number;
  disbursement_amount: number;
  num_periods: number;
  first_payment_date: string;
  issue_date: string;
  disbursement_date: string;
  created_at: string;
  schedule: Array<{
    payment: number;
    iof: number;
    balance: number;
    interest: number;
    principal: number;
    additional_value: number;
    present_value: number;
    status: string;
    period: number;
    running_day: number;
    late_day: number;
    paid_value: number;
    payment_date: string;
  }>;
  schedule_type: string;
  borrower_type: string;
  amortization_type: string;
  interest_compounding_type: string;
  financed_iof: boolean;
  last_payment_date: string;
};

export class SimulatorV2HttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  simulate = async (productId: string, params: TSimulatorV2Request) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/products/${productId}/preview`,
      {
        requested_amount: params.requestedAmount,
        total_amount_owed: params.totalAmountOwed,
        payment_value: params.paymentValue,
        financed_amount: params.financedAmount,

        interest_rate: params.interestRate,
        tac_amount: params.tacAmount,
        finance_fee: params.financeFee,
        num_payments: params.numPayments,
        discount_rate: params.discountRate,
        first_payment_date: params.firstPaymentDate,
        disbursement_date: params.disbursementDate,
        iof_type: params.iofType,
        schedule_type: params.scheduleType,
        tac_rate: params.tacRate,
        additional_installment_fee: params.additionalInstallmentFee,
        additional_installment_description:
          params.additionalInstallmentDescription,
        financial_indicator_cobransaas: params.financialIndicatorCobransaas,
      },
    );
    return res;
  };
}
