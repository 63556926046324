import { useTheme } from '@mui/material';
import { CustomSpinnerContent, StyledSpin } from './styles';
import { Spin } from 'antd';

export interface ISpinner {
  color?: string;
  size?: number;
  fullscreen?: boolean;
}

const Spinner = ({ size = 48, color, fullscreen }: ISpinner) => {
  const theme = useTheme();
  return fullscreen ? (
    <StyledSpin>
      <Spin fullscreen />
    </StyledSpin>
  ) : (
    <CustomSpinnerContent
      size={size}
      color={color ?? (theme.palette.brand.primary.base as string)}
    >
      <div className="loader">Loading...</div>
    </CustomSpinnerContent>
  );
};

export default Spinner;
