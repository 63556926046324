import { CustomSelect, StepContent } from 'app/components';
import {
  EPersonType,
  usePersonContext,
} from 'modules/customer/context/PersonProvider/PersonProvider';
import { roleTypesOptions } from 'modules/customer/context/PersonProvider/person.interfaces';
import CreateCompanyForm from './CreateCompanyForm/CreateCompanyForm';
import CreatePersonForm from './CreatePersonForm/CreatePersonForm';
import {
  StepContentInput,
  StepContentInputArea,
} from './CreatePersonForm/styles';
import { ICompanyForm, IPersonForm } from '../types';

interface IAboutContainerProps {
  hasRole?: boolean;
  formTypeRender?: EPersonType;
}

const AboutContainer = ({ hasRole, formTypeRender }: IAboutContainerProps) => {
  const {
    formType,
    setFormType,
    person,
    setPerson,
    setPersonCompany,
    personCompany,
  } = usePersonContext();

  const formTypes = [
    { label: 'Pessoa física', value: EPersonType.PERSON },
    { label: 'Pessoa jurídica', value: EPersonType.COMPANY },
  ];

  return (
    <StepContent title="Sobre">
      {!formTypeRender && (
        <StepContentInputArea>
          <StepContentInput>
            <CustomSelect
              labelValue="Tipo de assinante"
              name="type"
              placeholder="Selecione"
              value={formType}
              options={formTypes}
              handleInputChange={(v) => {
                setFormType(v.target.value);
                setPerson({} as IPersonForm);
                setPersonCompany({} as ICompanyForm);
              }}
            />
          </StepContentInput>
          {hasRole && (
            <StepContentInput>
              <CustomSelect
                labelValue="Função"
                name="role"
                placeholder="Selecione"
                value={person?.role}
                disabled={!formType}
                options={roleTypesOptions}
                handleInputChange={(e) => {
                  formType?.value === EPersonType.PERSON
                    ? setPerson({ ...person, role: e.target.value })
                    : setPersonCompany({
                        ...personCompany,
                        role: e.target.value,
                      });
                }}
              />
            </StepContentInput>
          )}
        </StepContentInputArea>
      )}

      {/** Render Form PF */}
      {((!!formTypeRender && formTypeRender === EPersonType.PERSON) ||
        formType?.value === EPersonType.PERSON) && (
        <CreatePersonForm
          setPerson={setPerson}
          person={person}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateExistsPerson={() => {}}
        />
      )}

      {/** Render Form PJ */}
      {((!!formTypeRender && formTypeRender === EPersonType.COMPANY) ||
        formType?.value === EPersonType.COMPANY) && <CreateCompanyForm />}
    </StepContent>
  );
};

export default AboutContainer;
