import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import {
  TPreviewSimulationData,
  TSimulatorParametersData,
} from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { useBreadcrumbsContext } from 'app/context';
import { IApplicationCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { Stepper } from 'app/components';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { TProducts } from 'modules/products/context';
import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { BorrowerStep } from './BorrowerStep';
import { ProductStep } from './ProductStep';
import { ReviewStep } from './ReviewStep';
import { SimulationStep } from './SimulationStep';
import {
  ApplicationRequestContainer,
  ApplicationRequestStepContainer,
  ApplicationRequestStepperContainer,
} from './styles';
import { SubscribersStep } from './SubscribersStep';
import { TSubscriber } from './SubscribersStep/SubscribersAddNewSideSheet';
import { VariablesStep } from './VariablesStep';
import { useLocation } from 'react-router-dom';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  simulatorActionTypes,
  useSimulatorContext,
} from 'modules/simulation/context';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { useAuthContext } from 'modules/auth/context';
import { FundingStep } from './FundingStep';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { BeneficiaryAccountStep } from './BeneficiaryAccountStep';
import useCreditSimulator from 'app/hooks/useCreditSimulator';
import CustodianStep from './CustodianStep/CustodianStep';
import { TCustodian } from 'modules/products/interfaces/custodian';
import { BRAZILIAN_STATES_OBJECT } from 'app/helpers/addressHelper';
import { BillPaymentStep } from './BillPaymentStep';
import { ValidateBilletsResponse } from 'modules/products/interfaces/billets';
import { TypeProductEmission } from 'modules/products/context/ProductsProvider/products.interfaces';
import { OriginatorStep } from './OriginatorStep';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { ValidateEmvResponse } from 'modules/products/interfaces/qrCode';
import { QRCodeStep } from './QRCodeStep';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import {
  ESplitPaymentAmountType,
  ESplitPaymentType,
  SplitPayment,
} from 'modules/products/interfaces/splitPayment';
import SplitPaymentStep from './SplitPaymentStep/SplitPaymentStep';
import {
  TSimulatorV2Request,
  TSimulatorV2Response,
} from 'app/services/simulatorHttpServiceV2';
import { SimulationV2Step } from './SimulationV2Step';
import { ISimulationResponse } from 'app/components/CreditSimulatorV2/types';

const stepsComponent = [
  {
    title: 'Originador',
    component: <OriginatorStep />,
  },
  {
    title: 'Funding',
    component: <FundingStep />,
  },
  {
    title: 'Produto',
    component: <ProductStep />,
  },
  {
    title: 'Tomador',
    component: <BorrowerStep />,
  },
  {
    title: 'Custodiante',
    component: <CustodianStep />,
  },
  {
    title: 'Conta Beneficiária',
    component: <BeneficiaryAccountStep />,
  },
  {
    title: 'Boletos para desembolso',
    component: <BillPaymentStep />,
  },
  {
    title: 'Pix Copia e Cola',
    component: <QRCodeStep />,
  },
  {
    title: 'Valores da Solicitação',
    component: <SimulationStep />,
  },
  {
    title: 'Split de Pagamento',
    component: <SplitPaymentStep />,
  },
  {
    title: 'Assinantes',
    component: <SubscribersStep />,
  },
  {
    title: 'Campos adicionais',
    component: <VariablesStep />,
  },
  {
    title: 'Ficou tudo certo?',
    component: <ReviewStep />,
  },
  {
    title: 'Assinatura',
  },
];

type IApplicationRequestContext = {
  activeStep: number;
  onForward: () => void;
  onBack: () => void;
  onBackTwoSteps: () => void;

  hasCustomVariables?: boolean;
  updateHasCustomVariables: (hasCustomVariables: boolean) => void;

  borrowerData:
    | (TRegisterPersonDataRequest & TRegisterBusinessDataRequest)
    | null;
  updateBorrowerData: (
    borrower:
      | (TRegisterPersonDataRequest & TRegisterBusinessDataRequest)
      | null,
  ) => void;

  borrowerQualification: TQualification | null;
  updateBorrowerQualification: (qualification: TQualification | null) => void;

  subscribers: TSubscriber[] | null;
  updateSubscribers: (subscribers: TSubscriber[] | null) => void;

  product: TProducts | null;
  updateProduct: (product: TProducts) => void;

  funding: string | null;
  hasMultiplesFundings: boolean;
  updateFunding: (funding: string) => void;

  beneficiaryAccount: TBeneficiaryAccount | null;
  updateBeneficiaryAccount: (beneficiaryAccount: TBeneficiaryAccount) => void;

  customVariables: IApplicationCustomVariablesValues[] | null;
  updateCustomVariables: (
    variables: IApplicationCustomVariablesValues[],
  ) => void;

  custodian: TCustodian | null;
  updateCustodian: (custodian: TCustodian) => void;

  basePremiumAmount: number | null;
  simulation: TPreviewSimulationData | null;
  simulationRequest: TSimulatorParametersData | null;
  updateSimulation: (
    simulation: TPreviewSimulationData,
    simulationRequest: TSimulatorParametersData,
    tabIndex: string,
  ) => void;

  simulationV2: TSimulatorV2Response | null;
  simulationV2Request: TSimulatorV2Request | null;
  updateSimulationV2: (
    simulation: TSimulatorV2Response,
    simulationRequest: TSimulatorV2Request,
    tabIndex: string,
  ) => void;

  resimulation: TPreviewSimulationData | null;
  simulateWithDifferentDisbursementDate: (disbursementDate: string) => void;

  billetValidationResponse: ValidateBilletsResponse | null;
  updateBilletValidationResponse: (
    billetsResponse: ValidateBilletsResponse | null,
  ) => void;

  qrcodeEmvValidationResponse: ValidateEmvResponse | null;
  updateQrcodeEmvValidationResponse: (
    emvResponse: ValidateEmvResponse | null,
  ) => void;

  originator: string | null;
  updateOriginator: (originator: string | null) => void;

  splitPayments: SplitPayment[];
  updateSplitPayments: (splitPayments: SplitPayment[]) => void;

  isClonning: TApplicationResponse | null;

  cobransaasResponse?: ISimulationResponse;
  setCobransaasResponse: (cobransaasResponse?: ISimulationResponse) => void;
};

export const ApplicationRequestContext =
  createContext<IApplicationRequestContext>({
    activeStep: 1,
    hasCustomVariables: false,
    isClonning: null,

    updateHasCustomVariables: () => {
      throw new Error('Método não implementado');
    },

    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },
    onBackTwoSteps: () => {
      throw new Error('Método não implementado');
    },

    beneficiaryAccount: null,
    updateBeneficiaryAccount: () => {
      throw new Error('Método não implementado');
    },

    funding: null,
    hasMultiplesFundings: false,
    updateFunding: () => {
      throw new Error('Método não implementado');
    },

    product: null,
    updateProduct: () => {
      throw new Error('Método não implementado');
    },

    customVariables: null,
    updateCustomVariables: () => {
      throw new Error('Método não implementado');
    },

    borrowerData: null,
    updateBorrowerData: () => {
      throw new Error('Método não implementado');
    },

    borrowerQualification: null,
    updateBorrowerQualification: () => {
      throw new Error('Método não implementado');
    },

    subscribers: null,
    updateSubscribers: () => {
      throw new Error('Método não implementado');
    },

    custodian: null,
    updateCustodian: () => {
      throw new Error('Método não implementado');
    },

    simulation: null,
    basePremiumAmount: null,
    simulationRequest: null,
    updateSimulation: () => {
      throw new Error('Método não implementado');
    },

    simulationV2: null,
    simulationV2Request: null,
    updateSimulationV2: () => {
      throw new Error('Método não implementado');
    },

    resimulation: null,
    simulateWithDifferentDisbursementDate: () => {
      throw new Error('Método não implementado');
    },

    billetValidationResponse: null,
    updateBilletValidationResponse: () => {
      throw new Error('Método não implementado');
    },

    qrcodeEmvValidationResponse: null,
    updateQrcodeEmvValidationResponse: () => {
      throw new Error('Método não implementado');
    },

    originator: null,
    updateOriginator: () => {
      throw new Error('Método não implementado');
    },

    splitPayments: [],
    updateSplitPayments: () => {
      throw new Error('Método não implementado');
    },

    cobransaasResponse: undefined,
    setCobransaasResponse: () => {
      throw new Error('Método não implementado');
    },
  });

export enum TacValueType {
  PERCENTAGE = 'PERCENTAGE',
  MONETARY = 'MONETARY',
}

const ApplicationRequest = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { is_issuer_app, feature_flags } = useAppContext();
  const { selectedOriginator, setSelectedOriginator } = useOriginatorContext();
  const [cobransaasResponse, setCobransaasResponse] =
    useState<ISimulationResponse>();
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [product, updateProduct] = useState<TProducts | null>(null);
  const [hasCustomVariables, updateHasCustomVariables] = useState(false);
  const [billetValidationResponse, updateBilletValidationResponse] =
    useState<ValidateBilletsResponse | null>(null);
  const [qrcodeEmvValidationResponse, updateQrcodeEmvValidationResponse] =
    useState<ValidateEmvResponse | null>(null);
  const [isClonning, updateIsClonning] = useState<TApplicationResponse | null>(
    null,
  );
  const [splitPayments, updateSplitPayments] = useState<SplitPayment[]>([]);
  const [beneficiaryAccount, updateBeneficiaryAccount] =
    useState<TBeneficiaryAccount | null>(null);
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const { getProduct } = useProductsService();
  const [borrowerData, updateBorrowerData] = useState<
    (TRegisterPersonDataRequest & TRegisterBusinessDataRequest) | null
  >(null);
  const [simulation, updateSimulation] =
    useState<TPreviewSimulationData | null>(null);
  const [resimulation, updateResimulation] =
    useState<TPreviewSimulationData | null>(null);
  const [borrowerQualification, updateBorrowerQualification] =
    useState<TQualification | null>(null);
  const [custodian, updateCustodian] = useState<TCustodian | null>(null);

  const [simulationRequest, updateSimulationRequest] =
    useState<TSimulatorParametersData | null>(null);

  const [simulationV2, updateSimulationV2] =
    useState<TSimulatorV2Response | null>(null);
  const [simulationV2Request, updateSimulationV2Request] =
    useState<TSimulatorV2Request | null>(null);
  const [subscribers, updateSubscribers] = useState<TSubscriber[] | null>(null);
  const [basePremiumAmount, updateBasePremiumAmount] = useState<number | null>(
    null,
  );
  const [tabSimulationIndex, updateTabSimulationIndex] = useState<
    string | null
  >(null);
  const { simulate } = useCreditSimulator();

  const { getBasePremiumAmount } = useApplicationsService();
  const { dispatch } = useSimulatorContext();
  const [customVariables, updateCustomVariables] = useState<
    IApplicationCustomVariablesValues[] | null
  >(null);
  const [selectedFunding, updateSelectedFunding] = useState<string | null>(
    null,
  );
  const { userInfo } = useAuthContext();
  const location = useLocation();

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const isNewSimulator = useMemo(() => {
    return feature_flags?.NEW_SIMULATOR_ENABLED
      ? feature_flags?.NEW_SIMULATOR_ENABLED?.isActive
      : false;
  }, [feature_flags]);

  const hasBorrower = useMemo(() => {
    if (typeof location.state?.person === 'object') {
      history.replaceState({}, document.title);
      return location.state?.person;
    }
    if (typeof location.state?.business === 'object') {
      history.replaceState({}, document.title);
      return location.state?.business;
    }
    return null;
  }, [location]);

  const hasApplication = useMemo<TApplicationResponse | null>(() => {
    if (typeof location.state?.application === 'object') {
      history.replaceState({}, document.title);
      return location.state?.application;
    }
    return null;
  }, [location]);

  const hasCreatedBorrower = useMemo(() => {
    if (typeof location.state?.borrower === 'object') {
      history.replaceState({}, document.title);
      return {
        product: location.state?.product,
        borrower: location.state?.borrower,
      };
    }
    return null;
  }, [location]);

  const onForward = useCallback(() => {
    if (activeStep < steps.length) {
      updateActiveStep(activeStep + 1);
    }
  }, [activeStep, hasBorrower]);

  const onBackTwoSteps = useCallback(() => {
    if (activeStep > 1) {
      updateActiveStep(activeStep - 2);
    }
  }, [activeStep]);

  const onBack = useCallback(() => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  useEffect(() => {
    setItems(
      [
        { to: '/products/main', label: 'Produtos' },
        { to: '/products/application', label: 'Criar solicitação' },
      ],
      <AccountBalanceOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  useEffect(() => {
    if (hasCreatedBorrower !== null) {
      if (
        typeof hasCreatedBorrower.product === 'object' &&
        typeof hasCreatedBorrower.borrower === 'object'
      ) {
        updateBorrowerData(hasCreatedBorrower.borrower);
        updateProduct(hasCreatedBorrower.product);
        updateActiveStep(2);
      }
    }
  }, [hasCreatedBorrower]);

  useEffect(() => {
    if (typeof hasBorrower === 'object' && hasBorrower !== null) {
      updateBorrowerData(hasBorrower);
    }
  }, [hasBorrower]);

  useEffect(() => {
    if (hasApplication !== null) {
      updateIsClonning(hasApplication);

      if (hasApplication.originator?.id) {
        setSelectedOriginator(hasApplication.originator.id);
      }
      getProduct(hasApplication.product.id).then((productDetails) => {
        const productData = productDetails?.data as TProducts;
        updateProduct(productData);

        if (
          productData?.type_product_emission ===
            TypeProductEmission.COMMERCIAL_PAPER &&
          hasApplication.custodian
        ) {
          updateCustodian(() => ({
            taxpayer_id: hasApplication.custodian!.taxpayer_id,
            legal_name: hasApplication.custodian!.legal_name,
            phone: {
              country_code: hasApplication.custodian!.phone.country_code,
              area_code: '',
              number: `${hasApplication.custodian!.phone.area_code}${
                hasApplication.custodian!.phone.number
              }`,
            },
            foundation_date: hasApplication.custodian!.foundation_date,
            email_address: hasApplication.custodian!.email_address,
            address: {
              city: {
                label: hasApplication.custodian!.address.city,
                value: hasApplication.custodian!.address.city,
              },
              country_code: {
                label: hasApplication.custodian!.address.country_code,
                value: hasApplication.custodian!.address.country_code,
              },
              district: hasApplication.custodian!.address.district,
              extra_info: hasApplication.custodian!.address.extra_info,
              postal_code: hasApplication.custodian!.address.postal_code,
              state_code: {
                label:
                  BRAZILIAN_STATES_OBJECT[
                    hasApplication.custodian!.address.state_code
                  ],
                value: hasApplication.custodian!.address.state_code,
              },
              street_name: hasApplication.custodian!.address.street_name,
              street_number: String(
                hasApplication.custodian!.address.street_number,
              ),
            },
          }));
        }

        if (
          productData?.beneficiary_type === 'EXTERNAL_REGISTERED_ACCOUNT' &&
          hasApplication?.beneficiary_account
        ) {
          updateBeneficiaryAccount(() => hasApplication!.beneficiary_account!);
        }

        let borrowerDataInternal: TRegisterPersonDataRequest &
          TRegisterBusinessDataRequest;
        if (productData.borrower_type === 'PERSON') {
          getPerson(hasApplication.borrower.id).then((personDetails) => {
            borrowerDataInternal = personDetails as TRegisterPersonDataRequest &
              TRegisterBusinessDataRequest;
            updateBorrowerData(
              personDetails as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest,
            );
          });
        } else {
          getBusiness(hasApplication.borrower.id).then((personDetails) => {
            borrowerDataInternal = personDetails as TRegisterPersonDataRequest &
              TRegisterBusinessDataRequest;
            updateBorrowerData(
              personDetails as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest,
            );
          });
        }

        if (
          productData?.beneficiary_type === 'SPLIT_DISBURSEMENT' &&
          hasApplication?.split_beneficiary_account
        ) {
          const amountType = hasApplication.split_beneficiary_account!
            .percentage
            ? ESplitPaymentAmountType.PERCENT
            : ESplitPaymentAmountType.MONEY;

          const borrowerAmount =
            amountType === ESplitPaymentAmountType.PERCENT
              ? 100 - hasApplication.split_beneficiary_account!.percentage!
              : hasApplication.requested_amount -
                hasApplication.split_beneficiary_account!.amount!;
          updateSplitPayments(() => [
            {
              type: ESplitPaymentType.BORROWER,
              taxpayer_id: borrowerDataInternal!.taxpayer_id,
              name: borrowerDataInternal!.full_name,
              external_bank_account:
                borrowerDataInternal!.external_bank_account,
              pix: borrowerDataInternal!.pix,
              amount: borrowerAmount,
              amountString:
                amountType === ESplitPaymentAmountType.PERCENT
                  ? `${borrowerAmount}%`
                  : formatCurrency(borrowerAmount),
              amount_type: amountType,
            },
            {
              name: hasApplication.split_beneficiary_account!.holder.name,
              taxpayer_id:
                hasApplication.split_beneficiary_account!.holder.taxpayer_id,
              amount: hasApplication.split_beneficiary_account!.amount!,
              amountString:
                amountType === ESplitPaymentAmountType.PERCENT
                  ? `${hasApplication.split_beneficiary_account!.amount}%`
                  : formatCurrency(
                      hasApplication.split_beneficiary_account!.amount,
                    ),
              amount_type: amountType,
              external_bank_account: hasApplication.split_beneficiary_account!
                .external_bank_account
                ? {
                    bank_account:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.bank_account,
                    bank_code:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.bank_code,
                    bank_branch:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.bank_branch,
                    bank_account_digit:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.bank_account_digit,
                    ispb_code:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.ispb_code,
                    bank_account_type:
                      hasApplication.split_beneficiary_account!
                        .external_bank_account!.bank_account_type,
                  }
                : undefined,
              type: ESplitPaymentType.BENEFICIARY,
            },
          ]);
        }
      });

      if (hasApplication.funding?.id) {
        updateSelectedFunding(() => hasApplication.funding!.id!);
      }

      updateSimulationV2Request({
        requestedAmount: hasApplication.requested_amount,
        numPayments: hasApplication.num_payments,
        interestRate: hasApplication.interest_rate,
        financeFee: hasApplication.finance_fee,
        firstPaymentDate: hasApplication.first_payment_date,
        disbursementDate: hasApplication.disbursement_date,
        tacAmount: hasApplication.tac_amount,
      } as TSimulatorV2Request);

      dispatch({
        type: simulatorActionTypes.SET_SIMULATOR_DATA,
        payload: {
          amount: hasApplication.requested_amount,
          financeFee: hasApplication.finance_fee,
          firstPaymentDate: hasApplication.first_payment_date,
          interestRate: hasApplication.interest_rate,
          numPayments: hasApplication.num_payments,
          disbursementDate: hasApplication.disbursement_date,
          tacAmount: hasApplication.tac_amount,
          amountKey: '0',
        },
      });

      return () => {
        dispatch({
          type: simulatorActionTypes.RESET_SIMULATOR_DATA,
        });
      };
    }
  }, [hasApplication]);

  useEffect(() => {
    if (!hasMultiplesFundings && !hasApplication && userInfo) {
      updateSelectedFunding(userInfo.fundings[0]);
    }
  }, [hasMultiplesFundings]);

  const steps = useMemo(() => {
    const stepsList = stepsComponent
      .filter((step) => {
        if (step.title === 'Originador' && !is_issuer_app) return false;
        if (
          step.title === 'Custodiante' &&
          product?.type_product_emission !==
            TypeProductEmission.COMMERCIAL_PAPER
        )
          return false;
        if (
          step.title === 'Split de Pagamento' &&
          (product?.type_product_emission !== TypeProductEmission.CCB ||
            product?.beneficiary_type !== 'SPLIT_DISBURSEMENT')
        )
          return false;
        if (
          step.title === 'Boletos para desembolso' &&
          product?.type_product_emission !== TypeProductEmission.BILLET_PAYMENT
        )
          return false;
        if (
          step.title === 'Pix Copia e Cola' &&
          product?.type_product_emission !== TypeProductEmission.QRCODE_PIX
        )
          return false;
        if (step.title === 'Funding' && !hasMultiplesFundings) return false;
        if (
          step.title === 'Conta Beneficiária' &&
          product?.beneficiary_type !== 'EXTERNAL_REGISTERED_ACCOUNT'
        )
          return false;
        return true;
      })
      .map((step) => {
        if (step.title === 'Valores da Solicitação' && isNewSimulator) {
          return {
            ...step,
            component: <SimulationV2Step />,
          };
        }
        return step;
      });
    return stepsList;
  }, [product, hasMultiplesFundings, isNewSimulator, is_issuer_app]);

  const renderSteps = () => {
    return steps[activeStep].component;
  };

  const simulateWithDifferentDisbursementDate = useCallback(
    async (disbursementDate: string) => {
      const res = await simulate(
        {
          ...(simulationRequest as TSimulatorParametersData),
          disbursementDate,
        },
        tabSimulationIndex as string,
        product?.id,
      );
      const data = res?.data;

      const simulationDataRequest: TPreviewSimulationData = {
        numPeriods: data?.num_periods,
        paymentAmount: data?.payment_amount,
        requested_amount: data?.requested_amount,
        interestRate: data?.interest_rate,
        monthlyEffectiveInterestRate: data?.monthly_effective_interest_rate,
        tacAmount: data?.tac_amount,
        iofAmount: data?.iof_amount,
        iofBaseRate: data?.iof_base_rate,
        firstPaymentDate: data?.first_payment_date,
        lastPaymentDate: data?.last_payment_date,
        totalAmountOwed: data?.total_amount_owed,
        financed_amount: data?.financed_amount,
        disbursement_date: data?.disbursement_date,
        schedule: data?.schedule,
        finance_fee: data?.finance_fee,
        additionalInstallmentFee: simulationRequest?.additionalInstallmentFee
          ? Number(
              formatToCurrencyFloat(simulationRequest.additionalInstallmentFee),
            )
          : null,
        additionalInstallmentDescription:
          simulationRequest?.additionalInstallmentDescription ?? null,
      };
      updateResimulation(simulationDataRequest);
    },
    [simulationRequest, tabSimulationIndex, product],
  );

  return (
    <ApplicationRequestContext.Provider
      value={{
        activeStep,
        onBack,
        onBackTwoSteps,
        onForward,

        hasCustomVariables,
        updateHasCustomVariables,

        borrowerData,
        updateBorrowerData,

        product,
        updateProduct,

        customVariables,
        updateCustomVariables,

        subscribers,
        updateSubscribers,

        borrowerQualification,
        updateBorrowerQualification,

        beneficiaryAccount,
        updateBeneficiaryAccount,

        funding: selectedFunding,
        hasMultiplesFundings: !!hasMultiplesFundings,
        updateFunding: updateSelectedFunding,

        custodian,
        updateCustodian,

        basePremiumAmount,
        simulation,
        simulationRequest,
        updateSimulation: (s, sr, tabIndex) => {
          updateSimulation(s);
          updateSimulationRequest(sr);
          updateTabSimulationIndex(tabIndex);
          updateResimulation(null);
          if (s.financed_amount && selectedFunding) {
            getBasePremiumAmount(s.financed_amount, selectedFunding).then(
              (res) => updateBasePremiumAmount(res.amount),
            );
          }
        },

        simulationV2,
        simulationV2Request,
        updateSimulationV2: (
          simulationResponse,
          simulationRequestData,
          tabIndex,
        ) => {
          updateSimulationV2(simulationResponse);
          updateSimulationV2Request(simulationRequestData);
          updateTabSimulationIndex(tabIndex);
          if (simulationResponse.financed_amount && selectedFunding) {
            getBasePremiumAmount(
              simulationResponse.financed_amount,
              selectedFunding,
            ).then((res) => updateBasePremiumAmount(res.amount));
          }
        },

        resimulation,
        simulateWithDifferentDisbursementDate,

        billetValidationResponse,
        updateBilletValidationResponse,

        qrcodeEmvValidationResponse,
        updateQrcodeEmvValidationResponse,

        isClonning,

        originator: selectedOriginator,
        updateOriginator: (originatorId: string | null) => {
          setSelectedOriginator(originatorId);
        },

        splitPayments,
        updateSplitPayments,

        setCobransaasResponse,
        cobransaasResponse,
      }}
    >
      <ApplicationRequestContainer>
        <ApplicationRequestStepperContainer>
          <Stepper
            activeStep={activeStep}
            stepsItems={steps.map((step, inx) => ({
              step: inx,
              title: step.title,
              clickable: false,
            }))}
          />
        </ApplicationRequestStepperContainer>
        <ApplicationRequestStepContainer>
          {renderSteps()}
        </ApplicationRequestStepContainer>
      </ApplicationRequestContainer>
    </ApplicationRequestContext.Provider>
  );
};

export default ApplicationRequest;
