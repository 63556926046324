import { Divider, useTheme } from '@mui/material';
import { ButtonPrimary, TextL, TextM, TextS } from 'app/components';
import { formatCNPJ, formatCPF, formatPhone } from 'app/helpers';
import { CustomRadioInput } from 'app/components';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import {
  InfoArea,
  SubscribersEditSideSheetBtnArea,
  SubscribersEditSideSheetListItemAction,
  SubscribersEditSideSheetListItemInfo,
} from './styles';
import { ESubscriberType, TSubscriber } from './SubscribersAddNewSideSheet';
import { SubscriberInfoDivider } from './SubscribersStep';
import { TypeProductEmission } from 'modules/products/context/ProductsProvider/products.interfaces';

interface ISubscribersEditSideSheetProps {
  handleClose: () => void;
  isOpen: boolean;
  subscriberItem: Partial<TSubscriber>;
}

const SubscribersEditSideSheet = ({
  isOpen,
  handleClose,
  subscriberItem,
}: ISubscribersEditSideSheetProps) => {
  const theme = useTheme();
  const { subscriber, subscriberPersonType } = subscriberItem;
  const { subscribers, product, updateSubscribers } = useContext(
    ApplicationRequestContext,
  );
  const [subscriberType, setSubscriberType] = useState<ESubscriberType>(
    subscriberItem?.subscriberType || ESubscriberType.COSIGNERS,
  );

  useEffect(() => {
    if (subscriberItem?.subscriberType) {
      setSubscriberType(subscriberItem.subscriberType);
    }
  }, [subscriberItem]);

  const handleRemoveSubscriber = useCallback(() => {
    if (subscriberItem) {
      updateSubscribers(
        subscribers?.filter((sub) => sub.subscriber.id !== subscriber?.id) ||
          null,
      );
      setTimeout(() => handleClose());
    }
  }, [subscribers, subscriber]);

  const handleSaveSubscriber = useCallback(() => {
    if (subscriberItem) {
      updateSubscribers(
        subscribers?.map((sub) => {
          if (sub.subscriber.id === subscriber?.id) {
            return {
              ...sub,
              subscriberType,
            };
          }
          return sub;
        }) || null,
      );
      setTimeout(() => handleClose());
    }
  }, [subscribers, subscriberType, subscriber]);

  return (
    <ProductSideSheet
      open={isOpen}
      isBigger={true}
      handleClose={handleClose}
      title="Adicionar na lista"
    >
      <SubscribersEditSideSheetListItemInfo>
        <TextL weight="semibold">{subscriber?.full_name}</TextL>
        <InfoArea>
          <TextS>
            {subscriberPersonType === 'PERSON'
              ? formatCPF(subscriber?.taxpayer_id || '')
              : formatCNPJ(subscriber?.taxpayer_id || '')}
          </TextS>
          <SubscriberInfoDivider />
          <TextS>
            {formatPhone(
              (subscriber?.phone.area_code || '') + subscriber?.phone.number ||
                '',
            )}
          </TextS>
        </InfoArea>
        <TextS>{subscriber?.email_address}</TextS>
      </SubscribersEditSideSheetListItemInfo>

      <SubscribersEditSideSheetListItemAction>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSubscriberType(ESubscriberType.COSIGNERS)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.COSIGNERS}
              />
              <TextM style={{ marginLeft: '10px' }}>Anuente</TextM>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSubscriberType(ESubscriberType.GUARANTORS)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.GUARANTORS}
              />
              <TextM style={{ marginLeft: '10px' }}>Avalista</TextM>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSubscriberType(ESubscriberType.REPRESENTATIVE)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.REPRESENTATIVE}
              />
              <TextM style={{ marginLeft: '10px' }}>Representante</TextM>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSubscriberType(ESubscriberType.CO_DEBTOR)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.CO_DEBTOR}
              />
              <TextM style={{ marginLeft: '10px' }}>Devedor solidário</TextM>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              margin: '1rem 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setSubscriberType(ESubscriberType.SURETY)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.SURETY}
              />
              <TextM style={{ marginLeft: '10px' }}>Fiador</TextM>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '6rem',
              }}
              onClick={() =>
                setSubscriberType(ESubscriberType.COLLATERAL_PROVIDER)
              }
            >
              <CustomRadioInput
                isChecked={
                  subscriberType === ESubscriberType.COLLATERAL_PROVIDER
                }
              />
              <TextM style={{ marginLeft: '10px' }}>Garantidor</TextM>
            </div>
            {product?.type_product_emission ===
              TypeProductEmission.COMMERCIAL_PAPER && (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => setSubscriberType(ESubscriberType.INVESTORS)}
              >
                <CustomRadioInput
                  isChecked={subscriberType === ESubscriberType.INVESTORS}
                />
                <TextL style={{ marginLeft: '10px' }}>Investidor</TextL>
              </div>
            )}
          </div>
        </div>
        <Divider />
        <div className="buttons-area">
          <ButtonPrimary
            style={{ width: 'fit-content' }}
            typeVariant="ghost"
            onClick={() => {
              handleRemoveSubscriber();
            }}
          >
            <TextM
              weight="Semibold"
              style={{ color: theme.palette.brand.error[900] }}
            >
              Remover Assinante
            </TextM>
          </ButtonPrimary>
        </div>
      </SubscribersEditSideSheetListItemAction>

      <SubscribersEditSideSheetBtnArea>
        <ButtonPrimary
          style={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            marginRight: '15px',
          }}
          typeVariant="outline"
          onClick={handleClose}
        >
          Cancelar
        </ButtonPrimary>
        <ButtonPrimary
          style={{ width: 'fit-content', alignSelf: 'flex-end' }}
          typeVariant="fill"
          onClick={handleSaveSubscriber}
        >
          Salvar
        </ButtonPrimary>
      </SubscribersEditSideSheetBtnArea>
    </ProductSideSheet>
  );
};

export default SubscribersEditSideSheet;
