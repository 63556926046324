import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Heading3, TextL } from '../typography';
import { useTheme } from '@mui/material';
import { TSimulatorV2Response } from 'app/services/simulatorHttpServiceV2';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import {
  PreviewArea,
  PreviewItem,
  PreviewItems,
  ValidationDetails,
  ValidationQuestionMark,
} from './styles';
import { TSimulationValidation } from './types';
import { differenceInDays } from 'date-fns';
import { formatCurrency, normalizeAddDigits } from 'app/utils/normalizer';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';

export type CreditSimulatorPreviewRefs = {
  isValid: boolean;
};

type CreditSimulatorPreviewProps = {
  simulationResponse?: TSimulatorV2Response | null;
  qualification?: TQualification | null;
};

const CreditSimulatorPreview = forwardRef<
  CreditSimulatorPreviewRefs,
  CreditSimulatorPreviewProps
>(({ simulationResponse, qualification }, ref) => {
  const theme = useTheme();

  const [isValidationOpen, toggleValidationOpen] = useState<
    keyof TSimulationValidation | null
  >(null);

  const simulationValidation = useMemo(() => {
    const validation: TSimulationValidation = {
      firstPaymentDate: null,
      interestRate: null,
      numPeriods: null,
      paymentAmount: null,
      requested_amount: null,
      tacAmount: null,
    };
    if (!simulationResponse) {
      return validation;
    }

    if (qualification && qualification.condition) {
      // Valida primeiro dia de pagamento
      if (
        simulationResponse.first_payment_date &&
        qualification.condition.due_day
      ) {
        const day = new Date(simulationResponse.first_payment_date).getDate();
        if (
          qualification.condition.due_day &&
          day + 1 !== qualification.condition.due_day
        ) {
          validation.firstPaymentDate = `A condição exige dia ${qualification.condition.due_day} para pagamento`;
        }
      }

      // Valida carência mínima e máxima
      if (
        simulationResponse.first_payment_date &&
        !validation.firstPaymentDate
      ) {
        const differenceDays = differenceInDays(
          new Date(simulationResponse.first_payment_date),
          new Date(),
        );

        if (
          qualification.condition.min_grace_period &&
          qualification.condition.min_grace_period > differenceDays
        ) {
          validation.firstPaymentDate = `Carência mínima = ${qualification.condition.min_grace_period} dias`;
        }

        if (
          qualification.condition.max_grace_period &&
          qualification.condition.max_grace_period < differenceDays
        ) {
          validation.firstPaymentDate = `${
            validation.firstPaymentDate !== null
              ? `${validation.firstPaymentDate}, `
              : ''
          }Carência máxima = ${qualification.condition.max_grace_period} dias`;
        }
      }

      // Valida quantidade de parcelas
      if (
        typeof simulationResponse.num_periods === 'number' &&
        qualification.condition.min_payment_number &&
        qualification.condition.max_payment_number
      ) {
        if (
          simulationResponse.num_periods <
            qualification.condition.min_payment_number ||
          simulationResponse.num_periods >
            qualification.condition.max_payment_number
        ) {
          validation.numPeriods = `Prazo mínimo = ${qualification.condition.min_payment_number}, Prazo máximo = ${qualification.condition.max_payment_number}`;
        }
      }

      // Valida valor solicitado minimo
      if (
        typeof simulationResponse.requested_amount === 'number' &&
        qualification.condition.min_requested_amount
      ) {
        if (
          simulationResponse.requested_amount <
          qualification.condition.min_requested_amount
        ) {
          validation.requested_amount = `Valor mínimo = ${formatCurrency(
            qualification.condition.min_requested_amount,
          )}`;
        }
      }

      // Valida valor solicitado máximo
      if (
        typeof simulationResponse.requested_amount === 'number' &&
        qualification.condition?.line_of_credit?.credit_limit
      ) {
        if (
          simulationResponse.requested_amount >
          qualification.condition.line_of_credit.credit_limit
        ) {
          validation.requested_amount = `${
            validation.requested_amount !== null
              ? `${validation.requested_amount}, `
              : ''
          }Valor máximo = ${formatCurrency(
            qualification.condition.line_of_credit.credit_limit,
          )}`;
        }
      }

      // Valida valor de parcela
      if (
        typeof simulationResponse.payment_amount === 'number' &&
        qualification.condition?.line_of_credit?.max_payment_amount
      ) {
        if (
          simulationResponse.payment_amount >
          qualification.condition.line_of_credit.max_payment_amount
        ) {
          validation.paymentAmount = `Valor máximo = ${formatCurrency(
            qualification.condition.line_of_credit.max_payment_amount,
          )}`;
        }
      }

      // Valida TC
      if (
        typeof simulationResponse.tac_amount === 'number' &&
        qualification.condition?.tac
      ) {
        if (simulationResponse.tac_amount !== qualification.condition.tac) {
          validation.tacAmount = `TC = ${formatCurrency(
            qualification.condition.tac,
          )}`;
        }
      }

      // Valida taxa de juros
      if (
        qualification.condition.schedule_based_conditions.length > 0 &&
        typeof simulationResponse.interest_rate === 'number' &&
        typeof simulationResponse.num_periods === 'number'
      ) {
        const scheduleBasedCondition =
          qualification.condition.schedule_based_conditions
            .sort((a, b) => (a.upper_limit > b.upper_limit ? 1 : -1))
            .filter(
              (schedule) =>
                (simulationResponse.num_periods as number) <
                schedule.upper_limit,
            );
        if (
          scheduleBasedCondition.length > 0 &&
          scheduleBasedCondition[0].interest_rate !== null &&
          scheduleBasedCondition[0].interest_rate !==
            simulationResponse.interest_rate
        ) {
          validation.interestRate = `Taxa de juros = ${
            scheduleBasedCondition[0].interest_rate * 100
          }%`;
        }
      }
    }
    return validation;
  }, [qualification]);

  useImperativeHandle(
    ref,
    () => ({
      isValid: Object.values(simulationValidation).every(
        (value) => value === null,
      ),
    }),
    [simulationValidation],
  );

  return (
    <PreviewArea>
      <Heading3 style={{ color: '#1E1360' }}>Resultado</Heading3>

      <PreviewItems>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.numPeriods !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            Quant. parcelas
            {simulationValidation.numPeriods !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('numPeriods')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.num_periods &&
            simulationResponse?.num_periods > 0
              ? simulationResponse?.num_periods
              : '-'}
          </TextL>
          {isValidationOpen === 'numPeriods' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.numPeriods}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.paymentAmount !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            Valor da parcela
            {simulationValidation.paymentAmount !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('paymentAmount')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.payment_amount
              ? formatCurrency(simulationResponse?.payment_amount)
              : '-'}
          </TextL>
          {isValidationOpen === 'paymentAmount' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.paymentAmount}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.requested_amount !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            Valor liberado
            {simulationValidation.requested_amount !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('requested_amount')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.requested_amount
              ? formatCurrency(simulationResponse?.requested_amount)
              : '-'}
          </TextL>
          {isValidationOpen === 'requested_amount' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.requested_amount}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            Valor financiado
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.financed_amount
              ? formatCurrency(simulationResponse?.financed_amount)
              : '-'}
          </TextL>
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.interestRate !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            Juros
            {simulationValidation.interestRate !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('interestRate')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.interest_rate
              ? `${normalizeAddDigits(
                  simulationResponse?.interest_rate * 100,
                  2,
                )?.replace('.', ',')}% a.m.`
              : '-'}
          </TextL>
          {isValidationOpen === 'interestRate' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.interestRate}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            CET
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {typeof simulationResponse?.monthly_effective_interest_rate ===
            'number'
              ? `${normalizeAddDigits(
                  simulationResponse?.monthly_effective_interest_rate * 100,
                  2,
                ).replace('.', ',')}% a.m.`
              : '-'}
          </TextL>
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.tacAmount !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            TC
            {simulationValidation.tacAmount !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('tacAmount')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.tac_amount
              ? formatCurrency(simulationResponse?.tac_amount)
              : '-'}
          </TextL>
          {isValidationOpen === 'tacAmount' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.tacAmount}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            IOF
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.iof_amount
              ? formatCurrency(simulationResponse?.iof_amount)
              : '-'}
          </TextL>
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            Outros custos
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.finance_fee
              ? formatCurrency(simulationResponse?.finance_fee)
              : '-'}
          </TextL>
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{
              color:
                simulationValidation.firstPaymentDate !== null
                  ? theme.palette.brand.error[900]
                  : theme.palette.brand.secondary.base,
              display: 'flex',
            }}
          >
            Primeiro vencimento
            {simulationValidation.firstPaymentDate !== null && (
              <ValidationQuestionMark
                onClick={() => toggleValidationOpen('firstPaymentDate')}
              >
                ?
              </ValidationQuestionMark>
            )}
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.first_payment_date
              ? formatDateFromBackend(
                  simulationResponse?.first_payment_date,
                ).toLocaleDateString('pt-br')
              : '-'}
          </TextL>
          {isValidationOpen === 'firstPaymentDate' && (
            <ValidationDetails onClick={() => toggleValidationOpen(null)}>
              {simulationValidation.firstPaymentDate}
            </ValidationDetails>
          )}
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            Último vencimento
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.last_payment_date
              ? formatDateFromBackend(
                  simulationResponse?.last_payment_date,
                ).toLocaleDateString('pt-br')
              : '-'}
          </TextL>
        </PreviewItem>
        <PreviewItem>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            Saldo Total
          </TextL>
          <TextL style={{ color: theme.palette.brand.secondary.base }}>
            {simulationResponse?.total_amount_owed
              ? formatCurrency(simulationResponse?.total_amount_owed)
              : '-'}
          </TextL>
        </PreviewItem>
      </PreviewItems>
    </PreviewArea>
  );
});

CreditSimulatorPreview.displayName = 'CreditSimulatorPreview';
export default CreditSimulatorPreview;
