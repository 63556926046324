import { useState } from 'react';
import { TableContainer, Table, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ButtonDefault, Spinner, TextM, TextS } from 'app/components';
import { ChevronIcon, DeleteIcon } from 'app/components/Icons';
import { formatCPF } from 'app/helpers';
import { CellHeadContent, PersonRelationTableWrapper } from './styles';
import { IPerson } from '../../types';

export interface IRepresentativesTableProps {
  items?: IPerson[];
  removePerson: (person: IPerson) => void;
  handleOrdering?: () => void;
}

const RepresentativesTable = ({
  items,
  removePerson,
  handleOrdering,
}: IRepresentativesTableProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const awaitableOrdering = () => {
    return new Promise((res) => {
      setTimeout(() => {
        handleOrdering?.();
        res(true);
      }, 200);
    });
  };

  const handleClickOrdering = async () => {
    setLoading(true);
    await awaitableOrdering();
    setLoading(false);
  };

  return (
    <PersonRelationTableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>
                <CellHeadContent style={{ gap: '8px' }}>
                  <TextM>Representante</TextM>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleClickOrdering}
                  >
                    <ChevronIcon />
                  </div>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Assina pela empresa?</TextM>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Vínculo</TextM>
                </CellHeadContent>
              </TableCell>
              {/**
               *               <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Part. em quotas</TextM>
                </CellHeadContent>
              </TableCell>
               */}
              <TableCell sx={{ width: '20%', justifyContent: 'center' }}>
                <CellHeadContent style={{ justifyContent: 'center' }}>
                  <TextM>Ação</TextM>
                </CellHeadContent>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading && <Spinner fullscreen />}
          {items &&
            items.length > 0 &&
            items.map((person) => {
              return (
                <TableBody key={person.id}>
                  <TableRow>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM weight="Semibold">{person?.full_name}</TextM>
                        <div style={{ display: 'flex', marginTop: '8px' }}>
                          <TextS>{formatCPF(person?.taxpayer_id || '')}</TextS>
                        </div>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>{person.signer ? 'Sim' : 'Não'}</TextM>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>
                          {person?.workRelation?.label || 'Não especificado'}
                        </TextM>
                      </CellHeadContent>
                    </TableCell>
                    {/** 
                    * <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>
                          {person.share
                            ? person.share + '%'
                            : 'Não especificado'}
                        </TextM>
                      </CellHeadContent>
                    </TableCell>
                    * 
                    */}
                    <TableCell
                      sx={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                      <CellHeadContent
                        style={{
                          padding: '12px 0px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ButtonDefault
                          onClick={() => removePerson(person)}
                          style={{ width: '55px' }}
                        >
                          <DeleteIcon
                            color={theme.palette.brand.primary.base as string}
                          />
                        </ButtonDefault>
                      </CellHeadContent>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
        </Table>
        {!items?.length && (
          <p style={{ padding: '1rem' }}>Não há representantes cadastrados</p>
        )}
      </TableContainer>
    </PersonRelationTableWrapper>
  );
};

export default RepresentativesTable;
