import React, { HTMLAttributes } from 'react';
import { InputErrorWrapper } from './styles';

export interface IInputErrorProps extends HTMLAttributes<HTMLTextAreaElement> {
  children: React.ReactNode;
}

const InputError = ({ children }: IInputErrorProps) => {
  return (
    <InputErrorWrapper className="input-error">{children}</InputErrorWrapper>
  );
};

export default InputError;
