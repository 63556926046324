import { CSSProperties } from 'react';
import { DeleteIcon } from '../Icons';
import { DeleteButtonContainer } from './styles';

export interface IDeleteButtonButton {
  handleClick: () => void;
  style?: CSSProperties;
  disabled?: boolean;
}

const DeleteButton = ({
  handleClick,
  style,
  disabled,
}: IDeleteButtonButton) => {
  return (
    <DeleteButtonContainer
      style={style}
      disabled={disabled}
      onClick={disabled ? undefined : handleClick}
    >
      <DeleteIcon color={disabled ? '#C3C1CA' : '#F50404'} />
    </DeleteButtonContainer>
  );
};

export default DeleteButton;
