import styled from '@emotion/styled';
import { InputLabel } from '@mui/material';

export const CustomInputContainer = styled.div`
  width: 100%;
  height: 100%;
  
  .MuiInputBase-root {
    width: 100%;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
    
    fieldset {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
      border-radius: 10px;
    }
  }

  .MuiInputBase-root:hover {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
  }

  .MuiInputBase-root:focus-within {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.primary.base};
    }
  }

  
`;

export const InputLoading = styled.div`
  width: 20px;
  height: 20px;

  span {
    width: 20px !important;
    height: 20px !important;
    color: ${({ theme }) => theme.palette?.brand?.primary.base} !important;
  }
`;

export const InputLabelContainer = styled(InputLabel)`
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
`;

export const TextFieldArea = styled.div`
  margin-top: 10px;
  position: relative;

  input {
    &::placeholder{
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      color: #C3C1CA;
    }
  }
`;
