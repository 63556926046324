import { useRef } from 'react';
import { ClickAwayListener, Divider, useTheme } from '@mui/material';
import { ChevronDownIcon } from 'app/components/Icons';
import { TextM } from 'app/components/typography';
import {
  IconArea,
  PopoverItem,
  PopoverItemContainer,
  InputDropdownSelectContainer,
} from './styles';
import { TFilterItems } from '../InputDropdown';
import { Popover } from 'app/components/Popover';

export interface IInputDropdownSelectProps {
  handleClose: () => void;
  isOpen: boolean;
  handleSelectFilter: (elementValue: TFilterItems) => void;
  handleOpen: () => void;
  selectedElement?: string | undefined;
  items: TFilterItems[];
}

const InputDropdownSelect = ({
  items,
  isOpen,
  selectedElement,
  handleClose,
  handleSelectFilter,
  handleOpen,
}: IInputDropdownSelectProps) => {
  const theme = useTheme();
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const handleCloseInputDropdownSelect = () => {
    handleClose();
  };

  const handleSelectSearchFilter = (elementValue: TFilterItems) => {
    handleSelectFilter(elementValue);
    handleClose();
  };

  const handleOpenSearchFilter = () => {
    handleOpen();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <InputDropdownSelectContainer
          ref={selectBoxRef}
          onClick={handleOpenSearchFilter}
        >
          <TextM style={{ textOverflow: 'ellipsis', maxWidth: '120px' }}>
            {selectedElement
              ? items.find((i) => i.parameterName === selectedElement)?.name
              : items?.[0].name}
          </TextM>
          <IconArea className="icon-area">
            <ChevronDownIcon
              color={theme.palette.brand.primary.base as string}
            />
          </IconArea>
        </InputDropdownSelectContainer>
        <Popover
          open={isOpen}
          anchorEl={selectBoxRef.current}
          onClose={handleCloseInputDropdownSelect}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper	': {
              marginTop: '26px',
              marginLeft: '-10px',
              minWidth: '160px',
              boxShadow: '0px 18px 25px rgba(0, 21, 33, 0.14)',
              backgroundColor: '#FDFDFD',
              border: '1px solid #EDEDED',
              borderRadius: '20px',
            },
          }}
        >
          <PopoverItemContainer>
            {items.map((item, idx) => (
              <div key={idx}>
                <PopoverItem
                  onClick={() => handleSelectSearchFilter(item)}
                  key={idx}
                >
                  <TextM weight="Medium">{item.name}</TextM>
                </PopoverItem>
                <Divider />
              </div>
            ))}
          </PopoverItemContainer>
        </Popover>
      </>
    </ClickAwayListener>
  );
};

export default InputDropdownSelect;
