import styled from '@emotion/styled';

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
  isHalf?: boolean;
}>`
    width: ${(props) =>
      props.isFull ? '100%' : !props.isHalf ? '50%' : '25%'};
    margin-top: ${(props) => props.marginTop || '0px'};
    padding: 15px;
  `;

export const AboutStepExistingPerson = styled.div`
text-align: left;
border: 1px solid #E6E5EA;
padding: 16px;
border-radius: 10px;
span {
  display: block;
}
.link {
  display: block;
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette?.brand?.primary.base};
  font-weight: bold;
  margin-top: 8px;
}
`;
