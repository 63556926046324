import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Badge,
  BoxSection,
  ButtonPrimary,
  Heading4,
  PdfIcon,
  Spinner,
  TextL,
  TextM,
  TextS,
  TextXs,
} from 'app/components';
import {
  BadgeClockIcon,
  DownloadIcon,
  HyperlinkIcon,
} from 'app/components/Icons';
import { formatCPF, formatPhone } from 'app/helpers';
import { IRequiredSignatures } from '../ApplicationDetailsContent/ApplicationDetailsContent';
import { DocumentArea, SpinnerArea, TableContainer } from './styles';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  EApplicationStatus,
  ESignatureCollectMethod,
  TApplicationResponse,
} from 'modules/products/services/hooks/useApplicationsService';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { TRegisterPersonDataRequest } from 'modules/customer/context';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { Heading3, Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';

export interface IApplicationSignaturesTabProps {
  applicationData: TApplicationResponse;
  updateApplicationData?: (newApplicationValue: TApplicationResponse) => void;
}

const buttonStyle = {
  gap: '6px',
  width: 'fit-content',
};

const tableHeadCells = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="semibold">Pessoa</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="semibold">Função</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="semibold">Telefone</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="semibold">E-mail</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="semibold">Assinado em</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextL weight="semibold">Status</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextL weight="semibold">Copiar link</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const ApplicationSignaturesTab = ({
  applicationData,
  updateApplicationData,
}: IApplicationSignaturesTabProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getPerson, getPersonLoading } = usePersonService();
  const { creatBusinessLoading } = useCustomerService();
  const { getProductLoading } = useProductsService();
  const {
    getApplicationSignatures,
    getApplicationAgreement,
    getSignedApplicationAgreement,
    getApplication,
    getSignedEndorsementFile,
    downloadSignedEndorsementLoading,
    downloadAgreementLoading,
    applicationLoading,
    downloadSignedAgreementLoading,
  } = useApplicationsService();
  const { showSnackbarCopyLink } = useSnackbar();

  const [requiredPersonListParsed, setRequiredPersonListParsed] = useState<
    IRequiredSignatures[] | undefined
  >();
  const [loading, setLoading] = useState(true);

  const handleCopySignatureLink = (
    signatureLink: string | null | undefined,
  ) => {
    navigator.clipboard.writeText(signatureLink || '-');
    showSnackbarCopyLink();
  };

  const getPersonData = async (
    personId: string,
  ): Promise<TRegisterPersonDataRequest | null> => {
    const personDataRes = await getPerson(personId);
    return personDataRes;
  };

  const getFunction = (signId?: string) => {
    if (
      applicationData.co_signers.find((item) => item?.co_signer_id === signId)
    ) {
      return 'Anuente';
    }
    if (
      applicationData.guarantors.find((item) => item?.guarantor_id === signId)
    ) {
      return 'Avalista';
    }
    if (
      applicationData.investors?.find((item) => item?.investor_id === signId)
    ) {
      return 'Investidor';
    }
    if (
      applicationData.co_debtors?.find((item) => {
        return item?.co_debtor_id === signId;
      })
    ) {
      return 'Devedor solidário';
    }
    if (
      applicationData.representatives?.find(
        (item) => item?.representative_id === signId,
      )
    ) {
      return 'Representante';
    }
    if (applicationData.sureties?.find((item) => item?.surety_id === signId)) {
      return 'Fiador';
    }
    if (
      applicationData.collateral_providers?.find(
        (item) => item?.collateral_provider_id === signId,
      )
    ) {
      return 'Garantidor';
    }
    return 'Tomador';
  };

  const handleGetSignatureList = async () => {
    setLoading(true);
    const res = await getApplicationSignatures(applicationData.id).then<
      IRequiredSignatures[] | null
    >(async (signatures) => {
      if (!signatures) return null;

      const parsedSignaturesPromise = signatures.map(async (signature) => {
        return {
          id: signature.person.id,
          function: '',
          type: '',
          signatureData: signature,
          personData: (await getPersonData(signature?.person?.id)) || null,
        };
      });

      const parsedSignaturesResolved = await Promise.all(
        parsedSignaturesPromise,
      );

      const result = parsedSignaturesResolved.map((parsedSign) => {
        if (!parsedSign?.personData) {
          navigate(
            `/products/detail/${applicationData?.product?.id}/applications?page=0`,
          );
        }

        return {
          ...parsedSign,
          function: getFunction(parsedSign?.id),
        };
      });

      return result;
    });
    setLoading(false);
    if (!res) {
      navigate(
        `/products/detail/${applicationData.product.id}/applications?page=0`,
      );
    }
    setRequiredPersonListParsed(res as IRequiredSignatures[]);
  };

  const handleDownloadAgreement = async (getSignedAgreement = false) => {
    if (getSignedAgreement) {
      const signedAgreementFileRes = await getSignedApplicationAgreement(
        applicationData.id,
      );
      if (signedAgreementFileRes) {
        saveAs(
          signedAgreementFileRes.url,
          `signed_ccb_${applicationData.id}.pdf`,
        );
      }
    } else {
      const agreementFileRes = await getApplicationAgreement(
        applicationData.id,
      );
      saveAs(
        new Blob([agreementFileRes], {
          type: 'application/pdf',
        }),
        `original_ccb_${applicationData.id}.pdf`,
      );
    }
  };

  const handleDownloadSignedEndorsement = async () => {
    const endorsementFileRes = await getSignedEndorsementFile(
      applicationData.id,
    );
    if (endorsementFileRes) {
      saveAs(
        new Blob([endorsementFileRes], {
          type: 'application/pdf',
        }),
        `signed_endorsement_${applicationData.id}.pdf`,
      );
    }
  };

  useEffect(() => {
    handleGetSignatureList();
  }, [applicationData]);

  useEffect(() => {
    let applicationUpdateInterval: ReturnType<typeof setInterval> | undefined;
    if (
      applicationData.signature_collect_method ===
        ESignatureCollectMethod.LINK &&
      [
        EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT,
        EApplicationStatus.AGREEMENT_RENDERING,
      ].includes(applicationData.status as EApplicationStatus)
    ) {
      applicationUpdateInterval = setInterval(() => {
        getApplication(applicationData.id).then((application) => {
          if (application && application?.status !== applicationData?.status) {
            updateApplicationData?.(application);
            clearInterval(applicationUpdateInterval);
          }
        });
      }, 5000);
    } else {
      clearInterval(applicationUpdateInterval);
    }

    return () => {
      clearInterval(applicationUpdateInterval);
    };
  }, [applicationData]);

  const isLoading = () => {
    return (
      getPersonLoading ||
      creatBusinessLoading ||
      getProductLoading ||
      applicationLoading ||
      loading
    );
  };

  const tableTextStyle = {
    color: theme.palette.brand.secondary.base,
  };

  const tableBodyContent = requiredPersonListParsed?.map((signature) => {
    return {
      id: signature.id,
      cells: [
        {
          id: '1',
          content: (
            <TableBodyCellContainer>
              <TextS weight="Semibold" style={tableTextStyle}>
                {signature.personData?.full_name}
              </TextS>
              <TextXs>
                {formatCPF(signature?.personData?.taxpayer_id || '')}
              </TextXs>
            </TableBodyCellContainer>
          ),
        },
        {
          id: '2',
          content: (
            <TableBodyCellContainer>
              <TextS style={tableTextStyle}>{signature.function}</TextS>
            </TableBodyCellContainer>
          ),
        },
        {
          id: '3',
          content: (
            <TableBodyCellContainer>
              <TextS style={tableTextStyle}>
                {formatPhone(
                  `${
                    (signature?.personData?.phone?.area_code || '') +
                    (signature?.personData?.phone?.number || '')
                  }`,
                )}
              </TextS>
            </TableBodyCellContainer>
          ),
        },
        {
          id: '4',
          content: (
            <TableBodyCellContainer>
              <TextS style={tableTextStyle}>
                {signature.personData?.email_address}
              </TextS>
            </TableBodyCellContainer>
          ),
        },
        {
          id: '5',
          content: (
            <TableBodyCellContainer>
              {typeof signature?.signatureData?.signed_at === 'string' &&
              signature?.signatureData?.signed_at.length > 0 ? (
                <div style={{ width: '150px', display: 'flex' }}>
                  <TextS style={{ paddingRight: '5px' }}>
                    {`${formatDateFromBackend(
                      signature?.signatureData?.signed_at || '',
                    ).toLocaleDateString('pt-BR')} às
            ${formatStringHourFromBackend(
              signature?.signatureData?.signed_at || '',
            )}`}
                  </TextS>
                </div>
              ) : (
                <>-</>
              )}
            </TableBodyCellContainer>
          ),
        },
        {
          id: '6',
          content: (
            <TableBodyCellContainer>
              <Badge
                typeVariant={
                  signature.signatureData?.signed_at ? 'success' : 'attention'
                }
                icon={
                  signature.signatureData?.signed_at ? undefined : (
                    <BadgeClockIcon />
                  )
                }
              >
                {signature.signatureData?.signed_at ? 'Assinado' : 'Pendente'}
              </Badge>
            </TableBodyCellContainer>
          ),
        },
        {
          id: '7',
          content: (
            <TableBodyCellContainer
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ButtonPrimary
                disabled={
                  typeof signature.signatureData?.collect_sign_link !== 'string'
                }
                typeVariant="outline"
                style={{ width: 'fit-content' }}
                onClick={() =>
                  handleCopySignatureLink(
                    signature.signatureData?.collect_sign_link,
                  )
                }
              >
                <HyperlinkIcon
                  color={theme.palette.brand.primary.base as string}
                />
              </ButtonPrimary>
            </TableBodyCellContainer>
          ),
        },
      ],
    };
  });

  return (
    <BoxSection>
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Lista de assinaturas
      </Heading3>

      {isLoading() ? (
        <SpinnerArea>
          <Spinner />
        </SpinnerArea>
      ) : (
        <>
          <TableContainer>
            {applicationData.status !==
            EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT ? (
              <Table
                tableHeadCell={
                  applicationData.signature_collect_method === 'LINK'
                    ? tableHeadCells
                    : tableHeadCells.filter((item) => item.id !== '6')
                }
                tableBodyContent={
                  applicationData.signature_collect_method === 'LINK'
                    ? tableBodyContent
                    : tableBodyContent?.map((item) => {
                        return {
                          ...item,
                          cells: item.cells.filter((value) => value.id !== '6'),
                        };
                      })
                }
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner />
              </div>
            )}
          </TableContainer>
          <DocumentArea>
            <PdfIcon />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Heading4 style={{ verticalAlign: 'center' }}>
                Contrato Original:{' '}
              </Heading4>
            </div>
            {downloadAgreementLoading ? (
              <Spinner />
            ) : (
              <ButtonPrimary
                typeVariant="outline"
                style={{
                  ...buttonStyle,
                  color: theme.palette.brand.primary.base,
                }}
                onClick={() => handleDownloadAgreement(false)}
              >
                <DownloadIcon
                  color={theme.palette.brand.primary.base as string}
                />
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.primary.base }}
                >
                  Baixar
                </TextM>
              </ButtonPrimary>
            )}
            {/* <ButtonPrimary
              typeVariant="outline"
              style={{ ...buttonStyle }}
              onClick={() => navigator.clipboard.writeText(CCBUrl)}
            >
              <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
              <TextM
                weight="Semibold"
                style={{ color: theme.palette.brand.primary.base }}
              >
                Copiar link
              </TextM>
            </ButtonPrimary> */}
          </DocumentArea>
          {applicationData?.status &&
            ![
              EApplicationStatus.CANCELED,
              EApplicationStatus.AGREEMENT_RENDERING,
              EApplicationStatus.PENDING_SIGNATURE,
              EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT,
              EApplicationStatus.KYC_PROCESSING,
            ].includes(applicationData?.status as EApplicationStatus) && (
              <DocumentArea>
                <PdfIcon />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Heading4 style={{ verticalAlign: 'center' }}>
                    Contrato Assinado:{' '}
                  </Heading4>
                </div>
                {downloadSignedAgreementLoading ? (
                  <Spinner />
                ) : (
                  <ButtonPrimary
                    typeVariant="outline"
                    style={{
                      ...buttonStyle,
                      color: theme.palette.brand.primary.base,
                    }}
                    onClick={() => handleDownloadAgreement(true)}
                  >
                    <DownloadIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Baixar
                    </TextM>
                  </ButtonPrimary>
                )}
              </DocumentArea>
            )}
          {applicationData?.status &&
            [EApplicationStatus.ISSUED].includes(
              applicationData?.status as EApplicationStatus,
            ) && (
              <DocumentArea>
                <PdfIcon />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Heading4 style={{ verticalAlign: 'center' }}>
                    Endosso Assinado:{' '}
                  </Heading4>
                </div>
                {downloadSignedEndorsementLoading ? (
                  <Spinner />
                ) : (
                  <ButtonPrimary
                    typeVariant="outline"
                    style={{
                      ...buttonStyle,
                      color: theme.palette.brand.primary.base,
                    }}
                    onClick={() => handleDownloadSignedEndorsement()}
                  >
                    <DownloadIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Baixar
                    </TextM>
                  </ButtonPrimary>
                )}
              </DocumentArea>
            )}
        </>
      )}
    </BoxSection>
  );
};

export default ApplicationSignaturesTab;
