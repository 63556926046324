import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Heading3, Table, TextL, TextM, TextS } from 'app/components';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { TableArea } from './styles';
import { formatCurrency } from 'app/utils/normalizer';
import { format } from 'date-fns';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { BoxSection, ButtonPrimary } from 'app/components';
import { Tooltip, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { CopyIcon } from 'app/components/Icons';
import { InfoOutlined } from '@mui/icons-material';

export interface IApplicationScheduleTabProps {
  applicationData: TApplicationResponse;
}

const tableHeadCell = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Situação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dt. vencimento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dt. pagamento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor parcela</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valores adicionais</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor pago</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '8',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Amortização</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '9',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Juros</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '10',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dias atraso</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '11',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dias corridos</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '12',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Saldo</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const ApplicationScheduleTab = ({
  applicationData,
}: IApplicationScheduleTabProps) => {
  const theme = useTheme();

  const { showSnackbarCopy } = useSnackbar();

  const baseIof = useMemo(
    () =>
      (applicationData?.tac_amount || 0) +
      (applicationData?.finance_fee || 0) +
      (applicationData?.requested_amount || 0),
    [applicationData],
  );

  const handleCopySchedule = useCallback(() => {
    const hasAdditionalFee =
      typeof applicationData.additional_installment_fee === 'number' &&
      applicationData.additional_installment_fee > 0;
    const header = hasAdditionalFee
      ? 'Parcela\tValor no vencimento\tValores adicionais\tAmortização\tJuros\tIOF\tVencimento\tDias corridos\r\n'
      : 'Parcela\tValor no vencimento\tAmortização\tJuros\tIOF\tVencimento\tDias corridos\r\n';

    const sum_running_iof = applicationData.loan_details?.schedule?.reduce(
      (sum, j) => sum + j.iof,
      0,
    );

    const base_iof_per_installment =
      (applicationData.loan_details?.iof_amount - sum_running_iof) /
      applicationData.num_payments;

    const records = applicationData.loan_details?.schedule
      ?.map((schedule, inx) => {
        const { payment, principal, interest, payment_date, iof, running_day } =
          schedule;
        const finalIof = base_iof_per_installment + iof;
        if (hasAdditionalFee) {
          return `${
            inx + 1
          }\t${payment.toLocaleString()}\t${applicationData.additional_installment_fee?.toLocaleString()}\t${principal.toLocaleString()}\t${interest.toLocaleString()}\t${finalIof}\t${payment_date}\t${running_day}`;
        }
        return `${
          inx + 1
        }\t${payment.toLocaleString()}\t${principal.toLocaleString()}\t${interest.toLocaleString()}\t${finalIof}\t${payment_date}\t${running_day}`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${records}`);
    showSnackbarCopy();
  }, [applicationData, baseIof]);

  return (
    <BoxSection>
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Parcelas
      </Heading3>
      <TableArea>
        {Array.isArray(applicationData?.loan_details?.schedule) &&
          applicationData.loan_details.schedule.length > 0 && (
            <Table
              tableHeadCell={tableHeadCell.filter(
                (cell) =>
                  cell.id !== '6' ||
                  (typeof applicationData.additional_installment_fee ===
                    'number' &&
                    applicationData.additional_installment_fee > 0),
              )}
              tableBodyContent={applicationData.loan_details.schedule.map(
                (item, idx) => {
                  return {
                    id: String(idx),
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {item?.period ? item.period : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {item?.status ? item.status : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.payment_date
                                ? format(
                                    formatDateFromBackend(item?.payment_date),
                                    'dd/MM/yyyy',
                                  )
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.payment_date
                                ? format(
                                    formatDateFromBackend(item?.payment_date),
                                    'dd/MM/yyyy',
                                  )
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.payment
                                ? formatCurrency(item.payment)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              gap: '0.4rem',
                              alignItems: 'center',
                              paddingLeft: '1rem',
                            }}
                          >
                            <TextS>
                              {applicationData.additional_installment_fee &&
                              !isNaN(applicationData.additional_installment_fee)
                                ? formatCurrency(
                                    applicationData.additional_installment_fee,
                                  )
                                : '-'}
                            </TextS>
                            <Tooltip
                              title={
                                <TextM
                                  style={{ color: theme.palette.common.white }}
                                >
                                  {
                                    applicationData.additional_installment_description
                                  }
                                </TextM>
                              }
                            >
                              <InfoOutlined
                                style={{
                                  fontSize: '1rem',
                                  color: theme.palette.brand.gray[600],
                                }}
                              />
                            </Tooltip>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '7',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.present_value &&
                              !isNaN(item?.present_value)
                                ? formatCurrency(item.present_value)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },

                      {
                        id: '8',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.principal
                                ? formatCurrency(item.principal)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '9',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.interest
                                ? formatCurrency(item.interest)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '10',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{item.late_day ? item.late_day : '-'}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '11',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item.running_day ? item.running_day : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '12',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {item?.balance || !isNaN(item?.balance)
                                ? formatCurrency(item.balance)
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                    ].filter(
                      (cell) =>
                        cell.id !== '6' ||
                        (typeof applicationData.additional_installment_fee ===
                          'number' &&
                          applicationData.additional_installment_fee > 0),
                    ),
                  };
                },
              )}
            />
          )}
      </TableArea>
      <ButtonPrimary
        style={{
          backgroundColor: 'transparent',
          width: 'fit-content',
          margin: '15px auto 0px',
        }}
        typeVariant="outline"
        btnIcon={
          <CopyIcon color={theme.palette.brand.primary.base as string} />
        }
        iconPosition="left"
        onClick={handleCopySchedule}
      >
        <TextM
          weight="Semibold"
          style={{
            marginLeft: '11px',
            color: theme.palette.brand.primary.base,
          }}
        >
          Copiar resultado
        </TextM>
      </ButtonPrimary>
    </BoxSection>
  );
};

export default ApplicationScheduleTab;
