import React from 'react';
import { InputDropdownInputContainer } from './styles';
import { EInputType, TFilterItems } from '../InputDropdown';
import { CustomCurrencyInput } from 'app/components/CustomCurrencyInput';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { formatDecimalField } from 'app/helpers';

export interface IInputDropdownInputProps {
  value: string;
  selectedItem: TFilterItems;
  onPressEnter?: () => void;
  handleInputChange: (value: string) => void;
}

const InputDropdownInput = ({
  value,
  selectedItem,
  onPressEnter,
  handleInputChange,
}: IInputDropdownInputProps) => {
  return (
    <InputDropdownInputContainer>
      {(!selectedItem.type || selectedItem.type === EInputType.TEXT) && (
        <input
          onChange={(evt) => handleInputChange(evt.target.value)}
          value={value}
          onKeyDown={(e) =>
            e.key === 'Enter' &&
            typeof onPressEnter === 'function' &&
            onPressEnter()
          }
        />
      )}
      {selectedItem.type === EInputType.NUMBER && (
        <input
          onChange={(evt) =>
            handleInputChange(formatDecimalField(evt.target.value))
          }
          value={value}
          placeholder="100%"
          onKeyDown={(e) =>
            e.key === 'Enter' &&
            typeof onPressEnter === 'function' &&
            onPressEnter()
          }
        />
      )}
      {selectedItem.type === EInputType.CURRENCY && (
        <CustomCurrencyInput
          withLeftSelect={true}
          value={value}
          placeholder="0,00"
          handleInputChange={(evt) =>
            handleInputChange(
              formatBRLCurrencyMaskInput(evt.target.value || ''),
            )
          }
        />
      )}
    </InputDropdownInputContainer>
  );
};

export default InputDropdownInput;
