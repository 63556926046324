import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CreditSimulatorWrapper = styled.div`
  position: relative;
`;

export const CreditSimulatorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .credit-simulator-tabs {
    max-width: 60%;

    .tab-button {
      padding: 8px 16px;
      height: 38px;
      border-radius: 8px !important;

      p {
        font-size: 16px !important;
      }
    }
  }
`;

export const CreditSimulatorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  label, .label {
    font-weight: 400 !important;
    font-family: Inter;
    font-size: 16px;
  }

  .credit-simulator-fields {
    height: 47px !important;

    .MuiInputBase-root {
      height: 47px !important;
    }
    & > div {
      background-color: white;
      input {
        color: black !important;
        font-size: 16px;
      }
    }

    .select-control {
      height: 49px !important;
    }

    .value-container {
      overflow-y: auto;
      max-height: 45px;
    }
  }

  .input-indicator {
    display: none !important;
  }

  .text-field-area {
    margin-top: 7px !important;

    input {
      color: black !important;
      font-size: 16px;
    }

    p, div {
      color: black !important;
    }
  }

  .input-error {
    font-size: 14px;
    font-weight: 400;
    color: #f44336;
  }

  svg {
    fill: #808080 !important;
  }
`;

export const DisbursementDateInput = styled.div<{
  disabled?: boolean;
  isMultiplesDisbursementDate?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: ${({ isMultiplesDisbursementDate }) =>
    isMultiplesDisbursementDate ? '50%' : 'auto'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled, theme }) =>
    disabled &&
    css`
    .MuiInputBase-root {
      color: ${theme.palette?.grey?.[600]};
    }

    .MuiFormLabel-root + div > div > div:first-of-type {
      background-color: ${theme.palette?.grey?.[200]};
    }

    .MuiIconButton-root {
      svg {
        fill: ${theme.palette?.grey?.[600]};
      }
    }
  `};

  small {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.palette?.grey?.[600]};
  }
`;

export const PreviewArea = styled.div`
  border: 1px solid #F7F7F7;
  background-color: #F7F7F7;
  padding: 24px;
  width: 100%;
  border-radius: 20px;
`;

export const PreviewItems = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px 24px;
`;

export const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid #E6E5EA;

  p {
    color: #1E1360 !important;
  }
`;

export const ValidationQuestionMark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.palette?.brand.error[900]};
  font-size: 9px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  cursor: pointer;
`;

export const ValidationDetails = styled.div`
  background-color: #424242;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 35px;
  max-width: 300px;
  right: 20%;
  z-index: 99991;

  &.review {
    width: 450px;
    left: -50% !important;

    &:before {
      left: 40%;
    }
  }

  &:before {
    content: " ";
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid  #424242;
    position: absolute;
    top: -10px;
    right: 40%;
  }
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    content: " ";
    z-index: 9999;
    background-color: transparent;
  }

  ul {
    li {
      white-space: nowrap;
    }
  }
`;

export const NumPaymentContent = styled.div`
`;

export const DisbursementDatesSelector = styled.div`
  max-width: 300px;
  margin-bottom: 1rem;
`;

export const CreditSimulatorScheduleContainer = styled.div`
  .MuiAccordion-root {
    width: 100% !important;
    padding: 0px 8px;
    border: 1px solid #E0E0E0 !important;
    border-radius: 12px !important;
  }

  .MuiAccordionSummary-root {
    min-height: 64px !important;
  }
`;

export const CreditSimulatorFormContainer = styled.div`
  padding: 24px 18px;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
