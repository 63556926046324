import styled from '@emotion/styled';

export const PersonRelationTableWrapper = styled.div`
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  margin-top: 32px;
`;

export const CellHeadContent = styled.div`
  display: flex;
`;

export const SpinnerArea = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.div`
  font-family: Urbanist;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
  vertical-align: middle;
  margin: 2rem 0 1rem 0;
`;
