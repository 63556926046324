import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Divider, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import {
  BaseSidesheet,
  ButtonPrimary,
  CloseIcon,
  Spinner,
} from 'app/components';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { usePersonContext } from 'modules/customer/context';
import {
  EMaritalStatus,
  ESex,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { EPersonType } from 'modules/customer/context/PersonProvider/PersonProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AboutContainer from './About/About';
import AddressStep from './Address/Address';
import IdentificationStep from './Identification/Identification';
import RepresentativesContainer from './Representatives/Representatives';
import UploadDocuments from './UploadDocuments/UploadDocuments';
import UploadBusinessDocument from './UploadDocuments/UploadBusinessDocuments';
import {
  CloseIconWrapper,
  ModalFooter,
  SideSheetHeader,
  StyledButtonWrapper,
  StyledContentWrapper,
  StyledFooter,
  StyledHeader,
  StyledSteps,
} from './styles';
import { IRelativePayload } from 'modules/customer/services/api/interfaces';

interface ICreatePersonModalProps {
  handleClose: () => void;
  isOpen: boolean;
  title?: string;
  hasRole?: boolean;
  successMessage?: string;
}

const CreatePersonModal = ({
  handleClose,
  hasRole,
  isOpen,
  title = 'Cadastro',
  successMessage = 'Cadastro efetuado com sucesso!',
}: ICreatePersonModalProps) => {
  /* Hooks */
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const {
    setPerson,
    formType,
    person,
    createPerson,
    createCompany,
    createdPersonId,
    isLoading,
    personCompany,
    setPersonCompany,
    clearContext,
    createRepresentative,
    representatives,
    createdBusinessId,
    setRepresentatives,
    setCreatedBusinessId,
  } = usePersonContext();

  /* States */
  const [activeStep, setActiveStep] = useState(0);
  const isPerson = formType?.value === EPersonType.PERSON;

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    return;
  };

  const handleBack = () => setActiveStep((prev) => Math.max(prev - 1, 0));

  const closeModalHandler = () => {
    clearContext();
    setRepresentatives([]);
    handleClose();
  };

  const createPersonHandler = useCallback(async () => {
    if (isPerson) {
      const data = person;
      const response = await createPerson({
        birth_date: data?.birth_date,
        email_address: data?.email_address,
        full_name: data?.full_name?.trim() || '',
        nationality: data?.nationality?.trim() || '',
        occupation: data?.occupation?.trim() || '',
        pep:
          data?.pep && data.pep?.value ? data.pep.value === 'true' : undefined,
        marital_status:
          (data?.marital_status?.value as EMaritalStatus) || undefined,
        mothers_name: data?.mothers_name
          ? data?.mothers_name?.trim()
          : undefined,
        sex: data?.sex?.value as ESex,
        phone: {
          area_code: data?.phone
            ? data?.phone.replace(/\D+/g, '').slice(0, 2)
            : '',
          country_code: data?.phone || '55',
          number: data?.phone ? data?.phone.replace(/\D+/g, '').slice(2) : '',
        },
        taxpayer_id: data?.taxpayer_id?.replace(/\D+/g, '') || '',
        address: {
          city: data?.address?.city?.value.trim() || '',
          state_code: data?.address?.state_code?.value || '',
          country_code: '+55',
          district: data?.address?.district?.trim() || '',
          extra_info: data?.address?.extra_info?.trim() || '',
          postal_code: data?.address?.postal_code?.replace(/\D+/g, '') || '',
          street_name: data?.address?.street_name?.trim() || '',
          street_number: data?.address?.street_number?.trim() || '',
        },
        monthly_income: parseFloat(data?.monthly_income || '0'),
        birthplace: data?.birthplace,
      });

      if (response) {
        showSnackbar(successMessage);
        handleNext();
      }
      return;
    }

    const data = personCompany;
    const response = await createCompany({
      address: {
        city: data?.address?.city?.value.trim() || '',
        state_code: data?.address?.state_code?.value || '',
        country_code: '+55',
        district: data?.address?.district?.trim() || '',
        extra_info: data?.address?.extra_info?.trim() || '',
        postal_code: data?.address?.postal_code?.replace(/\D+/g, '') || '',
        street_name: data?.address?.street_name?.trim() || '',
        street_number: data?.address?.street_number?.trim() || '',
      },
      foundation_date: data?.foundation_date,
      email_address: data?.email_address?.trim(),
      legal_name: data?.legal_name?.trim(),
      name: data?.name ? data?.name?.trim() : undefined,
      phone: {
        area_code: data?.phone.replace(/\D+/g, '').slice(0, 2),
        country_code: '55',
        number: data?.phone?.replace(/\D+/g, '').slice(2),
      },
      taxpayer_id: data?.taxpayer_id?.replace(/\D+/g, ''),
      tax_regime: data?.tax_regime?.value || 'SIMPLE',
      qualification_request: data?.hasQualificationRequest
        ? {
            product: {
              id: data?.productId as string,
            },
            funding: {
              id: data?.fundingId as string,
            },
            role: data?.role,
          }
        : undefined,
      share_capital: parseFloat(data?.share_capital || '0'),
      monthly_revenue: parseFloat(data?.monthly_revenue || '0'),
      billing_address: {
        city: data?.address?.city?.value.trim() || '',
        state_code: data?.address?.state_code?.value || '',
        country_code: '+55',
        district: data?.address?.district?.trim() || '',
        extra_info: data?.address?.extra_info?.trim() || '',
        postal_code: data?.address?.postal_code?.replace(/\D+/g, '') || '',
        street_name: data?.address?.street_name?.trim() || '',
        street_number: data?.address?.street_number?.trim() || '',
      },
      incorporation_type:
        data?.incorporation_type?.value &&
        data?.incorporation_type?.value.length > 0
          ? data?.incorporation_type?.value
          : undefined,
      industry_classification: data?.industry_classification || undefined,
    });

    if (response?.id) {
      setCreatedBusinessId(response?.id);

      const parsedRepresentatives: IRelativePayload[] = representatives.map(
        (r) => {
          const rawValue = r?.share || 0;
          const numericValue =
            Number(rawValue.toString().replace(/\D/g, '')) / 100;

          return {
            person: {
              id: r.id || '',
            },
            signer: r.signer?.value === 'Sim' ? true : false,
            type: r.workRelation?.value || '',
            share: numericValue ?? 0,
          };
        },
      );

      await createRepresentative(response?.id, parsedRepresentatives);
      showSnackbar(successMessage);
      handleNext();
      return;
    }

    showSnackbar('Ocorreu um erro. Tente novamente.');
  }, [person, isPerson, personCompany, createdPersonId]);

  const steps = useMemo(() => {
    const baseSteps = [
      {
        label: 'Sobre',
        render: <AboutContainer hasRole={hasRole} />,
        actionButton: 'Avançar',
        icon: <ArrowForwardIosIcon fontSize="small" />,
        nextStep: () => handleNext(),
        isButtonDisabled:
          formType?.value === EPersonType.PERSON
            ? !person.taxpayer_id ||
              !person.full_name ||
              !person.nationality ||
              !person.occupation ||
              !person.birth_date ||
              !person.phone ||
              !person.email_address
            : formType?.value === EPersonType.COMPANY
            ? !personCompany.taxpayer_id ||
              !personCompany.legal_name ||
              !personCompany.phone ||
              !personCompany.email_address ||
              !personCompany.foundation_date
            : !formType?.value,
      },
      {
        label: 'Endereço',
        actionButton: 'Cadastrar',
        nextStep: () => createPersonHandler(),
        icon: <PersonAddAltIcon fontSize="small" />,
        render: (
          <AddressStep
            address={
              formType?.value === EPersonType.COMPANY
                ? personCompany.address
                : person.address
            }
            setAddress={(address) =>
              formType?.value === EPersonType.COMPANY
                ? setPersonCompany({ ...personCompany, address: address })
                : setPerson({ ...person, address: address })
            }
          />
        ),
        isButtonDisabled:
          formType?.value === EPersonType.PERSON
            ? !person.address?.postal_code ||
              !person.address.street_name ||
              !person.address.street_number ||
              !person.address.district ||
              !person.address.city ||
              !person.address?.state_code
            : !personCompany.address?.postal_code ||
              !personCompany.address.street_name ||
              !personCompany.address.street_number ||
              !personCompany.address.district ||
              !personCompany.address.city ||
              !personCompany.address?.state_code,
      },
      {
        label: 'Documentos',
        actionButton: 'Salvar',
        icon: <DoneIcon fontSize="small" />,
        nextStep: () => closeModalHandler(),
        render: isPerson ? (
          <UploadDocuments personId={createdPersonId} hasSuccessMessage />
        ) : (
          <UploadBusinessDocument
            personId={createdBusinessId}
            hasSuccessMessage
          />
        ),
      },
    ];

    if (formType?.value === EPersonType.COMPANY) {
      baseSteps.splice(1, 0, {
        label: 'Representantes',
        render: <RepresentativesContainer />,
        actionButton: 'Avançar',
        nextStep: () => handleNext(),
        icon: <ArrowForwardIosIcon fontSize="small" />,
      });
    } else {
      baseSteps.splice(1, 0, {
        label: 'Identificação',
        actionButton: 'Avançar',
        nextStep: () => handleNext(),
        icon: <ArrowForwardIosIcon fontSize="small" />,
        render: (
          <IdentificationStep
            identification={person.identification}
            setIdentification={(identification) =>
              setPerson({ ...person, identification: identification })
            }
          />
        ),
      });
    }

    return baseSteps;
  }, [
    formType,
    hasRole,
    person,
    personCompany,
    createdBusinessId,
    createdPersonId,
  ]);

  // Info: Valida se está no último Step (Documentos)
  const isLastStep = steps[activeStep]?.label === 'Documentos';

  useEffect(() => {
    setActiveStep(0);
  }, [formType]);

  return (
    <BaseSidesheet hideBackdrop open={isOpen} handleClose={closeModalHandler}>
      <SideSheetHeader>
        <StyledHeader>{title}</StyledHeader>
        <CloseIconWrapper onClick={closeModalHandler}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </SideSheetHeader>
      <Divider />
      <StyledContentWrapper>{steps[activeStep]?.render}</StyledContentWrapper>

      <StyledFooter>
        <StyledSteps>
          <Stepper activeStep={activeStep}>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </StyledSteps>
        <Divider />
        <ModalFooter>
          <StyledButtonWrapper>
            <ButtonPrimary typeVariant="ghost" onClick={closeModalHandler}>
              {isLastStep ? 'Fechar' : 'Cancelar'}
            </ButtonPrimary>
          </StyledButtonWrapper>
          {activeStep > 0 && (
            <StyledButtonWrapper>
              <ButtonPrimary
                onClick={handleBack}
                typeVariant="outline"
                disabled={isLastStep}
              >
                <div style={{ marginRight: '10px' }}>
                  <ArrowBackIosNewIcon fontSize="small" />
                </div>
                Voltar
              </ButtonPrimary>
            </StyledButtonWrapper>
          )}
          <StyledButtonWrapper>
            <ButtonPrimary
              onClick={() => steps[activeStep].nextStep()}
              disabled={steps[activeStep].isButtonDisabled}
            >
              {steps[activeStep].actionButton}
              <div style={{ marginLeft: '10px' }}>{steps[activeStep].icon}</div>
            </ButtonPrimary>
          </StyledButtonWrapper>
        </ModalFooter>
      </StyledFooter>
      {isLoading && <Spinner fullscreen />}
    </BaseSidesheet>
  );
};

export default CreatePersonModal;
