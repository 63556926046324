import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ButtonContainer,
  ButtonFilterContent,
  Content,
  MovementStatementIdCell,
  MovementStatementMovementCell,
  MovementStatementMovementIndicator,
  MovementsStatementButtonsArea,
  ResultCopyArea,
  TableArea,
  TableBodyCellContainer,
  TableHeadCellContainer,
} from './styles';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import {
  TBalanceType,
  TFilterType,
  TMovement,
  TMovementType,
  TMovementStatus,
} from 'modules/disbursement/interfaces/disbursement';
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  formatStringHourFromBackend,
  subtractDays,
} from 'app/helpers/dateHelpers';
import { Spinner, Table, TextL, TextM, TextS } from 'app/components';
import { Badge, ButtonPrimary } from 'app/components';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { FilterMovementsDropdown } from './components/FilterMovementsDropdown';
import { CalendarIcon, CopyIcon } from 'app/components/Icons';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { EmptyContent } from 'modules/products/pages/ProductDetail/components/EmptyContent';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import {
  ArrowCircleDown,
  ArrowCircleUp,
  FilterAlt,
  FilterAltOutlined,
  Settings,
  FileDownload,
  AccountBalanceWallet,
} from '@mui/icons-material';
import { ExternalIcon } from 'app/components/Icons';
import { Link } from 'react-router-dom';
import { ColumnSettings } from './components/ColumnSettings';
import { TTableHeadCell } from 'app/components/Table';
import { TBodyContentCell } from 'app/components';
import { BANK_LIST_OBJECT, isJSON } from 'app/helpers';
import { Column } from './components/ColumnSettings/ColumnSettings';
import { Filters, TFilterItem } from './components/Filters';
import { DownloadReportModal } from './components/DownloadReportModal';
import { OversizedRegistersModal } from './components/OversizedRegistersModal';
import {
  EFilterItemType,
  TFilterResult,
  TFilterValues,
} from './components/Filters/types';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import {
  MovementTypeLabel,
  MovementStatusLabel,
} from 'modules/disbursement/utils/mappers';

const COLUMN_SETTINGS_KEY = 'disbursement_columns_settings';

const BalanceTypeConfig = {
  [TBalanceType.CREDIT]: {
    label: 'Entrada',
    icon: <ArrowCircleDown />,
    bgColor: '#B8F1C1',
    color: '#246B30',
  },
  [TBalanceType.DEBIT]: {
    label: 'Saída',
    icon: <ArrowCircleUp />,
    bgColor: '#F5F5F5',
    color: '#6D6980',
  },
  [TBalanceType.BALANCE]: {
    label: 'Saldo do dia',
    icon: <AccountBalanceWallet />,
    bgColor: '#F5F5F5',
    color: '#6D6980',
  },
};

const MovementsFilters: Array<TFilterItem> = [
  {
    label: 'ID',
    placeholder: 'Digite o ID',
    name: 'id',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Movimento',
    placeholder: 'Movimento',
    name: 'balanceType',
    type: EFilterItemType.MULTI_SELECT,
    options: [
      {
        label: BalanceTypeConfig[TBalanceType.CREDIT].label,
        value: TBalanceType.CREDIT,
      },
      {
        label: BalanceTypeConfig[TBalanceType.DEBIT].label,
        value: TBalanceType.DEBIT,
      },
      {
        label: BalanceTypeConfig[TBalanceType.BALANCE].label,
        value: TBalanceType.BALANCE,
      },
    ],
  },
  {
    label: 'ID Originador',
    placeholder: 'Digite o ID do originador',
    name: 'disbursementOriginatorId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Solicitação',
    placeholder: 'Digite o ID da solicitação',
    name: 'disbursementApplicationId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Produto',
    placeholder: 'Digite o ID do produto',
    name: 'disbursementProductId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Funding',
    placeholder: 'Digite o ID do funding',
    name: 'disbursementFundingId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Nome Tomador',
    placeholder: 'Digite o nome do tomador',
    name: 'disbursementBorrowerName',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'CPF/CNPJ Tomador',
    placeholder: 'Digite o CPF/CNPJ do tomador',
    name: 'disbursementBorrowerTaxpayerId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'ID Tomador',
    placeholder: 'Digite o ID do tomador',
    name: 'disbursementBorrowerId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Nome Recebedor',
    placeholder: 'Digite o nome do recebedor',
    name: 'creditPartyName',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'CPF/CNPJ Recebedor',
    placeholder: 'Digite o CPF/CNPJ do recebedor',
    name: 'creditPartyTaxId',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Pix Recebedor',
    placeholder: 'Digite a chave PIX do recebedor',
    name: 'creditPartyKey',
    type: EFilterItemType.TEXT,
  },
  {
    label: 'Razão',
    placeholder: 'Razão',
    isFull: true,
    name: 'movementType',
    type: EFilterItemType.MULTI_SELECT,
    options: Object.keys(MovementTypeLabel).map((movementType) => ({
      label: MovementTypeLabel[movementType as TMovementType],
      value: movementType,
    })),
  },
  {
    label: 'Status',
    placeholder: 'Status',
    name: 'status',
    type: EFilterItemType.SELECT,
    options: Object.keys(MovementStatusLabel).map((movementStatus) => ({
      label: MovementStatusLabel[movementStatus as TMovementStatus],
      value: movementStatus,
    })),
  },
];

const FilterTypeLabel = {
  [TFilterType.TODAY]: 'Hoje',
  [TFilterType.YESTERDAY]: 'Ontem',
  [TFilterType.LAST_7_DAYS]: 'Últimos 7 dias',
};

const getAccountType = (accountType: string): string => {
  switch (accountType) {
    case 'CACC':
      return 'Conta Corrente';
    case 'TRAN':
      return 'Conta de Pagamentos';
    case 'SLRY':
      return 'Conta Salário';
    case 'SVGS':
      return 'Conta Poupança';
  }
  return accountType;
};

const getPersonType = (personType: string): string => {
  if (personType === 'PERSON') return 'Pessoa';
  if (personType === 'BUSINESS') return 'Empresa';
  return '-';
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Movimento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Status</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data do movimento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Razão</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Transação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Origem</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '8',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Originador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '9',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Credor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '10',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Solicitação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '11',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Produto</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '12',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '13',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">CPF/CNPJ Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '14',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '15',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">CPF/CNPJ Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '16',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '17',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pix Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '18',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Movimento Celcoin</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '19',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Código Transação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '20',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Descricão</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '21',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Emissor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '22',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome Produto</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '23',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '24',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pix Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '25',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Número Conta Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '26',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Agência Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '27',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dígito Conta Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '28',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo Conta Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '29',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">ISPB Tomador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '30',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor solicitado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '31',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor TC</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '32',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor Demais Taxas</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '33',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor IOF</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '34',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data Desembolso</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '35',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor Desembolsado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '36',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor Financiado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '37',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data Solicitação Pagamento</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '38',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Ágio Adicional</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '39',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Ágio Base</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '40',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Número Conta Pagador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '41',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Agência Conta Pagador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '42',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">CPF/CNPJ Pagador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '43',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome Pagador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '44',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo Conta Pagador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '45',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Número Conta Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '46',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Agência Conta Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '47',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo Conta Recebedor</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '48',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Pagamento Original</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '49',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Info Adicional</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '50',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Descrição Estorno</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '51',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">E2E Original</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '52',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Código Transação Original</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '53',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id Original</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '54',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Id de Retorno</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '55',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Sequencial Solicitação</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '56',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">E2E Pix</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '57',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">CPF/CNPJ Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '58',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Nome Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '59',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Chave Pix Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '60',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Conta Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '61',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Agência Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '62',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Dígito Conta Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '63',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Tipo Conta Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '64',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Banco Beneficiário</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const initialColumns = [
  { name: 'Movimento', id: '1', key: 'balanceType' },
  { name: 'Status', id: '2', key: 'status' },
  { name: 'Data do movimento', id: '3', key: 'createDate' },
  { name: 'Razão', id: '4', key: 'movementType' },
  { name: 'Id Transação', id: '5', key: 'id' },
  { name: 'Origem', id: '6', key: 'clientCode' },
  { name: 'Valor', id: '7', key: 'amount' },
  { name: 'Nome Pagador', id: '43', key: 'debitPartyName' },
  { name: 'Nome Recebedor', id: '16', key: 'creditPartyName' },
];

const columnsIdsToRemoveFromReport = ['6'];

const allColumns = [
  {
    name: 'Info Adicional',
    id: '49',
    key: 'additionalInformation',
  },
  {
    name: 'Valor',
    id: '7',
    key: 'amount',
  },
  {
    name: 'Movimento',
    id: '1',
    key: 'balanceType',
  },
  {
    name: 'Código Transação',
    id: '19',
    key: 'clientCode',
  },
  {
    name: 'Origem',
    id: '6',
    key: 'clientCode',
  },
  {
    name: 'Data do movimento',
    id: '3',
    key: 'createDate',
  },
  {
    name: 'Número Conta Recebedor',
    id: '45',
    key: 'creditPartyAccount',
  },
  {
    name: 'Tipo Conta Recebedor',
    id: '47',
    key: 'creditPartyAccountType',
  },
  {
    name: 'Agência Conta Recebedor',
    id: '46',
    key: 'creditPartyBranch',
  },
  {
    name: 'Pix Recebedor',
    id: '17',
    key: 'creditPartyKey',
  },
  {
    name: 'Nome Recebedor',
    id: '16',
    key: 'creditPartyName',
  },
  {
    name: 'CPF/CNPJ Recebedor',
    id: '15',
    key: 'creditPartyTaxId',
  },
  {
    name: 'Número Conta Pagador',
    id: '40',
    key: 'debitPartyAccount',
  },
  {
    name: 'Tipo Conta Pagador',
    id: '44',
    key: 'debitPartyAccountType',
  },
  {
    name: 'Agência Conta Pagador',
    id: '41',
    key: 'debitPartyBranch',
  },
  {
    name: 'Nome Pagador',
    id: '43',
    key: 'debitPartyName',
  },
  {
    name: 'CPF/CNPJ Pagador',
    id: '42',
    key: 'debitPartyTaxId',
  },
  {
    name: 'Descricão',
    id: '20',
    key: 'description',
  },
  {
    name: 'Ágio Adicional',
    id: '38',
    key: 'disbursementAdditionalPremiumAmount',
  },
  {
    name: 'Valor Desembolsado',
    id: '35',
    key: 'disbursementAmount',
  },
  {
    name: 'Id Solicitação',
    id: '10',
    key: 'disbursementApplicationId',
  },
  {
    name: 'Ágio Base',
    id: '39',
    key: 'disbursementBasePremiumAmount',
  },
  {
    name: 'Número Conta Tomador',
    id: '25',
    key: 'disbursementBorrowerBankAccount',
  },
  {
    name: 'Dígito Conta Tomador',
    id: '27',
    key: 'disbursementBorrowerBankAccountDigit',
  },
  {
    name: 'Tipo Conta Tomador',
    id: '28',
    key: 'disbursementBorrowerBankAccountType',
  },
  {
    name: 'Agência Tomador',
    id: '26',
    key: 'disbursementBorrowerBankBranch',
  },
  {
    name: 'ISPB Tomador',
    id: '29',
    key: 'disbursementBorrowerBankIspbCode',
  },
  {
    name: 'Id Tomador',
    id: '12',
    key: 'disbursementBorrowerId',
  },
  {
    name: 'Nome Tomador',
    id: '14',
    key: 'disbursementBorrowerName',
  },
  {
    name: 'Pix Tomador',
    id: '24',
    key: 'disbursementBorrowerPixKey',
  },
  {
    name: 'CPF/CNPJ Tomador',
    id: '13',
    key: 'disbursementBorrowerTaxpayerId',
  },
  {
    name: 'Tipo Tomador',
    id: '23',
    key: 'disbursementBorrowerType',
  },
  {
    name: 'Data Desembolso',
    id: '34',
    key: 'disbursementDate',
  },
  {
    name: 'Valor Demais Taxas',
    id: '32',
    key: 'disbursementFinanceFee',
  },
  {
    name: 'Valor Financiado',
    id: '36',
    key: 'disbursementFinancedAmount',
  },
  {
    name: 'Id Credor',
    id: '9',
    key: 'disbursementFundingId',
  },
  {
    name: 'Valor IOF',
    id: '33',
    key: 'disbursementIofAmount',
  },
  {
    name: 'Id Emissor',
    id: '21',
    key: 'disbursementIssuerId',
  },
  {
    name: 'Id Originador',
    id: '8',
    key: 'disbursementOriginatorId',
  },
  {
    name: 'Id Produto',
    id: '11',
    key: 'disbursementProductId',
  },
  {
    name: 'Nome Produto',
    id: '22',
    key: 'disbursementProductName',
  },
  {
    name: 'Valor solicitado',
    id: '30',
    key: 'disbursementRequestedAmount',
  },
  {
    name: 'Data Solicitação Pagamento',
    id: '37',
    key: 'disbursementRequestedAt',
  },
  {
    name: 'Valor TC',
    id: '31',
    key: 'disbursementTacAmount',
  },
  {
    name: 'Id Transação',
    id: '5',
    key: 'id',
  },
  {
    name: 'Id Movimento Celcoin',
    id: '18',
    key: 'movementId',
  },
  {
    name: 'Razão',
    id: '4',
    key: 'movementType',
  },
  {
    name: 'Código Transação Original',
    id: '52',
    key: 'originalClientCode',
  },
  {
    name: 'E2E Original',
    id: '51',
    key: 'originalEndToEndId',
  },
  {
    name: 'Id Original',
    id: '53',
    key: 'originalId',
  },
  {
    name: 'Id Pagamento Original',
    id: '48',
    key: 'originalPaymentId',
  },
  {
    name: 'Id de Retorno',
    id: '54',
    key: 'returnIdentification',
  },
  {
    name: 'Descrição Estorno',
    id: '50',
    key: 'reversalDescription',
  },
  {
    name: 'Status',
    id: '2',
    key: 'status',
  },
  {
    id: '55',
    name: 'Sequencial Solicitação',
    key: 'disbursementSequentialId',
  },
  {
    id: '56',
    name: 'E2E Pix',
    key: 'endToEndId',
  },
  {
    id: '57',
    name: 'CPF/CNPJ Beneficiário',
    key: 'disbursementBeneficiaryTaxpayerId',
  },
  {
    id: '58',
    name: 'Nome Beneficiário',
    key: 'disbursementBeneficiaryName',
  },
  {
    id: '59',
    name: 'Chave Pix Beneficiário',
    key: 'disbursementBeneficiaryPixKey',
  },
  {
    id: '60',
    name: 'Conta Beneficiário',
    key: 'disbursementBeneficiaryBankAccount',
  },
  {
    id: '61',
    name: 'Agência Beneficiário',
    key: 'disbursementBeneficiaryBankBranch',
  },
  {
    id: '62',
    name: 'Dígito Conta Beneficiário',
    key: 'disbursementBeneficiaryBankAccountDigit',
  },
  {
    id: '63',
    name: 'Tipo Conta Beneficiário',
    key: 'disbursementBeneficiaryBankAccountType',
  },
  {
    id: '64',
    name: 'Banco Beneficiário',
    key: 'disbursementBeneficiaryBankIspbCode',
  },
];

export interface MovementsStatementContentHandler {
  reloadMovement: () => void;
}

interface MovementsStatementContentProps {
  funding?: IFunding;
}

const MovementsStatementContent = forwardRef<
  MovementsStatementContentHandler,
  MovementsStatementContentProps
>(({ funding }: MovementsStatementContentProps, ref) => {
  const [filterType, updateFilterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, TFilterType.TODAY),
  );
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [dateFrom, setDateFrom] = useQueryParam(
    'dateFrom',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [dateTo, setDateTo] = useQueryParam(
    'dateTo',
    withDefault(StringParam, formatDateForBackendString(new Date()) as string),
  );
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 50),
  );
  const [columns, updateColumns] = useState<Array<Column>>([]);
  const [isSettingsOpen, toggleSettingsOpen] = useState(false);
  const [isFiltersOpen, toggleFiltersOpen] = useState(false);
  const [isRequestReportModalOpen, toggleRequestReportModalOpen] =
    useState(false);
  const [isOversizedRegistersModalOpen, toggleIsOversizedRegistersModalOpen] =
    useState(false);

  const [filterValuesQuery, updateFilterValuesQuery] =
    useQueryParam<TFilterValues>(
      'filter',
      withDefault(JsonParam, {} as TFilterValues),
    );
  const [filterValues, updateFilterValues] =
    useState<TFilterValues>(filterValuesQuery);
  const [movementsData, setMovementsData] = useState<TMovement[]>();
  const {
    getMovements,
    getMovementsLoading,
    requestConciliationReport,
    requestConciliationReportLoading,
  } = useDisbursementService();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const filterBtnRef = useRef<HTMLDivElement>(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const { showSnackbarCopy, showSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    const columnSettingsStorage = localStorage.getItem(COLUMN_SETTINGS_KEY);
    if (isJSON(columnSettingsStorage)) {
      const columnSettings: Array<Column> = JSON.parse(
        columnSettingsStorage as string,
      );
      updateColumns(columnSettings);
      return;
    }
    updateColumns(initialColumns);
  }, []);

  const fetchMovements = async () => {
    if (funding) {
      const result = await getMovements({
        id: funding.id,
        page,
        dateFrom,
        dateTo,
        linesPerPage,
        filters: filterValues,
      });
      setMovementsData(result?.movements);
      updateFilterValuesQuery(filterValues);
      setTotalItemsQty((result?.totalPages ?? 0) * linesPerPage);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      reloadMovement: fetchMovements,
    }),
    [funding, page, dateFrom, dateTo, linesPerPage, filterValues],
  );

  const tableHeaderColumns = useMemo<TTableHeadCell[]>(() => {
    return columns
      .map((column) => {
        const tableHeaderColumn = tableHeader.find(
          ({ id }) => id === column.id,
        );
        return tableHeaderColumn || null;
      })
      .filter((column) => column !== null) as TTableHeadCell[];
  }, [columns]);

  const getTableBodyContent = useCallback(
    (contentCell: TBodyContentCell[]) => {
      return columns
        .map((column) => {
          const contentCellColumn = contentCell.find(
            ({ id }) => id === column.id,
          );
          return contentCellColumn || null;
        })
        .filter((column) => column !== null) as TBodyContentCell[];
    },
    [columns],
  );

  useEffect(() => {
    fetchMovements();
  }, [page, dateFrom, dateTo, filterValues, filterType, linesPerPage, funding]);

  const onApplyFilter = (filterResult: TFilterResult) => {
    const filterValuesObject = Object.keys(filterResult).reduce<TFilterValues>(
      (filterValuesObj, filterKey) => {
        if (
          !filterResult[filterKey]?.value ||
          (Array.isArray(filterResult[filterKey]?.value) &&
            (filterResult[filterKey]?.value as string[]).length === 0)
        )
          return filterValuesObj;
        return {
          ...filterValuesObj,
          [filterKey]: filterResult[filterKey]?.value,
        };
      },
      {} as TFilterValues,
    );
    updateFilterValues(() => filterValuesObject);
    toggleFiltersOpen(false);
  };

  const handleDownloadReport = async (email: string) => {
    if (funding) {
      const result = await requestConciliationReport({
        fundingId: funding.id || '',
        filters: filterValues,
        columns: columns
          .filter(({ id }) => !columnsIdsToRemoveFromReport.includes(id))
          .map(({ key }) => key),
        dateFrom,
        dateTo,
        email,
      });

      toggleRequestReportModalOpen(false);
      if (result) {
        showSnackbar('Relatório solicitado com sucesso! Verifique seu e-mail.');
      }
    }
  };

  const handleOpenRequestReportModal = () => {
    toggleIsOversizedRegistersModalOpen(false);
    toggleRequestReportModalOpen(true);
  };

  const handleCopyItems = useCallback(() => {
    if (movementsData && movementsData.length > 100) {
      toggleIsOversizedRegistersModalOpen(true);
      return;
    }

    const header = `${allColumns
      .map(({ id, key }) => (id === '6' ? 'applicationId' : `${key}`))
      .join('\t')}\r\n`;
    const records = movementsData
      ?.map((movement) => {
        return allColumns
          .map(({ id, key }) => {
            if (key === 'balanceType') {
              return `${BalanceTypeConfig[movement.balanceType].label}`;
            }
            if (id === '6') {
              const applicationId = [
                TMovementType.PIXPAYMENTIN,
                TMovementType.TEDTRANSFERIN,
              ].includes(movement.movementType)
                ? '-'
                : movement.clientCode?.slice?.(
                    movement.clientCode.length - 36,
                    movement.clientCode.length,
                  );
              return `${applicationId}`;
            }
            if (
              [
                'createDate',
                'disbursementDate',
                'disbursementRequestedAt',
              ].includes(key)
            ) {
              if (movement[key as keyof typeof movement])
                return `${formatDateFromBackend(
                  movement[key as keyof typeof movement] as string,
                ).toLocaleString('pt-br')}`;
            }
            if (
              [
                'amount',
                'disbursementRequestedAmount',
                'disbursementTacAmount',
                'disbursementFinanceFee',
                'disbursementIofAmount',
                'disbursementAmount',
                'disbursementFinancedAmount',
                'disbursementAdditionalPremiumAmount',
                'disbursementBasePremiumAmount',
              ].includes(key)
            ) {
              if (typeof movement[key as keyof typeof movement] === 'string')
                return `${parseFloat(
                  movement[key as keyof typeof movement] as string,
                ).toLocaleString()}`;
              return '-';
            }

            if (
              [
                'disbursementBorrowerTaxpayerId',
                'creditPartyTaxId',
                'debitPartyTaxId',
              ].includes(key)
            ) {
              if (movement[key as keyof typeof movement])
                return `${normalizeCpfCnpj(
                  movement[key as keyof typeof movement] as string,
                )}`;
            }

            if (movement[key as keyof typeof movement])
              return `${movement[key as keyof typeof movement] as string}`;

            return '-';
          })
          .join('\t');
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${records}`);
    showSnackbarCopy();
  }, [movementsData]);

  const handleCopyId = useCallback((movement: TMovement) => {
    navigator.clipboard.writeText(movement.id);
    showSnackbarCopy();
  }, []);

  const handleApplicationId = useCallback((movement: TMovement) => {
    navigator.clipboard.writeText(movement.disbursementApplicationId);
    showSnackbarCopy();
  }, []);

  return (
    <Content>
      <TextL style={{ fontSize: '24px' }}>Relatório de Conciliação</TextL>
      <ButtonContainer>
        <div ref={filterBtnRef}>
          <ButtonPrimary
            style={{ backgroundColor: 'transparent' }}
            typeVariant="outline"
            onClick={() => setFilterDropdownOpen((state) => !state)}
          >
            <ButtonFilterContent>
              <div className="filter-qty-container">
                <CalendarIcon
                  color={theme.palette.brand.primary.base as string}
                />
                <TextM weight="Medium">Data:</TextM>
                <TextM weight="Medium" className="filter-qty">
                  {filterType !== TFilterType.CUSTOM
                    ? FilterTypeLabel[
                        filterType as keyof typeof FilterTypeLabel
                      ]
                    : `${formatDateFromBackend(dateFrom).toLocaleDateString(
                        'pt-br',
                      )}-${formatDateFromBackend(dateTo).toLocaleDateString(
                        'pt-br',
                      )}`}
                </TextM>
              </div>
              <KeyboardArrowDownOutlinedIcon />
            </ButtonFilterContent>
          </ButtonPrimary>
        </div>

        <FilterMovementsDropdown
          open={filterDropdownOpen}
          refElement={filterBtnRef}
          filterType={filterType as TFilterType}
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleClose={() => setFilterDropdownOpen(false)}
          handleFilter={(from: string, to: string, type: TFilterType) => {
            if (type === TFilterType.TODAY) {
              setDateFrom(formatDateForBackendString(new Date()));
              setDateTo(formatDateForBackendString(new Date()));
            }
            if (type === TFilterType.YESTERDAY) {
              setDateFrom(
                formatDateForBackendString(subtractDays(new Date(), 1)),
              );
              setDateTo(
                formatDateForBackendString(subtractDays(new Date(), 1)),
              );
            }
            if (type === TFilterType.LAST_7_DAYS) {
              setDateFrom(
                formatDateForBackendString(subtractDays(new Date(), 6)),
              );
              setDateTo(formatDateForBackendString(new Date()));
            }
            if (type === TFilterType.CUSTOM) {
              setDateFrom(from);
              setDateTo(to);
            }
            updateFilterType(type);
            setFilterDropdownOpen(false);
          }}
        />

        <MovementsStatementButtonsArea>
          <ButtonPrimary
            onClick={() => toggleFiltersOpen(true)}
            style={{ marginTop: '8px' }}
            typeVariant="ghost"
          >
            {Object.keys(filterValues).length > 0 ? (
              <FilterAlt />
            ) : (
              <FilterAltOutlined />
            )}
          </ButtonPrimary>
          <ButtonPrimary
            onClick={() => toggleSettingsOpen(true)}
            style={{ marginTop: '8px' }}
            typeVariant="ghost"
          >
            <Settings />
          </ButtonPrimary>
          {!!movementsData?.length && (
            <ResultCopyArea>
              <ButtonPrimary
                onClick={() => toggleRequestReportModalOpen(true)}
                typeVariant="ghost"
              >
                <FileDownload />
              </ButtonPrimary>
              <ButtonPrimary
                style={{
                  backgroundColor: 'transparent',
                  maxWidth: '202px',
                  minWidth: '202px',
                }}
                typeVariant="outline"
                iconPosition="left"
                btnIcon={
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                onClick={() => handleCopyItems()}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar resultado
                </TextM>
              </ButtonPrimary>
            </ResultCopyArea>
          )}
        </MovementsStatementButtonsArea>
      </ButtonContainer>
      {getMovementsLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <Spinner />
        </div>
      ) : !getMovementsLoading &&
        (!movementsData || movementsData.length <= 0) ? (
        <EmptyContent titleText="Nenhum movimento registrado" />
      ) : (
        <TableArea>
          <div>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setPage(selectedPage);
                fetchMovements();
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setPage(1);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
              }}
              totalElementsQty={totalItemsQty}
              currentPage={page}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeaderColumns}
              tableBodyContent={movementsData?.map((movement, index) => {
                const { disbursementApplicationId } = movement;

                const applicationId =
                  (![
                    TMovementType.PIXPAYMENTIN,
                    TMovementType.TEDTRANSFERIN,
                  ].includes(movement.movementType) ||
                    movement.isBorrowerRollback) &&
                  disbursementApplicationId &&
                  disbursementApplicationId?.slice?.(
                    disbursementApplicationId.length - 36,
                    disbursementApplicationId.length,
                  );
                return {
                  id: movement?.id || String(index),
                  cells: getTableBodyContent([
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <MovementStatementMovementCell
                            style={{
                              color:
                                BalanceTypeConfig[movement.balanceType]?.color,
                            }}
                          >
                            <MovementStatementMovementIndicator
                              style={{
                                background:
                                  BalanceTypeConfig[movement.balanceType]
                                    ?.bgColor,
                                color:
                                  BalanceTypeConfig[movement.balanceType]
                                    ?.color,
                              }}
                            >
                              {BalanceTypeConfig[movement.balanceType]?.icon ||
                                '-'}
                            </MovementStatementMovementIndicator>
                            {BalanceTypeConfig[movement.balanceType]?.label}
                          </MovementStatementMovementCell>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          {movement.status ? (
                            <Badge
                              style={{
                                background: '#DBD6FE',
                                borderColor: 'transparent',
                              }}
                              typeVariant="info"
                            >
                              {movement.status}
                            </Badge>
                          ) : (
                            '-'
                          )}
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {`${formatDateFromBackend(
                              movement?.createDate || '',
                            ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                movement?.createDate || '',
                              )}`}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          {MovementTypeLabel[movement.movementType] +
                            (movement.isBorrowerRollback === true
                              ? ' - Devolução pelo tomador'
                              : '')}
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer>
                          <MovementStatementIdCell>
                            <ButtonPrimary
                              typeVariant="ghost"
                              style={{
                                padding: '0px',
                                width: 'fit-content',
                                marginRight: '5px',
                              }}
                              onClick={() => handleCopyId(movement)}
                            >
                              <CopyIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                            <TextS
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '200px',
                                overflow: 'hidden',
                              }}
                            >
                              {movement.id}
                            </TextS>
                          </MovementStatementIdCell>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '6',
                      content: (
                        <TableBodyCellContainer>
                          {([
                            TMovementType.PIXPAYMENTIN,
                            TMovementType.TEDTRANSFERIN,
                            TMovementType.ENTRYCREDIT,
                          ].includes(movement.movementType) &&
                            !movement.isBorrowerRollback) ||
                          !applicationId ? (
                            '-'
                          ) : (
                            <Link
                              to={`/products/application/${applicationId}/details`}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: theme.palette.brand.primary
                                  .base as string,
                                textDecoration: 'underline',
                              }}
                            >
                              Ver solicitação
                              <span
                                style={{
                                  paddingLeft: '10px',
                                }}
                              >
                                <ExternalIcon
                                  color={
                                    theme.palette.brand.primary.base as string
                                  }
                                  width={10}
                                  height={10}
                                />
                              </span>
                            </Link>
                          )}
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '7',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            weight={
                              movement.balanceType === TBalanceType.CREDIT
                                ? 'Bold'
                                : 'Regular'
                            }
                            style={{
                              color:
                                movement.balanceType === TBalanceType.CREDIT
                                  ? '#246B30'
                                  : (theme.palette.brand.secondary
                                      .base as string),
                            }}
                          >
                            {movement?.amount
                              ? `${
                                  movement.balanceType === TBalanceType.CREDIT
                                    ? '+'
                                    : movement.balanceType ===
                                      TBalanceType.BALANCE
                                    ? ' '
                                    : '-'
                                } ${formatCurrency(
                                  parseFloat(movement?.amount),
                                )}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '8',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementOriginatorId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '9',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementFundingId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '10',
                      content: (
                        <TableBodyCellContainer>
                          {typeof movement.disbursementApplicationId ===
                          'string' ? (
                            <MovementStatementIdCell>
                              <ButtonPrimary
                                typeVariant="ghost"
                                style={{
                                  padding: '0px',
                                  width: 'fit-content',
                                  marginRight: '5px',
                                }}
                                onClick={() => handleApplicationId(movement)}
                              >
                                <CopyIcon
                                  color={
                                    theme.palette.brand.primary.base as string
                                  }
                                />
                              </ButtonPrimary>
                              <TextS
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  width: '200px',
                                  overflow: 'hidden',
                                }}
                              >
                                {movement.disbursementApplicationId}
                              </TextS>
                            </MovementStatementIdCell>
                          ) : (
                            '-'
                          )}
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '11',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementProductId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '12',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '13',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerTaxpayerId
                              ? normalizeCpfCnpj(
                                  movement.disbursementBorrowerTaxpayerId,
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '14',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerName || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '15',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyTaxId
                              ? normalizeCpfCnpj(movement.creditPartyTaxId)
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '16',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyName || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '17',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyKey || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '18',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.movementId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '19',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.clientCode || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '20',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.description || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '21',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementIssuerId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '22',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementProductName || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '23',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {getPersonType(movement.disbursementBorrowerType)}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '24',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerPixKey || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '25',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerBankAccount || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '26',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerBankBranch || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '27',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerBankAccountDigit ||
                              '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '28',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerBankAccountType
                              ? getAccountType(
                                  movement.disbursementBorrowerBankAccountType,
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '29',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBorrowerBankIspbCode || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '30',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementRequestedAmount ===
                              'string' &&
                            !isNaN(
                              parseFloat(movement.disbursementRequestedAmount),
                            )
                              ? formatCurrency(
                                  parseFloat(
                                    movement.disbursementRequestedAmount,
                                  ),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '31',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementTacAmount ===
                              'string' &&
                            !isNaN(parseFloat(movement.disbursementTacAmount))
                              ? formatCurrency(
                                  parseFloat(movement.disbursementTacAmount),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '32',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementFinanceFee ===
                              'string' &&
                            !isNaN(parseFloat(movement.disbursementFinanceFee))
                              ? formatCurrency(
                                  parseFloat(movement.disbursementFinanceFee),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '33',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementIofAmount ===
                              'string' &&
                            !isNaN(parseFloat(movement.disbursementIofAmount))
                              ? formatCurrency(
                                  parseFloat(movement.disbursementIofAmount),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '34',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement?.disbursementDate
                              ? `${formatDateFromBackend(
                                  movement?.disbursementDate || '',
                                ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                movement?.disbursementDate || '',
                              )}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '35',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementAmount === 'string' &&
                            !isNaN(parseFloat(movement.disbursementAmount))
                              ? formatCurrency(
                                  parseFloat(movement.disbursementAmount),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '36',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementFinancedAmount ===
                              'string' &&
                            !isNaN(
                              parseFloat(movement.disbursementFinancedAmount),
                            )
                              ? formatCurrency(
                                  parseFloat(
                                    movement.disbursementFinancedAmount,
                                  ),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '37',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement?.disbursementRequestedAt
                              ? `${formatDateFromBackend(
                                  movement?.disbursementRequestedAt || '',
                                ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                movement?.disbursementRequestedAt || '',
                              )}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '38',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementAdditionalPremiumAmount ===
                              'string' &&
                            !isNaN(
                              parseFloat(
                                movement.disbursementAdditionalPremiumAmount,
                              ),
                            )
                              ? formatCurrency(
                                  parseFloat(
                                    movement.disbursementAdditionalPremiumAmount,
                                  ),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '39',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {typeof movement.disbursementBasePremiumAmount ===
                              'string' &&
                            !isNaN(
                              parseFloat(
                                movement.disbursementBasePremiumAmount,
                              ),
                            )
                              ? formatCurrency(
                                  parseFloat(
                                    movement.disbursementBasePremiumAmount,
                                  ),
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '40',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.debitPartyAccount || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '41',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.debitPartyBranch || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '42',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.debitPartyTaxId
                              ? normalizeCpfCnpj(movement.debitPartyTaxId)
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '43',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.debitPartyName || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '44',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.debitPartyAccountType
                              ? getAccountType(movement.debitPartyAccountType)
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '45',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyAccount || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '46',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyBranch || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '47',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.creditPartyAccountType
                              ? getAccountType(movement.creditPartyAccountType)
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '48',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.originalPaymentId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '49',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.additionalInformation || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '50',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.reversalDescription || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '51',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.originalEndToEndId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '52',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.originalClientCode || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '53',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.originalId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '54',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.returnIdentification || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '55',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementSequentialId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '56',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.endToEndId || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '57',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryTaxpayerId
                              ? normalizeCpfCnpj(
                                  movement.disbursementBeneficiaryTaxpayerId,
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '58',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryName || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '59',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryPixKey || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '60',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryBankAccount || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '61',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryBankBranch || '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '62',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryBankAccountDigit ||
                              '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '63',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryBankAccountType ||
                              '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '64',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                              overflow: 'hidden',
                            }}
                          >
                            {movement.disbursementBeneficiaryBankIspbCode
                              ? BANK_LIST_OBJECT[
                                  movement.disbursementBeneficiaryBankIspbCode
                                ]
                                ? `${
                                    BANK_LIST_OBJECT[
                                      movement
                                        .disbursementBeneficiaryBankIspbCode
                                    ]
                                  } (${
                                    movement.disbursementBeneficiaryBankIspbCode
                                  })`
                                : movement.disbursementBeneficiaryBankIspbCode
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                  ]),
                };
              })}
            />
          </div>
        </TableArea>
      )}
      <ColumnSettings
        isOpen={isSettingsOpen}
        columns={columns}
        allColumns={allColumns}
        onApply={(_columns) => {
          updateColumns(_columns);
          localStorage.setItem(COLUMN_SETTINGS_KEY, JSON.stringify(_columns));
          toggleSettingsOpen(false);
        }}
        onClose={() => toggleSettingsOpen(false)}
      />
      <Filters
        filters={MovementsFilters}
        isOpen={isFiltersOpen}
        filterValues={filterValues}
        handleFilter={onApplyFilter}
        handleClose={() => toggleFiltersOpen(false)}
      />
      <DownloadReportModal
        isOpen={isRequestReportModalOpen}
        handleClose={() => toggleRequestReportModalOpen(false)}
        handleSubmit={handleDownloadReport}
        loading={requestConciliationReportLoading}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
      <OversizedRegistersModal
        isOpen={isOversizedRegistersModalOpen}
        handleClose={() => toggleIsOversizedRegistersModalOpen(false)}
        handleRequestReport={handleOpenRequestReportModal}
      />
    </Content>
  );
});

MovementsStatementContent.displayName = 'MovementsStatementContent';

export default MovementsStatementContent;
