import { useEffect, useMemo, useRef, useState } from 'react';
import { BoxSection } from 'app/components';
import {
  BalancePageContentWrapper,
  FundingsSelector,
  HeadingArea,
  MovementsStatementButtonsArea,
} from './styles';
import { Heading4, TextL } from 'app/components';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import {
  FundingAccount,
  TBalance,
} from 'modules/disbursement/interfaces/disbursement';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import BankStatementContent from './BankStatementContent';
import MovementsStatementContent, {
  MovementsStatementContentHandler,
} from './MovementsStatementContent';
import { useAuthContext } from 'modules/auth/context';
import { CustomSelect } from 'app/components/CustomSelect';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { useFundingsService } from 'modules/products/services/hooks';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

const BankStatementPageContent = () => {
  const { is_issuer_app } = useAppContext();
  const [fundings, updateFundings] = useState<IFunding[]>([]);
  const [balanceData, setBalanceData] = useState<TBalance>();
  const [fundingAccount, setFundingAccount] = useState<FundingAccount>();
  const movementsStatementContentHandler =
    useRef<MovementsStatementContentHandler>(null);
  const { userInfo } = useAuthContext();
  const { getAccount, getBalance, getBalanceLoading, getAccountLoading } =
    useDisbursementService();
  const {
    originators,
    selectedOriginator,
    getOriginatorFundingsLoading,
    getOriginatorFundings,
    setSelectedOriginator,
  } = useOriginatorContext();
  const { getFundings, getFundingsLoading } = useFundingsService();
  const { id: fundingId } = useParams();
  const navigate = useNavigate();
  const [selectedFundingId, setSelectedFundingId] = useState<string | null>(
    null,
  );

  const [dateFrom] = useQueryParam('dateFrom', withDefault(StringParam, null));
  const [dateTo] = useQueryParam('dateTo', withDefault(StringParam, null));
  const [filterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, null),
  );

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const fetchBalance = async (id: string) => {
    const account = await getAccount(id);
    setFundingAccount(account);
    const result = await getBalance(id);
    setBalanceData(result?.data);
  };

  useEffect(() => {
    setSelectedOriginator(null);
  }, []);

  useEffect(() => {
    if (!is_issuer_app && fundingId) {
      fetchBalance(fundingId);
      if (userInfo) {
        getFundings(userInfo.fundings).then((fundingResponse) => {
          if (fundingResponse) {
            updateFundings(fundingResponse.content);
          }
        });
      }
    }
  }, [fundingId, is_issuer_app]);

  const selectWidth = useMemo(() => {
    if (fundings.length > 0) {
      const largerFundingName = fundings
        .map((funding) => ({
          label: funding.alias
            ? `${funding.legal_name} (${funding.alias})`
            : funding.legal_name,
          value: funding.id,
        }))
        .sort((a, b) => b.label.length - a.label.length)[0];
      return largerFundingName.label.length * 10;
    }
  }, [fundings]);

  const onChangeOriginator = async (originatorId: string) => {
    setSelectedOriginator(originatorId);

    const fundingsFromOriginator = await getOriginatorFundings(originatorId);
    if (fundingsFromOriginator.length > 0) {
      updateFundings(fundingsFromOriginator);
      fetchBalance(fundingsFromOriginator[0].id);
      setSelectedFundingId(fundingsFromOriginator[0].id);
      navigate(`/disbursement/${fundingsFromOriginator[0].id}/statement`);
    } else {
      updateFundings([]);
      setBalanceData(undefined);
      setFundingAccount(undefined);
      setSelectedFundingId(null);
    }
  };

  const selectedFunding = fundings.find(
    (funding) => funding.id === (is_issuer_app ? selectedFundingId : fundingId),
  );
  const selectedOriginatorContent = originators.find(
    (originator) => originator.id === selectedOriginator,
  );

  return (
    <BalancePageContentWrapper>
      <BoxSection boxVariant="rounded">
        <HeadingArea>
          <Heading4 fontWeight="Sb">Extrato</Heading4>
          <MovementsStatementButtonsArea
            style={{ gap: '1rem', marginTop: '1rem' }}
          >
            {is_issuer_app && (
              <FundingsSelector
                style={{
                  width: `${selectWidth}px`,
                  minWidth: '400px',
                }}
              >
                <CustomSelect
                  labelValue="Originador"
                  value={
                    selectedOriginatorContent
                      ? {
                          label: selectedOriginatorContent.legal_name,
                          value: selectedOriginatorContent.id,
                        }
                      : undefined
                  }
                  options={originators.map((originator) => ({
                    label: originator.legal_name,
                    value: originator.id,
                  }))}
                  handleInputChange={(e) => {
                    onChangeOriginator(e.currentTarget.value.value);
                  }}
                  name="originatorId"
                  isClearable={false}
                  placeholder="Selecione o Originador"
                />
              </FundingsSelector>
            )}
            {hasMultiplesFundings || is_issuer_app ? (
              <FundingsSelector
                style={{
                  width: `${selectWidth}px`,
                  minWidth: '400px',
                }}
              >
                <CustomSelect
                  labelValue="Funding"
                  disabled={getOriginatorFundingsLoading || getFundingsLoading}
                  value={
                    selectedFunding
                      ? {
                          label: selectedFunding.alias
                            ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                            : selectedFunding.legal_name,
                          value: selectedFunding.id,
                        }
                      : undefined
                  }
                  options={fundings.map((funding) => ({
                    label: funding.alias
                      ? `${funding.legal_name} (${funding.alias})`
                      : funding.legal_name,
                    value: funding.id,
                  }))}
                  handleInputChange={(e) => {
                    const params: string[] = [];
                    if (dateFrom) params.push(`dateFrom=${dateFrom}`);
                    if (dateTo) params.push(`dateTo=${dateTo}`);
                    if (filterType) params.push(`filterType=${filterType}`);
                    setSelectedFundingId(e.currentTarget.value.value);
                    navigate(
                      `/disbursement/${e.currentTarget.value.value}/statement${
                        params.length > 0 ? `?${params.join('&')}` : ''
                      }`,
                    );
                  }}
                  name="fundingId"
                  isClearable={false}
                  placeholder="Selecione o funding"
                />
              </FundingsSelector>
            ) : (
              <TextL style={{ marginTop: '12px' }}>
                {selectedFunding?.alias
                  ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                  : selectedFunding?.legal_name}
              </TextL>
            )}
          </MovementsStatementButtonsArea>
        </HeadingArea>
        <BankStatementContent
          balanceData={balanceData}
          fetchBalance={fetchBalance}
          fetchMovements={() => {
            movementsStatementContentHandler.current?.reloadMovement();
          }}
          fundingId={
            is_issuer_app
              ? (selectedFundingId as string)
              : (fundingId as string)
          }
          balanceDataLoading={getBalanceLoading}
          selectedFunding={selectedFunding}
          fundingAccount={fundingAccount}
          fundingAccountLoading={getAccountLoading}
        />
        <div style={{ padding: '0px 48px' }}>
          <Divider />
        </div>
        <MovementsStatementContent
          funding={selectedFunding}
          ref={movementsStatementContentHandler}
        />
      </BoxSection>
    </BalancePageContentWrapper>
  );
};

export default BankStatementPageContent;
