import { useState } from 'react';
import { useSnackbar } from './useSnackbar';
import {
  SimulatorV2HttpService,
  TSimulatorV2Request,
  TSimulatorV2Response,
} from 'app/services/simulatorHttpServiceV2';

const useCreditSimulatorV2 = () => {
  const [simulationLoading, setSimulationLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const simulate = async (
    productId: string,
    parameters: TSimulatorV2Request,
  ): Promise<TSimulatorV2Response | null> => {
    try {
      setSimulationLoading(true);
      const simulatorService = new SimulatorV2HttpService();
      const res = await simulatorService.simulate(productId, parameters);
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao simular, tente novamente.');
      setSimulationLoading(false);
      return null;
    } finally {
      setSimulationLoading(false);
    }
  };

  return { simulate, simulationLoading };
};

export default useCreditSimulatorV2;
