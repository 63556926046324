/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { InputLabel } from '@mui/material';
import { Theme } from '@mui/system';

export const CustomSelectStyle = (
  theme: Theme,
  styles?: React.CSSProperties,
  withInput?: boolean,
) => ({
  control: (provided: any) => ({
    ...provided,
    height: '55.99px',
    width: '100%',
    borderRadius: '10px',
    paddingLeft: '3px',
    boxShadow: 'unset !important',
    borderColor: theme.palette?.brand?.gray[300],
    '&:hover': !withInput
      ? {
          outline: 'unset !important',
          boxShadow: 'unset !important',
          borderWidth: '2px',
          borderColor: theme.palette?.brand?.primary.base,
        }
      : {},
    ...(styles ?? {}),
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 99999,
  }),
  menuList: (provided: any) => ({
    ...provided,
    zIndex: 99999,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.15)',
    fontSize: '14px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: theme.palette?.brand?.primary.base,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    color: theme.palette?.brand?.primary.base,
  }),
});

export const CustomSelectContainer = styled.div<{ withInput?: boolean }>`
  width: 100%;
  height: 100%;
  
  .MuiInputBase-root {
    width: 100%;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
    
    fieldset {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
      border-radius: 10px;
    }
  }

  .MuiInputBase-root:hover {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
  }

  .MuiInputBase-root:focus-within {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.primary.base};
    }
  }

  
`;

export const InputLabelContainer = styled(InputLabel)`
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
`;

export const TextFieldArea = styled.div`
  margin-top: 10px;
  position: relative;

  input {
    &::placeholder{
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      color: #C3C1CA;
    }
  }
`;
