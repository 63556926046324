import { useState } from 'react';
import { TableContainer, Paper, Table, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ButtonDefault, TextM, TextS } from 'app/components';
import { ChevronIcon, PencilIcon } from 'app/components/Icons';
import { formatCPF, formatPhone } from 'app/helpers';
import { InfoDivider } from '../InfoDivider';
import {
  CellHeadContent,
  PersonRelationTableWrapper,
  SpinnerArea,
} from './styles';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { translatedWorkRelation } from 'modules/customer/helpers';

export interface IPersonRelationTableProps {
  items?: RelationPerson[];
  selectedPersonToEdit: (selectedPerson: RelationPerson) => void;
  handleOrdering?: () => void;
}

const PersonRelationTable = ({
  items,
  selectedPersonToEdit,
  handleOrdering,
}: IPersonRelationTableProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const awaitableOrdering = () => {
    return new Promise((res) => {
      setTimeout(() => {
        handleOrdering?.();
        res(true);
      }, 200);
    });
  };

  const handleClickOrdering = async () => {
    setLoading(true);
    await awaitableOrdering();
    setLoading(false);
  };

  return (
    <PersonRelationTableWrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>
                <CellHeadContent style={{ gap: '8px' }}>
                  <TextM>Pessoa</TextM>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleClickOrdering}
                  >
                    <ChevronIcon />
                  </div>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Assina pela empresa</TextM>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Vínculo trabalhista</TextM>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <CellHeadContent>
                  <TextM>Participação em quotas (%)</TextM>
                </CellHeadContent>
              </TableCell>
              <TableCell sx={{ width: '20%', justifyContent: 'center' }}>
                <CellHeadContent style={{ justifyContent: 'center' }}>
                  <TextM>Editar</TextM>
                </CellHeadContent>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <SpinnerArea>
              <div></div>
            </SpinnerArea>
          ) : (
            items &&
            items.length > 0 &&
            items.map((addedPerson) => {
              return (
                <TableBody key={addedPerson.id}>
                  <TableRow>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM weight="Semibold">
                          {addedPerson?.full_name}
                        </TextM>
                        <div style={{ display: 'flex', marginTop: '8px' }}>
                          <TextS>{formatCPF(addedPerson?.taxpayer_id)}</TextS>
                          <InfoDivider />
                          <TextS>
                            {formatPhone(
                              `${
                                (addedPerson?.phone?.area_code || '') +
                                (addedPerson?.phone?.number || '')
                              }`,
                            )}
                          </TextS>
                        </div>
                        <TextS style={{ marginTop: '4px' }}>
                          {addedPerson?.email_address}
                        </TextS>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>{addedPerson.signer ? 'Sim' : 'Não'}</TextM>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>
                          {translatedWorkRelation[addedPerson.workRelation] ||
                            'Não especificado'}
                        </TextM>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell>
                      <CellHeadContent
                        style={{ padding: '12px 0px', flexDirection: 'column' }}
                      >
                        <TextM>
                          {addedPerson.share
                            ? addedPerson.share + '%'
                            : 'Não especificado'}
                        </TextM>
                      </CellHeadContent>
                    </TableCell>
                    <TableCell
                      sx={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                      <CellHeadContent
                        style={{
                          padding: '12px 0px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ButtonDefault
                          onClick={() => selectedPersonToEdit(addedPerson)}
                          style={{ width: '55px' }}
                        >
                          <PencilIcon
                            color={theme.palette.brand.primary.base as string}
                          />
                        </ButtonDefault>
                      </CellHeadContent>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          )}
        </Table>
      </TableContainer>
    </PersonRelationTableWrapper>
  );
};

export default PersonRelationTable;
