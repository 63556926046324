import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InputLabel } from '@mui/material';

export const CustomCurrencyInputContainer = styled.div<{
  withSelect?: boolean;
  withLeftSelect?: boolean;
}>`
  width: 100%;
  height: 100%;
  
  .MuiInputBase-root {
    width: 100%;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
    
    fieldset {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
      border-radius: 10px;
      ${({ withSelect }) =>
        withSelect
          ? css`
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border-right: none !important;
            `
          : ''}

      ${({ withLeftSelect }) =>
        withLeftSelect
          ? css`
          border: none !important;
            `
          : ''}
    }
  }

  .MuiInputBase-root:hover {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
  }

  .MuiInputBase-root:focus-within {
    fieldset {
      border-color: ${({ theme, withSelect }) =>
        withSelect
          ? theme.palette?.brand?.gray[300]
          : theme.palette?.brand?.primary.base};
    }
  }

  
`;

export const InputLabelContainer = styled(InputLabel)`
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
`;

export const TextFieldArea = styled.div<{ withLeftSelect?: boolean }>`
  margin-top: ${({ withLeftSelect }) => (withLeftSelect ? '0px' : '10px')};
  position: relative;

  input {
    &::placeholder{
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      color: #C3C1CA;
    }
    padding-left: 35px;
    ${({ withLeftSelect }) =>
      withLeftSelect
        ? css`
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: unset !important;
    `
        : ''}
  }

  p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette?.brand.primary.base}
  }
`;
