import styled from '@emotion/styled';

export const StepContentContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette?.common?.white};
  width: 100%;
  border-radius: 10px;
  padding: 0 50px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const StepContentContent = styled.div`
  margin-top: 20px;
`;
