import { CustomInput, CustomSelect, StepContent } from 'app/components';
import { BRAZILIAN_STATES, formatCep } from 'app/helpers';
import { useAddress } from 'app/hooks';
import { useFormik } from 'formik';
import { addressStepSchema } from 'modules/customer/helpers/businessValidation';
import {
  StepAddressContentInputArea,
  StepContentInputGroup,
} from 'modules/customer/pages/NaturalPersonHandler/steps/styles';
import { useEffect, useState } from 'react';
import { StepContentInput } from '../About/CreatePersonForm/styles';
import { IAddress } from './types';

type TAddressStepProps = {
  address?: IAddress;
  setAddress: (address: IAddress) => void;
  hideTitle?: boolean;
};

const AddressStep = ({ address, setAddress, hideTitle }: TAddressStepProps) => {
  const [citiesList, setCitiesList] = useState<{ value: string }[]>([]);
  const { fetchCities, fetchAddressByPostalCode, isFetchingPostalCode } =
    useAddress();
  const [stateCode, setStateCode] = useState('');
  const [cityManually, setCityManually] = useState(true);

  const formik = useFormik({
    initialValues: address || ({} as IAddress),
    validationSchema: addressStepSchema,
    onSubmit: (values) => console.info(values),
  });

  const handleCities = async (stateCodeFound?: string) => {
    setCityManually(false);
    try {
      const result = await fetchCities(stateCodeFound ?? stateCode);
      if (!result || result.data.status !== '200') {
        setCityManually(true);
        return [];
      }

      const parsedResult = result.data?.body?.city?.map?.(
        (item: { name: string; id: string }) => {
          return {
            value: item.name,
            label: item.name,
          };
        },
      );
      setCitiesList(parsedResult || []);
      return parsedResult;
    } catch (error) {
      setCityManually(true);
    }
  };

  const handleZipode = async (value: string) => {
    if (value.length < 9) return;
    const res = await fetchAddressByPostalCode(value);
    if (res && res.data.status === '200') {
      const values = res.data.body.address;
      const stateCodeFound = BRAZILIAN_STATES.find(
        (state) =>
          state.value.toLocaleLowerCase() === values.state.toLocaleLowerCase(),
      )?.value;
      formik.setFieldValue('district', values.neighborhood);
      formik.setFieldValue('street_name', values.street);
      formik.setFieldValue(
        'state_code',
        BRAZILIAN_STATES.find(
          (state) =>
            state.value.toLocaleLowerCase() ===
            values.state.toLocaleLowerCase(),
        ),
      );

      const resCities = await handleCities(stateCodeFound || '');
      const foundCity = resCities.find(
        (item: { value: string; label: string }) =>
          item.value.toLowerCase() === values.city.toLowerCase(),
      );
      formik.setFieldValue('city', foundCity);
      return;
    }
    setCityManually(true);
  };

  const handleNumber = (numberValue: string) => {
    formik.setFieldValue(
      'street_number',
      numberValue.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
    );
  };

  useEffect(() => {
    if (stateCode) {
      handleCities();
    }
  }, [stateCode]);

  useEffect(() => {
    setAddress({ ...address, ...formik.values });
  }, [formik.values]);

  const content = (
    <StepAddressContentInputArea>
      <StepContentInput>
        <CustomInput
          name="postal_code"
          value={formatCep(formik.values?.postal_code || '')}
          handleInputChange={formik.handleChange}
          isLoading={isFetchingPostalCode}
          placeholder="Ex.: 00000-000"
          onBlur={(evt) => {
            formik.handleBlur(evt);
            if (formik.values.postal_code)
              handleZipode(formik.values.postal_code);
          }}
          labelValue="CEP *"
          validationError={
            formik.touched.postal_code ? formik.errors.postal_code : ''
          }
        />
      </StepContentInput>

      <StepContentInputGroup>
        <StepContentInput>
          <CustomInput
            name="street_name"
            value={formik.values?.street_name || ''}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Av Presidente Vargas"
            labelValue="Rua/Avenida *"
            validationError={
              formik.touched.street_name ? formik.errors.street_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="street_number"
            value={formik.values?.street_number || ''}
            handleInputChange={(e) => handleNumber(e.target.value)}
            placeholder="Ex.: 300"
            labelValue="Número *"
            validationError={
              formik.touched.street_number ? formik.errors.street_number : ''
            }
          />
        </StepContentInput>
      </StepContentInputGroup>
      <StepContentInputGroup>
        <StepContentInput>
          <CustomInput
            name="extra_info"
            value={formik.values.extra_info || ''}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Próximo a escola..."
            labelValue="Complemento (opcional)"
            validationError={
              formik.touched.extra_info ? formik.errors.extra_info : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="district"
            value={formik.values?.district || ''}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Planalto do Sol"
            labelValue="Bairro *"
            validationError={
              formik.touched.district ? formik.errors.district : ''
            }
          />
        </StepContentInput>
      </StepContentInputGroup>
      <StepContentInputGroup>
        <StepContentInput marginTop="15px">
          <CustomSelect
            labelValue="Estado *"
            name="state_code"
            placeholder="Selecione o estado"
            value={
              formik.values.state_code?.value === ''
                ? undefined
                : formik.values.state_code
            }
            handleInputChange={(v) => {
              formik.handleChange(v);
              setStateCode(v.currentTarget.value.value);
            }}
            options={BRAZILIAN_STATES}
            validationError={
              formik.touched.state_code ? formik.errors.state_code : ''
            }
          />
        </StepContentInput>

        <StepContentInput marginTop="15px">
          {cityManually ? (
            <CustomInput
              name="cityManually"
              value={formik.values?.cityManually || ''}
              handleInputChange={(evt) => {
                formik.setFieldValue('cityManually', evt.target.value);
                formik.setFieldValue('city', {
                  label: evt.target.value,
                  value: evt.target.value,
                });
              }}
              placeholder="Cidade"
              labelValue="Cidade *"
              validationError={
                formik.touched.cityManually ? formik.errors.cityManually : ''
              }
            />
          ) : (
            <CustomSelect
              labelValue="Cidade *"
              name="city"
              placeholder="Selecione a cidade"
              value={
                formik.values.city?.value === ''
                  ? undefined
                  : formik.values.city
              }
              handleInputChange={formik.handleChange}
              options={citiesList}
              validationError={formik.touched.city ? formik.errors.city : ''}
            />
          )}
        </StepContentInput>
      </StepContentInputGroup>
      <StepContentInput isFull={true}>
        <CustomInput
          name="references"
          value={formik.values?.references || ''}
          handleInputChange={formik.handleChange}
          placeholder="Digite aqui outras informações"
          labelValue="Referências / Informações adicionais (opcional)"
          validationError={
            formik.touched.references ? formik.errors.references : ''
          }
        />
      </StepContentInput>
    </StepAddressContentInputArea>
  );

  return hideTitle ? (
    content
  ) : (
    <StepContent title="Endereço">{content}</StepContent>
  );
};

export default AddressStep;
