import styled from '@emotion/styled';

export const UploadArea = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const TableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const TableBodyFile = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 18px;
  }
`;

export const TableFilesWrapper = styled.div`
  width: 100%;

  thead {
    th {
      padding: 20px !important;
    }
  }

  tbody {

    tr {
      td {
        padding: 24px !important;
        width: 50%;
        &:first-of-type {
          width: 40% !important;
        }
      }

    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
`;

export const StepContentInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const StylesSuccessMessage = styled.div`
  width: 100%;
  background-color:  ${({ theme }) => theme.palette?.brand.success[300]};
  border-radius: 10px;
  padding: 1rem;
`;
