import styled from '@emotion/styled';

export const SplitPaymentStepContent = styled.div``;

export const SplitPaymentStepHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SplitPaymentStepSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const SplitPaymentStepContainer = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const SplitPaymentStepTableWrapper = styled.div`
  width: 100%;
  margin: 40px 0px auto;
  thead {
    th {
      padding: 20px !important;
    }
  }
  tbody {
    tr {
      td {
        padding: 24px 10px 20px 20px !important;
        width: 20%;
        &:first-of-type {
          width: 65% !important;
        }
        &:last-of-type {
          width: 10% !important;
          text-align: right;
        }
      }
    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
`;

export const SplitPaymentStepTableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  gap: 12px;
`;

export const SplitPaymentListContent = styled.div`
  strong {
    font-weight: 600 !important;
    color: #6F6B82;
  }
`;

export const SplitPaymentsAddNewSideSheetWrapper = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
`;

export const SplitPaymentEditSideSheetListItemInfo = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
`;

export const SplitPaymentsAddNewSideSheetListItemInfo = styled.div`
`;

export const SplitPaymentEditSideSheetBtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .spinner {
    height: 60px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
  }
`;

export const SplitPaymentEditSideSheetListItemAction = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
  margin-top: 16px;
  .switch-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    padding-top: 0px;

    .switch-item {
      display: flex;
      gap: 8px;
    }
  }

  .relationship-area {
    align-items: center;

    .relationship-area-items {
      display: flex;
      gap: 20px;
      padding: 20px 0px 30px;
      .relationship-area-item {
        display: flex;
        gap: 8px;
        cursor: pointer;

        input {
          margin-right: 12px;
        }
      }
    }
  }
  .buttons-area {
    padding: 20px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const SplitPaymentsAddNewSideSheetListItemAction = styled.div`
  margin-top: 24px;
  .switch-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    padding-top: 0px;

    .switch-item {
      display: flex;
      gap: 8px;
    }
  }

  .relationship-area {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .relationship-area-items {
      display: flex;
      gap: 20px;

      .relationship-area-item {
        display: flex;
        gap: 8px;
        cursor: pointer;

        input {
          margin-right: 12px;
        }
      }
    }
  }
  .buttons-area {
    padding: 20px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
  isHalf?: boolean;
}>`
  width: ${(props) => (props.isHalf ? '75%' : props.isFull ? '100%' : '50%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;
