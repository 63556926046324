import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { TMovement } from '../interfaces/disbursement';

export const descriptionMatchers = (movement: TMovement) => [
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-cession-value-funding-issuer',
      ) && movement.balanceType == 'DEBIT',
    description:
      `Devolução de Cessão - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes('transfer-cession-value-funding-issuer') &&
      movement.balanceType == 'DEBIT',
    description: `Pagamento de Cessão - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'DEBIT',
    description:
      `Devolução Solicitação ${movement.disbursementSequentialId || '-'}` +
      (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes(
        'transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'DEBIT',
    description: `Desembolso - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-cession-value-funding-issuer',
      ) && movement.balanceType == 'CREDIT',
    description:
      `Estorno de Cessão - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes('transfer-cession-value-funding-issuer') &&
      movement.balanceType == 'CREDIT',
    description: `Recebimento de Cessão - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'CREDIT',
    description: `Recebimento de Desembolso - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'CREDIT',
    description:
      `Estorno Desembolso - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes('transfer-cession-value-funding-issuer') &&
      movement.balanceType == 'DEBIT',
    description: `Pagamento de Cessão - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-cession-value-funding-issuer',
      ) && movement.balanceType == 'DEBIT',
    description:
      `Devolução de Cessão - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes(
        'transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'DEBIT',
    description: `Desembolso - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'DEBIT',
    description:
      `Devolução Solicitação ${movement.disbursementSequentialId || '-'}` +
      (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes('transfer-cession-value-funding-issuer') &&
      movement.balanceType == 'CREDIT',
    description: `Recebimento de Cessão - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-transfer-cession-value-funding-issuer',
      ) && movement.balanceType == 'CREDIT',
    description:
      `Estorno de Cessão - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher:
      movement.clientCode?.includes(
        'transfer-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'CREDIT',
    description: `Recebimento de Desembolso - Solicitação ${
      movement.disbursementSequentialId || '-'
    }`,
  },
  {
    matcher:
      movement.clientCode?.includes(
        'rollback-disbursement-value-issuer-borrower',
      ) && movement.balanceType == 'CREDIT',
    description:
      `Estorno Desembolso - Solicitação ${
        movement.disbursementSequentialId || '-'
      }` + (movement.isBorrowerRollback ? ' - Devolução pelo tomador' : ''),
  },
  {
    matcher: movement.movementType === 'TEDTRANSFEROUT',
    description: `Transferência TED - ${movement.creditPartyName}`,
  },
  {
    matcher: movement.movementType === 'TEDTRANSFEROUT',
    description: `Transferência TED - ${movement.creditPartyName}`,
  },
  {
    matcher: movement.movementType === 'TEDTRANSFERIN',
    description: `Recebimento TED - ${movement.debitPartyName}`,
  },
  {
    matcher: movement.movementType === 'PIXREVERSALIN',
    description: `${movement.description}`,
  },
  {
    matcher: movement.movementType === 'PIXREVERSALOUT',
    description: `Estorno PIX - ${movement.originalEndToEndId}`,
  },
  {
    matcher: movement.movementType === 'PIXPAYMENTOUT',
    description: `Transferência Pix - ${movement.creditPartyName}`,
  },
  {
    matcher: movement.movementType === 'PIXPAYMENTIN',
    description: `Recebimento Pix - ${movement.debitPartyName}`,
  },
  {
    matcher: movement.movementType === 'BALANCE',
    description: `Saldo do dia - ${formatDateFromBackend(
      movement.createDate,
    ).toLocaleString('pt-br')}`,
  },
];

export const getReportDescription = (movement: TMovement): string => {
  const match = descriptionMatchers(movement).find(
    (matcher) => matcher.matcher,
  );
  return match ? match.description : movement.description || '';
};
