import { useState, useEffect } from 'react';
import { Divider, useTheme } from '@mui/material';
import {
  ButtonDefault,
  ButtonPrimary,
  CloseIcon,
  ConfirmModal,
  Heading3,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import { formatCPF, formatDecimalField, formatPhone } from 'app/helpers';
import { InfoDivider } from '../InfoDivider';
import {
  ButtonArea,
  CloseIconWrapper,
  ContentArea,
  HeaderArea,
  Section,
  SelectedAddInfo,
} from './styles';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import {
  BaseSidesheet,
  CustomInput,
  CustomRadioInput,
  CustomSwitchIOS,
} from 'app/components';
import { useFormik } from 'formik';
import { businessRelationSchema } from 'modules/customer/helpers/businessValidation';
import {
  formatNumberToStringWithComma,
  formatToCurrencyFloat,
} from 'app/utils/normalizer';

export interface IPersonEditSidesheetProps {
  isOpen: boolean;
  handleClose: () => void;
  personData?: RelationPerson;
  removePerson?: (removedPerson?: RelationPerson) => void;
  handleSave?: (removedPerson?: RelationPerson) => void;
}

const PersonEditSidesheet = ({
  isOpen,
  personData,
  handleClose,
  removePerson,
  handleSave,
}: IPersonEditSidesheetProps) => {
  const [isConfirmationRemoveModalOpen, setIsConfirmationRemoveModalOpen] =
    useState(false);
  const [personSelectedToEdit, setPersonSelectedToEdit] =
    useState<RelationPerson>();
  const [signForCompany, setSignForCompany] = useState(
    personData?.signer || false,
  );
  const [workRelation, setWorkRelation] = useState(
    personData?.workRelation || 'OTHER',
  );
  const theme = useTheme();

  const handleCloseModal = () => {
    handleClose();
  };

  const handleOpenConfirmationRemoveModal = () => {
    setIsConfirmationRemoveModalOpen(true);
  };

  const handleRemovePerson = () => {
    removePerson?.(personData);
    setIsConfirmationRemoveModalOpen(false);
    handleCloseModal();
  };

  const closeConfirmationRemoveModal = () => {
    setIsConfirmationRemoveModalOpen(false);
  };

  const setRelationType = (value: string) => {
    setWorkRelation(value);
    setPersonSelectedToEdit((state) => {
      if (state) {
        return {
          ...state,
          workRelation: value,
        };
      }
    });
  };

  const handleSignForCompany = (value: boolean) => {
    setSignForCompany(value);
    setPersonSelectedToEdit((state) => {
      if (state) {
        return {
          ...state,
          signer: value,
        };
      }
    });
  };

  const handleShare = (value: string) => {
    return formatDecimalField(value);
  };

  useEffect(() => {
    if (personData) {
      setPersonSelectedToEdit(personData);
      setSignForCompany(personData.signer);
      setWorkRelation(personData.workRelation);
    }
  }, [personData]);

  const formik = useFormik({
    initialValues: {
      share: personData?.share
        ? formatNumberToStringWithComma(personData?.share)
        : '',
    },
    validationSchema: businessRelationSchema,
    onSubmit: (values) => {
      handleSave?.({
        ...personSelectedToEdit,
        share: values.share
          ? Number(formatToCurrencyFloat(values.share))
          : undefined,
      } as RelationPerson);

      handleClose?.();
    },
  });

  useEffect(() => {
    if (personData && personData.share) {
      formik.setFieldValue(
        'share',
        formatNumberToStringWithComma(personData.share),
      );
    }
  }, [personData]);

  return (
    <>
      <BaseSidesheet open={isOpen} handleClose={handleCloseModal}>
        <HeaderArea>
          <Heading3 fontWeight="Sb">Editar representante</Heading3>
          <CloseIconWrapper onClick={handleCloseModal}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseIconWrapper>
        </HeaderArea>
        <Divider />
        <ContentArea>
          <Section>
            <TextM weight="Semibold">{personSelectedToEdit?.full_name}</TextM>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <TextS>
                {formatCPF(personSelectedToEdit?.taxpayer_id || '-')}
              </TextS>
              <InfoDivider />
              <TextS>
                {formatPhone(
                  `${
                    (personSelectedToEdit?.phone?.area_code || '') +
                    (personSelectedToEdit?.phone?.number || '')
                  }`,
                )}
              </TextS>
            </div>
            <TextS style={{ marginTop: '4px' }}>
              {personSelectedToEdit?.email_address}
            </TextS>
          </Section>
          <Section>
            <SelectedAddInfo>
              <div className="switch-area">
                <TextL weight="medium">Assina pela empresa</TextL>
                <div className="switch-item">
                  {!signForCompany && (
                    <TextL style={{ color: '#9490A3' }}>Não</TextL>
                  )}
                  <CustomSwitchIOS
                    onChange={(evt) => handleSignForCompany(evt.target.checked)}
                    checked={signForCompany}
                  />
                </div>
              </div>
              <Divider />
              <div style={{ marginTop: '20px' }}>
                <TextL weight="medium">Vínculo trabalhista</TextL>
                <div
                  className="relationship-area"
                  style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}
                >
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'EMPLOYEE'}
                      handleClick={() => setRelationType('EMPLOYEE')}
                    />
                    <TextL>Empregado</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PARTNER'}
                      handleClick={() => setRelationType('PARTNER')}
                    />
                    <TextL>Sócio</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'MANAGER'}
                      handleClick={() => setRelationType('MANAGER')}
                    />
                    <TextL>Administrador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PROXY'}
                      handleClick={() => setRelationType('PROXY')}
                    />
                    <TextL>Procurador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'OTHER'}
                      handleClick={() => setRelationType('OTHER')}
                    />
                    <TextL>Não especificado</TextL>
                  </div>
                </div>
              </div>
              <Divider />

              <div style={{ marginTop: '16px', display: 'flex', gap: '12px' }}>
                <TextL weight="medium">Participação em quotas (%)</TextL>
                <CustomInput
                  placeholder="Ex: 50"
                  value={formik.values.share}
                  handleInputChange={(e) => {
                    formik.setFieldValue('share', handleShare(e.target.value));
                  }}
                  validationError={
                    formik.touched.share ? formik.errors.share : ''
                  }
                />
              </div>

              <div className="btn-remove">
                <TextL
                  weight="semibold"
                  style={{ color: theme.palette.brand.error[900] }}
                  onClick={handleOpenConfirmationRemoveModal}
                >
                  Remover representante
                </TextL>
              </div>
            </SelectedAddInfo>
          </Section>
          <ButtonArea>
            <ButtonDefault
              onClick={handleCloseModal}
              style={{ width: '160px' }}
            >
              Cancelar
            </ButtonDefault>
            <ButtonPrimary
              onClick={() => formik.submitForm()}
              style={{ width: '160px' }}
            >
              Salvar
            </ButtonPrimary>
          </ButtonArea>
        </ContentArea>
      </BaseSidesheet>
      <ConfirmModal
        title="Tem certeza?"
        handleClose={closeConfirmationRemoveModal}
        isOpen={isConfirmationRemoveModalOpen}
        handleCancel={closeConfirmationRemoveModal}
        handleConfirm={handleRemovePerson}
      >
        Tem certeza que deseja remover esta pessoa?
      </ConfirmModal>
    </>
  );
};

export default PersonEditSidesheet;
