import {
  CalendarInput,
  CustomInput,
  CustomSelect,
  StepContent,
} from 'app/components';
import { useFormik } from 'formik';
import { documentTypeOptions } from 'modules/customer/context/PersonProvider/person.interfaces';
import { aboutStepSchema } from 'modules/customer/helpers/businessValidation';
import { useEffect } from 'react';
import {
  StepContentInput,
  StepContentInputArea,
} from '../About/CreatePersonForm/styles';
import { IIdentification } from '../types';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

interface IIdentificationStepProps {
  setIdentification: (identification: IIdentification) => void;
  identification?: IIdentification;
  hideTitle?: boolean;
}

const IdentificationStep = ({
  setIdentification,
  identification,
  hideTitle,
}: IIdentificationStepProps) => {
  const formik = useFormik({
    initialValues: identification || ({} as IIdentification),
    validationSchema: aboutStepSchema,
    onSubmit: (values) => {
      console.info(values);
    },
  });

  useEffect(() => {
    setIdentification({ ...identification, ...formik.values });
  }, [formik.values]);

  const content = (
    <StepContentInputArea>
      <StepContentInput>
        <CustomSelect
          labelValue="Tipo de documento"
          name="document_type"
          placeholder="Selecione"
          value={formik.values.document_type}
          handleInputChange={(v) => {
            formik.handleChange(v);
          }}
          options={documentTypeOptions}
          validationError={
            formik.touched.document_type ? formik.errors.document_type : ''
          }
        />
      </StepContentInput>
      <StepContentInput>
        <CustomInput
          name="document_number"
          value={formik.values?.document_number || ''}
          handleInputChange={formik.handleChange}
          placeholder="Ex.: 000.000.000-00"
          labelValue="Número do documento"
          validationError={
            formik.touched.document_number ? formik.errors.document_number : ''
          }
        />
      </StepContentInput>
      <StepContentInput>
        <CustomInput
          name="document_issuer"
          value={formik.values?.document_issuer || ''}
          handleInputChange={formik.handleChange}
          placeholder="Ex.: SSP/SP"
          labelValue="Orgão emissor"
          validationError={
            formik.touched.document_issuer ? formik.errors.document_issuer : ''
          }
        />
      </StepContentInput>
      <StepContentInput>
        <CalendarInput
          name="document_issue_date"
          labelValue="Data de emissão"
          placeholder="dd/mm/aaaa"
          value={formik.values?.document_issue_date || ''}
          onChange={(e) =>
            formik.handleChange({
              currentTarget: {
                value: e,
                name: 'document_issue_date',
              },
            })
          }
          validationError={
            formik.touched.document_issue_date
              ? formik.errors.document_issue_date
              : ''
          }
        />
      </StepContentInput>
    </StepContentInputArea>
  );

  return hideTitle ? (
    content
  ) : (
    <StepContent title="Identificação">{content}</StepContent>
  );
};

export default IdentificationStep;
