import styled from '@emotion/styled';
import { css } from '@emotion/react';

export interface ICustomTableWrapperProps {
  showPagination?: boolean;
  tableHeadSticky?: boolean;
}

export const CustomTableWrapper = styled.div<ICustomTableWrapperProps>`
  .MuiPaper-root {
    border-radius: 18px;
    border: 1px solid #eee;
    box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);


    ${({ showPagination }) =>
      showPagination &&
      css`
        border-bottom: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0px 0px 0px;
    `}

    ${({ tableHeadSticky }) =>
      tableHeadSticky &&
      css`
        table {
          border-collapse: separate;
          border-spacing: 0;
        }
      
        thead {
          th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background-color: white;
          }
        }
  `}
  }

  .MuiTableHead-root {
    th {
      /* padding: 13px 16px; */
      background-color: #FAFAFA !important;
    }

    th:last-of-type {
      border-top-right-radius: 18px;
    }

    th:first-of-type {
      border-top-left-radius: 18px;
    }
  }

  .MuiTableCell-root {
    /* padding: 14px 16px; */
    padding: 0px;

  }

  tfoot {
    td {
      position: sticky;
      bottom: -1px;
      background-color: white;
    }
  }
`;

export const CellHeadContent = styled.div`
  display: flex;
`;

export const CellBodyContent = styled.div``;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-top: 30px;
  border: 1px solid #eee;
  box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border-top: 0px;



  .pagination-lines {
    display: flex;
    align-items: center;
    gap: 10px;

    div {
      max-width: 70px;
      margin: 0;
    }

    .MuiInputBase-root {
      max-width: 70px;
      height: 40px;
    }

  }

  .pagination-btns {
    display: flex;
    align-items: center;

    .MuiPagination-ul {
      flex-wrap: nowrap;

      .MuiButtonBase-root {
        background: transparent;
        border-radius: 10px;

        
        .Mui-selected {
          background-color: ${({ theme }) =>
            theme.palette?.brand.background.base};
          color:${({ theme }) => theme.palette?.brand.primary.base};
        }

        &:hover {
          background-color: ${({ theme }) =>
            theme.palette?.brand.background.base};
          color:${({ theme }) => theme.palette?.brand.primary.base};
        }
      }

      .MuiButtonBase-root.Mui-selected {
          background-color: ${({ theme }) =>
            theme.palette?.brand.background.base};
          color:${({ theme }) => theme.palette?.brand.primary.base};
        } 
    }

    .page-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      padding: 8px 12px;
      border-radius: 10px;
      cursor: pointer;
      
      &:hover {
        background-color: ${({ theme }) =>
          theme.palette?.brand.background.base};

        p {
          color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
        
      }
    }

    .pagination-btns-prev {
      &:hover {
        p {
          color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
      }
    }

    .pagination-btns-next {
      &:hover {
        p {
          color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
