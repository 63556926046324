import styled from '@emotion/styled';

export const InputDropdownSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 150px;
  gap: 4px;
  cursor: pointer;

   p {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
   }

`;

export const IconArea = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopoverItemContainer = styled.div`
  padding: 16px;
`;

export const PopoverItem = styled.div`
  margin: 12px 0px;
  cursor: pointer;

  p:hover {
    color: ${({ theme }) => theme.palette?.brand.primary.base}
  }
`;
