import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { CustomCurrencyInput } from '../CustomCurrencyInput';
import { useFormik } from 'formik';
import {
  formatBRLCurrencyMaskInput,
  getDigitsFromValue,
} from 'app/utils/currency';
import {
  TProducts,
  TypeProductEmission,
} from 'modules/products/context/ProductsProvider/products.interfaces';
import { CustomSelect, TCustomValue } from '../CustomSelect';
import {
  YES_NO,
  INTEREST_TYPES,
  PRE_FIXED_TYPES,
  SIMULATION_TYPES,
  TABS_CONTENT,
  TAC_TYPES,
  INTEREST_GRACE_PERIODS,
  AMORTIZATION_TYPES,
  TABS_KEYS,
  IOF_TYPES,
  SCHEDULE_TYPES_TRANSLATED_EN_PT,
} from './constants';
import {
  getFormValidationSchema,
  getValidDisbursementDate,
  getValueForSelect,
  getValueForSelectMulti,
} from './helpers';
import CreditSimulatorFields from './CreditSimulatorFields';
import { InputDropdown } from '../InputDropdown';
import { EInputType, TFilterItems } from '../InputDropdown/InputDropdown';
import { CustomInput } from '../CustomInput';
import { CalendarInput } from '../CalendarInput';
import { formatDecimalField } from 'app/helpers';
import {
  InterestTypes,
  IofTypes,
  ISimulationResponse,
  PosFixedTypes,
  PreFixedTypes,
  ScheduleTypes,
  SimulationTypes,
  TacValueType,
  YesNoOptions,
} from './types';
import { TSimulatorV2Request } from 'app/services/simulatorHttpServiceV2';
import {
  formatCurrency,
  formatToCurrencyFloat,
  formatToFixed,
} from 'app/utils/normalizer';
import { formatInterestRate } from 'modules/products/helpers/interestRate';
import { format } from 'date-fns';
import { useAppContext } from 'app/context/AppContextProvider';
import { ValidateBilletsResponse } from 'modules/products/interfaces/billets';
import { ValidateEmvResponse } from 'modules/products/interfaces/qrCode';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { CreditSimulatorFormContainer } from './styles';
import { ApplicationsHttpService } from 'modules/products/services/api';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { ApplicationRequestContext } from 'modules/products/pages/ApplicationRequest/ApplicationRequest';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export type CreditSimulatorFormRefs = {
  simulate: () => void;
  reset: () => void;
};

type CreditSimulatorFormProps = {
  tabIndex: string;
  product?: TProducts | null;
  simulationRequest?: TSimulatorV2Request | null;
  billetValidationResponse?: ValidateBilletsResponse | null;
  qrcodeEmvValidationResponse?: ValidateEmvResponse | null;
  onFormSuccess: (data: TSimulatorV2Request) => void;
};

const CreditSimulatorForm = forwardRef<
  CreditSimulatorFormRefs,
  CreditSimulatorFormProps
>(
  (
    {
      tabIndex,
      product,
      simulationRequest,
      billetValidationResponse,
      qrcodeEmvValidationResponse,
      onFormSuccess,
    },
    ref,
  ) => {
    const { feature_flags } = useAppContext();
    const amountKey = TABS_KEYS[Number(tabIndex)];
    const { showSnackbar } = useSnackbar();
    const { setCobransaasResponse } = useContext(ApplicationRequestContext);

    const [referenceRate, setReferenceRate] = useState<TFilterItems[]>([
      {} as TFilterItems,
    ]);
    const [cobransaasList, setCobransaasList] =
      useState<ISimulationResponse[]>();

    const iofTypes = useMemo(() => {
      if (product?.iof_zero)
        return IOF_TYPES.filter((item) => item.value === IofTypes.ZERO);
      if (product?.borrower_type === 'PERSON')
        return IOF_TYPES.filter((item) => item.value === IofTypes.PERSON);
      if (product?.borrower_type === 'BUSINESS')
        return IOF_TYPES.filter((item) =>
          [IofTypes.BUSINESS, IofTypes.BUSINESS_SIMPLE].includes(item.value),
        );
      return IOF_TYPES;
    }, [product]);

    const tacRateTypes = useMemo(() => {
      if (amountKey === 'requestedAmount') return TAC_TYPES;
      return TAC_TYPES.filter(
        (item) => item.parameterName === TacValueType.MONETARY,
      );
    }, [amountKey]);

    const isDiscountRateVisible = useMemo(() => {
      return feature_flags?.DISCOUNT_RATE_ENABLED
        ? feature_flags?.DISCOUNT_RATE_ENABLED?.isActive
        : false;
    }, [feature_flags]);

    const getInitialAmount = () => {
      if (
        product?.type_product_emission === TypeProductEmission.BILLET_PAYMENT
      ) {
        return (
          formatCurrency(billetValidationResponse?.total_value || 0, false) ??
          '0,00'
        );
      }
      if (product?.type_product_emission === TypeProductEmission.QRCODE_PIX) {
        return (
          formatCurrency(qrcodeEmvValidationResponse?.amount || 0, false) ??
          '0,00'
        );
      }
      return '0,00';
    };

    const cleanInitialValues = {
      amount: getInitialAmount(),
      hasAdditionalFee: null,
      simulationType: null,
      interestType: InterestTypes.PRE,
      interestPreType: PreFixedTypes.MONTHLY,
      interestPreRate: '1',
      interestPosType: PosFixedTypes.CDI,
      interestPosRate: '1',
      additionalInstallmentFee: '',
      iofType: iofTypes[0].value,
      scheduleType: product?.schedule_type ?? ScheduleTypes.MONTHLY,
      additionalInstallmentDescription: '',
      tacType: TacValueType.MONETARY,
      tacAmount: '0,00',
      financeFee: '0,00',
      numPayments: '1',
      discountRate: '0',
      firstPaymentDate: new Date(
        new Date().setMonth(new Date().getMonth() + 1),
      ).toISOString(),
      disbursementDate: getValidDisbursementDate(
        product?.multiple_disbursements_dates,
      ),
      hasInterestMinGrace: null,
      interestGracePeriod: null,
      amortizationType: null,
      amortizationGrace: null,
      amortizationGraceMonths: [],
      financialIndicatorCobransaas: undefined,
    };

    const initialValues = useMemo(() => {
      if (simulationRequest) {
        const tacType =
          typeof simulationRequest.tacRate === 'number'
            ? TacValueType.PERCENTAGE
            : TacValueType.MONETARY;
        const amount = simulationRequest[
          amountKey as keyof TSimulatorV2Request
        ] as number | undefined;
        const hasAdditionalFee =
          typeof simulationRequest.additionalInstallmentFee === 'number'
            ? YesNoOptions.YES
            : YesNoOptions.NO;

        return {
          amount: formatCurrency(amount, false),
          hasAdditionalFee,
          simulationType: null,
          interestType: InterestTypes.PRE,
          interestPreType: PreFixedTypes.MONTHLY,
          interestPreRate: String(
            formatToFixed(simulationRequest.interestRate * 100, 2),
          ).replace('.', ','),
          interestPosType: PosFixedTypes.CDI,
          interestPosRate: String(
            formatToFixed(simulationRequest.interestRate * 100, 2),
          ).replace('.', ','),
          additionalInstallmentFee: hasAdditionalFee
            ? formatCurrency(simulationRequest.additionalInstallmentFee!, false)
            : '',
          iofType: simulationRequest.iofType ?? iofTypes[0].value,
          scheduleType: product?.schedule_type ?? ScheduleTypes.MONTHLY,
          additionalInstallmentDescription:
            simulationRequest.additionalInstallmentDescription,
          tacType,
          tacAmount: formatCurrency(simulationRequest.tacAmount, false),
          financeFee: formatCurrency(simulationRequest.financeFee, false),
          numPayments: String(simulationRequest.numPayments),
          discountRate:
            String(
              formatToFixed((simulationRequest.discountRate ?? 0) * 100, 2),
            ).replace('.', ',') || '0',
          firstPaymentDate: formatDateFromBackend(
            simulationRequest.firstPaymentDate as string,
          ).toISOString(),
          disbursementDate: formatDateFromBackend(
            simulationRequest.disbursementDate as string,
          ).toISOString(),
          hasInterestMinGrace: null,
          interestGracePeriod: null,
          amortizationType: null,
          amortizationGrace: null,
          amortizationGraceMonths: [],
          financialIndicatorCobransaas: {} as ISimulationResponse,
        };
      }
      return cleanInitialValues;
    }, [
      simulationRequest,
      product,
      iofTypes,
      billetValidationResponse,
      qrcodeEmvValidationResponse,
    ]);

    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: getFormValidationSchema(
        product?.multiple_disbursements_dates,
      ),
      onSubmit: async (values) => {
        const amount = Number(formatToCurrencyFloat(values.amount!));
        const numPayments = parseInt(values.numPayments);
        const tacAmount =
          values.tacType === TacValueType.MONETARY
            ? Number(formatToCurrencyFloat(values.tacAmount!))
            : undefined;
        const tacRate =
          values.tacType === TacValueType.PERCENTAGE
            ? formatInterestRate(values.tacAmount!)
            : undefined;
        const discountRate = formatInterestRate(values.discountRate) ?? 0;
        const interestRate =
          values.interestType === InterestTypes.PRE
            ? formatInterestRate(values.interestPreRate ?? '0')!
            : formatInterestRate(values.interestPosRate ?? '0')!;
        const financeFee = Number(formatToCurrencyFloat(values.financeFee!));
        const firstPaymentDate = format(
          new Date(values.firstPaymentDate!),
          'yyyy-MM-dd',
        );
        const disbursementDate = format(
          new Date(values.disbursementDate!),
          'yyyy-MM-dd',
        );

        const hasAdditionalFee = values.hasAdditionalFee === YesNoOptions.YES;
        const additionalInstallmentFee =
          values.additionalInstallmentFee && hasAdditionalFee
            ? Number(formatToCurrencyFloat(values.additionalInstallmentFee))
            : null;
        const additionalInstallmentDescription = hasAdditionalFee
          ? values.additionalInstallmentDescription
          : null;
        const iofType = values.iofType!;
        const scheduleType = values.scheduleType as string;
        const financialIndicatorCobransaas =
          values.financialIndicatorCobransaas;

        onFormSuccess({
          [amountKey]: amount,
          numPayments,
          tacAmount,
          tacRate,
          discountRate,
          interestRate,
          financeFee,
          firstPaymentDate,
          disbursementDate,
          additionalInstallmentFee,
          additionalInstallmentDescription,
          iofType,
          scheduleType,
          financialIndicatorCobransaas,
        });

        return true;
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        simulate: () => {
          formik.validateForm().then(() => formik.submitForm());
        },
        reset: () => {
          formik.resetForm({
            values: cleanInitialValues,
          });
        },
      }),
      [formik],
    );

    useEffect(() => {
      if (amountKey !== 'requestedAmount') {
        formik.setFieldValue('tacType', TacValueType.MONETARY);
      }
    }, [amountKey]);

    const handleCurrencyChange = (evt: TReactChangeInput) => {
      formik.setFieldValue(
        evt.target.name,
        formatBRLCurrencyMaskInput(evt.target.value ?? ''),
      );
    };

    const getFinancialIndicatorsHandler = async (): Promise<
      ISimulationResponse[] | undefined
    > => {
      try {
        const applicationService = new ApplicationsHttpService();

        const res = await applicationService.getFinancialIndicators();
        return res.data;
      } catch (error) {
        showSnackbar('Ocorreu um erro. Tente novamente.');
      }
    };

    const getFinancialIndicators = async () => {
      const response: ISimulationResponse[] | undefined =
        await getFinancialIndicatorsHandler();

      if (response) {
        setCobransaasList(response);

        const options = response.map((item) => {
          return {
            name: item.reference_rate,
            parameterName: item.id.toString(),
            type: EInputType.TEXT,
          };
        });

        setReferenceRate(options);
      }
    };

    const handleChangeSelect = async (evt: {
      target: { name: string; value?: TCustomValue };
    }) => {
      // Info: Chama o endpoint com listagem de juros pós fixado
      if (evt.target.value?.value === InterestTypes.POS) {
        getFinancialIndicators();
      }

      formik.setFieldValue(evt.target.name, evt.target.value?.value);
    };

    const handleChangeSelectMulti = (evt: {
      target: { name: string; value?: TCustomValue[] };
    }) => {
      formik.setFieldValue(
        evt.target.name,
        evt.target.value?.map((v) => v.value),
      );
    };

    const handleChangeInputDropdown =
      (fieldName: string) => (value: string) => {
        formik.setFieldValue(fieldName, value);
      };

    const handleChangeInputFilterDropdown = useCallback(
      (fieldName: string) => (value: TFilterItems) => {
        if (fieldName === 'interestPosType') {
          setCobransaasResponse(
            cobransaasList?.find(
              (item) => item.id.toString() === value.parameterName,
            ),
          );

          formik.setFieldValue(
            'financialIndicatorCobransaas',
            cobransaasList?.find(
              (item) => item.id.toString() === value.parameterName,
            ),
          );
        }

        formik.setFieldValue(fieldName, value.parameterName);
      },
      [cobransaasList],
    );

    const handleChangeNumber = (evt: TReactChangeInput) => {
      formik.setFieldValue(
        evt.target.name,
        getDigitsFromValue(evt.target.value),
      );
    };

    const handleChangeDatePicker =
      (fieldName: string) => (date: Date | null) => {
        formik.setFieldValue(fieldName, date);
      };

    const handlePercentageChange = (evt: TReactChangeInput) => {
      formik.setFieldValue(
        evt.target.name,
        formatDecimalField(evt.target.value),
      );
    };

    const numPayments = formik.values.numPayments;
    const amortizationGraceMonthsOptions = useMemo(() => {
      if (!numPayments) return [];
      return Array.from({ length: parseInt(numPayments) }, (item, inx) => ({
        value: (inx + 1).toString(),
        label: `${inx + 1}ª parcela`,
      }));
    }, [numPayments]);

    const isIrregularType =
      formik.values.simulationType === SimulationTypes.IRREGULAR;

    useEffect(() => {
      getFinancialIndicators();
    }, []);

    return (
      <CreditSimulatorFormContainer>
        <CreditSimulatorFields title="Informações da solicitação">
          <CustomCurrencyInput
            labelValue={TABS_CONTENT[Number(tabIndex)]}
            value={formik.values.amount!}
            handleInputChange={handleCurrencyChange}
            name="amount"
            disabled={[
              TypeProductEmission.BILLET_PAYMENT,
              TypeProductEmission.QRCODE_PIX,
            ].includes(product?.type_product_emission as TypeProductEmission)}
            validationError={formik.touched.amount ? formik.errors.amount : ''}
          />
          <CustomSelect
            labelValue="Valor adicional? (parcela)"
            value={getValueForSelect<string>(
              YES_NO,
              formik.values.hasAdditionalFee,
            )}
            options={YES_NO}
            handleInputChange={handleChangeSelect}
            name="hasAdditionalFee"
            placeholder="Selecionar"
            isClearable={false}
          />
          {formik.values.hasAdditionalFee === YesNoOptions.YES && (
            <CustomCurrencyInput
              labelValue="Valor adicional por parcela"
              name="additionalInstallmentFee"
              value={formik.values.additionalInstallmentFee!}
              handleInputChange={handleCurrencyChange}
              validationError={
                formik.touched.additionalInstallmentFee
                  ? formik.errors.additionalInstallmentFee
                  : ''
              }
            />
          )}
          {formik.values.hasAdditionalFee === YesNoOptions.YES && (
            <CustomInput
              labelValue="Motivo"
              value={formik.values.additionalInstallmentDescription!}
              handleInputChange={formik.handleChange}
              name="additionalInstallmentDescription"
              width="66%"
              placeholder="Escreva aqui (80 caracteres)"
              validationError={
                formik.touched.additionalInstallmentDescription
                  ? formik.errors.additionalInstallmentDescription
                  : ''
              }
            />
          )}
        </CreditSimulatorFields>

        <CreditSimulatorFields title="Tarifas, juros, taxas, etc. ">
          <CustomSelect
            labelValue="Tipo de juros"
            value={getValueForSelect<string>(
              product?.amortization_type === SimulationTypes.PRICE
                ? [{ value: SimulationTypes.PRICE, label: 'Price' }]
                : product?.amortization_type === SimulationTypes.AMERICAN
                ? [
                    {
                      value: SimulationTypes.AMERICAN,
                      label: 'Amortização Americana',
                    },
                  ]
                : SIMULATION_TYPES,
              formik.values.simulationType,
            )}
            options={
              product?.amortization_type === SimulationTypes.PRICE
                ? [{ value: SimulationTypes.PRICE, label: 'Price' }]
                : product?.amortization_type === SimulationTypes.AMERICAN
                ? [
                    {
                      value: SimulationTypes.AMERICAN,
                      label: 'Amortização Americana',
                    },
                  ]
                : SIMULATION_TYPES
            }
            handleInputChange={handleChangeSelect}
            name="simulationType"
            placeholder="Selecionar"
            isClearable={false}
          />
          <CustomSelect
            labelValue="Modalidade de juros"
            value={getValueForSelect<string>(
              product?.interest_type === InterestTypes.PRE
                ? [{ value: InterestTypes.PRE, label: 'Pré-fixado' }]
                : product?.interest_type === InterestTypes.POS
                ? [{ value: InterestTypes.POS, label: 'Pós-fixado' }]
                : INTEREST_TYPES,
              formik.values.interestType,
            )}
            options={
              product?.interest_type === InterestTypes.PRE
                ? { value: InterestTypes.PRE, label: 'Pré-fixado' }
                : product?.interest_type === InterestTypes.POS
                ? { value: InterestTypes.POS, label: 'Pós-fixado' }
                : INTEREST_TYPES
            }
            handleInputChange={handleChangeSelect}
            name="interestType"
            placeholder="Selecionar"
            isClearable={false}
          />
          {product?.interest_type === InterestTypes.PRE ||
          (!product?.interest_type &&
            formik.values.interestType === InterestTypes.PRE) ? (
            <InputDropdown
              items={PRE_FIXED_TYPES}
              handleInputChange={handleChangeInputDropdown('interestPreRate')}
              label="Juros Pré-fixado"
              handleSelectFilterItem={handleChangeInputFilterDropdown(
                'interestPreType',
              )}
              inputValue={formik.values.interestPreRate ?? ''}
              selectedFilter={formik.values.interestPreType ?? ''}
              validationError={
                formik.touched.interestPreRate
                  ? formik.errors.interestPreRate
                  : ''
              }
            />
          ) : (
            <InputDropdown
              items={
                referenceRate ?? [
                  {
                    name: 'Carregando',
                    parameterName: 'Carregando',
                    type: EInputType.TEXT,
                  },
                ]
              }
              handleInputChange={handleChangeInputDropdown('interestPosRate')}
              label="Juros Pos-fixado"
              handleSelectFilterItem={handleChangeInputFilterDropdown(
                'interestPosType',
              )}
              inputValue={formik.values.interestPosRate ?? ''}
              selectedFilter={referenceRate[0]?.parameterName ?? ''}
              validationError={
                formik.touched.interestPosRate
                  ? formik.errors.interestPosRate
                  : ''
              }
            />
          )}
          <InputDropdown
            items={tacRateTypes}
            handleInputChange={handleChangeInputDropdown('tacAmount')}
            label="Taxa de cadastro"
            handleSelectFilterItem={handleChangeInputFilterDropdown('tacType')}
            inputValue={formik.values.tacAmount ?? ''}
            selectedFilter={formik.values.tacType ?? ''}
            validationError={
              formik.touched.tacAmount ? formik.errors.tacAmount : ''
            }
          />
          <CustomCurrencyInput
            labelValue="Outros custos (absoluto)"
            name="financeFee"
            value={formik.values.financeFee!}
            handleInputChange={handleCurrencyChange}
            validationError={
              formik.touched.financeFee ? formik.errors.financeFee : ''
            }
          />
          {isDiscountRateVisible && (
            <CustomInput
              labelValue="Taxa de desconto"
              value={formik.values.discountRate}
              handleInputChange={handlePercentageChange}
              name="discountRate"
              placeholder="%"
              validationError={
                formik.touched.discountRate ? formik.errors.discountRate : ''
              }
            />
          )}
          <CustomSelect
            labelValue="Tipo de IOF"
            value={getValueForSelect<string>(iofTypes, formik.values.iofType)}
            options={iofTypes}
            handleInputChange={handleChangeSelect}
            name="iofType"
            placeholder="Selecionar"
            isClearable={false}
            validationError={
              formik.touched.iofType ? formik.errors.iofType : ''
            }
          />
        </CreditSimulatorFields>
        <CreditSimulatorFields title="Parcelas">
          <CustomInput
            labelValue="Prazo (parcelas)"
            value={formik.values.numPayments}
            handleInputChange={handleChangeNumber}
            name="numPayments"
            validationError={
              formik.touched.numPayments ? formik.errors.numPayments : ''
            }
          />
          <CalendarInput
            name="firstPaymentDate"
            labelValue="Data do 1º pagamento"
            placeholder="dd/mm/aaaa"
            value={formik.values.firstPaymentDate}
            onChange={handleChangeDatePicker('firstPaymentDate')}
            validationError={
              formik.touched.firstPaymentDate
                ? formik.errors.firstPaymentDate
                : ''
            }
          />
          {!product?.multiple_disbursements_dates && (
            <CalendarInput
              name="foundation_date"
              labelValue="Data de liberação do recurso"
              placeholder="dd/mm/aaaa"
              value={formik.values.disbursementDate}
              onChange={handleChangeDatePicker('disbursementDate')}
              validationError={
                formik.touched.disbursementDate
                  ? formik.errors.disbursementDate
                  : ''
              }
            />
          )}
          <CustomInput
            labelValue="Periocidade dos vencimentos"
            value={SCHEDULE_TYPES_TRANSLATED_EN_PT[formik.values.scheduleType!]}
            handleInputChange={formik.handleChange}
            name="scheduleType"
            readOnly
          />
        </CreditSimulatorFields>
        {isIrregularType && (
          <CreditSimulatorFields>
            <CustomSelect
              labelValue="Carência de juros"
              value={getValueForSelect<string>(
                YES_NO,
                formik.values.hasInterestMinGrace,
              )}
              options={YES_NO}
              handleInputChange={handleChangeSelect}
              name="hasInterestMinGrace"
              placeholder="Selecionar"
              isClearable={false}
            />
            {isIrregularType && (
              <CustomSelect
                labelValue="Período de carência de juros"
                value={getValueForSelect<string>(
                  INTEREST_GRACE_PERIODS,
                  formik.values.interestGracePeriod,
                )}
                options={INTEREST_GRACE_PERIODS}
                handleInputChange={handleChangeSelect}
                name="interestGracePeriod"
                placeholder="Selecionar"
                isClearable={false}
              />
            )}
            <br />
            <CustomSelect
              labelValue="Frequência de amortização"
              value={getValueForSelect<string>(
                AMORTIZATION_TYPES,
                formik.values.amortizationType,
              )}
              options={AMORTIZATION_TYPES}
              handleInputChange={handleChangeSelect}
              name="amortizationType"
              placeholder="Selecionar"
              isClearable={false}
            />
            <CustomSelect
              labelValue="Carência de amortização"
              value={getValueForSelect<string>(
                YES_NO,
                formik.values.amortizationGrace,
              )}
              options={YES_NO}
              handleInputChange={handleChangeSelect}
              name="amortizationGrace"
              placeholder="Selecionar"
              isClearable={false}
            />
            <CustomSelect
              labelValue="Meses da amortização"
              value={getValueForSelectMulti<string>(
                amortizationGraceMonthsOptions,
                formik.values.amortizationGraceMonths,
              )}
              options={amortizationGraceMonthsOptions}
              handleInputChange={handleChangeSelectMulti}
              name="amortizationGraceMonths"
              placeholder="Selecionar"
              multi
              isClearable={false}
            />
          </CreditSimulatorFields>
        )}
        {product?.multiple_disbursements_dates && (
          <CreditSimulatorFields
            title="Múltiplas datas de desembolso"
            tooltip="A operação será desembolsada de acordo com a data de
                  assinatura da operação, que poderá ser entre a data de início
                  e data fim do desembolso."
          >
            <CalendarInput
              name="disbursement_date_start"
              labelValue="Data de início do desembolso"
              placeholder="dd/mm/aaaa"
              disabled={true}
              value={new Date().toISOString()}
              //eslint-disable-next-line
              onChange={() => {}}
            />
            <CalendarInput
              name="foundation_date"
              labelValue="Data fim do desembolso"
              placeholder="dd/mm/aaaa"
              value={formik.values.disbursementDate}
              onChange={handleChangeDatePicker('disbursementDate')}
              validationError={
                formik.touched.disbursementDate
                  ? formik.errors.disbursementDate
                  : ''
              }
            />
          </CreditSimulatorFields>
        )}
      </CreditSimulatorFormContainer>
    );
  },
);

CreditSimulatorForm.displayName = 'CreditSimulatorForm';
export default CreditSimulatorForm;
