import React from 'react';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import {
  CustomCurrencyInputContainer,
  InputLabelContainer,
  TextFieldArea,
} from './styles';
import { InputError, TextM } from '../typography';

export interface ICustomCurrencyInputProps extends OutlinedInputProps {
  labelValue?: string;
  value: string;
  validationError?: string;
  withSelect?: boolean;
  withLeftSelect?: boolean;
  handleInputChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const CustomCurrencyInput = ({
  labelValue,
  handleInputChange,
  value,
  validationError,
  withSelect,
  withLeftSelect,
  ...props
}: ICustomCurrencyInputProps) => {
  const getColor = () => {
    if (props.disabled || !value) {
      return 'rgba(0, 0, 0, 0.38)';
    }
    if (withLeftSelect) {
      return 'black';
    }
    return 'var(--primary)';
  };
  return (
    <CustomCurrencyInputContainer
      withSelect={withSelect}
      withLeftSelect={withLeftSelect}
    >
      {labelValue && <InputLabelContainer>{labelValue}</InputLabelContainer>}
      <TextFieldArea
        className={withLeftSelect ? '' : 'text-field-area'}
        withLeftSelect={withLeftSelect}
      >
        <TextM
          style={{
            margin: '0px',
            zIndex: '99',
            color: getColor(),
          }}
        >
          R$
        </TextM>

        <OutlinedInput
          value={value}
          type={'text'}
          onChange={(e) => handleInputChange(e)}
          {...props}
        />
      </TextFieldArea>
      {validationError && <InputError>{validationError}</InputError>}
    </CustomCurrencyInputContainer>
  );
};

export default CustomCurrencyInput;
