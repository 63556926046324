import {
  ICompanyForm,
  ICompanyResponse,
  IPerson,
  IPersonForm,
} from 'modules/common/CreatePersonModal/types';
import {
  CustomerHttpService,
  PersonHttpService,
} from 'modules/customer/services';
import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useState,
} from 'react';
import {
  IPersonAction,
  IPersonBusinessList,
  IPersonState,
  TCreatePersonDataRequest,
  TPersonBusinessParams,
} from './person.interfaces';
import { personInitialState, personReducer } from './personReducer';
import { TCreateBusinessDataRequest } from '../CustomerProvider';
import { IRelativePayload } from 'modules/customer/services/api/interfaces';

export enum EPersonType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export interface IPersonProviderProps {
  children?: React.ReactNode;
}

interface PersonPropsContext {
  formType?: { label: string; value: EPersonType };
  setFormType: (value?: { label: string; value: EPersonType }) => void;
  state: IPersonState;
  dispatch: React.Dispatch<IPersonAction>;
  person: IPersonForm;
  setPerson: (person: IPersonForm) => void;
  personCompany: ICompanyForm;
  setPersonCompany: (company: ICompanyForm) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setCreatedBusinessId: (value: string) => void;
  setCreatedPersonId: (value: string) => void;
  createdPersonId?: string;
  createdBusinessId?: string;
  clearContext: () => void;
  // HK Renomear interface:
  createPerson: (person: TCreatePersonDataRequest) => Promise<IPerson>;
  createRepresentative: (
    businessId: string,
    representativesList?: IRelativePayload[],
  ) => Promise<boolean>;
  createCompany: (
    company: TCreateBusinessDataRequest,
  ) => Promise<ICompanyResponse | undefined>;
  setRepresentatives: (representatives: IPerson[]) => void;
  representatives: IPerson[];
  getPersonBusinessList: (
    params: TPersonBusinessParams,
  ) => Promise<IPersonBusinessList | null>;
}

const PersonContext = createContext<PersonPropsContext>(
  {} as PersonPropsContext,
);

export const PersonProvider = ({ children }: { children?: ReactNode }) => {
  /* States */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<{
    label: string;
    value: EPersonType;
  }>();
  const [person, setPerson] = useState<IPersonForm>({} as IPersonForm);
  const [createdPersonId, setCreatedPersonId] = useState<string>();
  const [createdBusinessId, setCreatedBusinessId] = useState<string>();
  const [representatives, setRepresentatives] = useState<IPerson[]>([]);
  const [personCompany, setPersonCompany] = useState<ICompanyForm>(
    {} as ICompanyForm,
  );

  // HK
  const [state, dispatch] = useReducer(personReducer, personInitialState);

  const clearContext = () => {
    setCreatedPersonId(undefined);
    setPerson({} as IPersonForm);
    setPersonCompany({} as ICompanyForm);
    setFormType(undefined);
  };

  const createPerson = async (payload: TCreatePersonDataRequest) => {
    setIsLoading(true);

    try {
      const personService = new PersonHttpService();
      const res = await personService.creatPerson(payload);

      if (res.data.id) {
        setCreatedPersonId(res.data.id);
        return res.data;
      }

      return undefined;
    } catch (error) {
      console.error('Erro ao adicionar um representante', error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const getPersonBusinessList = async (
    params: TPersonBusinessParams,
  ): Promise<IPersonBusinessList | null> => {
    setIsLoading(true);
    try {
      const service = new PersonHttpService();
      const personList = await service.getPersonBusinessList(
        params.page,
        params.searchParameterName,
        params.searchParameterValue,
        params.itemsPerPage,
      );
      return personList;
    } catch (error) {
      console.error('Ocorreu um erro ao buscar a lista de pessoas', error);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const createRepresentative = async (
    businessId: string,
    representativesList?: IRelativePayload[],
  ) => {
    setIsLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const createBusinessRelationPromises = representativesList?.map(
        async (representative) => {
          await customerService.createRelative(businessId, representative);
        },
      );
      await Promise.all(createBusinessRelationPromises || []);
      return true;
    } catch (error) {
      console.error('Erro ao adicionar um representante', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const createCompany = async (payload: TCreateBusinessDataRequest) => {
    setIsLoading(true);

    try {
      const customerService = new CustomerHttpService();
      const res = await customerService.creatBusiness(payload);

      if (res.data.id) {
        setCreatedBusinessId(res.data.id);
        return res.data;
      }

      return undefined;
    } catch (error) {
      console.error('Erro ao criar uma empresa', error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PersonContext.Provider
      value={{
        setCreatedBusinessId,
        setCreatedPersonId,
        state,
        dispatch,
        formType,
        setFormType,
        person,
        setPerson,
        createPerson,
        isLoading,
        createdPersonId,
        setIsLoading,
        personCompany,
        setPersonCompany,
        clearContext,
        createCompany,
        createRepresentative,
        representatives,
        setRepresentatives,
        getPersonBusinessList,
        createdBusinessId,
      }}
    >
      {children}
    </PersonContext.Provider>
  );
};

const usePersonContext = () => {
  const context = useContext(PersonContext);
  return context;
};

export { PersonProvider as default, usePersonContext };
