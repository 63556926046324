const BANK_LIST_ARRAY = [
  {
    label: '001 - BANCO DO BRASIL S.A.',
    value: '00000000',
  },
  {
    label: '003 - BANCO DA AMAZÔNIA S.A.',
    value: '04902979',
  },
  {
    label: '004 - BANCO DO NORDESTE DO BRASIL S.A.',
    value: '07237373',
  },
  {
    label: '007 - BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
    value: '33657248',
  },
  {
    label: '010 - CREDICOAMO CREDITO RURAL COOPERATIVA',
    value: '81723108',
  },
  {
    label: '011 - CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
    value: '61809182',
  },
  {
    label: '012 - BANCO INBURSA S.A.',
    value: '04866275',
  },
  {
    label: '014 - STATE STREET BRASIL S.A. - BANCO COMERCIAL',
    value: '09274232',
  },
  {
    label:
      '015 - UBS BRASIL CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '02819125',
  },
  {
    label:
      '016 - COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CREDITRAN',
    value: '04715685',
  },
  {
    label: '017 - BNY MELLON BANCO S.A.',
    value: '42272526',
  },
  {
    label: '018 - BANCO TRICURY S.A.',
    value: '57839805',
  },
  {
    label: '021 - BANESTES S.A. BANCO DO ESTADO DO ESPÍRITO SANTO',
    value: '28127603',
  },
  {
    label: '024 - BANCO BANDEPE S.A.',
    value: '10866788',
  },
  {
    label: '025 - BANCO ALFA S.A.',
    value: '03323840',
  },
  {
    label: '029 - BANCO ITAÚ CONSIGNADO S.A.',
    value: '33885724',
  },
  {
    label: '033 - BANCO SANTANDER (BRASIL) S.A.',
    value: '90400888',
  },
  {
    label: '036 - BANCO BRADESCO BBI S.A.',
    value: '06271464',
  },
  {
    label: '037 - BANCO DO ESTADO DO PARÁ S.A.',
    value: '04913711',
  },
  {
    label: '040 - BANCO CARGILL S.A.',
    value: '03609817',
  },
  {
    label: '041 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.',
    value: '92702067',
  },
  {
    label: '047 - BANCO DO ESTADO DE SERGIPE S.A.',
    value: '13009717',
  },
  {
    label: '060 - CONFIDENCE CORRETORA DE CÂMBIO S.A.',
    value: '04913129',
  },
  {
    label: '062 - HIPERCARD BANCO MÚLTIPLO S.A.',
    value: '03012230',
  },
  {
    label: '063 - BANCO BRADESCARD S.A.',
    value: '04184779',
  },
  {
    label: '064 - GOLDMAN SACHS DO BRASIL BANCO MÚLTIPLO S.A.',
    value: '04332281',
  },
  {
    label: '065 - BANCO ANDBANK (BRASIL) S.A.',
    value: '48795256',
  },
  {
    label: '066 - BANCO MORGAN STANLEY S.A.',
    value: '02801938',
  },
  {
    label: '069 - BANCO CREFISA S.A.',
    value: '61033106',
  },
  {
    label: '070 - BRB - BANCO DE BRASÍLIA S.A.',
    value: '00000208',
  },
  {
    label: '074 - BANCO J. SAFRA S.A.',
    value: '03017677',
  },
  {
    label: '075 - BANCO ABN AMRO S.A.',
    value: '03532415',
  },
  {
    label: '076 - BANCO KDB DO BRASIL S.A.',
    value: '07656500',
  },
  {
    label: '077 - BANCO INTER S.A.',
    value: '00416968',
  },
  {
    label: '078 - HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A.',
    value: '34111187',
  },
  {
    label: '079 - PICPAY BANK - BANCO MÚLTIPLO S.A',
    value: '09516419',
  },
  {
    label: '080 - B&T CORRETORA DE CAMBIO LTDA.',
    value: '73622748',
  },
  {
    label: '081 - BANCOSEGURO S.A.',
    value: '10264663',
  },
  {
    label: '082 - BANCO TOPÁZIO S.A.',
    value: '07679404',
  },
  {
    label: '083 - BANCO DA CHINA BRASIL S.A.',
    value: '10690848',
  },
  {
    label: '084 - SISPRIME DO BRASIL - COOPERATIVA DE CRÉDITO',
    value: '02398976',
  },
  {
    label: '085 - COOPERATIVA CENTRAL DE CRÉDITO - AILOS',
    value: '05463212',
  },
  {
    label: '088 - BANCO RANDON S.A.',
    value: '11476673',
  },
  {
    label: '089 - CREDISAN COOPERATIVA DE CRÉDITO',
    value: '62109566',
  },
  {
    label:
      '091 - CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
    value: '01634601',
  },
  {
    label: '092 - BRK S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '12865507',
  },
  {
    label:
      '093 - PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    value: '07945233',
  },
  {
    label: '094 - BANCO FINAXIS S.A.',
    value: '11758741',
  },
  {
    label: '095 - TRAVELEX BANCO DE CÂMBIO S.A.',
    value: '11703662',
  },
  {
    label: '096 - BANCO B3 S.A.',
    value: '00997185',
  },
  {
    label: '097 - CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    value: '04632856',
  },
  {
    label: '098 - CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL',
    value: '78157146',
  },
  {
    label:
      '099 - UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO',
    value: '03046391',
  },
  {
    label: '100 - PLANNER CORRETORA DE VALORES S.A.',
    value: '00806535',
  },
  {
    label:
      '101 - RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '62287735',
  },
  {
    label:
      '102 - XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
    value: '02332886',
  },
  {
    label: '104 - CAIXA ECONÔMICA FEDERAL',
    value: '00360305',
  },
  {
    label: '105 - LECCA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
    value: '07652226',
  },
  {
    label: '107 - BANCO BOCOM BBM S.A.',
    value: '15114366',
  },
  {
    label: '108 - PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '01800019',
  },
  {
    label:
      '111 - OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
    value: '36113876',
  },
  {
    label: '113 - NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '61723847',
  },
  {
    label:
      '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO - CECOOP',
    value: '05790149',
  },
  {
    label: '117 - ADVANCED CORRETORA DE CÂMBIO LTDA',
    value: '92856905',
  },
  {
    label: '119 - BANCO WESTERN UNION DO BRASIL S.A.',
    value: '13720915',
  },
  {
    label: '120 - BANCO RODOBENS S.A.',
    value: '33603457',
  },
  {
    label: '121 - BANCO AGIBANK S.A.',
    value: '10664513',
  },
  {
    label: '122 - BANCO BRADESCO BERJ S.A.',
    value: '33147315',
  },
  {
    label: '124 - BANCO WOORI BANK DO BRASIL S.A.',
    value: '15357060',
  },
  {
    label: '125 - BANCO GENIAL S.A.',
    value: '45246410',
  },
  {
    label: '126 - BR PARTNERS BANCO DE INVESTIMENTO S.A.',
    value: '13220493',
  },
  {
    label: '127 - CODEPE CORRETORA DE VALORES E CÂMBIO S.A.',
    value: '09512542',
  },
  {
    label: '128 - BRAZA BANK S.A. BANCO DE CÂMBIO',
    value: '19307785',
  },
  {
    label: '129 - UBS BRASIL BANCO DE INVESTIMENTO S.A.',
    value: '18520834',
  },
  {
    label:
      '130 - CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '09313766',
  },
  {
    label: '131 - TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
    value: '61747085',
  },
  {
    label: '132 - ICBC DO BRASIL BANCO MÚLTIPLO S.A.',
    value: '17453575',
  },
  {
    label:
      '133 - CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E SOLIDÁRIA - CRESOL CONFEDERAÇÃO',
    value: '10398952',
  },
  {
    label:
      '134 - BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '33862244',
  },
  {
    label:
      '136 - CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASIL.',
    value: '00315557',
  },
  {
    label: '138 - GET MONEY CORRETORA DE CÂMBIO S.A.',
    value: '10853017',
  },
  {
    label: '139 - INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO',
    value: '55230916',
  },
  {
    label: '140 - NU INVEST CORRETORA DE VALORES S.A.',
    value: '62169875',
  },
  {
    label: '141 - BANCO MASTER DE INVESTIMENTO S.A.',
    value: '09526594',
  },
  {
    label: '142 - BROKER BRASIL CORRETORA DE CÂMBIO LTDA.',
    value: '16944141',
  },
  {
    label: '143 - TREVISO CORRETORA DE CÂMBIO S.A.',
    value: '02992317',
  },
  {
    label: '144 - EBURY BANCO DE CÂMBIO S.A.',
    value: '13059145',
  },
  {
    label: '145 - LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
    value: '50579044',
  },
  {
    label: '146 - GUITTA CORRETORA DE CAMBIO LTDA.',
    value: '24074692',
  },
  {
    label: '149 - FACTA FINANCEIRA S.A. - CRÉDITO FINANCIAMENTO E INVESTIMENTO',
    value: '15581638',
  },
  {
    label:
      '157 - ICAP DO BRASIL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '09105360',
  },
  {
    label:
      '159 - CASA DO CRÉDITO S.A. SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR',
    value: '05442029',
  },
  {
    label: '163 - COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO',
    value: '23522214',
  },
  {
    label:
      '173 - BRL TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '13486793',
  },
  {
    label: '174 - PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '43180355',
  },
  {
    label: '177 - GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES',
    value: '65913436',
  },
  {
    label:
      '180 - CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '02685483',
  },
  {
    label:
      '183 - SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE',
    value: '09210106',
  },
  {
    label: '184 - BANCO ITAÚ BBA S.A.',
    value: '17298092',
  },
  {
    label:
      '188 - ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
    value: '33775974',
  },
  {
    label: '189 - HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
    value: '07512441',
  },
  {
    label:
      '190 - SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAIS DO RIO GRANDE DO SUL',
    value: '03973814',
  },
  {
    label: '191 - NOVA FUTURA CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '04257795',
  },
  {
    label: '194 - UNIDA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '20155248',
  },
  {
    label: '195 - VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '07799277',
  },
  {
    label: '196 - FAIR CORRETORA DE CAMBIO S.A.',
    value: '32648370',
  },
  {
    label: '197 - STONE INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '16501555',
  },
  {
    label: '208 - BANCO BTG PACTUAL S.A.',
    value: '30306294',
  },
  {
    label: '212 - BANCO ORIGINAL S.A.',
    value: '92894922',
  },
  {
    label: '213 - BANCO ARBI S.A.',
    value: '54403563',
  },
  {
    label: '217 - BANCO JOHN DEERE S.A.',
    value: '91884981',
  },
  {
    label: '218 - BANCO BS2 S.A.',
    value: '71027866',
  },
  {
    label: '222 - BANCO CRÉDIT AGRICOLE BRASIL S.A.',
    value: '75647891',
  },
  {
    label: '224 - BANCO FIBRA S.A.',
    value: '58616418',
  },
  {
    label: '233 - BANCO CIFRA S.A.',
    value: '62421979',
  },
  {
    label: '237 - BANCO BRADESCO S.A.',
    value: '60746948',
  },
  {
    label: '241 - BANCO CLASSICO S.A.',
    value: '31597552',
  },
  {
    label: '243 - BANCO MASTER S/A',
    value: '33923798',
  },
  {
    label: '246 - BANCO ABC BRASIL S.A.',
    value: '28195667',
  },
  {
    label: '249 - BANCO INVESTCRED UNIBANCO S.A.',
    value: '61182408',
  },
  {
    label: '250 - BCV - BANCO DE CRÉDITO E VAREJO S.A.',
    value: '50585090',
  },
  {
    label: '253 - BEXS CORRETORA DE CÂMBIO S/A',
    value: '52937216',
  },
  {
    label: '254 - PARANÁ BANCO S.A.',
    value: '14388334',
  },
  {
    label: '259 - MONEYCORP BANCO DE CÂMBIO S.A.',
    value: '08609934',
  },
  {
    label: '260 - NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    value: '18236120',
  },
  {
    label: '265 - BANCO FATOR S.A.',
    value: '33644196',
  },
  {
    label: '266 - BANCO CEDULA S.A.',
    value: '33132044',
  },
  {
    label: '268 - BARI COMPANHIA HIPOTECÁRIA',
    value: '14511781',
  },
  {
    label: '269 - BANCO HSBC S.A.',
    value: '53518684',
  },
  {
    label: '270 - SAGITUR CORRETORA DE CÂMBIO S.A.',
    value: '61444949',
  },
  {
    label: '271 - IB CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '27842177',
  },
  {
    label: '272 - AGK CORRETORA DE CAMBIO S.A.',
    value: '00250699',
  },
  {
    label:
      '273 - COOPERATIVA DE CRÉDITO RURAL DE SÃO MIGUEL DO OESTE - SULCREDI/SÃO MIGUEL',
    value: '08253539',
  },
  {
    label:
      '274 - BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.',
    value: '11581339',
  },
  {
    label: '276 - BANCO SENFF S.A.',
    value: '11970623',
  },
  {
    label: '278 - GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIÁRIOS S.A.',
    value: '27652684',
  },
  {
    label:
      '279 - PRIMACREDI CREDISIS - COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE',
    value: '26563270',
  },
  {
    label: '280 - WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '23862762',
  },
  {
    label: '281 - COOPERATIVA DE CRÉDITO RURAL COOPAVEL',
    value: '76461557',
  },
  {
    label:
      '283 - RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
    value: '89960090',
  },
  {
    label: '285 - FRENTE CORRETORA DE CÂMBIO S.A.',
    value: '71677850',
  },
  {
    label: '286 - UNIPRIME OURO - COOPERATIVA DE CRÉDITO DE OURO',
    value: '07853842',
  },
  {
    label: '288 - CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    value: '62237649',
  },
  {
    label: '289 - EFX CORRETORA DE CÂMBIO LTDA.',
    value: '94968518',
  },
  {
    label: '290 - PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '08561701',
  },
  {
    label:
      '292 - GALAPAGOS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '28650236',
  },
  {
    label:
      '293 - LASTRO RDV DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '71590442',
  },
  {
    label: '296 - OZ CORRETORA DE CÂMBIO S.A.',
    value: '04062902',
  },
  {
    label: "298 - VIP'S CORRETORA DE CÂMBIO LTDA.",
    value: '17772370',
  },
  {
    label: '299 - BANCO AFINZ S.A. - BANCO MÚLTIPLO',
    value: '04814563',
  },
  {
    label: '300 - BANCO DE LA NACION ARGENTINA',
    value: '33042151',
  },
  {
    label: '301 - DOCK INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '13370835',
  },
  {
    label: '305 - FOURTRADE CORRETORA DE CÂMBIO LTDA.',
    value: '40353377',
  },
  {
    label:
      '306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    value: '40303299',
  },
  {
    label:
      '307 - TERRA INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '03751794',
  },
  {
    label: '309 - CAMBIONET CORRETORA DE CÂMBIO LTDA.',
    value: '14190547',
  },
  {
    label: '310 - VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    value: '22610500',
  },
  {
    label: '311 - DOURADA CORRETORA DE CÂMBIO LTDA.',
    value: '76641497',
  },
  {
    label:
      '312 - HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    value: '07693858',
  },
  {
    label: '313 - AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
    value: '16927221',
  },
  {
    label: '315 - PI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '03502968',
  },
  {
    label: '318 - BANCO BMG S.A.',
    value: '61186680',
  },
  {
    label: '319 - OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '11495073',
  },
  {
    label: '320 - CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A',
    value: '07450604',
  },
  {
    label:
      '321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE S.A.',
    value: '18188384',
  },
  {
    label:
      '322 - COOPERATIVA DE CRÉDITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ',
    value: '01073966',
  },
  {
    label: '323 - MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.',
    value: '10573521',
  },
  {
    label: '324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '21332862',
  },
  {
    label: '325 - ÓRAMA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '13293225',
  },
  {
    label: '326 - PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    value: '03311443',
  },
  {
    label:
      '328 - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA LTDA.',
    value: '05841967',
  },
  {
    label: '329 - QI SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '32402502',
  },
  {
    label: '330 - BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
    value: '00556603',
  },
  {
    label:
      '331 - FRAM CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '13673855',
  },
  {
    label: '332 - ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    value: '13140088',
  },
  {
    label: '334 - BANCO BESA S.A.',
    value: '15124464',
  },
  {
    label: '335 - BANCO DIGIO S.A.',
    value: '27098060',
  },
  {
    label: '336 - BANCO C6 S.A.',
    value: '31872495',
  },
  {
    label: '340 - SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '09554480',
  },
  {
    label: '341 - ITAÚ UNIBANCO S.A.',
    value: '60701190',
  },
  {
    label: '342 - CREDITAS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '32997490',
  },
  {
    label:
      '343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    value: '24537861',
  },
  {
    label: '348 - BANCO XP S.A.',
    value: '33264668',
  },
  {
    label: '349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '27214112',
  },
  {
    label:
      '350 - COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DE AGRICULTORES E AEROPORTUÁRIOS DO BRASIL - CREHNOR',
    value: '01330387',
  },
  {
    label: '352 - TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '29162769',
  },
  {
    label:
      '354 - NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
    value: '52904364',
  },
  {
    label: '355 - ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '34335592',
  },
  {
    label: '358 - MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '09464032',
  },
  {
    label: '359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
    value: '05351887',
  },
  {
    label:
      '360 - TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '02276653',
  },
  {
    label: '362 - CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    value: '01027058',
  },
  {
    label: '363 - SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '62285390',
  },
  {
    label: '364 - EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO',
    value: '09089356',
  },
  {
    label: '365 - SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.',
    value: '68757681',
  },
  {
    label: '366 - BANCO SOCIETE GENERALE BRASIL S.A.',
    value: '61533584',
  },
  {
    label: '367 - VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '34711571',
  },
  {
    label: '368 - BANCO CSF S.A.',
    value: '08357240',
  },
  {
    label: '370 - BANCO MIZUHO DO BRASIL S.A.',
    value: '61088183',
  },
  {
    label: '371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
    value: '92875780',
  },
  {
    label: '373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
    value: '35977097',
  },
  {
    label: '374 - REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    value: '27351731',
  },
  {
    label: '376 - BANCO J.P. MORGAN S.A.',
    value: '33172537',
  },
  {
    label: '377 - BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '17826860',
  },
  {
    label: '378 - BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA',
    value: '01852137',
  },
  {
    label:
      '379 - COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITUIÇÕES FINANCEIRAS PÚBLICAS FEDERAIS LTDA.',
    value: '01658426',
  },
  {
    label: '380 - PICPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '22896431',
  },
  {
    label: '381 - BANCO MERCEDES-BENZ DO BRASIL S.A.',
    value: '60814191',
  },
  {
    label:
      '382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LIMITADA.',
    value: '04307598',
  },
  {
    label: '383 - EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
    value: '21018182',
  },
  {
    label:
      '384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    value: '11165756',
  },
  {
    label:
      '385 - COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE VITORIA - CREDESTIVA.',
    value: '03844699',
  },
  {
    label:
      '386 - NU FINANCEIRA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '30680829',
  },
  {
    label: '387 - BANCO TOYOTA DO BRASIL S.A.',
    value: '03215790',
  },
  {
    label: '389 - BANCO MERCANTIL DO BRASIL S.A.',
    value: '17184037',
  },
  {
    label: '390 - BANCO GM S.A.',
    value: '59274605',
  },
  {
    label: '391 - COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
    value: '08240446',
  },
  {
    label: '393 - BANCO VOLKSWAGEN S.A.',
    value: '59109165',
  },
  {
    label: '394 - BANCO BRADESCO FINANCIAMENTOS S.A.',
    value: '07207996',
  },
  {
    label:
      "395 - F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    value: '08673569',
  },
  {
    label: '396 - HUB INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '13884775',
  },
  {
    label: '397 - LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
    value: '34088029',
  },
  {
    label: '398 - IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '31749596',
  },
  {
    label: '399 - KIRTON BANK S.A. - BANCO MÚLTIPLO',
    value: '01701201',
  },
  {
    label: '400 - COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS',
    value: '05491616',
  },
  {
    label: '401 - IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '15111975',
  },
  {
    label:
      '402 - COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
    value: '36947229',
  },
  {
    label: '403 - CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '37880206',
  },
  {
    label: '404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '37241230',
  },
  {
    label: '406 - ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '37715993',
  },
  {
    label:
      '407 - SEFER INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '00329598',
  },
  {
    label: '408 - BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '36586946',
  },
  {
    label: '410 - PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '05684234',
  },
  {
    label:
      '411 - VIA CERTA FINANCIADORA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
    value: '05192316',
  },
  {
    label: '412 - SOCIAL BANK BANCO MÚLTIPLO S/A',
    value: '15173776',
  },
  {
    label: '413 - BANCO BV S.A.',
    value: '01858774',
  },
  {
    label: '414 - LEND SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '37526080',
  },
  {
    label: '416 - LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '19324634',
  },
  {
    label: '418 - ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A',
    value: '37414009',
  },
  {
    label: '419 - NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '38129006',
  },
  {
    label: '421 - LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
    value: '39343350',
  },
  {
    label: '422 - BANCO SAFRA S.A.',
    value: '58160789',
  },
  {
    label: '423 - COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
    value: '00460065',
  },
  {
    label: '425 - SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '03881423',
  },
  {
    label: '426 - NEON FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    value: '11285104',
  },
  {
    label:
      '427 - COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
    value: '27302181',
  },
  {
    label: '428 - CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '39664698',
  },
  {
    label: '429 - CREDIARE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '05676026',
  },
  {
    label: '430 - COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
    value: '00204963',
  },
  {
    label:
      '433 - BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '44077014',
  },
  {
    label: '435 - DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '38224857',
  },
  {
    label: '438 - TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '67030395',
  },
  {
    label: '439 - ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '16695922',
  },
  {
    label: '440 - CREDIBRF - COOPERATIVA DE CRÉDITO',
    value: '82096447',
  },
  {
    label:
      '442 - MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '87963450',
  },
  {
    label: '443 - CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '39416705',
  },
  {
    label: '444 - TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '40654622',
  },
  {
    label: '445 - PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '35551187',
  },
  {
    label:
      '447 - MIRAE ASSET (BRASIL) CORRETORA DE CÂMBIO, TÍTULOS  E VALORES MOBILIÁRIOS LTDA.',
    value: '12392983',
  },
  {
    label: '448 - HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '39669186',
  },
  {
    label: '449 - DM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '37555231',
  },
  {
    label: '450 - FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.',
    value: '13203354',
  },
  {
    label: '451 - J17 - SOCIEDADE DE CRÉDITO DIRETO S/A',
    value: '40475846',
  },
  {
    label: '452 - CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '39676772',
  },
  {
    label: '454 - MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '41592532',
  },
  {
    label: '455 - FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '38429045',
  },
  {
    label: '456 - BANCO MUFG BRASIL S.A.',
    value: '60498557',
  },
  {
    label: '457 - UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
    value: '39587424',
  },
  {
    label:
      '458 - HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '07253654',
  },
  {
    label:
      '459 - COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CREDIFISCO',
    value: '04546162',
  },
  {
    label: '460 - UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A',
    value: '42047025',
  },
  {
    label: '461 - ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '19540550',
  },
  {
    label: '462 - STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '39908427',
  },
  {
    label: '463 - AZUMI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '40434681',
  },
  {
    label: '464 - BANCO SUMITOMO MITSUI BRASILEIRO S.A.',
    value: '60518222',
  },
  {
    label: '465 - CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '40083667',
  },
  {
    label:
      '467 - MASTER S/A CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS',
    value: '33886862',
  },
  {
    label: '468 - PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '04862600',
  },
  {
    label:
      '469 - PICPAY INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '07138049',
  },
  {
    label: '470 - CDC SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '18394228',
  },
  {
    label:
      '471 - COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRESERV-PINHÃO',
    value: '04831810',
  },
  {
    label: '473 - BANCO CAIXA GERAL - BRASIL S.A.',
    value: '33466988',
  },
  {
    label: '475 - BANCO YAMAHA MOTOR DO BRASIL S.A.',
    value: '10371492',
  },
  {
    label: '477 - CITIBANK N.A.',
    value: '33042953',
  },
  {
    label:
      '478 - GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '11760553',
  },
  {
    label: '479 - BANCO ITAUBANK S.A.',
    value: '60394079',
  },
  {
    label: '481 - SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '43599047',
  },
  {
    label: '482 - SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '42259084',
  },
  {
    label: '484 - MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '36864992',
  },
  {
    label: '487 - DEUTSCHE BANK S.A. - BANCO ALEMAO',
    value: '62331228',
  },
  {
    label: '488 - JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
    value: '46518205',
  },
  {
    label: '492 - ING BANK N.V.',
    value: '49336860',
  },
  {
    label: '495 - BANCO DE LA PROVINCIA DE BUENOS AIRES',
    value: '44189447',
  },
  {
    label: '505 - BANCO CREDIT SUISSE (BRASIL) S.A.',
    value: '32062580',
  },
  {
    label: '506 - RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    value: '42066258',
  },
  {
    label: '507 - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO EFÍ S.A.',
    value: '37229413',
  },
  {
    label:
      '508 - AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '61384004',
  },
  {
    label: '509 - CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
    value: '13935893',
  },
  {
    label: '510 - FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..',
    value: '39738065',
  },
  {
    label: '511 - MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '44683140',
  },
  {
    label: '512 - FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '36266751',
  },
  {
    label: '513 - ATF CREDIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '44728700',
  },
  {
    label: '514 - EXIM CORRETORA DE CAMBIO LTDA',
    value: '73302408',
  },
  {
    label: '516 - QISTA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '36583700',
  },
  {
    label:
      '518 - MERCADO CRÉDITO SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    value: '37679449',
  },
  {
    label:
      '519 - LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '40768766',
  },
  {
    label: '520 - SOMAPAY SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '44705774',
  },
  {
    label: '521 - PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
    value: '44019481',
  },
  {
    label: '522 - RED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '47593544',
  },
  {
    label: '523 - HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A',
    value: '44292580',
  },
  {
    label:
      '524 - WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '45854066',
  },
  {
    label: '525 - INTERCAM CORRETORA DE CÂMBIO LTDA.',
    value: '34265629',
  },
  {
    label: '526 - MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '46026562',
  },
  {
    label: '527 - ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '44478623',
  },
  {
    label: '528 - REAG DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    value: '34829992',
  },
  {
    label: '529 - PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '17079937',
  },
  {
    label: '530 - SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '47873449',
  },
  {
    label: '531 - BMP SOCIEDADE DE CRÉDITO DIRETO S.A',
    value: '34337707',
  },
  {
    label: '532 - EAGLE SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '45745537',
  },
  {
    label: '533 - SRM BANK INSTITUIÇÃO DE PAGAMENTO S/A',
    value: '22575466',
  },
  {
    label: '534 - EWALLY INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '00714671',
  },
  {
    label: '535 - OPEA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '39519944',
  },
  {
    label: '536 - NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    value: '20855875',
  },
  {
    label:
      '537 - MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    value: '45756448',
  },
  {
    label: '538 - SUDACRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '20251847',
  },
  {
    label: '539 - SANTINVEST S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS',
    value: '00122327',
  },
  {
    label: '540 - HBI SOCIEDADE DE CRÉDITO DIRETO S/A.',
    value: '04849745',
  },
  {
    label: '541 - FUNDO GARANTIDOR DE CREDITOS - FGC',
    value: '00954288',
  },
  {
    label: '542 - CLOUDWALK INSTITUIÇÃO DE PAGAMENTO E SERVICOS LTDA',
    value: '18189547',
  },
  {
    label:
      '543 - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS ELETRICITÁRIOS E DOS TRABALHADORES DAS EMPRESAS DO SETOR DE ENERGIA - COOPCRECE',
    value: '92825397',
  },
  {
    label: '544 - MULTICRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '38593706',
  },
  {
    label: '545 - SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
    value: '17352220',
  },
  {
    label: '546 - U4C INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '30980539',
  },
  {
    label: '547 - BNK DIGITAL SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '45331622',
  },
  {
    label: '548 - RPW S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '06249129',
  },
  {
    label:
      '549 - INTRA INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '15489568',
  },
  {
    label: '550 - BEETELLER INSTITUIÇÃO DE PAGAMENTO LTDA.',
    value: '32074986',
  },
  {
    label: '552 - UZZIPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
    value: '32192325',
  },
  {
    label: '553 - PERCAPITAL SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '48707451',
  },
  {
    label: '554 - STONEX BANCO DE CÂMBIO S.A.',
    value: '28811341',
  },
  {
    label: '555 - PAN FINANCEIRA S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS',
    value: '02682287',
  },
  {
    label: '556 - PROSEFTUR CORRETORA DE CAMBIO S.A',
    value: '40333582',
  },
  {
    label: '557 - PAGPRIME INSTITUICAO DE PAGAMENTO LTDA',
    value: '30944783',
  },
  {
    label: '558 - QI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    value: '46955383',
  },
  {
    label: '559 - KANASTRA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '49288113',
  },
  {
    label: '560 - MAG INSTITUICAO DE PAGAMENTO LTDA',
    value: '21995256',
  },
  {
    label: '561 - PAY4FUN INSTITUICAO DE PAGAMENTO S.A.',
    value: '20757199',
  },
  {
    label:
      '562 - AZIMUT BRASIL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    value: '18684408',
  },
  {
    label: '563 - PROTEGE PAY CASH INSTITUICAO DE PAGAMENTO S/A',
    value: '40276692',
  },
  {
    label: '565 - ÁGORA CORRETORA DE TITULOS E VALORES MOBILIARIOS S.A.',
    value: '74014747',
  },
  {
    label: '566 - FLAGSHIP INSTITUICAO DE PAGAMENTOS LTDA',
    value: '23114447',
  },
  {
    label:
      '567 - CREDITAQUI FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    value: '33040601',
  },
  {
    label: '568 - BRCONDOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '49933388',
  },
  {
    label: '569 - CONTA PRONTA INSTITUICAO DE PAGAMENTO LTDA',
    value: '12473687',
  },
  {
    label: '572 - ALL IN CRED SOCIEDADE DE CREDITO DIRETO S.A.',
    value: '51414521',
  },
  {
    label: '576 - MERCADO BITCOIN INSTITUICAO DE PAGAMENTO LTDA',
    value: '11351086',
  },
  {
    label:
      '577 - DESENVOLVE SP - AGÊNCIA DE FOMENTO DO ESTADO DE SÃO PAULO S.A.',
    value: '10663610',
  },
  {
    label:
      '578 - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS MUNICIPAIS DA GRANDE VITÓRIA/ES',
    value: '01235921',
  },
  {
    label: '579 - QUADRA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '49555647',
  },
  {
    label:
      '580 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL E SUDESTE - CENTRAL SICREDI SUL/SUDESTE',
    value: '87437687',
  },
  {
    label:
      '581 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORDESTE - CENTRAL SICREDI NORDESTE',
    value: '70119680',
  },
  {
    label:
      '582 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DE MATO GROSSO DO SUL, GOIÁS, DISTRITO FEDERAL E TOCANTINS - CENTRAL SICREDI BRASIL CENTRAL',
    value: '33737818',
  },
  {
    label:
      '583 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CENTRO NORTE DO BRASIL - CENTRAL SICREDI CENTRO NORTE',
    value: '33667205',
  },
  {
    label:
      '584 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS ESTADOS DO PARANÁ, SÃO PAULO E RIO DE JANEIRO - CENTRAL SICREDI PR/SP/RJ',
    value: '80230774',
  },
  {
    label: '586 - Z1 INSTITUIÇÃO DE PAGAMENTO LTDA.',
    value: '35810871',
  },
  {
    label: '588 - PROVER PROMOCAO DE VENDAS INSTITUICAO DE PAGAMENTO LTDA',
    value: '20308187',
  },
  {
    label:
      '590 - REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS INSTITUICAO DE PAGAMENTO S.A.',
    value: '40473435',
  },
  {
    label: '593 - TRANSFEERA INSTITUIÇÃO DE PAGAMENTO S.A',
    value: '27084098',
  },
  {
    label: '594 - EMBRACRED S/A SOCIEDADE DE CRÉDITO DIRETO',
    value: '48703388',
  },
  {
    label: '595 - ZOOP TECNOLOGIA & INSTITUICAO DE PAGAMENTO S.A.',
    value: '19468242',
  },
  {
    label: '597 - ISSUER INSTITUICAO DE PAGAMENTO LTDA.',
    value: '34747388',
  },
  {
    label: '600 - BANCO LUSO BRASILEIRO S.A.',
    value: '59118133',
  },
  {
    label: '604 - BANCO INDUSTRIAL DO BRASIL S.A.',
    value: '31895683',
  },
  {
    label: '610 - BANCO VR S.A.',
    value: '78626983',
  },
  {
    label: '611 - BANCO PAULISTA S.A.',
    value: '61820817',
  },
  {
    label: '612 - BANCO GUANABARA S.A.',
    value: '31880826',
  },
  {
    label: '613 - OMNI BANCO S.A.',
    value: '60850229',
  },
  {
    label: '620 - REVOLUT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    value: '51342763',
  },
  {
    label: '623 - BANCO PAN S.A.',
    value: '59285411',
  },
  {
    label: '626 - BANCO C6 CONSIGNADO S.A.',
    value: '61348538',
  },
  {
    label: '630 - BANCO LETSBANK S.A.',
    value: '58497702',
  },
  {
    label: '633 - BANCO RENDIMENTO S.A.',
    value: '68900810',
  },
  {
    label: '634 - BANCO TRIANGULO S.A.',
    value: '17351180',
  },
  {
    label: '637 - BANCO SOFISA S.A.',
    value: '60889128',
  },
  {
    label: '643 - BANCO PINE S.A.',
    value: '62144175',
  },
  {
    label: '652 - ITAÚ UNIBANCO HOLDING S.A.',
    value: '60872504',
  },
  {
    label: '653 - BANCO VOITER S.A.',
    value: '61024352',
  },
  {
    label: '654 - BANCO DIGIMAIS S.A.',
    value: '92874270',
  },
  {
    label: '655 - BANCO VOTORANTIM S.A.',
    value: '59588111',
  },
  {
    label: '707 - BANCO DAYCOVAL S.A.',
    value: '62232889',
  },
  {
    label: '712 - BANCO OURINVEST S.A.',
    value: '78632767',
  },
  {
    label: '720 - BANCO RNX S.A.',
    value: '80271455',
  },
  {
    label: '739 - BANCO CETELEM S.A.',
    value: '00558456',
  },
  {
    label: '741 - BANCO RIBEIRAO PRETO S.A.',
    value: '00517645',
  },
  {
    label: '743 - BANCO SEMEAR S.A.',
    value: '00795423',
  },
  {
    label: '745 - BANCO CITIBANK S.A.',
    value: '33479023',
  },
  {
    label: '746 - BANCO MODAL S.A.',
    value: '30723886',
  },
  {
    label: '747 - BANCO RABOBANK INTERNATIONAL BRASIL S.A.',
    value: '01023570',
  },
  {
    label: '748 - BANCO COOPERATIVO SICREDI S.A.',
    value: '01181521',
  },
  {
    label: '751 - SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO',
    value: '29030467',
  },
  {
    label: '752 - BANCO BNP PARIBAS BRASIL S.A.',
    value: '01522368',
  },
  {
    label: '753 - NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO',
    value: '74828799',
  },
  {
    label: '754 - BANCO SISTEMA S.A.',
    value: '76543115',
  },
  {
    label: '755 - BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.',
    value: '62073200',
  },
  {
    label: '756 - BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    value: '02038232',
  },
  {
    label: '757 - BANCO KEB HANA DO BRASIL S.A.',
    value: '02318507',
  },
];

export const BANK_CODE_OBJECT = {
  '081': '10264663',
  '475': '10371492',
  '133': '10398952',
  '323': '10573521',
  '577': '10663610',
  '121': '10664513',
  '083': '10690848',
  '138': '10853017',
  '024': '10866788',
  '384': '11165756',
  '426': '11285104',
  '576': '11351086',
  '088': '11476673',
  '319': '11495073',
  '274': '11581339',
  '095': '11703662',
  '094': '11758741',
  '478': '11760553',
  '276': '11970623',
  '447': '12392983',
  '569': '12473687',
  '092': '12865507',
  '047': '13009717',
  '144': '13059145',
  '332': '13140088',
  '450': '13203354',
  '126': '13220493',
  '325': '13293225',
  '301': '13370835',
  '173': '13486793',
  '331': '13673855',
  '119': '13720915',
  '396': '13884775',
  '509': '13935893',
  '309': '14190547',
  '254': '14388334',
  '268': '14511781',
  '401': '15111975',
  '107': '15114366',
  '334': '15124464',
  '412': '15173776',
  '124': '15357060',
  '549': '15489568',
  '149': '15581638',
  '197': '16501555',
  '439': '16695922',
  '313': '16927221',
  '142': '16944141',
  '529': '17079937',
  '389': '17184037',
  '184': '17298092',
  '634': '17351180',
  '545': '17352220',
  '132': '17453575',
  '298': '17772370',
  '377': '17826860',
  '321': '18188384',
  '542': '18189547',
  '260': '18236120',
  '470': '18394228',
  '129': '18520834',
  '562': '18684408',
  '128': '19307785',
  '416': '19324634',
  '595': '19468242',
  '461': '19540550',
  '194': '20155248',
  '538': '20251847',
  '588': '20308187',
  '561': '20757199',
  '536': '20855875',
  '383': '21018182',
  '324': '21332862',
  '560': '21995256',
  '533': '22575466',
  '310': '22610500',
  '380': '22896431',
  '566': '23114447',
  '163': '23522214',
  '280': '23862762',
  '146': '24074692',
  '343': '24537861',
  '279': '26563270',
  '593': '27084098',
  '335': '27098060',
  '349': '27214112',
  '427': '27302181',
  '374': '27351731',
  '278': '27652684',
  '271': '27842177',
  '021': '28127603',
  '246': '28195667',
  '292': '28650236',
  '554': '28811341',
  '751': '29030467',
  '352': '29162769',
  '208': '30306294',
  '386': '30680829',
  '746': '30723886',
  '557': '30944783',
  '546': '30980539',
  '241': '31597552',
  '398': '31749596',
  '336': '31872495',
  '612': '31880826',
  '604': '31895683',
  '505': '32062580',
  '550': '32074986',
  '552': '32192325',
  '329': '32402502',
  '196': '32648370',
  '342': '32997490',
  '567': '33040601',
  '300': '33042151',
  '477': '33042953',
  '266': '33132044',
  '122': '33147315',
  '376': '33172537',
  '348': '33264668',
  '473': '33466988',
  '745': '33479023',
  '120': '33603457',
  '265': '33644196',
  '007': '33657248',
  '583': '33667205',
  '582': '33737818',
  '188': '33775974',
  '134': '33862244',
  '029': '33885724',
  '467': '33886862',
  '243': '33923798',
  '397': '34088029',
  '078': '34111187',
  '525': '34265629',
  '355': '34335592',
  '531': '34337707',
  '367': '34711571',
  '597': '34747388',
  '528': '34829992',
  '445': '35551187',
  '586': '35810871',
  '373': '35977097',
  '111': '36113876',
  '512': '36266751',
  '516': '36583700',
  '408': '36586946',
  '484': '36864992',
  '402': '36947229',
  '507': '37229413',
  '404': '37241230',
  '418': '37414009',
  '414': '37526080',
  '449': '37555231',
  '518': '37679449',
  '406': '37715993',
  '403': '37880206',
  '419': '38129006',
  '435': '38224857',
  '455': '38429045',
  '544': '38593706',
  '421': '39343350',
  '443': '39416705',
  '535': '39519944',
  '457': '39587424',
  '428': '39664698',
  '448': '39669186',
  '452': '39676772',
  '510': '39738065',
  '462': '39908427',
  '465': '40083667',
  '563': '40276692',
  '306': '40303299',
  '556': '40333582',
  '305': '40353377',
  '463': '40434681',
  '590': '40473435',
  '451': '40475846',
  '444': '40654622',
  '519': '40768766',
  '454': '41592532',
  '460': '42047025',
  '506': '42066258',
  '482': '42259084',
  '017': '42272526',
  '174': '43180355',
  '481': '43599047',
  '521': '44019481',
  '433': '44077014',
  '495': '44189447',
  '523': '44292580',
  '527': '44478623',
  '511': '44683140',
  '520': '44705774',
  '513': '44728700',
  '125': '45246410',
  '547': '45331622',
  '532': '45745537',
  '537': '45756448',
  '524': '45854066',
  '526': '46026562',
  '488': '46518205',
  '558': '46955383',
  '522': '47593544',
  '530': '47873449',
  '594': '48703388',
  '553': '48707451',
  '065': '48795256',
  '559': '49288113',
  '492': '49336860',
  '579': '49555647',
  '568': '49933388',
  '145': '50579044',
  '250': '50585090',
  '620': '51342763',
  '572': '51414521',
  '354': '52904364',
  '253': '52937216',
  '269': '53518684',
  '213': '54403563',
  '139': '55230916',
  '018': '57839805',
  '422': '58160789',
  '630': '58497702',
  '224': '58616418',
  '393': '59109165',
  '600': '59118133',
  '390': '59274605',
  '623': '59285411',
  '655': '59588111',
  '479': '60394079',
  '456': '60498557',
  '464': '60518222',
  '341': '60701190',
  '237': '60746948',
  '381': '60814191',
  '613': '60850229',
  '652': '60872504',
  '637': '60889128',
  '653': '61024352',
  '069': '61033106',
  '370': '61088183',
  '249': '61182408',
  '318': '61186680',
  '626': '61348538',
  '508': '61384004',
  '270': '61444949',
  '366': '61533584',
  '113': '61723847',
  '131': '61747085',
  '011': '61809182',
  '611': '61820817',
  '755': '62073200',
  '089': '62109566',
  '643': '62144175',
  '140': '62169875',
  '707': '62232889',
  '288': '62237649',
  '363': '62285390',
  '101': '62287735',
  '487': '62331228',
  '233': '62421979',
  '177': '65913436',
  '438': '67030395',
  '365': '68757681',
  '633': '68900810',
  '581': '70119680',
  '218': '71027866',
  '293': '71590442',
  '285': '71677850',
  '514': '73302408',
  '080': '73622748',
  '565': '74014747',
  '753': '74828799',
  '222': '75647891',
  '281': '76461557',
  '754': '76543115',
  '311': '76641497',
  '098': '78157146',
  '610': '78626983',
  '712': '78632767',
  '584': '80230774',
  '720': '80271455',
  '010': '81723108',
  '440': '82096447',
  '580': '87437687',
  '442': '87963450',
  '283': '89960090',
  '033': '90400888',
  '217': '91884981',
  '041': '92702067',
  '543': '92825397',
  '117': '92856905',
  '654': '92874270',
  '371': '92875780',
  '212': '92894922',
  '289': '94968518',
  '001': '00000000',
  '003': '04902979',
  '004': '07237373',
  '012': '04866275',
  '014': '09274232',
  '015': '02819125',
  '016': '04715685',
  '025': '03323840',
  '036': '06271464',
  '037': '04913711',
  '040': '03609817',
  '060': '04913129',
  '062': '03012230',
  '063': '04184779',
  '064': '04332281',
  '066': '02801938',
  '070': '00000208',
  '074': '03017677',
  '075': '03532415',
  '076': '07656500',
  '077': '00416968',
  '079': '09516419',
  '082': '07679404',
  '084': '02398976',
  '085': '05463212',
  '091': '01634601',
  '093': '07945233',
  '096': '00997185',
  '097': '04632856',
  '099': '03046391',
  '100': '00806535',
  '102': '02332886',
  '104': '00360305',
  '105': '07652226',
  '108': '01800019',
  '114': '05790149',
  '127': '09512542',
  '130': '09313766',
  '136': '00315557',
  '141': '09526594',
  '143': '02992317',
  '157': '09105360',
  '159': '05442029',
  '180': '02685483',
  '183': '09210106',
  '189': '07512441',
  '190': '03973814',
  '191': '04257795',
  '195': '07799277',
  '259': '08609934',
  '272': '00250699',
  '273': '08253539',
  '286': '07853842',
  '290': '08561701',
  '296': '04062902',
  '299': '04814563',
  '307': '03751794',
  '312': '07693858',
  '315': '03502968',
  '320': '07450604',
  '322': '01073966',
  '326': '03311443',
  '328': '05841967',
  '330': '00556603',
  '340': '09554480',
  '350': '01330387',
  '358': '09464032',
  '359': '05351887',
  '360': '02276653',
  '362': '01027058',
  '364': '09089356',
  '368': '08357240',
  '378': '01852137',
  '379': '01658426',
  '382': '04307598',
  '385': '03844699',
  '387': '03215790',
  '391': '08240446',
  '394': '07207996',
  '395': '08673569',
  '399': '01701201',
  '400': '05491616',
  '407': '00329598',
  '410': '05684234',
  '411': '05192316',
  '413': '01858774',
  '423': '00460065',
  '425': '03881423',
  '429': '05676026',
  '430': '00204963',
  '458': '07253654',
  '459': '04546162',
  '468': '04862600',
  '469': '07138049',
  '471': '04831810',
  '534': '00714671',
  '539': '00122327',
  '540': '04849745',
  '541': '00954288',
  '548': '06249129',
  '555': '02682287',
  '578': '01235921',
  '739': '00558456',
  '741': '00517645',
  '743': '00795423',
  '747': '01023570',
  '748': '01181521',
  '752': '01522368',
  '756': '02038232',
  '757': '02318507',
};

const BANK_LIST_OBJECT: { [key: string]: string } = BANK_LIST_ARRAY.reduce(
  (obj, bank) => {
    return {
      ...obj,
      [bank.value]: bank.label,
    };
  },
  {},
);

export { BANK_LIST_ARRAY, BANK_LIST_OBJECT };
