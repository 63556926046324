import { EInputType, TFilterItems } from '../InputDropdown/InputDropdown';
import {
  InterestTypes,
  IofTypes,
  PosFixedTypes,
  PreFixedTypes,
  ScheduleTypes,
  SimulationTypes,
  TacValueType,
  YesNoOptions,
} from './types';

export const YES_NO = [
  { value: YesNoOptions.NO, label: 'Não' },
  { value: YesNoOptions.YES, label: 'Sim' },
];

export const INTEREST_GRACE_PERIODS = [
  { value: '1', label: '1 mês de carência' },
  { value: '2', label: '2 meses de carência' },
  { value: '3', label: '3 meses de carência' },
  { value: '4', label: '4 meses de carência' },
  { value: '5', label: '5 meses de carência' },
  { value: '6', label: '6 meses de carência' },
];

export const AMORTIZATION_TYPES = [
  { value: '15', label: 'Quinzenal' },
  { value: '30', label: 'Mensal' },
  { value: '60', label: 'Bimestral' },
  { value: '180', label: 'Semestral' },
  { value: '360', label: 'Anual' },
];

export const IOF_TYPES = [
  { value: IofTypes.PERSON, label: 'PF' },
  { value: IofTypes.BUSINESS, label: 'PJ' },
  { value: IofTypes.BUSINESS_SIMPLE, label: 'PJ Simples' },
  { value: IofTypes.ZERO, label: 'Zerado' },
];

export const SCHEDULE_TYPES = [
  { value: ScheduleTypes.DAILY, label: 'Diário' },
  { value: ScheduleTypes.WEEKLY, label: 'Semanal' },
  { value: ScheduleTypes.BIWEEKLY, label: 'Quinzenal' },
  { value: ScheduleTypes.MONTHLY, label: 'Mensal' },
];

export const SCHEDULE_TYPES_TRANSLATED_EN_PT: { [key: string]: string } = {
  [ScheduleTypes.DAILY]: 'Diário',
  [ScheduleTypes.WEEKLY]: 'Semanal',
  [ScheduleTypes.BIWEEKLY]: 'Quinzenal',
  [ScheduleTypes.MONTHLY]: 'Mensal',
};

export const SIMULATION_TYPES = [
  { value: SimulationTypes.PRICE, label: 'Price' },
  { value: SimulationTypes.AMERICAN, label: 'Amortização Americana' },
  // { value: SimulationTypes.IRREGULAR, label: 'Irregular' }, Fora de escopo no momento
];

export const INTEREST_TYPES = [
  { value: InterestTypes.PRE, label: 'Pré-fixado' },
  { value: InterestTypes.POS, label: 'Pós-fixado' },
];

export const TAC_TYPES: TFilterItems[] = [
  {
    name: 'Monetário',
    parameterName: TacValueType.MONETARY,
    type: EInputType.CURRENCY,
  },
  {
    name: 'Porcentagem',
    parameterName: TacValueType.PERCENTAGE,
    type: EInputType.NUMBER,
  },
];

export const TABS_CONTENT = [
  'Valor Solicitado',
  'Total Devido',
  'Valor da Parcela',
  'Valor Financiado',
];

export const TABS_KEYS = [
  'requestedAmount',
  'totalAmountOwed',
  'paymentValue',
  'financedAmount',
];

export const PRE_FIXED_TYPES: TFilterItems[] = [
  {
    name: 'ao mês',
    parameterName: PreFixedTypes.MONTHLY,
    type: EInputType.NUMBER,
  },
  // { Fora do escopo no momento
  //   name: 'ao ano',
  //   parameterName: PreFixedTypes.YEARLY,
  //   type: EInputType.NUMBER,
  // },
];

export const POS_FIXED_TYPES: TFilterItems[] = [
  {
    name: 'CDI',
    parameterName: PosFixedTypes.CDI,
    type: EInputType.NUMBER,
  },
  {
    name: 'IPCA',
    parameterName: PosFixedTypes.IPCA,
    type: EInputType.NUMBER,
  },
  {
    name: 'IGP-M',
    parameterName: PosFixedTypes.IGPM,
    type: EInputType.NUMBER,
  },
  {
    name: 'Selic',
    parameterName: PosFixedTypes.SELIC,
    type: EInputType.NUMBER,
  },
  {
    name: 'TR',
    parameterName: PosFixedTypes.TR,
    type: EInputType.NUMBER,
  },
];
