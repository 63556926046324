import { CustomInput, CustomSelect } from 'app/components';
import { formatCPF, formatDecimalField, formatPhone } from 'app/helpers';
import { useFormik } from 'formik';
import { TRegisterPersonDataRequest } from 'modules/customer/context/PersonProvider/person.interfaces';
import { aboutStepSchema } from 'modules/customer/helpers/personValidation';
import { useCallback, useEffect } from 'react';
import { IPersonForm, workRelationOptions } from '../../types';
import {
  AboutStepExistingPerson,
  StepContentInput,
  StepContentInputArea,
} from '../../About/CreatePersonForm/styles';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

interface ICreatePersonFormProps {
  setPerson: (person: IPersonForm) => void;
  person: IPersonForm;
  existsPerson?: TRegisterPersonDataRequest | null;
}

const RepresentativeCreated = ({
  setPerson,
  person,
  existsPerson,
}: ICreatePersonFormProps) => {
  const formik = useFormik({
    initialValues: person || ({} as IPersonForm),
    validationSchema: aboutStepSchema,
    onSubmit: (values) => console.info(values),
  });

  const getFormatedPhoneFromExistingPerson = useCallback(() => {
    if (existsPerson) {
      const phoneNumber = existsPerson?.phone?.number || '';
      const areaCodeNumber = existsPerson?.phone?.area_code || '';

      if (!phoneNumber || !areaCodeNumber) return null;
      return formatPhone(areaCodeNumber + phoneNumber);
    }
    return null;
  }, [existsPerson]);

  const handleShare = (value: string) => {
    return formatDecimalField(value);
  };

  useEffect(() => {
    setPerson(formik.values);
  }, [formik.values]);

  return (
    <StepContentInputArea>
      <AboutStepExistingPerson style={{ width: '100%' }}>
        <span style={{ marginBottom: '8px' }}>
          <strong>{formatCPF(existsPerson?.taxpayer_id || '')}</strong>
        </span>
        <span>{existsPerson?.full_name}</span>
        {getFormatedPhoneFromExistingPerson() && (
          <span>{getFormatedPhoneFromExistingPerson()}</span>
        )}
        <span>{existsPerson?.email_address}</span>
        <span>...</span>
      </AboutStepExistingPerson>

      <StepContentInput>
        <CustomSelect
          labelValue="Assina pela empresa? *"
          name="signer"
          placeholder="Selecione"
          value={formik.values.signer}
          handleInputChange={formik.handleChange}
          options={[
            { value: 'true', label: 'Sim' },
            { value: 'false', label: 'Não' },
          ]}
        />
      </StepContentInput>
      <StepContentInput>
        <CustomSelect
          labelValue="Vínculo *"
          name="workRelation"
          placeholder="Selecione"
          value={formik.values.workRelation}
          handleInputChange={formik.handleChange}
          options={workRelationOptions}
        />
      </StepContentInput>
      <StepContentInput>
        <CustomInput
          labelValue="Participação em quotas (%)"
          placeholder="Ex: 50"
          value={formik.values?.share?.toString() || ''}
          handleInputChange={(e) => {
            formik.setFieldValue('share', handleShare(e.target.value));
          }}
          validationError={formik.touched.share ? formik.errors.share : ''}
        />
      </StepContentInput>
    </StepContentInputArea>
  );
};

export default RepresentativeCreated;
