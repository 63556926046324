import { ButtonPrimary, StepContent, TextL } from 'app/components';
import { useCallback, useContext, useState } from 'react';
import { IPerson, IPersonForm } from '../types';
import RepresentativesTable from './RepresentativesTable/RepresentativesTable';
import { LegalPersonHandlerStepperContext } from 'modules/customer/pages/LegalPersonHandler/LegalPersonHandler';
import { useTheme } from '@mui/material';
import CreatePersonForm from '../About/CreatePersonForm/CreatePersonForm';
import IdentificationStep from '../Identification/Identification';
import AddressStep from '../Address/Address';
import PersonAddIcon from 'app/components/Icons/PersonAddIcon';
import { StyledTitle } from './RepresentativesTable/styles';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { usePersonContext } from 'modules/customer/context';
import { IAddress } from '../Address/types';
import {
  EMaritalStatus,
  ESex,
  TRegisterPersonDataRequest,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import RepresentativeCreated from './RepresentativeCreated/RepresentativeCreated';

const RepresentativesContainer = () => {
  /* Hooks */
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { setRepresentatives, representatives, createPerson } =
    usePersonContext();
  const { handleSelectedRelationPersonListOrder } = useContext(
    LegalPersonHandlerStepperContext,
  );

  /* States */
  const [existsPerson, updateExistsPerson] = useState<
    TRegisterPersonDataRequest | null | undefined
  >();
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);
  const [representativeForm, setRepresentativeForm] = useState<IPersonForm>(
    {} as IPersonForm,
  );

  const [address, setAddress] = useState<IAddress>();

  const removePersonHandler = (fullName?: string) => {
    if (!fullName) {
      showSnackbar('Não foi possível excluir, tente novamente!');
      return;
    }

    setRepresentatives(
      representatives.filter((person) => person.full_name !== fullName),
    );
  };

  const clearHandler = () => {
    setRepresentativeForm({} as IPersonForm);
    setIsOpenCreateForm(false);
    setAddress(undefined);
    updateExistsPerson(null);
  };

  const handleTableItemsOrdering = () => {
    handleSelectedRelationPersonListOrder();
  };

  const addRepresentativeHandler = useCallback(async () => {
    const rawValue = representativeForm?.share || 0;
    const numericValue = Number(rawValue.toString().replace(/\D/g, '')) / 10;

    if (existsPerson?.id) {
      setRepresentatives([
        ...representatives,
        {
          ...({} as IPerson),
          id: existsPerson.id,
          taxpayer_id: existsPerson.taxpayer_id,
          full_name: existsPerson.full_name,
          share: numericValue,
          workRelation: representativeForm.workRelation,
          role: representativeForm.role?.value,
          signer: representativeForm.signer,
        },
      ]);

      clearHandler();
      return;
    }

    const data = { ...representativeForm, address: address };

    const response: IPerson | undefined = await createPerson({
      birth_date: data?.birth_date,
      email_address: data?.email_address,
      full_name: data?.full_name?.trim() || '',
      nationality: data?.nationality?.trim() || '',
      occupation: data?.occupation?.trim() || '',
      pep: data?.pep && data.pep?.value ? data.pep.value === 'true' : undefined,
      marital_status:
        (data?.marital_status?.value as EMaritalStatus) || undefined,
      mothers_name: data?.mothers_name ? data?.mothers_name?.trim() : undefined,
      sex: data?.sex?.value as ESex,
      phone: {
        area_code: data?.phone
          ? data?.phone.replace(/\D+/g, '').slice(0, 2)
          : '',
        country_code: data?.phone || '55',
        number: data?.phone ? data?.phone.replace(/\D+/g, '').slice(2) : '',
      },
      taxpayer_id: data?.taxpayer_id?.replace(/\D+/g, '') || '',
      address: {
        city: data?.address?.city?.value.trim() || '',
        state_code: data?.address?.state_code?.value || '',
        country_code: '+55',
        district: data?.address?.district?.trim() || '',
        extra_info: data?.address?.extra_info?.trim() || '',
        postal_code: data?.address?.postal_code?.replace(/\D+/g, '') || '',
        street_name: data?.address?.street_name?.trim() || '',
        street_number: data?.address?.street_number?.trim() || '',
      },
      monthly_income: parseFloat(data?.monthly_income || '0'),
      birthplace: data?.birthplace,
      share: numericValue ?? 0,
      signer: data.signer?.value,
      workRelation: data.workRelation?.value,
      identification: data.identification,
      aditional_information: data?.aditional_information,
    });

    if (response) {
      setRepresentatives([
        ...representatives,
        {
          ...response,
          share: representativeForm.share,
          workRelation: representativeForm.workRelation,
          role: representativeForm.role?.value,
          signer: representativeForm.signer,
        },
      ]);
    }

    clearHandler();
  }, [representativeForm, representatives, address]);

  return (
    <StepContent
      title="Representantes"
      action={
        !isOpenCreateForm && (
          <div>
            <ButtonPrimary
              typeVariant="fill"
              style={{ marginTop: '2rem', width: '15rem' }}
              onClick={() => setIsOpenCreateForm(true)}
            >
              <div style={{ marginRight: '10px' }}>Cadastrar</div>
              <PersonAddIcon color={theme.palette.common.white} size={22} />
            </ButtonPrimary>
          </div>
        )
      }
    >
      <RepresentativesTable
        items={representatives}
        removePerson={(person) => removePersonHandler(person?.full_name)}
        handleOrdering={handleTableItemsOrdering}
      />

      {isOpenCreateForm && (
        <>
          <StyledTitle>Cadastrar Representante</StyledTitle>
          {existsPerson ? (
            <RepresentativeCreated
              person={representativeForm}
              setPerson={(person) =>
                setRepresentativeForm({ ...person, ...representativeForm })
              }
              existsPerson={existsPerson}
            />
          ) : (
            <CreatePersonForm
              setRepresentativeAddres={setAddress}
              isRepresentative={true}
              person={representativeForm}
              existsPerson={existsPerson}
              updateExistsPerson={updateExistsPerson}
              setPerson={(person) =>
                setRepresentativeForm({ ...person, ...representativeForm })
              }
            />
          )}

          {!existsPerson && (
            <>
              <TextL weight="medium" style={{ marginTop: '1rem' }}>
                Identificação
              </TextL>
              <IdentificationStep
                identification={representativeForm?.identification}
                setIdentification={(identification) =>
                  setRepresentativeForm({
                    ...representativeForm,
                    identification: identification,
                  })
                }
                hideTitle
              />
              <TextL weight="medium" style={{ marginTop: '1rem' }}>
                Endereço
              </TextL>
              <AddressStep
                address={address}
                setAddress={setAddress}
                hideTitle
              />
            </>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ButtonPrimary
              typeVariant="ghost"
              onClick={clearHandler}
              style={{
                margin: '2rem',
                width: '8rem',
              }}
            >
              Cancelar
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant="fill"
              disabled={
                !representativeForm.signer || !representativeForm.workRelation
              }
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                width: '12rem',
              }}
              onClick={addRepresentativeHandler}
            >
              <div style={{ marginRight: '10px' }}>Cadastrar</div>
              <PersonAddIcon color={theme.palette.common.white} size={22} />
            </ButtonPrimary>
          </div>
        </>
      )}
    </StepContent>
  );
};

export default RepresentativesContainer;
