import {
  CalendarInput,
  ConfirmModal,
  CustomCurrencyInput,
  CustomInput,
  CustomSelect,
} from 'app/components';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { formatCNPJ, formatPhone, isValidCNPJ } from 'app/helpers';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { useFormik } from 'formik';
import {
  TRegisterBusinessDataRequest,
  usePersonContext,
} from 'modules/customer/context';
import { ETaxRegime } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { aboutStepSchema } from 'modules/customer/helpers/businessValidation';
import { INCORPORATION_TYPES } from 'modules/customer/pages/LegalPersonHandler/steps/AboutStep';
import { AboutStepExistingBusiness } from 'modules/customer/pages/LegalPersonHandler/steps/styles';
import { useCustomerService } from 'modules/customer/services';
import { useCallback, useEffect, useState } from 'react';
import { ICompanyForm } from '../../types';
import { TReactChangeInput } from '../CreatePersonForm/CreatePersonForm';
import { StepContentInput, StepContentInputArea } from './styles';
import dayjs from 'dayjs';

const CreateCompanyForm = () => {
  /* Hooks */
  const { setPersonCompany, personCompany } = usePersonContext();
  const { getBusinessList } = useCustomerService();

  /* States */
  const [isCheckingBusiness, setIsCheckingBusiness] = useState(false);
  const [isOpenExistingBusinessModal, setIsOpenExistingBusinessModal] =
    useState(false);
  const [existsBusiness, setExistsBusiness] =
    useState<TRegisterBusinessDataRequest | null>(null);

  const formik = useFormik({
    initialValues: personCompany || ({} as ICompanyForm),
    validationSchema: aboutStepSchema,
    onSubmit: (values) => console.info(values),
  });

  const onChangeTaxpayerId = useCallback(
    async (taxpayerId: string): Promise<boolean> => {
      try {
        if (!isValidCNPJ(taxpayerId)) return false;
        setIsCheckingBusiness(true);
        const business = await getBusinessList(
          0,
          'taxpayer_id',
          taxpayerId,
          undefined,
          true,
        );
        if (business && business.content.length > 0) {
          setExistsBusiness(business.content[0]);
          setIsOpenExistingBusinessModal(true);
          return true;
        }
        setExistsBusiness(null);
        return false;
      } catch {
        return false;
      } finally {
        setIsCheckingBusiness(false);
      }
    },
    [],
  );

  const getFormatedPhoneFromExistingBusiness = useCallback(() => {
    if (existsBusiness) {
      const phoneNumber = existsBusiness?.phone?.number || '';
      const areaCodeNumber = existsBusiness?.phone?.area_code || '';

      if (!phoneNumber || !areaCodeNumber) return null;
      return formatPhone(areaCodeNumber + phoneNumber);
    }
    return null;
  }, [existsBusiness]);

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_revenue',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleShareCapitalChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'share_capital',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  useEffect(() => {
    setPersonCompany(formik.values);
  }, [formik.values, setPersonCompany]);

  return (
    <>
      <StepContentInputArea>
        <StepContentInput>
          <CustomInput
            name="taxpayer_id"
            id="taxpayer_id"
            isLoading={isCheckingBusiness}
            value={formatCNPJ(formik.values?.taxpayer_id || '')}
            handleInputChange={(e) => {
              formik.handleChange(e);
              const taxpayerId = e?.currentTarget?.value;
              if (taxpayerId.length >= 18) {
                onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
              }
            }}
            onBlur={formik.handleBlur}
            placeholder="00.000.000/0000-00"
            labelValue="CNPJ*"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="legal_name"
            value={formik.values?.legal_name || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Empresa S.A."
            labelValue="Razão social*"
            validationError={
              formik.touched.legal_name ? formik.errors.legal_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="name"
            value={formik.values?.name || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Empresa XPTO"
            labelValue="Nome fantasia"
            validationError={formik.touched.name ? formik.errors.name : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values?.phone || '') || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone*"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="email_address"
            value={formik.values?.email_address || ''}
            handleInputChange={formik.handleChange}
            placeholder="exemplo@email.com"
            labelValue="E-mail*"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            maxDate={dayjs().subtract(1, 'day').endOf('day').toDate()}
            name="foundation_date"
            labelValue="Data de Constituição*"
            placeholder="dd/mm/aaaa"
            value={formik.values?.foundation_date}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'foundation_date',
                },
              })
            }
            validationError={
              formik.touched.foundation_date
                ? formik.errors.foundation_date
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Faturamento mensal"
            value={formik.values?.monthly_revenue || ''}
            handleInputChange={handleMonthlyIncomeChange}
            name="monthly_revenue"
            validationError={
              formik.touched.monthly_revenue
                ? formik.errors.monthly_revenue
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Capital Social"
            value={formik.values?.share_capital || ''}
            handleInputChange={handleShareCapitalChange}
            name="share_capital"
            validationError={
              formik.touched.share_capital ? formik.errors.share_capital : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Regime Fiscal"
            name="tax_regime"
            placeholder="Selecione o regime fiscal"
            value={formik.values?.tax_regime}
            handleInputChange={formik.handleChange}
            options={[
              { value: ETaxRegime.SIMPLE, label: 'Simples Nacional' },
              { value: ETaxRegime.REAL_PROFIT, label: 'Lucro real' },
              { value: ETaxRegime.PRESUMED_PROFIT, label: 'Lucro presumido' },
            ]}
            validationError={
              formik.touched.tax_regime ? formik.errors.tax_regime : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Formato Jurídico"
            name="incorporation_type"
            placeholder="Selecione o regime fiscal"
            value={formik.values?.incorporation_type}
            handleInputChange={formik.handleChange}
            options={INCORPORATION_TYPES}
            validationError={
              formik.touched.tax_regime ? formik.errors.tax_regime : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="industry_classification"
            value={formik.values?.industry_classification || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Comércio de roupas e acessórios"
            labelValue="Classificação da indústria"
            validationError={
              formik.touched.industry_classification
                ? formik.errors.industry_classification
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>

      <ConfirmModal
        isColumnButtons={true}
        isOpen={isOpenExistingBusinessModal}
        icon={<AttentionCircularIcon />}
        title="O CNPJ informado já está cadastrado"
        btnConfirmText="Limpar e informar outro CNPJ"
        handleConfirm={() => {
          formik.setFieldValue('taxpayer_id', '');
          formik.setFieldTouched('taxpayer_id', false);
          setExistsBusiness(null);
          setIsOpenExistingBusinessModal(false);
          setTimeout(() => {
            document.querySelector<HTMLInputElement>('#taxpayer_id')?.focus();
          });
        }}
        handleClose={() => setIsOpenExistingBusinessModal(false)}
        handleCancel={() => setIsOpenExistingBusinessModal(false)}
      >
        <AboutStepExistingBusiness>
          <span style={{ marginBottom: '8px' }}>
            <strong>{formatCNPJ(existsBusiness?.taxpayer_id || '')}</strong>
          </span>
          <span>{existsBusiness?.legal_name}</span>
          {getFormatedPhoneFromExistingBusiness() && (
            <span>{getFormatedPhoneFromExistingBusiness()}</span>
          )}
          <span>{existsBusiness?.email_address}</span>
        </AboutStepExistingBusiness>
      </ConfirmModal>
    </>
  );
};

export default CreateCompanyForm;
