import React, { useCallback, useMemo, useState } from 'react';
import { Heading4, TextM } from '../typography';
import { useTheme } from '@mui/material';
import { Accordion } from '../Accordion';
import {
  CreditSimulatorScheduleContainer,
  DisbursementDatesSelector,
  NumPaymentContent,
} from './styles';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { CustomSelect } from '../CustomSelect';
import {
  TSimulatorV2Request,
  TSimulatorV2Response,
} from 'app/services/simulatorHttpServiceV2';
import { TProducts } from 'modules/products/context';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { Table } from '../Table';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { formatCurrency } from 'app/utils/normalizer';
import { CopyIcon } from '../Icons';
import { ButtonPrimary } from '../ButtonPrimary';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { CustomTableWrapper } from '../Table/styles';

const tableHeadContent = [
  { id: '1', content: null },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Valor no vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Amortização</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Juros</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">IOF</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
];

type CreditSimulatorScheduleProps = {
  product?: TProducts | null;
  simulationRequest?: TSimulatorV2Request | null;
  simulationResponse?: TSimulatorV2Response | null;
  simulateWithDifferentDisbursementDate: (date: string) => void;
  noHeader?: boolean;
};

const CreditSimulatorSchedule = ({
  product,
  simulationRequest,
  simulationResponse,
  simulateWithDifferentDisbursementDate,
  noHeader,
}: CreditSimulatorScheduleProps) => {
  const theme = useTheme();
  const { showSnackbarCopy } = useSnackbar();
  const [isAccordionOpen, toggleIsAccordionOpen] = useState(true);

  const baseIofPerInstallment = useMemo(() => {
    const sum_running_iof =
      simulationResponse?.schedule?.reduce((sum, j) => sum + j.iof, 0) || 0;

    if (!simulationResponse?.iof_amount || !simulationResponse.num_periods) {
      return 0;
    }

    return (
      (simulationResponse?.iof_amount - sum_running_iof) /
      simulationResponse.num_periods
    );
  }, [simulationResponse]);

  const handleCopySchedule = useCallback(() => {
    const header =
      'Parcela\tValor no vencimento\tAmortização\tJuros\tIOF\tVencimento\r\n';
    const records = simulationResponse?.schedule
      ?.map((schedule, inx) => {
        const { payment, principal, interest, payment_date, iof } = schedule;
        const finalIof = baseIofPerInstallment + iof;
        return `${
          inx + 1
        }\t${payment.toLocaleString()}\t${principal.toLocaleString()}\t${interest.toLocaleString()}\t${finalIof}\t${payment_date}`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${records}`);
    showSnackbarCopy();
  }, [simulationResponse, baseIofPerInstallment]);

  const content = (
    <NumPaymentContent>
      {simulationResponse &&
      simulationRequest &&
      Array.isArray(simulationResponse?.schedule) &&
      simulationResponse?.schedule.length > 0 ? (
        <>
          {product?.multiple_disbursements_dates && (
            <DisbursementDatesSelector>
              <CustomSelect
                labelValue="Por data de desembolso"
                options={new Array(
                  differenceInDays(
                    startOfDay(new Date(simulationRequest.disbursementDate)),
                    startOfDay(new Date()),
                  ) + 1,
                )
                  .fill(0)
                  .map((_, index) => {
                    const date = new Date();
                    date.setDate(date.getDate() + index);
                    return {
                      label: format(date, 'dd/MM/yyyy'),
                      value: startOfDay(date).toISOString(),
                    };
                  })}
                handleInputChange={(evt) => {
                  const { value } = evt.currentTarget.value;
                  simulateWithDifferentDisbursementDate(value);
                }}
                isClearable={false}
                value={{
                  label: format(
                    formatDateFromBackend(simulationResponse.disbursement_date),
                    'dd/MM/yyyy',
                  ),
                  value: startOfDay(
                    formatDateFromBackend(simulationResponse.disbursement_date),
                  ).toISOString(),
                }}
                name="disbursementScheduleDate"
                placeholder="Selecionar"
              />
            </DisbursementDatesSelector>
          )}
          <CustomTableWrapper>
            <Table
              tableHeadCell={tableHeadContent}
              maxBodyHeight="45vh"
              tableHeadSticky
              tableBodyContent={simulationResponse?.schedule.map(
                (simulationData, inx) => {
                  const iof = baseIofPerInstallment + simulationData.iof;
                  return {
                    id: String(inx),
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextM style={{ margin: '0 auto' }} weight="Bold">
                              {inx + 1}
                            </TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextM>
                              {formatCurrency(simulationData.payment)}
                            </TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextM>
                              {formatCurrency(simulationData.principal)}
                            </TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextM>
                              {formatCurrency(simulationData.interest)}
                            </TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <TextM>{formatCurrency(iof)}</TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer>
                            <TextM>
                              {formatDateFromBackend(
                                simulationData.payment_date,
                              ).toLocaleDateString('pt-BR')}
                            </TextM>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                },
              )}
              tableFooterCell={[
                {
                  id: '1',
                  content: null,
                },
                {
                  id: '2',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>Total</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              simulationResponse?.schedule
                                .map((schedule) => schedule.payment)
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '3',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>Total</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              simulationResponse?.schedule
                                .map((schedule) => schedule.principal)
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '4',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>Total</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              simulationResponse?.schedule
                                .map((schedule) => schedule.interest)
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '5',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>Total</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              simulationResponse?.schedule
                                .map(
                                  (schedule) =>
                                    baseIofPerInstallment + schedule.iof,
                                )
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '6',
                  content: null,
                },
              ]}
            />
          </CustomTableWrapper>
          <ButtonPrimary
            style={{
              backgroundColor: 'transparent',
              width: 'fit-content',
              margin: '15px auto 0px',
            }}
            typeVariant="outline"
            btnIcon={
              <CopyIcon color={theme.palette.brand.primary.base as string} />
            }
            iconPosition="left"
            onClick={handleCopySchedule}
          >
            <TextM
              weight="Semibold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base,
              }}
            >
              Copiar resultado
            </TextM>
          </ButtonPrimary>
        </>
      ) : (
        <TextM>
          Nenhuma parcela disponível. Conclua primeiro a simulação no bloco de
          condições.
        </TextM>
      )}
    </NumPaymentContent>
  );

  return noHeader ? (
    content
  ) : (
    <CreditSimulatorScheduleContainer>
      <Accordion
        cleanStyle={true}
        isOpen={isAccordionOpen}
        title={
          <Heading4 style={{ color: '#1E1360' }}>Visualizar parcelas</Heading4>
        }
        onClick={() => toggleIsAccordionOpen(!isAccordionOpen)}
      >
        {content}
      </Accordion>
    </CreditSimulatorScheduleContainer>
  );
};

export default CreditSimulatorSchedule;
