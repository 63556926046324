import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  CreditSimulatorContent,
  CreditSimulatorHeader,
  CreditSimulatorWrapper,
} from './styles';
import { Tabs } from '../Tabs';
import { Heading3 } from '../typography';
import { TProducts } from 'modules/products/context/ProductsProvider/products.interfaces';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { TABS_CONTENT } from './constants';
import CreditSimulatorForm, {
  CreditSimulatorFormRefs,
} from './CreditSimulatorForm';
import {
  TSimulatorV2Request,
  TSimulatorV2Response,
} from 'app/services/simulatorHttpServiceV2';
import useCreditSimulatorV2 from 'app/hooks/useCreditSimulatorV2';
import { ValidateBilletsResponse } from 'modules/products/interfaces/billets';
import { ValidateEmvResponse } from 'modules/products/interfaces/qrCode';
import CreditSimulatorPreview, {
  CreditSimulatorPreviewRefs,
} from './CreditSimulatorPreview';
import CreditSimulatorSchedule from './CreditSimulatorSchedule';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { formatCurrency, formatToFixed } from 'app/utils/normalizer';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';

export type CreditSimulatorRefs = {
  simulate: () => void;
  reset: () => void;
  copyPreview: () => void;
  isValid: boolean;
  isLoading: boolean;
};
type CreditSimulatorProps = {
  product?: TProducts | null;
  qualification?: TQualification | null;
  simulationResponse?: TSimulatorV2Response | null;
  simulationRequest: TSimulatorV2Request | null;
  billetValidationResponse?: ValidateBilletsResponse | null;
  qrcodeEmvValidationResponse?: ValidateEmvResponse | null;
  onSimulate?: (
    simulation: TSimulatorV2Response,
    simulationRequest: TSimulatorV2Request,
    tabIndex: string,
  ) => void;
  onChangeFormMeta?: (isLoading: boolean, isValid: boolean) => void;
};

const CreditSimulator = forwardRef<CreditSimulatorRefs, CreditSimulatorProps>(
  (
    {
      product,
      qualification,
      simulationResponse,
      simulationRequest,
      billetValidationResponse,
      qrcodeEmvValidationResponse,
      onSimulate,
      onChangeFormMeta,
    },
    ref,
  ) => {
    const formRef = useRef<CreditSimulatorFormRefs>(null);
    const previewRef = useRef<CreditSimulatorPreviewRefs>(null);

    const [tabIndex, setTabIndex] = useState('0');
    const { showSnackbarCopy } = useSnackbar();
    const [simulationResponseData, setSimulationResponseData] =
      useState<TSimulatorV2Response | null>(simulationResponse ?? null);
    const { simulate, simulationLoading } = useCreditSimulatorV2();

    const handleCopyPreview = () => {
      navigator.clipboard.writeText(
        `Quant. parcelas: ${
          simulationResponseData?.num_periods
            ? simulationResponseData?.num_periods
            : '-'
        }\nValor da parcela: ${
          simulationResponseData?.payment_amount
            ? formatCurrency(simulationResponseData?.payment_amount)
            : '-'
        }\nValor liberado: ${
          simulationResponseData?.requested_amount
            ? formatCurrency(simulationResponseData?.requested_amount || 0.0)
            : '-'
        }\nValor financiado: ${
          simulationResponseData?.financed_amount
            ? formatCurrency(simulationResponseData?.financed_amount || 0.0)
            : '-'
        }\nJuros: ${
          simulationResponseData?.interest_rate
            ? `${formatToFixed(simulationResponseData?.interest_rate * 100, 2)
                .toString()
                .replace('.', ',')}% a.m.`
            : '-'
        }\nCET: ${
          typeof simulationResponseData?.monthly_effective_interest_rate ===
          'number'
            ? `${formatToFixed(
                simulationResponseData?.monthly_effective_interest_rate * 100,
                2,
              )
                .toString()
                .replace('.', ',')}% a.m.`
            : '-'
        }\nTC: ${
          simulationResponseData?.tac_amount
            ? formatCurrency(simulationResponseData?.tac_amount)
            : '-'
        }\nIOF: ${
          simulationResponseData?.iof_amount
            ? formatCurrency(simulationResponseData?.iof_amount)
            : '-'
        }\nOutros custos: ${
          simulationResponseData?.finance_fee
            ? formatCurrency(simulationResponseData?.finance_fee)
            : '-'
        }\nPrimeiro vencimento: ${
          simulationResponseData?.first_payment_date
            ? formatDateFromBackend(
                simulationResponseData?.first_payment_date,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nÚltimo vencimento: ${
          simulationResponseData?.last_payment_date
            ? formatDateFromBackend(
                simulationResponseData?.last_payment_date,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nSaldo Total: ${
          simulationResponseData?.total_amount_owed
            ? formatCurrency(simulationResponseData?.total_amount_owed)
            : '-'
        }`,
      );
      showSnackbarCopy();
    };

    useImperativeHandle(
      ref,
      () => ({
        simulate: () => {
          formRef.current?.simulate();
        },
        reset: () => {
          formRef.current?.reset();
        },
        copyPreview: handleCopyPreview,
        isValid: !!previewRef.current?.isValid,
        isLoading: simulationLoading,
      }),
      [previewRef.current?.isValid, simulationLoading],
    );

    useEffect(() => {
      if (typeof onChangeFormMeta === 'function')
        onChangeFormMeta(simulationLoading, !!previewRef.current?.isValid);
    }, [simulationLoading, previewRef.current?.isValid]);

    const onFormSuccess = (data: TSimulatorV2Request) => {
      if (product) {
        simulate(product.id, data).then((response) => {
          if (response) {
            setSimulationResponseData(response);
            if (typeof onSimulate === 'function')
              onSimulate(response, data, tabIndex);
          }
        });
      }
    };

    const simulateWithDifferentDisbursementDate = (date: string) => {
      if (product && simulationRequest) {
        simulate(product.id, {
          ...simulationRequest,
          disbursementDate: date,
        }).then((response) => {
          if (response) {
            setSimulationResponseData(response);
          }
        });
      }
    };

    const handleChangeTabIndex = (value: number) => {
      setTabIndex(String(value));
    };

    return (
      <CreditSimulatorWrapper>
        <CreditSimulatorHeader>
          <Heading3 style={{ color: '#1E1360' }}>
            Valores da solicitação
          </Heading3>
          <Tabs
            className="credit-simulator-tabs"
            content={TABS_CONTENT}
            selectedIndex={Number(tabIndex)}
            style={{ fontSize: '16px' }}
            handleClick={handleChangeTabIndex}
          />
        </CreditSimulatorHeader>
        <CreditSimulatorContent>
          <CreditSimulatorForm
            ref={formRef}
            tabIndex={tabIndex}
            product={product}
            simulationRequest={simulationRequest}
            billetValidationResponse={billetValidationResponse}
            qrcodeEmvValidationResponse={qrcodeEmvValidationResponse}
            onFormSuccess={onFormSuccess}
          />
          <CreditSimulatorSchedule
            product={product}
            simulationRequest={simulationRequest}
            simulationResponse={simulationResponseData}
            simulateWithDifferentDisbursementDate={
              simulateWithDifferentDisbursementDate
            }
          />
          <CreditSimulatorPreview
            ref={previewRef}
            simulationResponse={simulationResponseData}
            qualification={qualification}
          />
        </CreditSimulatorContent>
      </CreditSimulatorWrapper>
    );
  },
);

CreditSimulator.displayName = 'CreditSimulator';
export default CreditSimulator;
