import { BaseHttpService } from 'app/services';
import {
  FundingAccount,
  GetBankStatementServiceProps,
  RequestConciliationReportProps,
  WithdrawRequest,
  WithdrawStatusResponse,
} from 'modules/disbursement/interfaces/disbursement';

export class DisbursementHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}`;

  withdraw = async (
    fundingId: string,
    params: WithdrawRequest,
  ): Promise<WithdrawRequest> => {
    this.setHeader({
      funding: fundingId,
      handled: true,
    });
    const res = await this.post(
      `${this.BASE_URL}/proxy/funding/account/withdraw`,
      params,
    );
    return res.data as WithdrawRequest;
  };

  getWithdrawStatus = async (
    fundingId: string,
    identifier: string,
  ): Promise<WithdrawStatusResponse> => {
    this.setHeader({
      funding: fundingId,
    });
    const res = await this.get(
      `${this.BASE_URL}/proxy/funding/account/withdraw/${identifier}`,
    );
    return res.data as WithdrawStatusResponse;
  };

  getBalance = async (fundingId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/disbursement/${fundingId}/balance`,
    );
    return res;
  };

  getAccountSettings = async (fundingId: string) => {
    this.setHeader({
      funding: fundingId,
    });
    const res = await this.get(
      `${this.BASE_URL}/proxy/funding/account/settings`,
    );
    return res;
  };

  getAccount = async (fundingId: string): Promise<FundingAccount> => {
    this.setHeader({
      funding: fundingId,
    });
    const res = await this.get(`${this.BASE_URL}/proxy/funding/account`);
    return res.data as FundingAccount;
  };

  getMovements = async ({
    id,
    dateFrom,
    dateTo,
    page,
    linesPerPage,
    filters,
  }: {
    id: string;
    dateFrom: string;
    dateTo: string;
    page: number;
    linesPerPage: number;
    filters: string;
  }) => {
    const res = await this.get(
      `${this.BASE_URL}/disbursement/${id}/movements?page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}&limitPerPage=${linesPerPage}${filters}`,
    );
    return res;
  };

  requestConciliationReport = async ({
    fundingId,
    dateFrom,
    dateTo,
    columns,
    filters,
    email,
    format,
    type,
  }: RequestConciliationReportProps) => {
    const parsedFilters: { [key: string]: string } = {};

    Object.keys(filters).forEach((filterKey) => {
      if (Array.isArray(filters[filterKey])) {
        parsedFilters[filterKey] = (filters[filterKey] as string[]).join(',');
        return;
      }
      parsedFilters[filterKey] = filters[filterKey] as string;
    });

    const res = await this.post(
      `${this.BASE_URL}/disbursement/${fundingId}/conciliation-report`,
      {
        dateFrom,
        dateTo,
        columns,
        filters: parsedFilters,
        email,
        format,
        type,
      },
    );
    return res;
  };

  getBankStatement = async (props: GetBankStatementServiceProps) => {
    const { id, dateFrom, dateTo, filters, linesPerPage, page } = props;

    const res = await this.get(
      `${this.BASE_URL}/disbursement/${id}/bank-statement?page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}&limitPerPage=${linesPerPage}${filters}`,
    );

    return res;
  };
}
