import { IList } from 'modules/products/services/hooks/intefaces';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { BaseHttpService } from '../../../../app/services';
import { TRegisterPersonDataRequest } from '../../context';
import {
  IPersonBusinessList,
  IPersonList,
  TBatchImportResponse,
  TCreatePersonDataRequest,
} from '../../context/PersonProvider/person.interfaces';
import {
  TLimit,
  TQualification,
} from 'modules/customer/interfaces/qualifications';
import { IUpatePersonData } from './usePersonService';

export class PersonHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  creatPerson = async (data: TCreatePersonDataRequest) => {
    const res = await this.post(`${this.BASE_URL}/originator/persons`, data);
    return res;
  };

  getPersonList = async (
    page: number,
    searchParameterName?: string,
    searchParameterValue?: string,
    itemsPerPage?: number,
    isIssuer?: boolean,
  ): Promise<IPersonList> => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.BASE_URL}/${entity}/persons?page=${page}&size=${
        itemsPerPage || 10
      }${
        searchParameterValue
          ? `&${encodeURIComponent(
              searchParameterName || '',
            )}=${encodeURIComponent(searchParameterValue || '')}`
          : ''
      }`,
    );
    return res.data as IPersonList;
  };

  getPersonBusinessList = async (
    page: number,
    searchParameterName?: string,
    searchParameterValue?: string,
    itemsPerPage?: number,
  ): Promise<IPersonBusinessList> => {
    const res = await this.get(
      `${this.BASE_URL}/originator/person-business?page=${page}&size=${
        itemsPerPage || 10
      }${
        searchParameterValue
          ? `&${encodeURIComponent(
              searchParameterName || '',
            )}=${encodeURIComponent(searchParameterValue || '')}`
          : ''
      }`,
    );
    return res.data as IPersonBusinessList;
  };

  personBatchImport = async (
    csvFile: FormData,
  ): Promise<TBatchImportResponse> => {
    const res = await this.upload(
      `${this.BASE_URL}/originator/persons/batch-import`,
      csvFile,
    );
    return res.data as TBatchImportResponse;
  };

  getPersonBatchImportStatus = async (
    id: string,
  ): Promise<TBatchImportResponse> => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/batch-import/${id}`,
    );
    return res.data as TBatchImportResponse;
  };

  getPersonBatchImportResult = async (id: string): Promise<string> => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/batch-import/${id}/result-file`,
    );
    return res.data as string;
  };

  getPerson = async (
    personId: string,
    isIssuer?: boolean,
  ): Promise<TRegisterPersonDataRequest> => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.BASE_URL}/${entity}/persons/${personId}`,
    );
    return res.data as TRegisterPersonDataRequest;
  };

  getQualifications = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TQualification>> => {
    console.log('ESSA CHAMADA');
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/persons/${personId}/qualifications?role=BORROWER&page=${page}&size=${
        itemsPerPage || 10
      }`,
    );
    return res.data as IList<TQualification>;
  };

  getLimits = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TLimit>> => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/persons/${personId}/limits?role=BORROWER&page=${page}&size=${
        itemsPerPage || 10
      }`,
    );
    return res.data as IList<TQualification>;
  };

  updatePerson = async (personId: string, data: IUpatePersonData) => {
    const res = await this.put(
      `${this.BASE_URL}/originator/persons/${personId}`,
      data,
    );
    return res;
  };

  getApplicationsFromPerson = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
    parameters?: { [key: string]: string | undefined },
    filterStatusSelect?: string[],
  ): Promise<IList<TApplicationResponse> | null> => {
    const filterStatusSelectParsed: string[] = [];

    filterStatusSelect &&
      filterStatusSelect.map((item) => {
        filterStatusSelectParsed.push(`&status=${item}`);
      });

    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/persons/${personId}/applications?page=${page}&size=${
        itemsPerPage || 10
      }&${
        parameters
          ? Object.entries(parameters)
              .filter((item) => item?.[1])
              .map((item) => {
                return `${item?.[0]}=${item?.[1]}`;
              })
              .join('&')
          : ''
      }${filterStatusSelectParsed.toString().replaceAll(',', '')}`,
    );
    return res.data as IList<TApplicationResponse> | null;
  };

  getPersonKyc = async (taxpayerId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/background-check?taxpayer_id=${taxpayerId}`,
    );
    return res.data;
  };

  requestPersonKyc = async (name: string, taxpayerId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/persons/background-check?force=true`,
      {
        name,
        taxpayer_id: taxpayerId,
      },
    );
    return res.data;
  };

  getPersonScr = async (personId: string, scrId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/${personId}/scr/${scrId}`,
    );
    return res.data;
  };

  getPersonScrList = async (personId: string, page: number, size?: number) => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/persons/${personId}/list-scr?page=${page}&size=${
        size || 10
      }`,
    );
    return res.data;
  };

  requestPersonScr = async (personId: string, referenceDate?: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/${personId}/scr?refresh=true${
        referenceDate ? `&reference_date=${referenceDate}` : ''
      }`,
    );
    return res.data;
  };

  requestAcceptanceTermsScr = async (
    personId: string,
  ): Promise<{ status: string }> => {
    const res = await this.post(
      `${this.BASE_URL}/originator/persons/${personId}/scr/evidence`,
      {
        hash: null,
      },
    );
    return res.data;
  };
}
