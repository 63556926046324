import styled from '@emotion/styled';

export const TableWrapper = styled.div<{ isIssuer?: boolean }>`
  ${({ isIssuer }) =>
    isIssuer
      ? `
  th:nth-of-type(9) {
    text-align: center;
    max-width: 200px;
  }
  td:nth-of-type(9) {
    max-width: 200px;
  }

  th:nth-of-type(10) {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }
  td:nth-of-type(10) {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }`
      : `
      th:nth-of-type(8) {
    text-align: center;
    max-width: 200px;
  }
  td:nth-of-type(8) {
    max-width: 200px;
  }

  th:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }
  td:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }
  `}
`;

export const TableHeadCellContainer = styled.div`
  padding: 14px;
`;

export const TableBodyCellContainer = styled.div`
  padding: 13px;
  height: 100%;
  margin-left: 0.5em;
`;
