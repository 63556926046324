import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import {
  EApplicationStatus,
  EApplicationStatusDescription,
  TApplicationResponse,
} from '../services/hooks/useApplicationsService';
import { EQualificationStatus } from '../context/QualificationsProvider/qualifications.interfaces';
import { UserInfo } from 'modules/auth/services/authService';

export const handleStatusListPermissions =
  (
    application: TApplicationResponse,
    currentStatus?: string,
    userInfo?: UserInfo,
  ) =>
  (status: { label: string; value: string }): boolean => {
    if (!userInfo) return false;
    if (
      [
        EQualificationStatus.DENIED,
        EQualificationStatus.CANCELED,
        EApplicationStatus.CANCELED,
      ].includes(status.value as EQualificationStatus | EApplicationStatus) &&
      currentStatus !== status.value &&
      !userInfo.permissions.includes(EAccountPermissions.CANCEL_APPLICATION)
    ) {
      return false;
    }

    if (
      [EQualificationStatus.APPROVED, EApplicationStatus.ISSUED].includes(
        status.value as EQualificationStatus | EApplicationStatus,
      ) &&
      currentStatus !== status.value &&
      (!userInfo.permissions.includes(
        EAccountPermissions.APPROVE_APPLICATION,
      ) ||
        (typeof userInfo.permission_properties?.[
          EAccountPermissions.APPROVE_APPLICATION
        ]?.amount === 'number' &&
          userInfo.permission_properties[
            EAccountPermissions.APPROVE_APPLICATION
          ].amount > 0 &&
          application.requested_amount >
            userInfo.permission_properties[
              EAccountPermissions.APPROVE_APPLICATION
            ].amount))
    ) {
      return false;
    }
    return true;
  };

export const handleGetStaticStatusList = (applicationStatus: string) => {
  if (applicationStatus === EApplicationStatus.PENDING_CASHOUT) {
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_CASHOUT],
        value: EApplicationStatus.PENDING_CASHOUT,
      },
    ];
  }
  if (applicationStatus === EApplicationStatus.PENDING_SIGNATURE) {
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_SIGNATURE],
        value: EApplicationStatus.PENDING_SIGNATURE,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
        value: EApplicationStatus.CANCELED,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.PENDING_PAYMENT) {
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_PAYMENT],
        value: EApplicationStatus.PENDING_PAYMENT,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.ISSUED) {
    return [
      {
        label: EApplicationStatusDescription[EApplicationStatus.ISSUED],
        value: EApplicationStatus.ISSUED,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.PENDING_DISBURSEMENT) {
    return [
      {
        label:
          EApplicationStatusDescription[
            EApplicationStatus.PENDING_DISBURSEMENT
          ],
        value: EApplicationStatus.PENDING_DISBURSEMENT,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.PENDING_CESSION) {
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_CESSION],
        value: EApplicationStatus.PENDING_CESSION,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
        value: EApplicationStatus.CANCELED,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED) {
    return [
      {
        label:
          EApplicationStatusDescription[
            EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED
          ],
        value: EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.PARTIAL_DISBURSEMENT) {
    return [
      {
        label:
          EApplicationStatusDescription[
            EApplicationStatus.PARTIAL_DISBURSEMENT
          ],
        value: EApplicationStatus.PARTIAL_DISBURSEMENT,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.SIGNATURE_ERROR) {
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.SIGNATURE_ERROR],
        value: EApplicationStatus.SIGNATURE_ERROR,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
        value: EApplicationStatus.CANCELED,
      },
    ];
  }

  if (applicationStatus === EApplicationStatus.CANCELED) {
    return [
      {
        label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
        value: EApplicationStatus.CANCELED,
      },
    ];
  }

  return [
    {
      label: EApplicationStatusDescription[EApplicationStatus.ISSUED],
      value: EApplicationStatus.ISSUED,
    },
    {
      label:
        EApplicationStatusDescription[EApplicationStatus.PENDING_DISBURSEMENT],
      value: EApplicationStatus.PENDING_DISBURSEMENT,
    },
    {
      label:
        EApplicationStatusDescription[EApplicationStatus.PARTIAL_DISBURSEMENT],
      value: EApplicationStatus.PARTIAL_DISBURSEMENT,
    },
    {
      label:
        EApplicationStatusDescription[EApplicationStatus.AGREEMENT_RENDERING],
      value: EApplicationStatus.AGREEMENT_RENDERING,
    },
    {
      label:
        EApplicationStatusDescription[
          EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT
        ],
      value: EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT,
    },
    {
      label:
        EApplicationStatusDescription[EApplicationStatus.PENDING_QUALIFICATION],
      value: EApplicationStatus.PENDING_QUALIFICATION,
    },
    {
      label:
        EApplicationStatusDescription[
          EApplicationStatus.PENDING_CESSION_QUALIFICATION
        ],
      value: EApplicationStatus.PENDING_CESSION_QUALIFICATION,
    },
    {
      label: EApplicationStatusDescription[EApplicationStatus.PENDING_PAYMENT],
      value: EApplicationStatus.PENDING_PAYMENT,
    },
    {
      label: EApplicationStatusDescription[EApplicationStatus.KYC_PROCESSING],
      value: EApplicationStatus.KYC_PROCESSING,
    },
    {
      label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
      value: EApplicationStatus.CANCELED,
    },
    {
      label:
        EApplicationStatusDescription[
          EApplicationStatus.PENDING_INTEGRATION_LAQUS
        ],
      value: EApplicationStatus.PENDING_INTEGRATION_LAQUS,
    },
    {
      label:
        EApplicationStatusDescription[
          EApplicationStatus.AWAITING_APPROVAL_DISBURSEMENT
        ],
      value: EApplicationStatus.AWAITING_APPROVAL_DISBURSEMENT,
    },
  ];
};

export interface IApplicationStatusFilters {
  title: string;
  value: string;
  selected: boolean;
}

export const applicationStatusFilters = [
  {
    title: 'Pendente Assinatura',
    value: 'PENDING_SIGNATURE',
    selected: true,
  },
  {
    title: 'Pendente Aprovação',
    value: 'PENDING_QUALIFICATION',
    selected: true,
  },
  {
    title: 'Pendente Pagamento',
    value: 'PENDING_PAYMENT',
    selected: true,
  },
  {
    title: 'Pendente KYC',
    value: 'KYC_PROCESSING',
    selected: true,
  },
  {
    title: 'Emitido',
    value: 'ISSUED',
    selected: true,
  },
  // {
  //   title: 'Pendente Desembolso',
  //   value: 'PENDING_DISBURSEMENT',
  //   selected: true,
  // },
  {
    title: 'Cancelado',
    value: 'CANCELED',
    selected: true,
  },
];
