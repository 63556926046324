import styled from '@emotion/styled';

export const SimulationStepContent = styled.div``;

export const SimulationStepContainer = styled.div`
  margin-bottom: 32px;
  max-height: 70vh;
  overflow-y: auto;
`;

export const ButtonsArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .btn-preview {
    display: flex;
    justify-content: space-around;
    gap: 8px;
  }
`;
