import styled from '@emotion/styled';

export const InputDropdownInputContainer = styled.div`
  width: 100%;
  display: flex;

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    font-family: 'Inter';
    font-size: 18px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    line-height: 27px;
    font-weight: 500;
  }
`;
