import { Divider } from '@mui/material';
import { InputDropdownInput } from './InputDropdownInput';
import { InputDropdownSelect } from './InputDropdownSelect';
import { InputDropdownContent, InputDropdownContainer } from './styles';
import { useState } from 'react';
import { InputError, TextS } from '../typography';

const CustomDivider = () => {
  return (
    <div style={{ margin: '0px 12px' }}>
      <Divider sx={{ height: '25px', width: '3px' }} orientation="vertical" />
    </div>
  );
};

export enum EInputType {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}

export type TFilterItems = {
  name: string;
  parameterName: string;
  type?: EInputType;
};

export interface IInputDropdownProps {
  items: TFilterItems[];
  inputValue: string;
  label?: string;
  className?: string;
  selectedFilter?: string | undefined;
  validationError?: string;
  handleInputChange: (value: string) => void;
  handleSelectFilterItem: (filterSelectedValue: TFilterItems) => void;
}

const InputDropdown = ({
  inputValue,
  items,
  validationError,
  label,
  className,
  selectedFilter,
  handleInputChange,
  handleSelectFilterItem,
}: IInputDropdownProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const handleClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectedFilter = (selectElementValue: TFilterItems) => {
    handleSelectFilterItem(selectElementValue);
  };

  const handleOpen = () => {
    setIsSelectOpen(true);
  };

  const selectedItem =
    items.find((item) => item.parameterName === selectedFilter) ?? items[0];

  return (
    <InputDropdownContainer className={className}>
      {typeof label === 'string' && (
        <TextS className="label" weight="Bold" style={{ marginBottom: '10px' }}>
          {label}
        </TextS>
      )}
      <InputDropdownContent>
        <InputDropdownSelect
          handleClose={handleClose}
          handleOpen={handleOpen}
          isOpen={isSelectOpen}
          selectedElement={selectedFilter}
          items={items}
          handleSelectFilter={(selectElementValue) =>
            handleSelectedFilter(selectElementValue)
          }
        />
        <CustomDivider />
        <InputDropdownInput
          value={inputValue}
          selectedItem={selectedItem}
          handleInputChange={(value) => handleInputChange(value)}
        />
      </InputDropdownContent>
      {typeof validationError === 'string' && (
        <InputError>{validationError}</InputError>
      )}
    </InputDropdownContainer>
  );
};

export default InputDropdown;
