import styled from '@emotion/styled';

export const SubscribersStepContent = styled.div``;

export const SubscribersStepHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubscribersStepContainer = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubscribersStepTableWrapper = styled.div`
  width: 100%;
  margin: 40px 0px auto;
  thead {
    th {
      padding: 20px !important;
    }
  }
  tbody {
    tr {
      td {
        padding: 24px !important;
        width: 50%;
        &:first-of-type {
          width: 40% !important;
        }
      }
    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
`;

export const SubscribersStepTableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const SubscriberListContent = styled.div`
`;

export const SubscribersAddNewSideSheetWrapper = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
`;

export const SubscribersEditSideSheetListItemInfo = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
`;

export const SubscribersAddNewSideSheetListItemInfo = styled.div`
`;

export const SubscribersEditSideSheetBtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .spinner {
    height: 60px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
  }
`;

export const SubscribersEditSideSheetListItemAction = styled.div`
  border: 1px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
  margin-top: 16px;
  .switch-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    padding-top: 0px;

    .switch-item {
      display: flex;
      gap: 8px;
    }
  }

  .relationship-area {
    align-items: center;

    .relationship-area-items {
      display: flex;
      gap: 20px;
      padding: 20px 0px 30px;
      .relationship-area-item {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        input {
          margin-right: 12px;
        }
      }
    }
  }
  .buttons-area {
    padding: 20px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const SubscribersAddNewSideSheetListItemAction = styled.div`
  margin-top: 24px;
  .switch-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    padding-top: 0px;

    .switch-item {
      display: flex;
      gap: 8px;
    }
  }

  .relationship-area {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .relationship-area-items {
      display: flex;
      gap: 20px;

      .relationship-area-item {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        input {
          margin-right: 12px;
        }
      }
    }
  }
  .buttons-area {
    padding: 20px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center
`;
