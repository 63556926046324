import styled from '@emotion/styled';

export const InputDropdownContainer = styled.div`
`;

export const InputDropdownContent = styled.div`
  width: 100%;
  padding: 12px;
  background-color: #FDFDFD;
  width: 100%;
  height: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #E6E5EA;

  &:hover {
    border-color: ${({ theme }) => theme.palette?.brand?.primary.base};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.palette?.brand?.primary.base};
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  cursor: pointer;
`;
