import React, { ReactNode } from 'react';
import { Heading4, TextM } from '../typography';
import { Grid, Tooltip, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

type CreditSimulatorProps = {
  title?: string;
  children: ReactNode;
  tooltip?: string;
};

const CreditSimulatorFields = ({
  title,
  tooltip,
  children,
}: CreditSimulatorProps) => {
  const theme = useTheme();

  const hasTitle = typeof title === 'string';
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={
        !hasTitle
          ? {
              '> .MuiGrid-root': {
                paddingTop: '0px',
              },
            }
          : {}
      }
    >
      {hasTitle && (
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Heading4 style={{ color: '#1E1360' }}>{title}</Heading4>
          {typeof tooltip === 'string' && (
            <Tooltip
              placement="bottom-start"
              title={
                <TextM style={{ color: theme.palette.common.white }}>
                  {tooltip}
                </TextM>
              }
            >
              <InfoOutlined
                style={{
                  fontSize: '1.2rem',
                  width: '20px',
                  height: '20px',
                  display: 'block',
                  marginLeft: '10px',
                  color: theme.palette.brand.gray[900],
                }}
              />
            </Tooltip>
          )}
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={3}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return (
                <Grid item width={child.props.width ?? '33%'}>
                  {React.cloneElement(child, {
                    className: 'credit-simulator-fields',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } as any)}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreditSimulatorFields;
