import {
  ESplitPaymentAmountType,
  SplitPayment,
} from 'modules/products/interfaces/splitPayment';
import { TPreviewSimulationData } from 'modules/simulation/interfaces/context.interfaces';

export const getSplitAmount = (
  item: SplitPayment,
  simulation: TPreviewSimulationData | null,
) => {
  if (typeof simulation?.requested_amount !== 'number' || !item.amount)
    return 0;
  if (item.amount_type === ESplitPaymentAmountType.PERCENT) {
    const total = (item.amount / 100) * simulation.requested_amount;
    if (total >= 1) return Math.round(total);
    return total;
  }
  return item.amount;
};
