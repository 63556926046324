import { Divider, useTheme } from '@mui/material';
import { ButtonPrimary, TextL, TextM, TextS } from 'app/components';
import { formatPhone } from 'app/helpers';
import { CustomRadioInput } from 'app/components';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
  usePersonContext,
} from 'modules/customer/context';
import { useCustomerService } from 'modules/customer/services';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem } from '../components';
import {
  InfoArea,
  StyledButtonWrapper,
  SubscribersAddNewSideSheetListItemAction,
  SubscribersAddNewSideSheetListItemInfo,
  SubscribersAddNewSideSheetWrapper,
} from './styles';
import { TypeProductEmission } from 'modules/products/context/ProductsProvider/products.interfaces';
import PersonAddIcon from 'app/components/Icons/PersonAddIcon';
import {
  IPersonBusiness,
  IPersonBusinessList,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { normalizeCpfCnpj, serializePhoneObject } from 'app/utils/normalizer';

interface ISubscribersAddNewSideSheetProps {
  handleClose: () => void;
  isOpen: boolean;
  openCreateSubscriber?: () => void;
}

type ItemPersonType = TRegisterPersonDataRequest;
type ItemBusinessType = TRegisterBusinessDataRequest;
type TBorrowerType = 'PERSON' | 'BUSINESS';
export enum ESubscriberType {
  COSIGNERS = 'co_signers', // Anuente
  GUARANTORS = 'guarantors', // Avalista
  INVESTORS = 'investors',
  REPRESENTATIVE = 'representatives', // Representante
  CO_DEBTOR = 'co_debtors', // Devedor solidário
  SURETY = 'sureties', // Fiador
  COLLATERAL_PROVIDER = 'collateral_providers', // Garantidor
}

export type TSubscriber = {
  subscriber: ItemPersonType & ItemBusinessType;
  subscriberType: ESubscriberType;
  subscriberPersonType: TBorrowerType;
};

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const SubscribersAddNewSideSheet = ({
  isOpen,
  handleClose,
  openCreateSubscriber,
}: ISubscribersAddNewSideSheetProps) => {
  const theme = useTheme();
  const { getPersonBusinessList, isLoading } = usePersonContext();
  const { creatBusinessLoading } = useCustomerService();
  const { product, subscribers, updateSubscribers } = useContext(
    ApplicationRequestContext,
  );

  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');

  const [subscribersList, setSubscribersList] = useState<IPersonBusinessList>();
  const [selectedItem, setSelectedItem] = useState<IPersonBusiness | null>(
    null,
  );
  const [hasMoreItems, updateHasMoreItems] = useState<boolean>(false);
  const [subscriberType, setSubscriberType] = useState<ESubscriberType>(
    ESubscriberType.COSIGNERS,
  );

  const borrowerType: TBorrowerType = product?.borrower_type as TBorrowerType;

  const handleGetPerson = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      const res = await getPersonBusinessList({
        page: page,
        itemsPerPage: 20,
        searchParameterName: searchParameterName,
        searchParameterValue: searchParameterValue,
      });

      if (res) {
        setSubscribersList(res);
      }

      updateHasMoreItems(!res?.last);
      currentPage.current = page;
    },
    [currentPage],
  );

  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;

    if (field === '') {
      handleGetPerson(0);
      return;
    }
    handleGetPerson(0, field, value);
    return;
  };

  const handleAddNewSubscriber = useCallback(() => {
    if (selectedItem) {
      updateSubscribers(
        (subscribers || []).concat([
          {
            subscriber: {
              ...({} as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest),
              address: null,
              full_name: selectedItem.name,
              nationality: '',
              email_address: selectedItem.email_address,
              occupation: '',
              phone: serializePhoneObject(selectedItem.phone_number),
              taxpayer_id: selectedItem.taxpayer_id,
              id: selectedItem.id || undefined,
            } as unknown as TRegisterPersonDataRequest &
              TRegisterBusinessDataRequest,
            subscriberType,
            subscriberPersonType: selectedItem.type,
          },
        ]),
      );
      setTimeout(() => {
        setSelectedItem(() => null);
        setSubscriberType(ESubscriberType.COSIGNERS);
      });
    }
  }, [subscribers, selectedItem, borrowerType, subscriberType]);

  useEffect(() => {
    setSelectedItem(null);
  }, []);

  return (
    <ProductSideSheet
      open={isOpen}
      isBigger={true}
      handleClose={handleClose}
      title="Adicionar na lista"
    >
      <SubscribersAddNewSideSheetWrapper>
        <ListBox
          noGutters={true}
          fullHeight={true}
          hasMoreItems={hasMoreItems}
          filterItems={[
            { name: 'Nome', parameterName: 'name' },
            // HK precisa adaptar para receber um select
            // { name: 'Tipo', parameterName: 'BUSINESS' },
          ]}
          items={subscribersList?.content || []}
          onSearch={onSearch}
          isLoading={isLoading || creatBusinessLoading}
          handleRequest={() =>
            handleGetPerson(
              currentPage.current + 1,
              searchParameter.current ? searchParameter.current : undefined,
              searchValue.current ? searchValue.current : undefined,
            )
          }
        >
          {(i) => {
            const item = i as IPersonBusiness;
            return (
              <ListBoxItem
                className={
                  selectedItem?.id === item.id ? 'selected-outline' : ''
                }
                onClick={() => setSelectedItem(() => item)}
                key={item.taxpayer_id}
              >
                <SubscribersAddNewSideSheetListItemInfo>
                  <TextL
                    style={{
                      color:
                        selectedItem?.id === item.taxpayer_id
                          ? theme.palette.brand.primary.base
                          : theme.palette.brand.secondary.base,
                    }}
                    weight="semibold"
                  >
                    {item.name}
                  </TextL>
                  <InfoArea>
                    <TextS>{normalizeCpfCnpj(item?.taxpayer_id || '')}</TextS>
                    <BorrowerInfoDivider />
                    <TextS>{formatPhone(item.phone_number)}</TextS>
                    <BorrowerInfoDivider />
                    <TextS>{item.email_address}</TextS>
                  </InfoArea>
                </SubscribersAddNewSideSheetListItemInfo>
                {selectedItem?.id === item.id && (
                  <SubscribersAddNewSideSheetListItemAction>
                    <TextL weight="semibold">Função</TextL>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() =>
                            setSubscriberType(ESubscriberType.COSIGNERS)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.COSIGNERS
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>Anuente</TextM>
                        </div>
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() =>
                            setSubscriberType(ESubscriberType.GUARANTORS)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.GUARANTORS
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>Avalista</TextM>
                        </div>
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() =>
                            setSubscriberType(ESubscriberType.REPRESENTATIVE)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.REPRESENTATIVE
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>
                            Representante
                          </TextM>
                        </div>
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          onClick={() =>
                            setSubscriberType(ESubscriberType.CO_DEBTOR)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.CO_DEBTOR
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>
                            Devedor solidário
                          </TextM>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          margin: '1rem 0',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() =>
                            setSubscriberType(ESubscriberType.SURETY)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.SURETY
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>Fiador</TextM>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '6rem',
                          }}
                          onClick={() =>
                            setSubscriberType(
                              ESubscriberType.COLLATERAL_PROVIDER,
                            )
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType ===
                              ESubscriberType.COLLATERAL_PROVIDER
                            }
                          />
                          <TextM style={{ marginLeft: '10px' }}>
                            Garantidor
                          </TextM>
                        </div>
                        {product?.type_product_emission ===
                          TypeProductEmission.COMMERCIAL_PAPER && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={() =>
                              setSubscriberType(ESubscriberType.INVESTORS)
                            }
                          >
                            <CustomRadioInput
                              isChecked={
                                subscriberType === ESubscriberType.INVESTORS
                              }
                            />
                            <TextL style={{ marginLeft: '10px' }}>
                              Investidor
                            </TextL>
                          </div>
                        )}
                      </div>
                    </div>

                    <Divider />
                    <div className="buttons-area">
                      <ButtonPrimary
                        style={{ maxWidth: '30%' }}
                        typeVariant="ghost"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedItem(() => null);
                        }}
                      >
                        <TextM
                          weight="Semibold"
                          style={{ color: theme.palette.brand.primary.base }}
                        >
                          Cancelar
                        </TextM>
                      </ButtonPrimary>
                      <ButtonPrimary
                        style={{ maxWidth: '70%' }}
                        typeVariant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddNewSubscriber();
                        }}
                      >
                        <TextM
                          weight="Semibold"
                          style={{ color: theme.palette.brand.primary.base }}
                        >
                          Adicionar
                        </TextM>
                      </ButtonPrimary>
                    </div>
                  </SubscribersAddNewSideSheetListItemAction>
                )}
              </ListBoxItem>
            );
          }}
        </ListBox>
      </SubscribersAddNewSideSheetWrapper>

      <StyledButtonWrapper>
        <ButtonPrimary
          typeVariant="ghost"
          onClick={handleClose}
          style={{
            margin: '2rem',
            width: '8rem',
          }}
        >
          Voltar
        </ButtonPrimary>
        <ButtonPrimary
          typeVariant="fill"
          onClick={openCreateSubscriber}
          style={{
            marginTop: '2rem',
            marginBottom: '2rem',
            width: '12rem',
          }}
        >
          <div style={{ marginRight: '10px' }}>Cadastrar</div>
          <PersonAddIcon color={theme.palette.common.white} size={22} />
        </ButtonPrimary>
      </StyledButtonWrapper>
    </ProductSideSheet>
  );
};

export default SubscribersAddNewSideSheet;
