import { useContext, useRef } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Spinner,
  TextL,
} from 'app/components';
import { ButtonsArea, SimulationStepContainer } from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';
import {
  CreditSimulator,
  CreditSimulatorRefs,
} from 'app/components/CreditSimulatorV2';
import { ArrowBack } from '@mui/icons-material';
import { useTheme } from '@mui/material';

const SimulationV2Step = () => {
  const theme = useTheme();
  const formRef = useRef<CreditSimulatorRefs>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    product,
    simulationV2,
    simulationV2Request,
    borrowerQualification,
    billetValidationResponse,
    qrcodeEmvValidationResponse,
    updateSimulationV2,
    onBack,
    onForward,
  } = useContext(ApplicationRequestContext);

  const handleSimulator = () => {
    formRef.current?.simulate();
  };

  return (
    <BoxSection boxVariant="rounded">
      <SimulationStepContainer ref={containerRef}>
        <CreditSimulator
          ref={formRef}
          product={product}
          simulationResponse={simulationV2}
          simulationRequest={simulationV2Request}
          billetValidationResponse={billetValidationResponse}
          qualification={borrowerQualification}
          qrcodeEmvValidationResponse={qrcodeEmvValidationResponse}
          onSimulate={(simulation, simulationRequest, tabIndex) => {
            updateSimulationV2(simulation, simulationRequest, tabIndex);
            setTimeout(() =>
              containerRef.current?.scrollTo({
                top: 9999,
                behavior: 'smooth',
              }),
            );
          }}
        />
      </SimulationStepContainer>
      <ButtonsArea>
        <ButtonDefault
          style={{
            maxWidth: '200px',
          }}
          onClick={() => formRef.current?.reset()}
        >
          <TextL style={{ color: theme.palette.brand.primary.base }}>
            Limpar condições
          </TextL>
        </ButtonDefault>
        {formRef.current?.isLoading ? (
          <Spinner />
        ) : (
          <div className="btn-preview">
            <ButtonDefault onClick={onBack}>
              <ArrowBack />
            </ButtonDefault>
            {simulationV2 && simulationV2.requested_amount && (
              <>
                <ButtonDefault onClick={() => formRef.current?.copyPreview()}>
                  <TextL
                    style={{
                      color: theme.palette.brand.primary.base,
                      minWidth: '200px',
                    }}
                  >
                    Copiar resultados
                  </TextL>
                </ButtonDefault>
                <ButtonDefault
                  style={{ color: theme.palette.brand.primary.base }}
                  onClick={handleSimulator}
                >
                  <TextL style={{ color: theme.palette.brand.primary.base }}>
                    Recalcular
                  </TextL>
                </ButtonDefault>
              </>
            )}
            <ButtonPrimary
              disabled={
                !formRef.current?.isValid && !!simulationV2?.requested_amount
              }
              onClick={
                !simulationV2?.requested_amount ? handleSimulator : onForward
              }
            >
              <TextL style={{ color: '#FDFDFD' }}>
                {!simulationV2?.requested_amount ? 'Calcular' : 'Avançar'}
              </TextL>
            </ButtonPrimary>
          </div>
        )}
      </ButtonsArea>
    </BoxSection>
  );
};

export default SimulationV2Step;
