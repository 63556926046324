import {
  CalendarInput,
  ConfirmModal,
  CustomCurrencyInput,
  CustomInput,
  CustomSelect,
} from 'app/components';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import {
  formatCPF,
  formatDecimalField,
  formatPhone,
  isValidCPF,
} from 'app/helpers';
import { routingPath } from 'app/routes';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { useFormik } from 'formik';
import {
  ESex,
  maritalStatusOptions,
  TRegisterPersonDataRequest,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { aboutStepSchema } from 'modules/customer/helpers/personValidation';
import { usePersonService } from 'modules/customer/services';
import { useCallback, useEffect, useState } from 'react';
import { IPersonForm, workRelationOptions } from '../../types';
import {
  AboutStepExistingPerson,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { IAddress } from '../../Address/types';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

interface ICreatePersonFormProps {
  setPerson: (person: IPersonForm) => void;
  person: IPersonForm;
  isRepresentative?: boolean;
  setRepresentativeAddres?: (values?: IAddress) => void;
  updateExistsPerson: (
    existsPerson?: TRegisterPersonDataRequest | null,
  ) => void;
  existsPerson?: TRegisterPersonDataRequest | null;
}

const CreatePersonForm = ({
  setPerson,
  person,
  isRepresentative,
  setRepresentativeAddres,
  existsPerson,
  updateExistsPerson,
}: ICreatePersonFormProps) => {
  const { getPersonList } = usePersonService();

  const [isLoading, setIsLoading] = useState(false);
  const [isExistingPersonModalVisible, toggleExistingPersonModalVisible] =
    useState(false);

  const formik = useFormik({
    initialValues: person || ({} as IPersonForm),
    validationSchema: aboutStepSchema,
    onSubmit: (values) => console.info(values),
  });

  const onChangeTaxpayerId = useCallback(
    async (taxpayerId: string): Promise<boolean> => {
      if (!isValidCPF(taxpayerId)) return false;

      try {
        setIsLoading(true);
        const personResponse = await getPersonList(
          0,
          'taxpayer_id',
          taxpayerId,
          undefined,
          true,
        );
        if (personResponse && personResponse.content.length > 0) {
          const findPerson = personResponse.content[0];
          const parsedPerson: IPersonForm = {
            id: findPerson.id,
            full_name: findPerson.full_name,
          };

          updateExistsPerson(personResponse.content[0]);
          setPerson(parsedPerson);
          toggleExistingPersonModalVisible(true);
          return true;
        }
        updateExistsPerson(null);
        return false;
      } catch {
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const getFormatedPhoneFromExistingPerson = useCallback(() => {
    if (existsPerson) {
      const phoneNumber = existsPerson?.phone?.number || '';
      const areaCodeNumber = existsPerson?.phone?.area_code || '';

      if (!phoneNumber || !areaCodeNumber) return null;
      return formatPhone(areaCodeNumber + phoneNumber);
    }
    return null;
  }, [existsPerson]);

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_income',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleShare = (value: string) => {
    return formatDecimalField(value);
  };

  useEffect(() => {
    setPerson(formik.values);
  }, [formik.values]);

  return (
    <>
      <StepContentInputArea>
        <StepContentInput>
          <CustomInput
            name="taxpayer_id"
            id="taxpayer_id"
            isLoading={isLoading}
            value={formatCPF(formik?.values.taxpayer_id || '')}
            handleInputChange={(e) => {
              formik.handleChange(e);
              const taxpayerId = e.currentTarget.value;
              if (taxpayerId.length >= 14) {
                onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
              }
            }}
            onBlur={formik.handleBlur}
            placeholder="000.000.000-00"
            labelValue="CPF*"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="full_name"
            value={formik.values?.full_name || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: João da Silva"
            labelValue="Nome completo*"
            validationError={
              formik.touched.full_name ? formik.errors.full_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="nationality"
            value={formik.values?.nationality || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Brasileiro"
            labelValue="Nacionalidade*"
            validationError={
              formik.touched.nationality ? formik.errors.nationality : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="occupation"
            value={formik.values?.occupation || ''}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Vendedor"
            labelValue="Ocupação*"
            validationError={
              formik.touched.occupation ? formik.errors.occupation : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values?.phone || '')}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone*"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="email_address"
            value={formik.values?.email_address || ''}
            handleInputChange={formik.handleChange}
            placeholder="exemplo@email.com"
            labelValue="E-mail*"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            name="birth_date"
            labelValue="Data de nascimento*"
            placeholder="dd/mm/aaaa"
            value={formik.values?.birth_date || ''}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'birth_date',
                },
              })
            }
            validationError={
              formik.touched.birth_date ? formik.errors.birth_date : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="birthplace"
            value={formik.values?.birthplace || ''}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: São Paulo"
            labelValue="Naturalidade"
            validationError={
              formik.touched.email_address ? formik.errors.email_address : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="mothers_name"
            value={formik.values?.mothers_name || ''}
            handleInputChange={formik.handleChange}
            placeholder="Ex.: Maria da Silva"
            labelValue="Nome da mãe"
            validationError={
              formik.touched.mothers_name ? formik.errors.mothers_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Sexo"
            name="sex"
            placeholder="Selecione"
            value={formik.values.sex}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              { value: ESex.MALE, label: 'Masculino' },
              { value: ESex.FEMALE, label: 'Feminino' },
              { value: ESex.OTHER, label: 'Não informado' },
            ]}
            validationError={
              formik.touched.marital_status ? formik.errors.marital_status : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Estado civil"
            name="marital_status"
            placeholder="Selecione"
            value={formik.values.marital_status}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={maritalStatusOptions}
            validationError={
              formik.touched.marital_status ? formik.errors.marital_status : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            labelValue="Renda mensal"
            value={formik.values?.monthly_income || ''}
            handleInputChange={handleMonthlyIncomeChange}
            name="monthly_income"
            validationError={
              formik.touched.monthly_income ? formik.errors.monthly_income : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Pessoa politicamente exposta (PEP)"
            name="pep"
            placeholder="Selecione"
            value={formik.values.pep}
            handleInputChange={formik.handleChange}
            options={[
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ]}
          />
        </StepContentInput>

        {isRepresentative && (
          <>
            <StepContentInput>
              <CustomSelect
                labelValue="Assina pela empresa? *"
                name="signer"
                placeholder="Selecione"
                value={formik.values.signer}
                handleInputChange={formik.handleChange}
                options={[
                  { value: 'true', label: 'Sim' },
                  { value: 'false', label: 'Não' },
                ]}
              />
            </StepContentInput>
            <StepContentInput>
              <CustomSelect
                labelValue="Vínculo *"
                name="workRelation"
                placeholder="Selecione"
                value={formik.values.workRelation}
                handleInputChange={formik.handleChange}
                options={workRelationOptions}
              />
            </StepContentInput>
            <StepContentInput>
              <CustomInput
                labelValue="Participação em quotas (%)"
                placeholder="Ex: 50"
                value={formik.values?.share?.toString() || ''}
                handleInputChange={(e) => {
                  formik.setFieldValue('share', handleShare(e.target.value));
                }}
                validationError={
                  formik.touched.share ? formik.errors.share : ''
                }
              />
            </StepContentInput>
          </>
        )}
      </StepContentInputArea>

      <ConfirmModal
        isColumnButtons={true}
        isOpen={isExistingPersonModalVisible}
        icon={<AttentionCircularIcon />}
        title="O CPF informado já está cadastrado"
        btnConfirmText={
          isRepresentative
            ? 'Adicionar como representante'
            : 'Limpar e informar outro CPF'
        }
        handleConfirm={() => {
          if (isRepresentative) {
            const parsedAddress = {
              city: {
                label: existsPerson?.address?.city || '',
                value: existsPerson?.address?.city || '',
              },
              country_code: existsPerson?.address?.country_code,
              district: existsPerson?.address?.district,
              postal_code: existsPerson?.address?.postal_code,
              state_code: {
                label: existsPerson?.address?.state_code || '',
                value: existsPerson?.address?.state_code || '',
              },
              street_name: existsPerson?.address?.street_name || '',
              street_number: existsPerson?.address?.street_number || '',
            };

            const parsedPersonForm: IPersonForm = {
              address: parsedAddress,
              birth_date: existsPerson?.birth_date,
              birthplace: existsPerson?.birthplace,
              identification: {
                document_issue_date: existsPerson?.id_document?.issue_date,
                document_issuer: existsPerson?.id_document?.issuer,
                document_number: existsPerson?.id_document?.number,
              },
              email_address: existsPerson?.email_address,
              full_name: existsPerson?.full_name,
              marital_status: {
                label: existsPerson?.marital_status || '',
                value: existsPerson?.marital_status || '',
              },
              monthly_income: existsPerson?.monthly_income?.toString(),
              mothers_name: existsPerson?.mothers_name,
              nationality: existsPerson?.nationality,
              occupation: existsPerson?.occupation,
              pep: {
                label: existsPerson?.pep ? 'Sim' : 'Não',
                value: existsPerson?.pep ? 'Sim' : 'Não',
              },
              sex: {
                label:
                  existsPerson?.sex === 'MALE'
                    ? 'Masculino'
                    : existsPerson?.sex === 'FEMALE'
                    ? 'Feminino'
                    : 'Não informado',
                value:
                  existsPerson?.sex === 'MALE'
                    ? 'Masculino'
                    : existsPerson?.sex === 'FEMALE'
                    ? 'Feminino'
                    : 'Não informado',
              },
              phone: `${existsPerson?.phone.area_code}${existsPerson?.phone.number}`,
              taxpayer_id: existsPerson?.taxpayer_id,
              id: existsPerson?.id,
              workRelation: undefined,
              signer: undefined,
              aditional_information: undefined,
              share: 0,
            };

            formik.setValues(parsedPersonForm);

            if (typeof setRepresentativeAddres === 'function') {
              setRepresentativeAddres(parsedAddress);
            }

            toggleExistingPersonModalVisible(false);
            return;
          }

          formik.setFieldValue('taxpayer_id', '');
          formik.setFieldTouched('taxpayer_id', false);
          updateExistsPerson(null);
          toggleExistingPersonModalVisible(false);
          setTimeout(() => {
            document.querySelector<HTMLInputElement>('#taxpayer_id')?.focus();
          });
        }}
        handleClose={() => {
          toggleExistingPersonModalVisible(false);
          updateExistsPerson(null);
        }}
        handleCancel={() => {
          toggleExistingPersonModalVisible(false);
          updateExistsPerson(null);
        }}
      >
        <AboutStepExistingPerson>
          <span style={{ marginBottom: '8px' }}>
            <strong>{formatCPF(existsPerson?.taxpayer_id || '')}</strong>
          </span>
          <span>{existsPerson?.full_name}</span>
          {getFormatedPhoneFromExistingPerson() && (
            <span>{getFormatedPhoneFromExistingPerson()}</span>
          )}
          <span>{existsPerson?.email_address}</span>
          <span>...</span>
          {!isRepresentative && (
            <a
              href={`#/${routingPath.records.natural}/${existsPerson?.id}`}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Abrir perfil completo em uma nova aba
            </a>
          )}
        </AboutStepExistingPerson>
      </ConfirmModal>
    </>
  );
};

export default CreatePersonForm;
