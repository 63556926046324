export interface TBalance {
  account: Account;
  balance: Balance;
}

export interface Account {
  name: string;
  account: string;
  cashout: Cashout;
}

export enum TransferType {
  TED = 'TED_OUT',
  PIX = 'PIX_OUT',
}

export type WithdrawRequest = {
  amount: number;
  description: string;
  transfer_type?: TransferType;
  identifier: string;
};

export type WithdrawStatusResponse = {
  status: 'CONFIRMED' | 'PROCESSING' | 'ERROR';
  receipt: string;
  body: {
    id: string;
    amount: number;
    clientCode: string;
    transactionIdentification: unknown;
    endToEndId: string;
    initiationType: string;
    paymentType: string;
    urgency: string;
    transactionType: string;
    debitParty: {
      account: string;
      branch: string;
      taxId: string;
      name: string;
      accountType: string;
    };
    creditParty: {
      bank: string;
      key: string;
      account: string;
      branch: string;
      taxId: string;
      name: string;
      accountType: string;
    };
    remittanceInformation: unknown;
    error: unknown;
  };
};

export type FundingAccount = {
  account: string;
  status_account: string;
  client_code: string;
  document_number: string;
  business_phone_number: string;
  business_email: string;
  create_date: string;
  business_name: string;
  trading_name: string;
  bank_account: string;
  bank_branch: string;
  pix_keys: Array<{
    key_type: string;
    key: string;
  }>;
  cashout: {
    pix: {
      key: string;
    };
  };
};

export interface Cashout {
  pix: Pix;
}

export interface Pix {
  key: string;
}

export interface Balance {
  amount: number;
}

export enum TMovementType {
  ENTRYCREDIT = 'ENTRYCREDIT',
  PIXPAYMENTOUT = 'PIXPAYMENTOUT',
  PIXPAYMENTIN = 'PIXPAYMENTIN',
  ENTRYDEBIT = 'ENTRYDEBIT',
  TEDTRANSFERIN = 'TEDTRANSFERIN',
  TEDTRANSFEROUT = 'TEDTRANSFEROUT',
  PIXREVERSALIN = 'PIXREVERSALIN',
  PIXREVERSALOUT = 'PIXREVERSALOUT',
  BALANCE = 'BALANCE',
}

export enum TBalanceType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  BALANCE = 'BALANCE',
}

export enum TMovementStatus {
  ISSUED = 'ISSUED',
  CANCELED = 'CANCELED',
}

export interface TMovement {
  id: string;
  clientCode: string;
  description: string;
  createDate: string;
  lastUpdateDate: string;
  amount: string;
  status: string;
  balanceType: TBalanceType;
  movementType: TMovementType;
  disbursementOriginatorId: string;
  disbursementFundingId: string;
  disbursementApplicationId: string;
  disbursementProductId: string;
  disbursementBorrowerId: string;
  disbursementBorrowerTaxpayerId: string;
  disbursementBorrowerName: string;
  creditPartyTaxId: string;
  creditPartyName: string;
  creditPartyKey: string;
  movementId: string;
  disbursementIssuerId: string;
  disbursementProductName: string;
  disbursementBorrowerType: string;
  disbursementBorrowerPixKey: string;
  disbursementBorrowerBankAccount: string;
  disbursementBorrowerBankBranch: string;
  disbursementBorrowerBankAccountDigit: string;
  disbursementBorrowerBankAccountType: string;
  disbursementBorrowerBankIspbCode: string;
  disbursementRequestedAmount: string;
  disbursementTacAmount: string;
  disbursementFinanceFee: string;
  disbursementIofAmount: string;
  disbursementDate: string;
  disbursementAmount: string;
  disbursementFinancedAmount: string;
  disbursementRequestedAt: string;
  disbursementAdditionalPremiumAmount: string;
  disbursementBasePremiumAmount: string;
  debitPartyAccount: string;
  debitPartyBranch: string;
  debitPartyTaxId: string;
  debitPartyName: string;
  debitPartyAccountType: string;
  creditPartyAccount: string;
  creditPartyBranch: string;
  creditPartyAccountType: string;
  originalPaymentId: string;
  additionalInformation: string;
  reason: string;
  reversalDescription: string;
  originalEndToEndId: string;
  originalClientCode: string;
  originalId: string;
  returnIdentification: string;
  disbursementSequentialId: string;
  endToEndId: string;
  disbursementBeneficiaryTaxpayerId: string;
  disbursementBeneficiaryName: string;
  disbursementBeneficiaryPixKey: string;
  disbursementBeneficiaryBankAccount: string;
  disbursementBeneficiaryBankBranch: string;
  disbursementBeneficiaryBankAccountDigit: string;
  disbursementBeneficiaryBankAccountType: string;
  disbursementBeneficiaryBankIspbCode: string;
  isBorrowerRollback?: boolean;
}

export interface TMoviments {
  totalPages: number;
  account: Account;
  dateFrom: string;
  dateTo: string;
  movements: TMovement[];
  currentPage: string;
}

export interface PaginatedResponse<T> {
  totalPages: number;
  account: Account;
  dateFrom: string;
  dateTo: string;
  data: T;
  currentPage: string;
}

export enum TFilterType {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  CUSTOM = 'CUSTOM',
}

export type TFilterValues = {
  [key: string]: null | string | string[] | TFilterDateRange | undefined;
};

export type TFilterDateRange = {
  dateFrom: string;
  dateTo: string;
};

export type BankStatementFormat = 'csv' | 'xlsx' | 'pdf';

export interface RequestConciliationReportProps {
  fundingId: string;
  dateFrom: string;
  dateTo: string;
  columns: string[] | null;
  filters: TFilterValues;
  email: string;
  format?: BankStatementFormat;
  type?: 'conciliation' | 'statement' | 'formattedStatement';
}

export interface GetMovementsProps {
  id?: string;
  dateFrom: string;
  dateTo: string;
  page: number;
  linesPerPage: number;
  filters: TFilterValues;
}

export interface GetBankStatementServiceProps {
  id: string;
  dateFrom: string;
  dateTo: string;
  page: number;
  linesPerPage: number;
  filters: string;
}

export interface BankStatementResponseData {
  balance: string;
  date: string;
  movements: TMovement[];
}

export type FundingSettings = {
  name: string;
  taxpayer_id: string;
  disbursement_agent: string;
  baas: {
    account_number: string;
    tax_payer_id: string;
  };
  withdraw: {
    pix: {
      key: string;
      key_type: string;
    };
    external_bank_account: {
      bank_code: string;
      bank_account: string;
      bank_account_digit: string;
      bank_branch: string;
      bank_account_type: string;
      ispb_code: string;
      tax_payer_id: string;
      name: string;
    };
  };
  created_at: string;
  updated_at: string;
  version: number;
};
