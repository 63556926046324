import { useTheme, Divider } from '@mui/material';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { Badge, EditSideSheet, TextL, TextM } from 'app/components';
import { Accordion } from 'app/components/Accordion';
import {
  BoxIcon,
  DisbursementIcon,
  HandCoinIcon,
  InstallmentsFilterStatusIcon,
  InstallmentsFiltersDueIcon,
  PersonBusinessIcon,
} from 'app/components/Icons';
import {
  ApplicationsFiltersAccordionTitle,
  ApplicationsFiltersInput,
  ApplicationsFiltersInputInvalid,
  ApplicationsFiltersInputs,
  ApplicationsFiltersStatusElement,
} from './styles';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { initalFilters as defaultInitialFilters } from 'modules/applications/utils/filters';
import {
  useFundingsService,
  useProductsService,
} from 'modules/products/services/hooks';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { TProducts } from 'modules/products/context';
import { useAuthContext } from 'modules/auth/context';
import { CustomSelect } from 'app/components/CustomSelect';
import { CustomSelectAsync } from 'app/components/CustomSelectAsync';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { CustomInput } from 'app/components/CustomInput';
import { CustomSwitchIOS } from 'app/components/CustomSwitch';
import { CalendarInput } from 'app/components/CalendarInput';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

export const ApplicationStatusDescription = {
  AGREEMENT_RENDERING: 'Gerando contrato',
  PENDING_SIGNATURE: 'Pendente de assinatura',
  PENDING_QUALIFICATION: 'Pendente de aprovação',
  PENDING_PAYMENT: 'Pendente de pagamento',
  KYC_PROCESSING: 'Pendente KYC',
  PENDING_DISBURSEMENT: 'Pendente Desembolso',
  PARTIAL_DISBURSEMENT: 'Desembolso Parcial',
  PENDING_CESSION: 'Pendente de Cessão',
  DISBURSEMENT_ATTEMPT_FAILED: 'Falha no Pagamento',
  ISSUED: 'Emitido',
  CANCELED: 'Cancelado',
  PENDING_RANDOM: 'Pendente aprovação',
  PENDING_CASHOUT: 'Aguardando Pix',
};

const statusItems = [
  {
    label: 'Pendente Assinatura',
    key: 'PENDING_SIGNATURE',
  },
  {
    label: 'Pendente Aprovação',
    key: 'PENDING_QUALIFICATION',
  },
  {
    label: 'Pendente Pagamento',
    key: 'PENDING_PAYMENT',
  },
  {
    label: 'Pendente KYC',
    key: 'KYC_PROCESSING',
  },
  {
    label: 'Emitido',
    key: 'ISSUED',
  },
  {
    label: 'Cancelado',
    key: 'CANCELED',
  },
];

export type TFilterStatus =
  | 'PENDING_SIGNATURE'
  | 'PENDING_QUALIFICATION'
  | 'PENDING_PAYMENT'
  | 'KYC_PROCESSING'
  | 'ISSUED'
  | 'CANCELED';

export type TFilters = {
  status?: TFilterStatus[];
  minCreatedAtDate?: string;
  maxCreatedAtDate?: string;
  minDisbursementDate?: string;
  maxDisbursementDate?: string;
  minDisbursementProcessedAtDate?: string;
  maxDisbursementProcessedAtDate?: string;
  productId?: string;
  fundingId?: string;
  borrower?: string;
  applicationId?: string;
  originator?: string;
};

export interface IApplicationsFilters {
  handleClose: () => void;
  handleApply: (filters: TFilters) => void;
  isLoading: boolean;
  isOpen: boolean;
  filters: TFilters;
  initialFilters?: TFilters;
  onlyOneStatus?: boolean;
  hideProduct?: boolean;
  hideFunding?: boolean;
  hideBorrower?: boolean;
  hideApplication?: boolean;
  hideDisbursementProcessedAtDate?: boolean;
}

const ApplicationsFilters = ({
  handleClose,
  handleApply,
  initialFilters,
  isOpen,
  isLoading,
  filters,
  onlyOneStatus,
  hideProduct,
  hideFunding,
  hideBorrower,
  hideApplication,
  hideDisbursementProcessedAtDate,
}: IApplicationsFilters) => {
  const theme = useTheme();
  const { getFundings } = useFundingsService();
  const { getProductsList } = useProductsService();
  const { getBusinessList, getBusiness, creatBusinessLoading } =
    useCustomerService();
  const { getPersonList, getPerson, personListLoading, getPersonLoading } =
    usePersonService();
  const { userInfo } = useAuthContext();
  const { is_issuer_app } = useAppContext();
  const { originators } = useOriginatorContext();

  const [fundings, updateFundings] = useState<IFunding[]>([]);
  const [products, updateProducts] = useState<TProducts[]>([]);
  const [borrowers, updateBorrowers] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const {
    status: initialStatus,
    minCreatedAtDate: initialMinCreatedAtDate,
    maxCreatedAtDate: initialMaxCreatedAtDate,
    maxDisbursementDate: initialMaxDisbursementDate,
    minDisbursementDate: initialMinDisbursementDate,
    maxDisbursementProcessedAtDate: initialMaxDisbursementProcessedAtDate,
    minDisbursementProcessedAtDate: initialMinDisbursementProcessedAtDate,
    productId: initialProductId,
    fundingId: initialFundingId,
    borrower: initialBorrower,
    applicationId: initialApplicationId,
    originator: initialOriginator,
  } = filters;

  const originatorsValues =
    originators?.map((o) => ({
      label: o.legal_name,
      value: o.id,
    })) ?? [];

  const getInitialBorrowers = useCallback(async () => {
    const getPersonBorrower = async (
      borrowerId: string,
    ): Promise<{ label: string; value: string } | null> => {
      try {
        const borrower = await getPerson(
          borrowerId,
          true,
          undefined,
          is_issuer_app,
        );
        if (borrower) {
          return { label: borrower.full_name, value: borrower.id as string };
        }
        return null;
      } catch {
        return null;
      }
    };

    const getBusinessBorrower = async (
      borrowerId: string,
    ): Promise<{ label: string; value: string } | null> => {
      try {
        const borrower = await getBusiness(
          borrowerId,
          true,
          undefined,
          is_issuer_app,
        );
        if (borrower) {
          return {
            label: borrower.name as string,
            value: borrower.id as string,
          };
        }
        return null;
      } catch {
        return null;
      }
    };
    try {
      const selectedBorrower: Array<{ label: string; value: string }> = [];
      if (initialBorrower) {
        const person = await getPersonBorrower(initialBorrower);
        if (person) {
          selectedBorrower.push(person);
        } else {
          const business = await getBusinessBorrower(initialBorrower);
          if (business) {
            selectedBorrower.push(business);
          }
        }
      }

      const person = await getPersonList(0, 'full_name', '', 50);
      const business = await getBusinessList(0, 'full_name', '', 50);
      updateBorrowers(
        (
          person?.content
            ?.map((p) => ({ label: p.full_name, value: p.id }))
            ?.filter(
              (p) => !initialBorrower || !initialBorrower.includes(p.value),
            ) ?? []
        )
          .concat(
            business?.content
              ?.map((p) => ({ label: p.name as string, value: p.id as string }))
              ?.filter(
                (p) => !initialBorrower || !initialBorrower.includes(p.value),
              ) ?? [],
          )
          .concat(selectedBorrower),
      );
    } catch (e) {
      console.log(e);
    }
  }, [initialBorrower]);

  useEffect(() => {
    if (userInfo && isOpen) {
      Promise.all([getFundings(userInfo.fundings), getProductsList()]).then(
        (response) => {
          if (response) {
            updateFundings(response[0]?.content ?? []);
            updateProducts(response[1]?.content ?? []);
          }
        },
      );
      getInitialBorrowers();
    }
  }, [isOpen]);

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const [isStatusAccordionOpen, toggleStatusAccordionOpen] = useState<boolean>(
    initialStatus?.length !== 6,
  );
  const [isCreatedAtDateAccordionOpen, toggleCreatedAtDateAccordionOpen] =
    useState<boolean>(!!initialMinCreatedAtDate || !!initialMaxCreatedAtDate);
  const [isDisbursementDateAccordionOpen, toggleDisbursementDateAccordionOpen] =
    useState<boolean>(
      !!initialMaxDisbursementDate || !!initialMinDisbursementDate,
    );
  const [
    isDisbursementProcessedAtDateAccordionOpen,
    toggleDisbursementProcessedAtDateAccordionOpen,
  ] = useState<boolean>(
    !!initialMaxDisbursementProcessedAtDate ||
      !!initialMinDisbursementProcessedAtDate,
  );
  const [isProductAccordionOpen, toggleProductAccordionOpen] =
    useState<boolean>(!!initialProductId);
  const [isFundingAccordionOpen, toggleFundingAccordionOpen] =
    useState<boolean>(!!initialFundingId);
  const [isBorrowerAccordionOpen, toggleBorrowerAccordionOpen] =
    useState<boolean>(!!initialBorrower);
  const [isApplicationAccordionOpen, toggleApplicationAccordionOpen] =
    useState<boolean>(!!initialApplicationId);
  const [isOriginatorAccordionOpen, toggleOriginatorAccordionOpen] =
    useState<boolean>(!!initialOriginator);

  const [filtersIn, updateFiltersIn] = useState<TFilters>(filters);

  const onClose = () => {
    handleClose();
  };

  const onSave = () => {
    handleApply(filtersIn);
  };

  const onClear = () => {
    updateFiltersIn((initialFilters ?? defaultInitialFilters) as TFilters);
  };

  const onSelectStatus = useCallback(
    (statusKey: TFilterStatus, isSelected: boolean) => {
      if (onlyOneStatus) {
        updateFiltersIn({
          ...filtersIn,
          status: isSelected ? [] : [statusKey],
        });
      } else {
        updateFiltersIn({
          ...filtersIn,
          status: isSelected
            ? filtersIn.status?.filter((status) => status !== statusKey)
            : filtersIn.status?.concat(statusKey),
        });
      }
    },
    [filtersIn, onlyOneStatus],
  );

  const onChangeSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field: keyof TFilters) => (event: any) => {
      updateFiltersIn({
        ...filtersIn,
        [field]: Array.isArray(event.currentTarget.value)
          ? event.currentTarget.value.map(
              (i: { label: string; value: string }) => i.value,
            )
          : event.currentTarget.value?.value,
      });
    },
    [filtersIn],
  );

  const onChangeInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field: keyof TFilters) => (event: any) => {
      updateFiltersIn({
        ...filtersIn,
        [field]: event.currentTarget.value,
      });
    },
    [filtersIn],
  );

  const onChangeDate = useCallback(
    (field: keyof TFilters) => (date: Date | null) => {
      try {
        updateFiltersIn({
          ...filtersIn,
          [field]: date?.toISOString(),
        });
      } catch {
        updateFiltersIn({
          ...filtersIn,
          [field]: null,
        });
      }
    },
    [filtersIn],
  );

  const isCreatedAtDateInvalid = useMemo(() => {
    let isInvalid = false;

    if (filtersIn.minCreatedAtDate !== undefined) {
      isInvalid = filtersIn.minCreatedAtDate === null;
    } else if (filtersIn.maxCreatedAtDate !== undefined) {
      isInvalid = filtersIn.maxCreatedAtDate === null;
    }

    if (isInvalid) {
      return 'Datas selecionadas inválidas';
    }

    if (
      filtersIn.minCreatedAtDate === undefined ||
      filtersIn.maxCreatedAtDate === undefined
    )
      return false;

    try {
      if (
        new Date(filtersIn.minCreatedAtDate) > new Date() ||
        new Date(filtersIn.maxCreatedAtDate) > new Date() ||
        new Date(filtersIn.minCreatedAtDate) >
          new Date(filtersIn.maxCreatedAtDate)
      )
        return 'Datas selecionadas inválidas';
    } catch {
      return 'Datas selecionadas inválidas';
    }
    return false;
  }, [filtersIn]);

  const isDisbursementDateInvalid = useMemo(() => {
    let isInvalid = false;

    if (filtersIn.minDisbursementDate !== undefined) {
      isInvalid = filtersIn.minDisbursementDate === null;
    } else if (filtersIn.maxDisbursementDate !== undefined) {
      isInvalid = filtersIn.maxDisbursementDate === null;
    }

    if (isInvalid) {
      return 'Datas selecionadas inválidas';
    }

    if (
      filtersIn.minDisbursementDate === undefined ||
      filtersIn.maxDisbursementDate === undefined
    )
      return false;

    try {
      if (
        new Date(filtersIn.minDisbursementDate) >
        new Date(filtersIn.maxDisbursementDate)
      )
        return 'Datas selecionadas inválidas';
    } catch {
      return 'Datas selecionadas inválidas';
    }

    return false;
  }, [filtersIn]);

  const isDisbursementProcessedAtDateInvalid = useMemo(() => {
    let isInvalid = false;

    if (filtersIn.minDisbursementProcessedAtDate !== undefined) {
      isInvalid = filtersIn.minDisbursementProcessedAtDate === null;
    } else if (filtersIn.maxDisbursementProcessedAtDate !== undefined) {
      isInvalid = filtersIn.maxDisbursementProcessedAtDate === null;
    }

    if (isInvalid) {
      return 'Datas selecionadas inválidas';
    }

    if (
      filtersIn.minDisbursementProcessedAtDate === undefined ||
      filtersIn.maxDisbursementProcessedAtDate === undefined
    )
      return false;

    try {
      if (
        new Date(filtersIn.minDisbursementProcessedAtDate) > new Date() ||
        new Date(filtersIn.maxDisbursementProcessedAtDate) > new Date() ||
        new Date(filtersIn.minDisbursementProcessedAtDate) >
          new Date(filtersIn.maxDisbursementProcessedAtDate)
      )
        return 'Datas selecionadas inválidas';
    } catch {
      return 'Datas selecionadas inválidas';
    }

    return false;
  }, [filtersIn]);

  const createdAtDateBadge = useMemo(() => {
    const { minCreatedAtDate, maxCreatedAtDate } = filtersIn;
    if (!minCreatedAtDate && maxCreatedAtDate) {
      return `Até: ${formatDateFromBackend(maxCreatedAtDate).toLocaleDateString(
        'pt-br',
      )}`;
    }
    if (minCreatedAtDate && !maxCreatedAtDate) {
      return `Desde: ${formatDateFromBackend(
        minCreatedAtDate,
      ).toLocaleDateString('pt-br')}`;
    }

    if (minCreatedAtDate && maxCreatedAtDate) {
      return `${formatDateFromBackend(minCreatedAtDate).toLocaleDateString(
        'pt-br',
      )} - ${formatDateFromBackend(maxCreatedAtDate).toLocaleDateString(
        'pt-br',
      )}`;
    }

    return 'Todos';
  }, [filtersIn]);

  const disbursementDateBadge = useMemo(() => {
    const { minDisbursementDate, maxDisbursementDate } = filtersIn;
    if (!minDisbursementDate && maxDisbursementDate) {
      return `Até: ${formatDateFromBackend(
        maxDisbursementDate,
      ).toLocaleDateString('pt-br')}`;
    }
    if (minDisbursementDate && !maxDisbursementDate) {
      return `Desde: ${formatDateFromBackend(
        minDisbursementDate,
      ).toLocaleDateString('pt-br')}`;
    }

    if (minDisbursementDate && maxDisbursementDate) {
      return `${formatDateFromBackend(minDisbursementDate).toLocaleDateString(
        'pt-br',
      )} - ${formatDateFromBackend(maxDisbursementDate).toLocaleDateString(
        'pt-br',
      )}`;
    }

    return 'Todos';
  }, [filtersIn]);

  const disbursementProcessedAtDateBadge = useMemo(() => {
    const { minDisbursementProcessedAtDate, maxDisbursementProcessedAtDate } =
      filtersIn;
    if (!minDisbursementProcessedAtDate && maxDisbursementProcessedAtDate) {
      return `Até: ${formatDateFromBackend(
        maxDisbursementProcessedAtDate,
      ).toLocaleDateString('pt-br')}`;
    }
    if (minDisbursementProcessedAtDate && !maxDisbursementProcessedAtDate) {
      return `Desde: ${formatDateFromBackend(
        minDisbursementProcessedAtDate,
      ).toLocaleDateString('pt-br')}`;
    }

    if (minDisbursementProcessedAtDate && maxDisbursementProcessedAtDate) {
      return `${formatDateFromBackend(
        minDisbursementProcessedAtDate,
      ).toLocaleDateString('pt-br')} - ${formatDateFromBackend(
        maxDisbursementProcessedAtDate,
      ).toLocaleDateString('pt-br')}`;
    }

    return 'Todos';
  }, [filtersIn]);

  const productBadge = useMemo(() => {
    const selectedProduct = products.find(
      (product) => product.id === filtersIn.productId,
    );
    if (selectedProduct) {
      return selectedProduct.name;
    }
    return 'Todos';
  }, [products, filtersIn]);

  const [fundingBadge, fundingFull] = useMemo(() => {
    const selectedFunding = fundings.find(
      (funding) => funding.id === filtersIn.fundingId,
    );
    if (selectedFunding) {
      return [
        selectedFunding.alias ?? selectedFunding.legal_name,
        selectedFunding.alias
          ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
          : selectedFunding.legal_name,
      ];
    }
    return ['Todos'];
  }, [fundings, filtersIn]);

  const borrowerBadge = useMemo(() => {
    const borrower = borrowers.filter((b) =>
      filtersIn.borrower?.includes(b.value),
    )?.[0];
    return filtersIn.borrower ? borrower?.label : 'Todos';
  }, [filtersIn, borrowers]);

  const originatorBadge = useMemo(() => {
    const originator = originatorsValues?.filter((b) =>
      filtersIn.originator?.includes(b.value),
    )?.[0];
    return filtersIn.originator ? originator?.label : 'Todos';
  }, [filtersIn, originatorsValues]);

  const applicationBage = useMemo(() => {
    return filtersIn.applicationId ? 'Selecionada' : 'Todos';
  }, [filtersIn, borrowers]);

  const loadBorrowerOptions = useCallback(async (inputValue: string) => {
    try {
      const person = await getPersonList(0, 'full_name', inputValue, 50);
      const business = await getBusinessList(0, 'full_name', '', 50);

      return (
        person?.content?.map((p) => ({
          label: p.full_name,
          value: p.id,
        })) ?? []
      ).concat(
        business?.content?.map((p) => ({
          label: p.name as string,
          value: p.id,
        })) ?? [],
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <EditSideSheet
      open={isOpen}
      handleSave={onSave}
      isLoading={isLoading}
      isDisabled={
        !!isCreatedAtDateInvalid ||
        !!isDisbursementDateInvalid ||
        !!isDisbursementProcessedAtDateInvalid
      }
      handleClose={onClose}
      handleClear={onClear}
      title="Filtros"
    >
      {!hideBorrower && (
        <Accordion
          isOpen={isBorrowerAccordionOpen}
          style={{ marginTop: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <PersonBusinessIcon
                color={theme.palette.brand.primary.base as string}
              />
              <TextL
                style={{ paddingLeft: '13px', paddingTop: '2px' }}
                weight="medium"
              >
                Tomador
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {borrowerBadge}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() => toggleBorrowerAccordionOpen(!isBorrowerAccordionOpen)}
        >
          <ApplicationsFiltersInputs>
            <CustomSelectAsync
              value={
                borrowers.filter((b) =>
                  filtersIn.borrower?.includes(b.value),
                )[0]
              }
              isLoading={
                personListLoading || creatBusinessLoading || getPersonLoading
              }
              loadOptions={loadBorrowerOptions}
              defaultOptions={borrowers}
              handleInputChange={onChangeSelect('borrower')}
              name="borrower"
              placeholder="Selecione o Credor"
            />
          </ApplicationsFiltersInputs>
        </Accordion>
      )}
      {is_issuer_app && (
        <Accordion
          isOpen={isOriginatorAccordionOpen}
          style={{ marginTop: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <PersonBusinessIcon
                color={theme.palette.brand.primary.base as string}
              />
              <TextL
                style={{ paddingLeft: '13px', paddingTop: '2px' }}
                weight="medium"
              >
                Originador
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {originatorBadge}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() =>
            toggleOriginatorAccordionOpen(!isOriginatorAccordionOpen)
          }
        >
          <ApplicationsFiltersInputs>
            <CustomSelect
              value={
                originatorsValues.filter((b) =>
                  filtersIn.originator?.includes(b.value),
                )[0]
              }
              options={originatorsValues}
              handleInputChange={onChangeSelect('originator')}
              name="originator"
              placeholder="Selecione o Originador"
            />
          </ApplicationsFiltersInputs>
        </Accordion>
      )}
      <Accordion
        isOpen={isCreatedAtDateAccordionOpen}
        style={{ marginTop: '15px' }}
        title={
          <ApplicationsFiltersAccordionTitle>
            <InstallmentsFiltersDueIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Data de Digitação
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {createdAtDateBadge}
            </Badge>
          </ApplicationsFiltersAccordionTitle>
        }
        onClick={() =>
          toggleCreatedAtDateAccordionOpen(!isCreatedAtDateAccordionOpen)
        }
      >
        <>
          <ApplicationsFiltersInputs>
            <ApplicationsFiltersInput>
              <CalendarInput
                name="minCreatedAtDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.minCreatedAtDate ?? null}
                onChange={onChangeDate('minCreatedAtDate')}
                validationError={''}
              />
            </ApplicationsFiltersInput>
            <ApplicationsFiltersInput>
              <CalendarInput
                name="maxCreatedAtDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.maxCreatedAtDate ?? null}
                onChange={onChangeDate('maxCreatedAtDate')}
                validationError={''}
              />
            </ApplicationsFiltersInput>
          </ApplicationsFiltersInputs>
          {isCreatedAtDateInvalid !== false && (
            <ApplicationsFiltersInputInvalid>
              {isCreatedAtDateInvalid}
            </ApplicationsFiltersInputInvalid>
          )}
        </>
      </Accordion>

      <Accordion
        isOpen={isDisbursementDateAccordionOpen}
        style={{ marginTop: '15px' }}
        title={
          <ApplicationsFiltersAccordionTitle>
            <InstallmentsFiltersDueIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Data de Desembolso
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {disbursementDateBadge}
            </Badge>
          </ApplicationsFiltersAccordionTitle>
        }
        onClick={() =>
          toggleDisbursementDateAccordionOpen(!isDisbursementDateAccordionOpen)
        }
      >
        <>
          <ApplicationsFiltersInputs>
            <ApplicationsFiltersInput>
              <CalendarInput
                name="minDisbursementDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.minDisbursementDate ?? null}
                onChange={onChangeDate('minDisbursementDate')}
                validationError={''}
              />
            </ApplicationsFiltersInput>
            <ApplicationsFiltersInput>
              <CalendarInput
                name="maxDisbursementDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.maxDisbursementDate ?? null}
                onChange={onChangeDate('maxDisbursementDate')}
                validationError={''}
              />
            </ApplicationsFiltersInput>
          </ApplicationsFiltersInputs>
          {isDisbursementDateInvalid !== false && (
            <ApplicationsFiltersInputInvalid>
              {isDisbursementDateInvalid}
            </ApplicationsFiltersInputInvalid>
          )}
        </>
      </Accordion>

      {!hideDisbursementProcessedAtDate && (
        <Accordion
          isOpen={isDisbursementProcessedAtDateAccordionOpen}
          style={{ marginTop: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <InstallmentsFiltersDueIcon
                color={theme.palette.brand.primary.base as string}
              />
              <TextL
                style={{ paddingLeft: '10px', paddingTop: '2px' }}
                weight="medium"
              >
                Data de Pagamento Pix
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {disbursementProcessedAtDateBadge}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() =>
            toggleDisbursementProcessedAtDateAccordionOpen(
              !isDisbursementProcessedAtDateAccordionOpen,
            )
          }
        >
          <>
            <ApplicationsFiltersInputs>
              <ApplicationsFiltersInput>
                <CalendarInput
                  name="minDisbursementProcessedAtDate"
                  labelValue=""
                  placeholder="dd/mm/aaaa"
                  value={filtersIn.minDisbursementProcessedAtDate ?? null}
                  onChange={onChangeDate('minDisbursementProcessedAtDate')}
                  validationError={''}
                />
              </ApplicationsFiltersInput>
              <ApplicationsFiltersInput>
                <CalendarInput
                  name="maxDisbursementProcessedAtDate"
                  labelValue=""
                  placeholder="dd/mm/aaaa"
                  value={filtersIn.maxDisbursementProcessedAtDate ?? null}
                  onChange={onChangeDate('maxDisbursementProcessedAtDate')}
                  validationError={''}
                />
              </ApplicationsFiltersInput>
            </ApplicationsFiltersInputs>
            {isDisbursementProcessedAtDateInvalid !== false && (
              <ApplicationsFiltersInputInvalid>
                {isDisbursementProcessedAtDateInvalid}
              </ApplicationsFiltersInputInvalid>
            )}
          </>
        </Accordion>
      )}
      <Accordion
        isOpen={isStatusAccordionOpen}
        style={{ marginTop: '15px' }}
        title={
          <ApplicationsFiltersAccordionTitle>
            <InstallmentsFilterStatusIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Status
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {onlyOneStatus
                ? filtersIn.status && filtersIn.status.length > 0
                  ? ApplicationStatusDescription[
                      filtersIn
                        .status[0] as keyof typeof ApplicationStatusDescription
                    ]
                  : 'Nenhum'
                : filtersIn.status?.length === 6
                ? 'Todos'
                : `Selecionados: ${filtersIn.status?.length}`}
            </Badge>
          </ApplicationsFiltersAccordionTitle>
        }
        onClick={() => toggleStatusAccordionOpen(!isStatusAccordionOpen)}
      >
        {statusItems.map((status, index) => {
          const isSelected =
            !filtersIn.status ||
            filtersIn.status.includes(status.key as TFilterStatus);
          return (
            <div key={status.label}>
              {index > 0 && <Divider />}
              <ApplicationsFiltersStatusElement>
                <TextM>{status.label}</TextM>
                <CustomSwitchIOS
                  checked={isSelected}
                  onClick={() =>
                    onSelectStatus(status.key as TFilterStatus, isSelected)
                  }
                />
              </ApplicationsFiltersStatusElement>
            </div>
          );
        })}
      </Accordion>

      {!hideProduct && (
        <Accordion
          isOpen={isProductAccordionOpen}
          style={{ marginTop: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <BoxIcon color={theme.palette.brand.primary.base as string} />
              <TextL
                style={{ paddingLeft: '10px', paddingTop: '2px' }}
                weight="medium"
              >
                Produto
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {productBadge}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() => toggleProductAccordionOpen(!isProductAccordionOpen)}
        >
          <ApplicationsFiltersInputs>
            <CustomSelect
              value={
                filtersIn.productId
                  ? {
                      label: productBadge,
                      value: filtersIn.productId,
                    }
                  : undefined
              }
              options={products.map((product) => ({
                label: product.name,
                value: product.id,
              }))}
              handleInputChange={onChangeSelect('productId')}
              name="productId"
              placeholder="Selecione o Produto"
            />
          </ApplicationsFiltersInputs>
        </Accordion>
      )}
      {hasMultiplesFundings && !hideFunding && (
        <Accordion
          isOpen={isFundingAccordionOpen}
          style={{ marginTop: '15px', marginBottom: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <DisbursementIcon
                color={theme.palette.brand.primary.base as string}
              />
              <TextL
                style={{ paddingLeft: '10px', paddingTop: '2px' }}
                weight="medium"
              >
                Credor
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {fundingBadge}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() => toggleFundingAccordionOpen(!isFundingAccordionOpen)}
        >
          <ApplicationsFiltersInputs>
            <CustomSelect
              value={
                filtersIn.fundingId
                  ? {
                      label: fundingFull,
                      value: filtersIn.fundingId,
                    }
                  : undefined
              }
              options={fundings.map((funding) => ({
                label: funding.alias
                  ? `${funding.legal_name} (${funding.alias})`
                  : funding.legal_name,
                value: funding.id,
              }))}
              handleInputChange={onChangeSelect('fundingId')}
              name="fundingId"
              placeholder="Selecione o Credor"
            />
          </ApplicationsFiltersInputs>
        </Accordion>
      )}
      {!hideApplication && (
        <Accordion
          isOpen={isApplicationAccordionOpen}
          style={{ marginBottom: '15px' }}
          title={
            <ApplicationsFiltersAccordionTitle>
              <HandCoinIcon
                color={theme.palette.brand.primary.base as string}
              />
              <TextL
                style={{ paddingLeft: '10px', paddingTop: '2px' }}
                weight="medium"
              >
                Solicitação
              </TextL>
              <Badge
                style={{
                  width: 'auto',
                  marginRight: '10px',
                  opacity: 0.7,
                  marginLeft: 'auto',
                }}
                typeVariant="info"
              >
                {applicationBage}
              </Badge>
            </ApplicationsFiltersAccordionTitle>
          }
          onClick={() =>
            toggleApplicationAccordionOpen(!isApplicationAccordionOpen)
          }
        >
          <ApplicationsFiltersInputs>
            <CustomInput
              name="applicationId"
              value={filtersIn?.applicationId ?? ''}
              handleInputChange={onChangeInput('applicationId')}
              placeholder="ID da Solicitação"
            />
          </ApplicationsFiltersInputs>
        </Accordion>
      )}
    </EditSideSheet>
  );
};

export default ApplicationsFilters;
