import { useEffect, useMemo, useState } from 'react';
import { BoxSection } from 'app/components';
import {
  Cnab444PageContentWrapper,
  FundingsSelector,
  HeadingArea,
} from './styles';
import { Heading4, TextL } from 'app/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import { CustomSelect } from 'app/components/CustomSelect';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { useFundingsService } from 'modules/products/services/hooks';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Cnab444ApplicationsTable } from '../Cnab444ApplicationsTable';

const Cnab444PageContent = () => {
  const [fundings, updateFundings] = useState<IFunding[]>([]);
  const { userInfo } = useAuthContext();
  const { getFundings } = useFundingsService();
  const { id: fundingId } = useParams();
  const navigate = useNavigate();

  const [dateFrom] = useQueryParam('dateFrom', withDefault(StringParam, null));
  const [dateTo] = useQueryParam('dateTo', withDefault(StringParam, null));
  const [filterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, null),
  );

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  useEffect(() => {
    if (userInfo) {
      getFundings(userInfo.fundings).then((fundingResponse) => {
        if (fundingResponse) {
          updateFundings(fundingResponse.content);
        }
      });
    }
  }, [fundingId]);

  const selectWidth = useMemo(() => {
    if (fundings.length > 0) {
      const largerFundingName = fundings
        .map((funding) => ({
          label: funding.alias
            ? `${funding.legal_name} (${funding.alias})`
            : funding.legal_name,
          value: funding.id,
        }))
        .sort((a, b) => b.label.length - a.label.length)[0];
      return largerFundingName.label.length * 10;
    }
  }, [fundings]);

  const selectedFunding = fundings.find((funding) => funding.id === fundingId);
  return (
    <Cnab444PageContentWrapper>
      <BoxSection boxVariant="rounded">
        <HeadingArea>
          <Heading4 fontWeight="Sb">CNAB 444</Heading4>
          {hasMultiplesFundings ? (
            <FundingsSelector
              style={{
                width: `${selectWidth}px`,
                minWidth: '400px',
              }}
            >
              <CustomSelect
                value={
                  selectedFunding
                    ? {
                        label: selectedFunding.alias
                          ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                          : selectedFunding.legal_name,
                        value: selectedFunding.id,
                      }
                    : undefined
                }
                options={fundings.map((funding) => ({
                  label: funding.alias
                    ? `${funding.legal_name} (${funding.alias})`
                    : funding.legal_name,
                  value: funding.id,
                }))}
                handleInputChange={(e) => {
                  const params: string[] = [];
                  if (dateFrom) params.push(`dateFrom=${dateFrom}`);
                  if (dateTo) params.push(`dateTo=${dateTo}`);
                  if (filterType) params.push(`filterType=${filterType}`);
                  navigate(
                    `/cession/${e.currentTarget.value.value}/cnab444${
                      params.length > 0 ? `?${params.join('&')}` : ''
                    }`,
                  );
                }}
                name="fundingId"
                isClearable={false}
                placeholder="Selecione o funding"
              />
            </FundingsSelector>
          ) : (
            <TextL style={{ marginTop: '12px' }}>
              {selectedFunding?.alias
                ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                : selectedFunding?.legal_name}
            </TextL>
          )}
        </HeadingArea>
        {selectedFunding && (
          <Cnab444ApplicationsTable fundingId={selectedFunding.id} />
        )}
      </BoxSection>
    </Cnab444PageContentWrapper>
  );
};

export default Cnab444PageContent;
