import styled from '@emotion/styled';

export const WarningList = styled.div`
  border-left: 8px solid #FDD9B8;
  background: #FFFAEE;
  position: relative;
  box-shadow: 0px 1px 0px rgba(253, 217, 184, 0.48);
  border-radius: 10px;
  width: 100%;
  padding: 13.5px 0px 13px 54px;
  margin-top: 32px;
`;

export const WarningListIcon = styled.span`
  position: absolute;
  top: 15.5px;
  left: 13.5px;
`;

export const WarningListItems = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

export const WarningListItem = styled.p`
`;
