import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { Divider, useTheme } from '@mui/material';
import {
  CloseIcon,
  Heading3,
  InfinityScroll,
  SearchFilter,
} from 'app/components';
import { BaseSidesheet } from 'app/components';
import {
  BusinessRelationsList,
  CloseIconWrapper,
  ContentArea,
  HeaderArea,
} from './styles';
import { TFilterItems } from 'app/components/SearchFilter/SearchFilter';
import { TRegisterPersonDataRequest } from 'modules/customer/context';
import { usePersonService } from 'modules/customer/services';
import { useDebounce } from 'app/hooks';
import { LegalPersonHandlerStepperContext } from '../../pages/LegalPersonHandler/LegalPersonHandler';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { ListItem } from 'modules/customer/pages/LegalPersonHandler/components';

export interface IPersonListSidesheetProps {
  isOpen: boolean;
  handleClose: () => void;
  handleAddFromEdit?: (updatedPersonData: RelationPerson) => void;
}

const filterItems = [
  { name: 'Nome', parameterName: 'full_name' },
  { name: 'CPF', parameterName: 'taxpayer_id' },
  { name: 'Email', parameterName: 'email_address' },
];

const PersonListSidesheet = ({
  isOpen,
  handleClose,
  handleAddFromEdit,
}: IPersonListSidesheetProps) => {
  const theme = useTheme();
  const [searchText, updateSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<TFilterItems>();
  const [personList, setPersonList] = useState<
    TRegisterPersonDataRequest[] | null | undefined
  >([]);
  const [personSelected, setPersonSelected] = useState<
    Partial<TRegisterPersonDataRequest> | null | undefined
  >();
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const { getPersonList, personListLoading } = usePersonService();
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const { updatePersonList, selectedRelationPersonList } = useContext(
    LegalPersonHandlerStepperContext,
  );

  const handleCloseModal = () => {
    searchParameter.current = '';
    searchValue.current = '';
    currentPage.current = 0;
    setPersonSelected(undefined);
    setPersonList([]);
    handleClose();
  };

  const handleSelectFilterItem = (selectedFilterValue: TFilterItems) => {
    setSelectedFilter(selectedFilterValue);
  };

  const handleGetPerson = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getPersonList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      if (!res) {
        handleClose();
      }
      setPersonList((state) =>
        state?.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setPersonList([]);

    if (field === '') {
      handleGetPerson(0);
      return;
    }
    handleGetPerson(0, field, value);
  };

  const handleSearch = () => {
    if (typeof onSearch === 'function') {
      onSearch(
        selectedFilter?.parameterName || filterItems?.[0].parameterName,
        searchText,
      );
    }
  };

  const handleAdd = (addedPerson: RelationPerson, fromEdit: boolean) => {
    if (fromEdit) {
      handleAddFromEdit?.(addedPerson);
    } else {
      updatePersonList(addedPerson);
    }
  };

  const handleCheckPersonIsAdded = (
    person: TRegisterPersonDataRequest,
  ): boolean => {
    return !!selectedRelationPersonList?.find((item) => item.id === person.id);
  };

  useEffect(() => {
    if (isOpen) {
      onSearch(
        selectedFilter?.parameterName || filterItems?.[0].parameterName,
        searchText,
      );
    }
  }, [isOpen, debouncedSearchTerm]);

  return (
    <BaseSidesheet open={isOpen} handleClose={handleCloseModal}>
      <HeaderArea>
        <Heading3 fontWeight="Sb">Adicionar na lista</Heading3>
        <CloseIconWrapper onClick={handleCloseModal}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </HeaderArea>
      <Divider />
      <ContentArea>
        <SearchFilter
          inputValue={searchText}
          handleClearInput={() => updateSearchText('')}
          handleInputChange={(evt) => updateSearchText(evt.target.value)}
          handleSelectFilterItem={handleSelectFilterItem}
          filterItems={filterItems}
          selectedFilter={selectedFilter?.name}
          handleSearch={handleSearch}
        />
        <BusinessRelationsList>
          <div className="container-list">
            <div className="content-list">
              {personList?.map((person) => {
                return (
                  <ListItem
                    key={person.id}
                    person={person}
                    isSelected={personSelected?.id === person.id}
                    personAdded={person && handleCheckPersonIsAdded(person)}
                    handleSelect={(selectedPerson) =>
                      setPersonSelected(selectedPerson)
                    }
                    cancelSelection={() => {
                      setPersonSelected(undefined);
                    }}
                    handleAdd={(addedPerson) =>
                      handleAdd(
                        addedPerson as RelationPerson,
                        typeof handleAddFromEdit === 'function',
                      )
                    }
                  />
                );
              })}
              <InfinityScroll
                isLoading={isLoading || personListLoading}
                onRequest={() =>
                  handleGetPerson(
                    currentPage.current + 1,
                    searchParameter.current
                      ? searchParameter.current
                      : undefined,
                    searchValue.current ? searchValue.current : undefined,
                  )
                }
              />
            </div>
          </div>
        </BusinessRelationsList>
      </ContentArea>
    </BaseSidesheet>
  );
};

export default PersonListSidesheet;
