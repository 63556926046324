import { StepContentInput, StepContentInputArea } from './styles';
import {
  ESplitPaymentAmountType,
  ESplitPaymentType,
  SplitPayment,
} from 'modules/products/interfaces/splitPayment';
import { BusinessEditSideSheet } from 'modules/customer/components/BusinessEditSideSheet';
import { InputDropdown } from 'app/components/InputDropdown';
import { useCallback, useEffect, useState } from 'react';
import {
  EInputType,
  TFilterItems,
} from 'app/components/InputDropdown/InputDropdown';
import { TPreviewSimulationData } from 'modules/simulation/interfaces/context.interfaces';
import { getSplitAmount } from './helpers';
import {
  WarningList,
  WarningListIcon,
  WarningListItem,
  WarningListItems,
} from 'app/components/WarningList';
import { AttentionRequiredDocuments } from 'app/components/Icons';
import { TextL } from 'app/components';
import { formatCurrency } from 'app/utils/normalizer';

export interface ISplitPaymentEditSideSheet {
  handleSave: (splitPayments: SplitPayment[]) => void;
  handleClose: () => void;
  simulation: TPreviewSimulationData | null;
  splitPayment: SplitPayment[];
  isOpen: boolean;
}

const AmountTypeDescriptions: TFilterItems[] = [
  {
    name: 'Monetário',
    parameterName: ESplitPaymentAmountType.MONEY,
    type: EInputType.CURRENCY,
  },
  {
    name: 'Porcentagem',
    parameterName: ESplitPaymentAmountType.PERCENT,
    type: EInputType.NUMBER,
  },
];

enum EErrorType {
  ERROR_AMOUNT_MAX = 'ERROR_AMOUNT_MAX',
  ERROR_AMOUNT_MIN = 'ERROR_AMOUNT_MIN',
}

const SplitPaymentEditSideSheet = ({
  handleSave,
  handleClose,
  splitPayment,
  simulation,
  isOpen,
}: ISplitPaymentEditSideSheet) => {
  const [internalSplitPayments, setInternalSplitPayments] =
    useState<SplitPayment[]>(splitPayment);
  const [error, setError] = useState<EErrorType | null>(null);

  useEffect(() => {
    setInternalSplitPayments(splitPayment);
    setError(null);
  }, [splitPayment, isOpen]);

  const onSave = () => {
    if (!simulation || !simulation.requested_amount) return;

    const splitPaymentParsed = internalSplitPayments.map((item) => ({
      ...item,
      amount: parseFloat(
        item.amountString?.replaceAll('.', '')?.replace(',', '.') ?? '0',
      ),
    }));

    const total = splitPaymentParsed.reduce((acc, item) => {
      return acc + getSplitAmount(item, simulation);
    }, 0);

    if (total > simulation.requested_amount) {
      setError(EErrorType.ERROR_AMOUNT_MAX);
      return;
    }
    if (total < simulation.requested_amount) {
      setError(EErrorType.ERROR_AMOUNT_MIN);
      return;
    }

    setError(null);
    handleSave(splitPaymentParsed);
  };
  const onClose = () => {
    handleClose();
  };

  const onChangeAmountType = useCallback(
    (item: SplitPayment, amountType: ESplitPaymentAmountType) => {
      setInternalSplitPayments((prev) =>
        prev.map((prevItem) => {
          if (prevItem.taxpayer_id === item.taxpayer_id) {
            return {
              ...prevItem,
              amount_type: amountType,
            };
          }
          return prevItem;
        }),
      );
    },
    [internalSplitPayments],
  );

  const onChangeAmount = useCallback(
    (item: SplitPayment, value: string) => {
      setInternalSplitPayments((prev) =>
        prev.map((prevItem) => {
          if (prevItem.taxpayer_id === item.taxpayer_id) {
            return {
              ...prevItem,
              amountString: value,
            };
          }
          return prevItem;
        }),
      );
    },
    [internalSplitPayments],
  );

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={onSave}
      isLoading={false}
      title="Editar valor do split"
    >
      <StepContentInputArea>
        {internalSplitPayments.map((item) => (
          <StepContentInput key={item.taxpayer_id} isFull={true}>
            <InputDropdown
              items={AmountTypeDescriptions}
              handleInputChange={(e) => onChangeAmount(item, e)}
              label={
                item.type === ESplitPaymentType.BORROWER
                  ? 'Conta do tomador'
                  : `Conta de ${item.name}`
              }
              handleSelectFilterItem={(amountType) =>
                onChangeAmountType(
                  item,
                  amountType.parameterName as ESplitPaymentAmountType,
                )
              }
              inputValue={item.amountString ?? ''}
              selectedFilter={item.amount_type ?? ESplitPaymentAmountType.MONEY}
            />
          </StepContentInput>
        ))}
      </StepContentInputArea>
      {error === EErrorType.ERROR_AMOUNT_MIN && (
        <WarningList>
          <WarningListIcon>
            <AttentionRequiredDocuments width={26} height={26} />
          </WarningListIcon>
          <TextL weight="bold">Valor solicitado insuficiente.</TextL>
          <WarningListItems>
            <WarningListItem>
              A soma do valor solicitado deve ser 100% (
              {formatCurrency(simulation!.requested_amount!, true)}).
            </WarningListItem>
          </WarningListItems>
        </WarningList>
      )}
      {error === EErrorType.ERROR_AMOUNT_MAX && (
        <WarningList>
          <WarningListIcon>
            <AttentionRequiredDocuments width={26} height={26} />
          </WarningListIcon>
          <TextL weight="bold">Valor superior ao valor da operação.</TextL>
          <WarningListItems>
            <WarningListItem>
              A soma do valor solicitado deve ser 100% (
              {formatCurrency(simulation!.requested_amount!, true)}).
            </WarningListItem>
          </WarningListItems>
        </WarningList>
      )}
    </BusinessEditSideSheet>
  );
};

export default SplitPaymentEditSideSheet;
