import { Divider, useTheme } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  DeleteButton,
  Heading3,
  Heading4,
  PencilIcon,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import { useCallback, useState, useContext, useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  InfoArea,
  SplitPaymentListContent,
  SplitPaymentStepContainer,
  SplitPaymentStepContent,
  SplitPaymentStepHeader,
  SplitPaymentStepTableHeadFile,
  SplitPaymentStepTableWrapper,
  SplitPaymentStepSubHeader,
} from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContentButtonsArea } from '../styles';
import SplitPaymentsAddNewSideSheet from './SplitPaymentAddNewSideSheet';
import { ACCOUNT_TYPE_OBJECT, BANK_LIST_OBJECT } from 'app/helpers';
import { Table } from 'app/components';
import {
  ESplitPaymentAmountType,
  ESplitPaymentType,
  SplitPayment,
} from 'modules/products/interfaces/splitPayment';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { PixType } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { AddCircleOutline } from '@mui/icons-material';
import SplitPaymentEditSideSheet from './SplitPaymentEditSideSheet';
import { TPreviewSimulationData } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';

const PixTypeDescription = {
  [PixType.ALEATORY_KEY]: 'Chave aleatória',
  [PixType.EMAIL]: 'Email',
  [PixType.PHONE_NUMBER]: 'Celular',
  [PixType.TAXPAYER_ID]: 'CPF/CNPJ',
};

export const SplitPaymentInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 16px',
      }}
    />
  );
};

const SplitPaymentStep = () => {
  const theme = useTheme();
  const [isAddNewSplitPaymentOpen, toggleIsAddNewSplitPaymentOpen] =
    useState(false);
  const [isEditSplitPaymentOpen, toggleIsEditSplitPaymentOpen] =
    useState(false);
  const {
    onBack,
    onForward,
    borrowerData,
    splitPayments,
    simulation: oldSimulation,
    simulationV2,
    updateSplitPayments,
  } = useContext(ApplicationRequestContext);

  const simulation: TPreviewSimulationData = oldSimulation || {
    numPeriods: simulationV2?.num_periods || null,
    paymentAmount: simulationV2?.payment_amount || null,
    disbursementAmount: simulationV2?.disbursement_amount || null,
    interestRate: simulationV2?.interest_rate || null,
    monthlyEffectiveInterestRate:
      simulationV2?.monthly_effective_interest_rate || null,
    tacAmount: simulationV2?.tac_amount || null,
    iofAmount: simulationV2?.iof_amount || null,
    firstPaymentDate: simulationV2?.first_payment_date || null,
    lastPaymentDate: simulationV2?.last_payment_date || null,
    totalAmountOwed: simulationV2?.total_amount_owed || null,
    financed_amount: simulationV2?.financed_amount || null,
    discount_amount: simulationV2?.discount_amount || null,
    iofBaseRate: simulationV2?.iof_base_rate || null,
    requested_amount: simulationV2?.requested_amount || null,
    disbursement_date: simulationV2?.disbursement_date || null,
    finance_fee: simulationV2?.finance_fee || null,
    schedule: simulationV2?.schedule,
    additionalInstallmentFee: null,
    additionalInstallmentDescription: null,
  };

  useEffect(() => {
    if (
      splitPayments.findIndex(
        (item) => item.type === ESplitPaymentType.BORROWER,
      ) === -1
    ) {
      updateSplitPayments(
        ([] as SplitPayment[]).concat(splitPayments, {
          type: ESplitPaymentType.BORROWER,
          taxpayer_id: borrowerData!.taxpayer_id,
          name: borrowerData!.full_name,
          external_bank_account: borrowerData!.external_bank_account,
          pix: borrowerData!.pix,
          amount: 100,
          amountString: '100',
          amount_type: ESplitPaymentAmountType.PERCENT,
        }),
      );
    }
  }, [splitPayments]);

  const onRemoveBeneficiary = useCallback(
    (item: SplitPayment) => {
      updateSplitPayments(
        splitPayments.filter(
          (beneficiary) => beneficiary.taxpayer_id !== item.taxpayer_id,
        ),
      );
    },
    [splitPayments, updateSplitPayments],
  );

  const handleAddNewSplitPayment = useCallback(() => {
    toggleIsAddNewSplitPaymentOpen(true);
  }, []);

  const handleEditSplitPayment = useCallback(() => {
    toggleIsEditSplitPaymentOpen(true);
  }, []);

  const getSplitAmount = useCallback(
    (item: SplitPayment) => {
      if (typeof simulation?.requested_amount !== 'number' || !item.amount)
        return 'Valor não informado';
      if (item.amount_type === ESplitPaymentAmountType.PERCENT) {
        return `${item.amount}% (${formatCurrency(
          (item.amount / 100) * simulation.requested_amount,
        )})`;
      }
      return `${Math.round(
        (item.amount / simulation.requested_amount) * 100,
      )}% (${formatCurrency(item.amount)})`;
    },
    [simulation],
  );

  const isDisabled =
    splitPayments.length < 2 ||
    !simulation?.requested_amount ||
    splitPayments.some((item) => !item.amount);

  return (
    <BoxSection boxVariant="rounded">
      <SplitPaymentStepContent>
        <SplitPaymentStepHeader>
          <Heading3>Configure o split de pagamento para desembolso</Heading3>
        </SplitPaymentStepHeader>
        <SplitPaymentStepSubHeader>
          {typeof simulation?.requested_amount === 'number' && (
            <Heading4>
              Valor da operação: {formatCurrency(simulation.requested_amount)}
            </Heading4>
          )}
          {splitPayments.length === 2 ? (
            <ButtonPrimary
              style={{ maxWidth: '250px' }}
              btnIcon={
                <div style={{ marginLeft: '12px' }}>
                  <PencilIcon color="white" />
                </div>
              }
              iconPosition="right"
              onClick={handleEditSplitPayment}
            >
              <TextM weight="Semibold" style={{ color: 'white' }}>
                Editar valores do split
              </TextM>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              style={{ maxWidth: '300px' }}
              btnIcon={
                <AddCircleOutline
                  style={{ color: 'white', marginLeft: '12px' }}
                />
              }
              iconPosition="right"
              onClick={handleAddNewSplitPayment}
            >
              <TextM weight="Semibold" style={{ color: 'white' }}>
                Adicionar conta beneficiária
              </TextM>
            </ButtonPrimary>
          )}
        </SplitPaymentStepSubHeader>
        <SplitPaymentStepContainer>
          <SplitPaymentStepTableWrapper>
            <Table
              tableHeadCell={[
                {
                  id: '0',
                  content: (
                    <SplitPaymentStepTableHeadFile>
                      <TextM weight="Bold" style={{ color: '#1E1360' }}>
                        Conta beneficiária
                      </TextM>
                      <ArrowDownwardIcon
                        style={{
                          color: theme.palette.brand.primary.base as string,
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    </SplitPaymentStepTableHeadFile>
                  ),
                },
                {
                  id: '1',
                  content: (
                    <TextM weight="Bold" style={{ color: '#1E1360' }}>
                      Valor solicitado
                    </TextM>
                  ),
                },
                {
                  id: '1',
                  content: (
                    <TextM
                      weight="Bold"
                      style={{ marginLeft: 'auto', color: '#1E1360' }}
                    >
                      Ações
                    </TextM>
                  ),
                },
              ]}
              tableBodyContent={splitPayments.map((item) => {
                const { taxpayer_id, name, external_bank_account, pix } = item;
                return {
                  id: '0',
                  cells: [
                    {
                      id: '0',
                      content: (
                        <SplitPaymentListContent>
                          <TextL weight="semibold" style={{ color: '#1E1360' }}>
                            {item.type === ESplitPaymentType.BORROWER
                              ? 'Conta do tomador'
                              : 'Conta do beneficiário'}
                          </TextL>
                          <TextS>
                            <strong>Beneficiário:</strong>{' '}
                            <span style={{ color: '#1E1360' }}>{name}</span>
                          </TextS>
                          <TextS>
                            <strong>CPF / CNPJ:</strong>{' '}
                            <span style={{ color: '#1E1360' }}>
                              {normalizeCpfCnpj(taxpayer_id)}
                            </span>
                          </TextS>
                          {external_bank_account &&
                            external_bank_account.ispb_code && (
                              <>
                                <TextS>
                                  <strong>Banco:</strong>{' '}
                                  <span style={{ color: '#1E1360' }}>{`${
                                    BANK_LIST_OBJECT[
                                      external_bank_account.ispb_code
                                    ]
                                  }`}</span>
                                </TextS>
                                <InfoArea>
                                  <TextS>
                                    <strong>Ag.:</strong>{' '}
                                    <span style={{ color: '#1E1360' }}>
                                      {external_bank_account.bank_account}
                                    </span>
                                  </TextS>
                                  <TextS>
                                    <strong>Cc.:</strong>{' '}
                                    <span style={{ color: '#1E1360' }}>
                                      {external_bank_account.bank_branch}
                                    </span>
                                  </TextS>
                                </InfoArea>
                                <TextS>
                                  <strong>Tipo de conta:</strong>{' '}
                                  <span style={{ color: '#1E1360' }}>
                                    {
                                      ACCOUNT_TYPE_OBJECT[
                                        external_bank_account.bank_account_type
                                      ]
                                    }
                                  </span>
                                </TextS>
                              </>
                            )}
                          {pix && pix.key && (
                            <>
                              <TextS>
                                <strong>Chave Pix:</strong>{' '}
                                <span style={{ color: '#1E1360' }}>
                                  {pix.key}
                                </span>
                              </TextS>
                              <TextS>
                                <strong>Tipo:</strong>{' '}
                                <span style={{ color: '#1E1360' }}>
                                  {PixTypeDescription[pix.key_type]}
                                </span>
                              </TextS>
                            </>
                          )}
                        </SplitPaymentListContent>
                      ),
                    },
                    {
                      id: '1',
                      content: <TextL>{getSplitAmount(item)}</TextL>,
                    },
                    {
                      id: '2',
                      content: (
                        <DeleteButton
                          style={{ border: 'none', marginLeft: 'auto' }}
                          disabled={item.type === ESplitPaymentType.BORROWER}
                          handleClick={() => onRemoveBeneficiary(item)}
                        />
                      ),
                    },
                  ],
                };
              })}
            />
          </SplitPaymentStepTableWrapper>
        </SplitPaymentStepContainer>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary disabled={isDisabled} onClick={onForward}>
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </SplitPaymentStepContent>
      <SplitPaymentsAddNewSideSheet
        isOpen={isAddNewSplitPaymentOpen}
        handleClose={() => toggleIsAddNewSplitPaymentOpen(false)}
        handleAdd={(beneficiary) => {
          updateSplitPayments(
            ([] as SplitPayment[]).concat(splitPayments, beneficiary),
          );
          toggleIsAddNewSplitPaymentOpen(false);
        }}
      />
      <SplitPaymentEditSideSheet
        handleClose={() => toggleIsEditSplitPaymentOpen(false)}
        simulation={simulation}
        handleSave={(newSplitPayments) => {
          updateSplitPayments(newSplitPayments);
          toggleIsEditSplitPaymentOpen(false);
        }}
        isOpen={isEditSplitPaymentOpen}
        splitPayment={splitPayments}
      />
    </BoxSection>
  );
};

export default SplitPaymentStep;
