import * as yup from 'yup';
import { InterestTypes, YesNoOptions } from './types';
import dayjs from 'dayjs';
import { differenceInDays } from 'date-fns';

export const getValueForSelect = <T>(
  allValues: Array<{ value: T; label: string }>,
  value?: T | null,
): { value: T; label: string } => {
  const valueContent = allValues.find((item) => item.value === value);
  return valueContent ?? allValues[0];
};

export const getValueForSelectMulti = <T>(
  allValues: Array<{ value: T; label: string }>,
  value?: T[] | null,
): Array<{ value: T; label: string }> => {
  const valueContent = allValues.filter((item) => value?.includes(item.value));
  return valueContent;
};

export const getFormValidationSchema = (
  isMultiplesDisbursementDates?: boolean,
) =>
  yup.object({
    amount: yup
      .string()
      .required('Campo obrigatório')
      .test({
        name: 'amount field',
        test: (value: unknown, { createError }) => {
          return value === '0,00'
            ? createError({
                message: 'Insira um valor válido para simular',
                path: 'amount',
              })
            : true;
        },
      }),
    numPayments: yup.string().required('Campo obrigatório'),
    tacAmount: yup.string().required('Campo obrigatório'),
    additionalInstallmentFee: yup
      .string()
      .nullable()
      .test({
        name: 'additionalInstallmentFee field',
        test: (value: unknown, { createError, parent }) => {
          if (parent.hasAdditionalFee !== YesNoOptions.YES) return true;
          return value === '0'
            ? createError({
                message: 'Insira um valor maior que 0',
              })
            : !value
            ? createError({
                message: 'Campo obrigatório',
              })
            : true;
        },
      }),
    additionalInstallmentDescription: yup
      .string()
      .nullable()
      .test({
        name: 'additionalInstallmentDescription field',
        test: (value: unknown, { createError, parent }) => {
          if (parent.hasAdditionalFee !== YesNoOptions.YES) return true;
          return !value || (value as string).length === 0
            ? createError({
                message: 'Campo obrigatório',
              })
            : true;
        },
      }),
    interestPreRate: yup
      .string()
      .nullable()
      .test({
        name: 'interestPreRate field',
        test: (value: unknown, { createError, parent }) => {
          if (parent.interestType !== InterestTypes.PRE) return true;
          return value === '0'
            ? createError({
                message: 'Insira um valor maior que 0',
              })
            : !value
            ? createError({
                message: 'Campo obrigatório',
              })
            : true;
        },
      }),

    interestPosRate: yup
      .string()
      .nullable()
      .test({
        name: 'interestPosRate field',
        test: (value: unknown, { createError, parent }) => {
          if (parent.interestType !== InterestTypes.POS) return true;
          return value === '0'
            ? createError({
                message: 'Insira um valor maior que 0',
              })
            : !value
            ? createError({
                message: 'Campo obrigatório',
              })
            : true;
        },
      }),
    financeFee: yup.string().required('Campo obrigatório'),
    iofType: yup.string().nullable().required('Campo obrigatório'),
    firstPaymentDate: yup
      .string()
      .nullable()
      .required('Campo obrigatório')
      .test({
        name: 'firstPaymentDate field',
        test: (value: unknown, { createError, parent }) => {
          return dayjs(value as string).isBefore(
            dayjs(parent.disbursementDate),
            'day',
          )
            ? createError({
                message: `Valor deve ser ${
                  !isMultiplesDisbursementDates ? 'igual ou ' : ''
                }posterior à data de liberação`,
                path: 'firstPaymentDate',
              })
            : !parent.disbursementDate
            ? createError({
                message: 'Preencha a data de liberação do recurso',
                path: 'firstPaymentDate',
              })
            : isMultiplesDisbursementDates && value === parent.disbursementDate
            ? createError({
                message:
                  'Valor deve ser superior à data de liberação do recurso',
                path: 'firstPaymentDate',
              })
            : true;
        },
      }),
    disbursementDate: yup
      .string()
      .nullable()
      .required('Campo obrigatório')
      .test({
        name: 'disbursementDate field',
        test: (value: unknown, { createError }) => {
          if (
            !isMultiplesDisbursementDates &&
            new Date(value as string) <
              new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            return createError({
              message: 'Valor deve ser superior à data atual',
              path: 'disbursementDate',
            });
          }

          if (
            isMultiplesDisbursementDates &&
            new Date(new Date(value as string).setHours(0, 0, 0, 0)) <=
              new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            return createError({
              message: 'Valor deve ser superior à data atual',
              path: 'disbursementDate',
            });
          }

          if (
            isMultiplesDisbursementDates &&
            differenceInDays(
              new Date(value as string),
              new Date(new Date().setHours(0, 0, 0, 0)),
            ) > 15
          ) {
            return createError({
              message:
                'A data de liberação do recurso não pode ser superior a 15 dias',
              path: 'disbursementDate',
            });
          }

          return true;
        },
      }),
  });

export const getValidDisbursementDate = (
  isMultiplesDisbursementDates?: boolean,
) => {
  if (isMultiplesDisbursementDates) {
    return new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
  }
  return new Date().toISOString();
};
