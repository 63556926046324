import { useCallback, useEffect, useState } from 'react';
import {
  DeleteButton,
  DownloadButton,
  StepContent,
  Table,
  TextM,
  TTableBodyContent,
  Upload,
} from 'app/components';
import { AttentionRequiredDocuments, ChevronIcon } from 'app/components/Icons';
import { TTableHeadCell } from 'app/components/Table';
import {
  DOCUMENT_TYPE,
  PersonDocumentTypeLabel,
} from 'app/helpers/documentTypes';
import { differenceInDays, format } from 'date-fns';
import {
  RequiredArea,
  RequiredAreaIcon,
  RequiredDocumentsList,
  RequiredDocumentsListItem,
} from 'modules/customer/components';
import { iconByFileType } from 'modules/customer/components/DocumentsModal/DocumentsModal';
import { usePersonContext } from 'modules/customer/context';
import {
  EPersonFunctionProduct,
  EPersonFunctions,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { useUploadPersonFiles } from 'modules/customer/hooks';
import {
  StepContentInputArea,
  StylesSuccessMessage,
  TableBodyFile,
  TableFilesWrapper,
  TableHeadFile,
  UploadArea,
} from './styles';

type PersonDocumentTypeLabelType = typeof PersonDocumentTypeLabel;

const tableBodyFileStyle = {
  display: 'flex',
};

const tableHeadRaw = {
  id: '1',
  content: ['Arquivos', 'Tipo', 'Criado em', 'Baixar', 'Excluir'],
};

const PersonFunctionToProductVariables = {
  [EPersonFunctions.BORROWER]: EPersonFunctionProduct.BORROWER,
  [EPersonFunctions.CO_SIGNER]: EPersonFunctionProduct.CO_SIGNER,
  [EPersonFunctions.GUARANTOR]: EPersonFunctionProduct.GUARANTOR,
};

type TUploadDocumentsProps = {
  personId?: string;
  hasSuccessMessage?: boolean;
};

const UploadDocuments = ({
  personId,
  hasSuccessMessage,
}: TUploadDocumentsProps) => {
  const {
    state: { func, product },
  } = usePersonContext();
  const { createdPersonId } = usePersonContext();
  const {
    handleUploadFiles,
    downloadPersonFile,
    handleDeletePersonFile,
    uploadedFiles,
    uploadedFilesLoading,
  } = useUploadPersonFiles();

  const [tableHeadContent, setTableHeadContent] = useState<
    TTableHeadCell[] | null
  >(null);
  const [tableBodyContent, setTableBodyContent] = useState<
    TTableBodyContent[] | null
  >(null);
  const [requiredDocuments, updateRequiredDocuments] = useState<
    DOCUMENT_TYPE[]
  >([]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleUploadFiles(acceptedFiles, personId || createdPersonId || '');
    },
    [personId, createdPersonId],
  );

  useEffect(() => {
    const personDocuments =
      product.variables_configuration?.[
        PersonFunctionToProductVariables[
          func.value as EPersonFunctions
        ] as EPersonFunctionProduct
      ].person_document;

    if (personDocuments)
      updateRequiredDocuments(
        personDocuments
          ?.filter((document) => {
            if (!document.required) return false;
            const hasUploaded = uploadedFiles.find(
              (uploadedFile) => uploadedFile.type === document.type,
            );
            return !hasUploaded;
          })
          .map((document) => document.type),
      );
  }, [product, func, uploadedFiles]);

  const renderCreationDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const diffInDays = differenceInDays(new Date(), date);
    return (
      <TextM weight="Regular">
        {diffInDays > 2
          ? format(date, 'dd/MM/yyyy')
          : diffInDays === 0
          ? 'Hoje'
          : `${diffInDays} dia(s) atrás`}
      </TextM>
    );
  };

  useEffect(() => {
    const parsedHeadContent = tableHeadRaw.content.map((item, index) => {
      if (index === 0) {
        return {
          id: String(index),
          content: (
            <TableHeadFile>
              <TextM weight="Bold">Arquivo</TextM>
              <ChevronIcon />
            </TableHeadFile>
          ),
        };
      } else {
        return {
          id: String(index),
          content: <TextM weight="Bold">{item}</TextM>,
        };
      }
    });

    setTableHeadContent(parsedHeadContent);
  }, []);

  useEffect(() => {
    const parsedBodyContent = uploadedFiles.map((item, index) => {
      return {
        id: String(index),
        cells: [
          {
            id: '1',
            content: (
              <TableBodyFile style={tableBodyFileStyle}>
                <div>
                  {
                    iconByFileType[
                      item?.file_extension?.toLocaleLowerCase() || 'pdf'
                    ]
                  }
                </div>
                <TextM weight="Semibold">{item?.file_name}</TextM>
              </TableBodyFile>
            ),
          },
          {
            id: '2',
            content: <TextM weight="Regular">{item?.file_extension}</TextM>,
          },
          {
            id: '3',
            content: renderCreationDate(
              item?.created_at || new Date().toISOString(),
            ),
          },
          {
            id: '4',
            content: (
              <DownloadButton
                handleClick={() =>
                  downloadPersonFile(personId || '', item.id || '', item)
                }
              />
            ),
          },
          {
            id: '5',
            content: (
              <DeleteButton
                handleClick={() => {
                  handleDeletePersonFile(item.id || '', personId || '');
                }}
              />
            ),
          },
        ],
      };
    });

    setTableBodyContent(parsedBodyContent);
  }, [uploadedFiles]);

  return (
    <StepContent title="Documentos">
      {hasSuccessMessage && (
        <StylesSuccessMessage>
          <p>
            <b>Cadastro efetuado com sucesso!</b>
          </p>
          <p>
            Caso queira adicionar um documento use o campo abaixo ou clique em
            salvar para sair.
          </p>
        </StylesSuccessMessage>
      )}
      <StepContentInputArea style={{ marginTop: '32px' }}>
        <TableFilesWrapper>
          <Table
            tableHeadCell={tableHeadContent}
            tableBodyContent={tableBodyContent}
          />
        </TableFilesWrapper>
        <UploadArea>
          <Upload handleDrop={handleDrop} loading={uploadedFilesLoading} />
        </UploadArea>
        {requiredDocuments.length > 0 && (
          <RequiredArea>
            <RequiredAreaIcon>
              <AttentionRequiredDocuments />
            </RequiredAreaIcon>
            <TextM weight="Bold">{`${requiredDocuments.length} documentos obrigatórios não foram enviados.`}</TextM>
            <RequiredDocumentsList>
              {requiredDocuments.map((document) => {
                return (
                  <RequiredDocumentsListItem key={document as string}>
                    {
                      PersonDocumentTypeLabel[
                        document as keyof PersonDocumentTypeLabelType
                      ]
                    }
                  </RequiredDocumentsListItem>
                );
              })}
            </RequiredDocumentsList>
          </RequiredArea>
        )}
      </StepContentInputArea>
    </StepContent>
  );
};

export default UploadDocuments;
