export enum YesNoOptions {
  YES = 'true',
  NO = 'false',
}

export enum SimulationTypes {
  PRICE = 'PRICE',
  AMERICAN = 'AMERICAN',
  IRREGULAR = 'IRREGULAR',
}

export enum InterestTypes {
  PRE = 'PRE',
  POS = 'POS',
}

export enum PreFixedTypes {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum TacValueType {
  PERCENTAGE = 'PERCENTAGE',
  MONETARY = 'MONETARY',
}

export enum IofTypes {
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS',
  BUSINESS_SIMPLE = 'BUSINESS_SIMPLE',
  ZERO = 'ZERO',
}

export enum ScheduleTypes {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum PosFixedTypes {
  CDI = 'CDI',
  IPCA = 'IPCA',
  IGPM = 'IGP-M',
  SELIC = 'Selic',
  TR = 'TR',
}

export type TSimulationValidation = {
  numPeriods: string | null;
  interestRate: string | null;
  firstPaymentDate: string | null;
  paymentAmount: string | null;
  tacAmount: string | null;
  requested_amount: string | null;
};

export interface ISimulationResponse {
  base_rate: string;
  id: number;
  external_id: string;
  acronym: string;
  name: string;
  periodicity: string;
  type: string;
  validity_day: number;
  reference_rate: string;
  exchange_variation: string;
}
