import {
  IBusinessCustomVariablesValues,
  IOption,
} from 'app/interfaces/customVariablesInterfaces';
import {
  EFunctions,
  EIncorporationType,
  IBankAccount,
  IPixKey,
} from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { IAddress } from './Address/types';

export interface IIdentification {
  document_issue_date?: string;
  document_issuer?: string;
  document_number?: string;
  document_type?: IOption;
}

export interface IPersonForm {
  address?: IAddress;
  birth_date?: string;
  birthplace?: string;
  identification?: IIdentification;
  email_address?: string;
  full_name?: string;
  marital_status?: IOption;
  monthly_income?: string;
  mothers_name?: string;
  nationality?: string;
  occupation?: string;
  pep?: IOption;
  phone?: string;
  role?: IOption;
  sex?: IOption;
  taxpayer_id?: string;

  // representatives options
  signer?: IOption;
  workRelation?: IOption;
  share?: number;
  aditional_information?: string;
  id?: string;
}

export interface IOriginator {
  id: string;
  legal_name: string;
}

export interface IPhone {
  area_code: string;
  country_code: string;
  number: string;
  formatted_number: string;
}

export interface IPerson {
  id: string;
  full_name: string;
  taxpayer_id: string;
  birth_date: string;
  birthplace: string | null;
  mothers_name: string | null;
  nationality: string;
  occupation: string;
  email_address: string;
  phone: IPhone;
  monthly_income: number;
  marital_status: string | null;
  marital_property_system: string | null;
  spouse_full_name: string | null;
  spouse_taxpayer_id: string | null;
  spouse_date_of_birth: string | null;
  sex: string | null;
  pep: boolean;
  address: IAddress;
  employer: string | null;
  external_bank_account: string | null;
  pix: string | null;
  scr_evidence_provider: string | null;
  scr_evidence_type: string | null;
  has_scr_evidence: boolean;
  income_range: string | null;
  id_document: string | null;
  custom_variables: Record<string, string>;
  originator: IOriginator;
  created_at: string;
  version: number;

  // representatives options
  signer?: IOption;
  workRelation?: IOption;
  share?: number;
  aditional_information?: string;
  role?: string;
}

export interface ICompanyForm {
  address: IAddress;
  billing_address?: IAddress;
  customVariables?: IBusinessCustomVariablesValues[];
  email_address: string;
  external_bank_account?: IBankAccount;
  foundation_date: string;
  fundingId?: string;
  hasQualificationRequest?: boolean;
  industry_classification?: string;
  legal_name: string;
  monthly_revenue?: string;
  name?: string;
  phone: string;
  pix?: IPixKey;
  productId?: string;
  role?: EFunctions;
  share_capital?: string;
  taxpayer_id: string;
  tax_regime?: {
    label: string;
    value: string;
  };
  incorporation_type?: {
    label: string;
    value: EIncorporationType | undefined;
  };
  representatives: IPersonForm[];
}

interface Phone {
  country_code: string;
  area_code: string;
  number: string;
  formatted_number: string;
}

export interface ICompanyResponse {
  address?: IAddress;
  billing_address?: IAddress;
  created_at?: string;
  custodian?: boolean;
  custom_variables?: Record<string, string>;
  email_address?: string;
  employer?: boolean;
  external_bank_account?: string;
  foundation_date?: string;
  has_scr_evidence?: boolean;
  id?: string;
  income_range?: string;
  incorporation_type?: string;
  industry_classification?: string;
  legal_name?: string;
  monthly_revenue?: number;
  name?: string | null;
  phone?: Phone;
  pix?: string;
  scr_evidence_provider?: string;
  scr_evidence_type?: string;
  share_capital?: number;
  tax_regime?: string;
  taxpayer_id?: string;
  version?: number;
}

export const workRelationOptions = [
  { value: 'EMPLOYEE', label: 'Empregado' },
  { value: 'MANAGER', label: 'Administrador' },
  { value: 'PARTNER', label: 'Sócio' },
  { value: 'PROXY', label: 'Procurador' },
  { value: 'OTHER', label: 'Não especificado' },
];
