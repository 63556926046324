import styled from '@emotion/styled';

export const ModalFooter = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const StyledButtonWrapper = styled.div`
    padding: 1rem;
`;

export const StyledContentWrapper = styled.div`
    overflow-y: auto;
    width: 60vw;
    min-width: 60rem;
    margin-top: 2rem;
    margin-bottom: 8rem;
`;

export const StyledSteps = styled.div`
    width: 100%;
    background-color: white;

    svg {
      color: ${({ theme }) => theme.palette?.brand?.primary?.base} !important;
    };

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    .MuiStepper-root {
      width: 80%
    }
  
`;

export const SideSheetHeader = styled.div`
  margin: 20px 0px;
  padding: 0px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const StyledFooter = styled.div`
    bottom: 0;
    position: fixed;
    width: -webkit-fill-available;
`;

export const StyledHeader = styled.div`
  font-family: Urbanist;
  font-weight: 400;
  font-size: 28px;
  line-height: 46.8px;
  letter-spacing: 0px;
  vertical-align: middle;
`;

export const StyledSpin = styled.div`
  .ant-spin-fullscreen {
    width: auto !important;
    height: auto !important;
  }
`;
