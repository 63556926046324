import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  useTheme,
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import {
  CustomInputContainer,
  InputLabelContainer,
  InputLoading,
  TextFieldArea,
} from './styles';
import { InputError } from '../typography';
import CircularProgress from '@mui/material/CircularProgress';

export interface ICustomInputProps extends OutlinedInputProps {
  labelValue?: string;
  value: string;
  type?: 'text' | 'password';
  isLoading?: boolean;
  validationError?: string;
  width?: string;
  handleInputChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const CustomInput = ({
  labelValue,
  handleInputChange,
  value,
  isLoading,
  validationError,
  type = 'text',
  ...props
}: ICustomInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const theme = useTheme();

  return (
    <CustomInputContainer>
      {labelValue && <InputLabelContainer>{labelValue}</InputLabelContainer>}
      <TextFieldArea className="text-field-area">
        <OutlinedInput
          value={value}
          type={
            type === 'password'
              ? isPasswordVisible
                ? 'text'
                : 'password'
              : 'text'
          }
          onChange={(e) => handleInputChange(e)}
          endAdornment={
            isLoading ? (
              <InputLoading>
                <CircularProgress />
              </InputLoading>
            ) : (
              type === 'password' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setIsPasswordVisible((prevState) => !prevState)
                    }
                    edge="end"
                  >
                    {isPasswordVisible ? (
                      <VisibilityOffOutlined
                        sx={{
                          color: theme.palette.brand.secondary.base,
                          width: '32px',
                          height: '32px',
                        }}
                      />
                    ) : (
                      <VisibilityOutlined
                        sx={{
                          color: theme.palette.brand.secondary.base,
                          width: '32px',
                          height: '32px',
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            )
          }
          {...props}
        />
      </TextFieldArea>
      {validationError && <InputError>{validationError}</InputError>}
    </CustomInputContainer>
  );
};

export default CustomInput;
