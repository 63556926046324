import {
  ButtonPrimary,
  CustomCurrencyInput,
  CustomInput,
  CustomSelect,
  TextS,
} from 'app/components';
import * as yup from 'yup';
import { TextM } from 'app/components';
import {
  FundingSettings,
  TransferType,
  WithdrawRequest,
} from 'modules/disbursement/interfaces/disbursement';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  ConfirmationContent,
  ConfirmationLine,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { BusinessEditSideSheet } from 'modules/customer/components/BusinessEditSideSheet';
import { Divider, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { CopyIcon } from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { ACCOUNT_TYPE_OBJECT, BANK_LIST_OBJECT } from 'app/helpers';
import { BANK_CODE_OBJECT } from 'app/helpers/banksList';
import { formatBankAccount } from 'app/utils/numbers';

const TransferTypeDescription = {
  [TransferType.PIX]: 'Pix',
  [TransferType.TED]: 'TED',
};

enum WithdrawStep {
  FORM,
  CONFIRM,
}

type WithdrawForm = {
  destination: {
    label: string;
    value: string;
  };
  amount: string;
  description: string;
  transferType: {
    label: string;
    value: string;
  };
};

export interface IWithdrawSideSheet {
  handleWithdraw: (params: WithdrawRequest) => void;
  handleClose: () => void;
  fundingId: string;
  fundingSettings?: FundingSettings;
  getAccountSettingsLoading: boolean;
  isOpen: boolean;
}

const withdrawModalValidation = yup.object({
  destination: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  amount: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'amount',
      test: (value, { createError }) => {
        return !value || Number(formatToCurrencyFloat(value)) <= 0
          ? createError({
              message: 'Insira um valor maior que 0',
              path: 'amount',
            })
          : true;
      },
    }),
  transferType: yup.object({
    value: yup.string().required('Campo obrigatório'),
    label: yup.string(),
  }),
  description: yup
    .string()
    .max(140, 'Máximo 140 caracteres')
    .required('Campo obrigatório'),
});

const WithdrawSideSheet = ({
  handleClose,
  handleWithdraw,
  fundingId,
  fundingSettings,
  getAccountSettingsLoading,
  isOpen,
}: IWithdrawSideSheet) => {
  const theme = useTheme();
  const { showSnackbarCopy } = useSnackbar();
  const [step, updateStep] = useState<WithdrawStep>(WithdrawStep.FORM);
  const [withdrawForm, updateWithdrawForm] = useState<WithdrawForm | null>(
    null,
  );

  useEffect(() => {
    if (isOpen) {
      updateStep(WithdrawStep.FORM);
      updateWithdrawForm(null);
    }
  }, [isOpen]);

  const destination = [
    {
      value: fundingSettings?.taxpayer_id,
      label: `${fundingSettings?.name} (${fundingSettings?.baas?.account_number})`,
    },
  ];

  const onClose = () => {
    handleClose();
  };

  const identifier = useMemo(() => {
    const date = format(new Date(), 'yyyyMMddHHmm');
    return `${fundingId}_${date}_${withdrawForm?.amount?.replace(',', '_')}`;
  }, [withdrawForm]);

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(`${identifier}`);
    showSnackbarCopy();
  }, [identifier]);

  const formik = useFormik({
    initialValues: {
      destination: withdrawForm
        ? withdrawForm.destination
        : {
            label: '',
            value: '',
          },
      amount: withdrawForm ? withdrawForm.amount : '0,00',
      description: withdrawForm ? withdrawForm.description : '',
      transferType: withdrawForm
        ? withdrawForm.transferType
        : {
            label: '',
            value: '',
          },
    },
    validationSchema: withdrawModalValidation,
    onSubmit: (values) => {
      updateWithdrawForm(values);
      updateStep(WithdrawStep.CONFIRM);
    },
  });

  const handleSave = () => {
    if (step === WithdrawStep.FORM) {
      formik.submitForm();
    } else if (withdrawForm) {
      handleWithdraw({
        amount: Number(formatToCurrencyFloat(withdrawForm.amount)),
        description: withdrawForm.description,
        transfer_type: withdrawForm.transferType.value as TransferType,
        identifier,
      });
    }
  };

  const handleCancel = () => {
    if (step === WithdrawStep.CONFIRM) {
      updateStep(WithdrawStep.FORM);
    } else {
      onClose();
    }
  };

  const getBankName = useCallback(() => {
    const externalBankAccount =
      fundingSettings?.withdraw?.external_bank_account;
    if (externalBankAccount?.ispb_code) {
      return BANK_LIST_OBJECT[externalBankAccount?.ispb_code];
    } else if (externalBankAccount?.bank_code) {
      if (externalBankAccount?.bank_code.length > 3) {
        return BANK_LIST_OBJECT[externalBankAccount?.bank_code];
      }
      if (
        BANK_CODE_OBJECT[
          externalBankAccount?.bank_code as keyof typeof BANK_CODE_OBJECT
        ] &&
        BANK_LIST_OBJECT[
          BANK_CODE_OBJECT[
            externalBankAccount?.bank_code as keyof typeof BANK_CODE_OBJECT
          ]
        ]
      ) {
        return BANK_LIST_OBJECT[
          BANK_CODE_OBJECT[
            externalBankAccount?.bank_code as keyof typeof BANK_CODE_OBJECT
          ]
        ];
      }
    }

    return '';
  }, [fundingSettings]);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      isDisabled={!formik.isValid}
      handleClose={handleCancel}
      handleSave={handleSave}
      title={step === WithdrawStep.FORM ? 'Saque' : 'Confirmação de saque'}
    >
      {step === WithdrawStep.FORM && (
        <StepContentInputArea>
          <StepContentInput isFull={true}>
            <CustomSelect
              labelValue="Selecione a conta de destino"
              name="destination"
              placeholder="Selecione a conta de destino"
              value={formik.values.destination}
              handleInputChange={formik.handleChange}
              options={destination}
              disabled={getAccountSettingsLoading}
              validationError={
                formik.touched.destination?.value
                  ? formik.errors.destination?.value
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput isFull={true}>
            <CustomCurrencyInput
              labelValue="Valor"
              value={formik.values.amount}
              handleInputChange={(evt) => {
                formik.setFieldValue(
                  'amount',
                  formatBRLCurrencyMaskInput(evt.target.value || ''),
                );
              }}
              name="amount"
              validationError={
                formik.touched.amount ? formik.errors.amount : ''
              }
            />
          </StepContentInput>
          <StepContentInput isFull={true}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: '12px',
                position: 'relative',
              }}
            >
              <CustomInput
                name="description"
                multiline={true}
                minRows={5}
                value={formik.values.description ?? ''}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                labelValue="Descrição"
                validationError={
                  formik.touched.description ? formik.errors.description : ''
                }
              />
              <TextS
                style={{
                  bottom:
                    formik.touched.description && formik.errors.description
                      ? -3
                      : -25,
                  right: 5,
                  position: 'absolute',
                  opacity: 0.8,
                }}
              >{`${formik.values.description.length}/140`}</TextS>
            </div>
          </StepContentInput>
          <StepContentInput isFull={true}>
            <CustomSelect
              labelValue="Transferir via"
              name="transferType"
              placeholder="Transferir via"
              value={formik.values.transferType}
              handleInputChange={formik.handleChange}
              options={[
                {
                  label: 'Pix',
                  value: TransferType.PIX,
                },
                {
                  label: 'TED',
                  value: TransferType.TED,
                },
              ]}
              validationError={
                formik.touched.transferType?.value
                  ? formik.errors.transferType?.value
                  : ''
              }
            />
          </StepContentInput>
        </StepContentInputArea>
      )}
      {step === WithdrawStep.CONFIRM && withdrawForm && (
        <ConfirmationContent>
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Identificador</TextM>
            </div>
            <div
              style={{
                maxWidth: '40%',
                textOverflow: 'clip',
                lineBreak: 'anywhere',
              }}
            >
              <TextM weight="Regular">{identifier}</TextM>
            </div>
            <div style={{ maxWidth: '10%' }}>
              {' '}
              <ButtonPrimary
                typeVariant="ghost"
                style={{ padding: '10px 15px', width: 'fit-content' }}
                onClick={handleCopyId}
              >
                <CopyIcon color={theme.palette.brand.primary.base as string} />
              </ButtonPrimary>
            </div>
          </ConfirmationLine>
          <Divider style={{ margin: '16px 0px' }} />
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Funding</TextM>
            </div>
            <div>
              <TextM weight="Regular">{`${fundingSettings?.name} (${fundingSettings?.baas?.account_number})`}</TextM>
            </div>
          </ConfirmationLine>
          <Divider style={{ margin: '16px 0px' }} />
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Tipo de transferência</TextM>
            </div>
            <div>
              <TextM weight="Regular">
                {withdrawForm &&
                  TransferTypeDescription[
                    withdrawForm.transferType.value as TransferType
                  ]}
              </TextM>
            </div>
          </ConfirmationLine>
          {fundingSettings?.withdraw?.external_bank_account &&
          withdrawForm &&
          withdrawForm.transferType.value === TransferType.TED ? (
            <>
              <Divider style={{ margin: '16px 0px' }} />
              <ConfirmationLine>
                <div>
                  <TextM weight="Bold">Banco:</TextM>
                </div>
                <div>
                  <TextM weight="Regular">{getBankName()}</TextM>
                </div>
              </ConfirmationLine>
              <Divider style={{ margin: '16px 0px' }} />
              <ConfirmationLine>
                <div>
                  <TextM weight="Bold">Agência:</TextM>
                </div>
                <div>
                  <TextM weight="Regular">
                    {fundingSettings.withdraw.external_bank_account.bank_branch}
                  </TextM>
                </div>
              </ConfirmationLine>
              <Divider style={{ margin: '16px 0px' }} />
              <ConfirmationLine>
                <div>
                  <TextM weight="Bold">Conta:</TextM>
                </div>
                <div>
                  <TextM weight="Regular">
                    {formatBankAccount(
                      fundingSettings.withdraw.external_bank_account
                        .bank_account +
                        fundingSettings.withdraw.external_bank_account
                          .bank_account_digit,
                    )}
                  </TextM>
                </div>
              </ConfirmationLine>
              <Divider style={{ margin: '16px 0px' }} />
              <ConfirmationLine>
                <div>
                  <TextM weight="Bold">Tipo:</TextM>
                </div>
                <div>
                  <TextM weight="Regular">
                    {
                      ACCOUNT_TYPE_OBJECT[
                        fundingSettings.withdraw.external_bank_account
                          .bank_account_type
                      ]
                    }
                  </TextM>
                </div>
              </ConfirmationLine>
            </>
          ) : (
            <>
              <Divider style={{ margin: '16px 0px' }} />
              <ConfirmationLine>
                <div>
                  <TextM weight="Bold">Chave Pix de Saque:</TextM>
                </div>
                <div>
                  <TextM weight="Regular">
                    {fundingSettings?.withdraw?.pix?.key}
                  </TextM>
                </div>
              </ConfirmationLine>
            </>
          )}
          <Divider style={{ margin: '16px 0px' }} />
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Valor:</TextM>
            </div>
            <div>
              <TextM weight="Regular">{`R$ ${withdrawForm?.amount}`}</TextM>
            </div>
          </ConfirmationLine>
          <Divider style={{ margin: '16px 0px' }} />
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Data:</TextM>
            </div>
            <div>
              <TextM weight="Regular">
                {`${format(new Date(), 'dd/MM/yyyy')} às ${format(
                  new Date(),
                  'HH:mm',
                )}`}
              </TextM>
            </div>
          </ConfirmationLine>
          <Divider style={{ margin: '16px 0px' }} />
          <ConfirmationLine>
            <div>
              <TextM weight="Bold">Descrição:</TextM>
            </div>
            <div>
              <TextM weight="Regular">{withdrawForm?.description}</TextM>
            </div>
          </ConfirmationLine>
        </ConfirmationContent>
      )}
    </BusinessEditSideSheet>
  );
};

export default WithdrawSideSheet;
