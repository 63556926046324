import { BaseHttpService } from 'app/services';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { IList } from 'modules/products/services/hooks/intefaces';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { TRegisterBusinessDataRequest } from '../../context';
import {
  IBusinessList,
  TCreateBusinessDataRequest,
} from '../../context/CustomerProvider/customer.interfaces';
import { IBusinessPersonRelation, IRelativePayload } from './interfaces';
import {
  TLimit,
  TQualification,
} from 'modules/customer/interfaces/qualifications';
import { IUpdateBusinessData } from './useCustomerService';

export class CustomerHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;
  creatBusiness = async (data: TCreateBusinessDataRequest) => {
    const res = await this.post(`${this.BASE_URL}/originator/business`, data);
    return res;
  };

  updateBusiness = async (businessId: string, data: IUpdateBusinessData) => {
    const res = await this.put(
      `${this.BASE_URL}/originator/business/${businessId}`,
      data,
    );
    return res;
  };

  getBusiness = async (
    businessId: string,
    isIssuer?: boolean,
  ): Promise<TRegisterBusinessDataRequest> => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.BASE_URL}/${entity}/business/${businessId}`,
    );
    return res.data as TRegisterBusinessDataRequest;
  };

  getBusinessList = async (
    page = 0,
    searchParameterName?: string,
    searchParameterValue?: string,
    itemsPerPage?: number,
    isIssuer?: boolean,
  ): Promise<IBusinessList> => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.BASE_URL}/${entity}/business?page=${page}&size=${
        itemsPerPage || 10
      }&${
        searchParameterValue
          ? `&${encodeURIComponent(
              searchParameterName || '',
            )}=${encodeURIComponent(searchParameterValue || '')}`
          : ''
      }`,
    );
    return res.data as IBusinessList;
  };

  // HK Depreciar
  createBusinessRelation = async (
    businessId: string,
    relatedPersonData: RelationPerson,
  ) => {
    const body = {
      person: {
        id: relatedPersonData.id,
      },
      signer: relatedPersonData.signer,
      type: relatedPersonData.workRelation,
      share: relatedPersonData.share
        ? relatedPersonData.share / 100
        : undefined,
    };

    const res = await this.post(
      `${this.BASE_URL}/originator/business/${businessId}/relations`,
      body,
    );
    return res.data;
  };

  createRelative = async (businessId: string, relative: IRelativePayload) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/business/${businessId}/relations`,
      relative,
    );
    return res.data;
  };

  getBusinessRelations = async (businessId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/${businessId}/relations`,
    );
    return res.data;
  };

  deleteBusinessRelations = async (businessId: string, relationId: string) => {
    const res = await this.delete(
      `${this.BASE_URL}/originator/business/${businessId}/relations/${relationId}`,
    );
    return res;
  };

  updateBusinessRelations = async (
    businessId: string,
    relationId: string,
    relationData: IBusinessPersonRelation | undefined,
  ) => {
    const res = await this.put(
      `${this.BASE_URL}/originator/business/${businessId}/relations/${relationId}`,
      relationData,
    );
    return res.data;
  };

  getApplicationsFromBusiness = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
    parameters?: { [key: string]: string | undefined },
    filterStatusSelect?: string[],
  ): Promise<IList<TApplicationResponse> | null> => {
    const filterStatusSelectParsed: string[] = [];

    filterStatusSelect &&
      filterStatusSelect.map((item) => {
        filterStatusSelectParsed.push(`&status=${item}`);
      });

    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/business/${personId}/applications?page=${page}&size=${
        itemsPerPage || 10
      }&${
        parameters
          ? Object.entries(parameters)
              .filter((item) => item?.[1])
              .map((item) => {
                return `${item?.[0]}=${item?.[1]}`;
              })
              .join('&')
          : ''
      }${filterStatusSelectParsed.toString().replaceAll(',', '')}`,
    );
    return res.data as IList<TApplicationResponse> | null;
  };

  getQualifications = async (
    businessId: string,
    role: 'BORROWER' | 'EMPLOYER',
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TQualification>> => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/business/${businessId}/qualifications?role=${role}&page=${page}&size=${
        itemsPerPage || 10
      }`,
    );
    return res.data as IList<TQualification>;
  };

  getLimits = async (
    businessId: string,
    role: 'BORROWER' | 'EMPLOYER',
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TLimit>> => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/business/${businessId}/limits?role=${role}&page=${page}&size=${
        itemsPerPage || 10
      }`,
    );
    return res.data as IList<TLimit>;
  };

  getBusinessKyc = async (taxpayerId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/background-check?taxpayer_id=${taxpayerId}`,
    );
    return res.data;
  };

  requestBusinessKyc = async (name: string, taxpayerId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/business/background-check?force=true`,
      {
        name,
        taxpayer_id: taxpayerId,
      },
    );
    return res.data;
  };

  requestAcceptanceTermsScr = async (
    businessId: string,
  ): Promise<{ status: string }> => {
    const res = await this.post(
      `${this.BASE_URL}/originator/business/${businessId}/scr/evidence`,
      {
        hash: null,
      },
    );
    return res.data;
  };

  getBusinessScr = async (businessId: string, scrId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/${businessId}/scr/${scrId}`,
    );
    return res.data;
  };

  getBusinessScrList = async (
    businessId: string,
    page: number,
    size?: number,
  ) => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/business/${businessId}/list-scr?page=${page}&size=${
        size || 10
      }`,
    );
    return res.data;
  };

  requestBusinessScr = async (businessId: string, referenceDate?: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/${businessId}/scr?refresh=true${
        referenceDate ? `&reference_date=${referenceDate}` : ''
      }`,
    );
    return res.data;
  };
}
