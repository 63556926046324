import {
  IBankAccount,
  IPixKey,
} from 'modules/customer/context/CustomerProvider/customer.interfaces';

export enum ESplitPaymentType {
  BORROWER = 'BORROWER',
  BENEFICIARY = 'BENEFICIARY',
}

export enum ESplitPaymentAmountType {
  MONEY = 'MONEY',
  PERCENT = 'PERCENT',
}

export type SplitPayment = {
  type: ESplitPaymentType;
  taxpayer_id: string;
  name: string;
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  amount?: number;
  amountString?: string;
  amount_type?: ESplitPaymentAmountType;
};
