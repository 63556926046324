import { CustomInput, CustomSelect, TCustomValue, TextS } from 'app/components';
import { useFormik } from 'formik';
import { StepContentInput, StepContentInputArea } from './styles';
import { normalizeCpfCnpj } from 'app/utils/normalizer';
import * as yup from 'yup';
import {
  ESplitPaymentAmountType,
  ESplitPaymentType,
  SplitPayment,
} from 'modules/products/interfaces/splitPayment';
import { BusinessEditSideSheet } from 'modules/customer/components/BusinessEditSideSheet';
import { formatBankAccount } from 'app/helpers/masks';
import {
  ACCOUNT_TYPE_ARRAY,
  BANK_LIST_ARRAY,
  isValidCNPJ,
  isValidCPF,
} from 'app/helpers';
import { PixSelector } from 'modules/customer/components/PixSelector';
import { useState } from 'react';
import {
  applyFormat,
  getErrorValidation,
  getPixTypeFromPixTypeToggle,
  getPlaceholder,
  PixTypeToggle,
} from 'modules/customer/components/PixSelector/PixSelector';
import { PixType } from 'modules/customer/context/CustomerProvider/customer.interfaces';

export interface ISplitPaymentAddNewSideSheet {
  handleAdd: (splitPayment: SplitPayment) => void;
  handleClose: () => void;
  isOpen: boolean;
}

type SplitPaymentForm = {
  name: string;
  taxpayer_id: string;
  ispb_code: { value: string; label: string } | undefined;
  bank_account: string;
  bank_branch: string;
  bank_account_type: { value: string; label: string } | undefined;
  bank_account_digit: string;
  pix_key: string;
};

const splitPaymentValidation = yup.object({
  taxpayer_id: yup
    .string()
    .required('Campo obrigatório')
    .test(
      'test-invalid-cnpj',
      'Identificador inválido',
      (taxpayerId: string | undefined) => {
        if (taxpayerId && taxpayerId.length > 14) {
          return isValidCNPJ(taxpayerId || '');
        }
        return isValidCPF(taxpayerId || '');
      },
    ),
  name: yup.string().required('Campo obrigatório'),
  ispb_code: yup.object().nullable(),
  pix_key: yup.string().when('ispb_code', {
    is: (ispb_code?: TCustomValue) =>
      !ispb_code || !ispb_code.value || ispb_code?.value?.length === 0,
    then: yup
      .string()
      .required('Campo obrigatório caso não seja informado o banco'),
  }),
  bank_account: yup.string().when('ispb_code', {
    is: (ispb_code?: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup.string().required('Campo obrigatório'),
  }),

  bank_branch: yup.string().when('ispb_code', {
    is: (ispb_code: TCustomValue) =>
      ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
    then: yup
      .string()
      .min(4, 'Formato incorreto')
      .required('Campo obrigatório'),
  }),
  bank_account_type: yup
    .object()
    .nullable()
    .when('ispb_code', {
      is: (ispb_code?: TCustomValue) =>
        ispb_code && ispb_code.value && ispb_code?.value?.length > 0,
      then: yup.object().nullable().required('Campo obrigatório'),
    }),
});

const SplitPaymentAddNewSideSheet = ({
  handleAdd,
  handleClose,
  isOpen,
}: ISplitPaymentAddNewSideSheet) => {
  const [pixType, togglePixType] = useState<PixTypeToggle>(PixTypeToggle.CPF);
  const formik = useFormik<SplitPaymentForm>({
    initialValues: {
      taxpayer_id: '',
      name: '',
      ispb_code: undefined,
      bank_account: '',
      bank_branch: '',
      bank_account_type: undefined,
      bank_account_digit: '',
      pix_key: '',
    },
    validationSchema: splitPaymentValidation,
    onSubmit: (values) => {
      const bank_account = values.bank_account.replace(/[^\w\s]/gi, '');
      const keyType = getPixTypeFromPixTypeToggle(pixType);
      const key =
        keyType === PixType.PHONE_NUMBER
          ? `+55${values.pix_key.replace(/[^\w\s]/gi, '').replace(/\s/g, '')}`
          : keyType === PixType.TAXPAYER_ID
          ? values.pix_key.replace(/[^\w\s]/gi, '')
          : keyType === PixType.EMAIL
          ? values.pix_key.toLowerCase()
          : values.pix_key.trim();

      handleAdd({
        name: values.name,
        taxpayer_id: values.taxpayer_id.replace(/\D+/g, ''),
        type: ESplitPaymentType.BENEFICIARY,
        amount_type: ESplitPaymentAmountType.PERCENT,
        external_bank_account: {
          ispb_code: values.ispb_code?.value as string,
          bank_account: bank_account.slice(0, bank_account.length - 1),
          bank_account_digit: bank_account.slice(
            bank_account.length - 1,
            bank_account.length,
          ),
          bank_branch: values.bank_branch,
          bank_account_type: values.bank_account_type?.value as string,
        },
        pix: {
          key,
          key_type: keyType,
        },
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const pixValidation =
    formik.values.pix_key !== '' &&
    getErrorValidation(pixType, formik.values.pix_key);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={false}
      title="Adicionar conta beneficiária"
    >
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomInput
            name="taxpayer_id"
            value={formik.values.taxpayer_id}
            handleInputChange={(evt) => {
              formik.setFieldValue(
                'taxpayer_id',
                normalizeCpfCnpj(evt.currentTarget.value),
              );
            }}
            onBlur={formik.handleBlur}
            placeholder="Digite o CPF ou CNPJ"
            labelValue="Identificação do beneficiário"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="name"
            value={formik.values.name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Digite aqui"
            labelValue="Nome do beneficiário"
            validationError={formik.touched.name ? formik.errors.name : ''}
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomSelect
            labelValue="Banco"
            name="ispb_code"
            placeholder="Selecione o banco"
            value={formik.values.ispb_code}
            handleInputChange={formik.handleChange}
            options={BANK_LIST_ARRAY}
            validationError={
              formik.touched?.ispb_code ? formik.errors?.ispb_code : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="bank_branch"
            value={formik.values.bank_branch}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="0000"
            labelValue="Agência"
            validationError={
              formik.touched.bank_branch ? formik.errors.bank_branch : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="bank_account"
            value={formatBankAccount(formik.values.bank_account)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="000000-0"
            labelValue="Conta"
            validationError={
              formik.touched.bank_account ? formik.errors.bank_account : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomSelect
            labelValue="Tipo de conta"
            name="bank_account_type"
            placeholder="Selecione o tipo"
            value={formik.values.bank_account_type}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={ACCOUNT_TYPE_ARRAY}
            validationError={
              formik.touched?.bank_account_type
                ? formik.errors?.bank_account_type
                : ''
            }
          />
        </StepContentInput>

        <StepContentInput isFull={true}>
          <TextS weight="Bold" style={{ marginBottom: '1rem' }}>
            Chave Pix
          </TextS>
          <PixSelector
            onChange={(pixKeySelector, pixTypeSelector) => {
              formik.setFieldValue('pix_key', '');
              togglePixType(pixTypeSelector);
            }}
          />
          <CustomInput
            name="pix_key"
            id="pixKey"
            value={applyFormat(formik.values.pix_key, pixType)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={getPlaceholder(pixType)}
            validationError={
              formik.touched.pix_key
                ? pixValidation || formik.errors?.pix_key
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default SplitPaymentAddNewSideSheet;
