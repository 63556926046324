import { normalizeCpfCnpj } from 'app/utils/normalizer';
import { useTheme } from '@mui/material';

import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { TProducts, useQualificationsContext } from 'modules/products/context';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { TQualificationStatusOption } from 'modules/products/pages/interfaces';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  TApplicationResponse,
  EApplicationStatus,
  EApplicationStatusDescription,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  CustomTableSelect,
  BoxSection,
  ButtonPrimary,
  Heading4,
  Spinner,
  SearchFilter,
} from 'app/components';
import { TextM, TextS } from 'app/components/typography/body';
import {
  formatDateForBackendString,
  formatDateFromBackend,
} from 'app/helpers/dateHelpers';
import {
  ButtonContainer,
  Container,
  EmptyContainer,
  FilterArea,
  TableArea,
  TableWrapper,
} from './styles';
import { CopyIcon, InstallmentsFiltersIcon } from 'app/components/Icons';
import { EmptyApplications } from 'app/components/Icons/svgs';
import {
  handleGetStaticStatusList,
  handleStatusListPermissions,
} from 'modules/products/helpers/statusList';
import { useQualificationChangeContext } from 'modules/products/context/QualificationChangeProvider/QualificationChangeProvider';
import { Table } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import {
  useQueryParam,
  NumberParam,
  withDefault,
  JsonParam,
} from 'use-query-params';
import ApplicationsFilters, {
  TFilters,
} from 'modules/applications/components/ApplicationsFilters/ApplicationsFilters';
import { initalFilters } from 'modules/applications/utils/filters';
import { ApplicationsContentFiltersCount } from 'modules/products/pages/ProductDetail/components/ApplicationContent/styles';
import { ApplicationDetailsSideSheet } from '../ApplicationDetailsSideSheet';
import {
  TRegisterBusinessData,
  TRegisterPersonData,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { TFilterItems } from 'app/components/SearchFilter/SearchFilter';
import { IList } from 'modules/products/services/hooks/intefaces';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { useAppContext } from 'app/context/AppContextProvider';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { ProductType } from 'modules/products/constants/enums';
import { TypeProductEmission } from 'modules/products/context/ProductsProvider/products.interfaces';
import EyeIcon from 'app/components/Icons/EyeIcon';

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">ID da solicitação</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Tipo</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">CCB</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ textAlign: 'center' }}>
          Dt. Criação
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ textAlign: 'center' }}>
          Originador
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ textAlign: 'center' }}>
          CPF/CNPJ do tomador
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ textAlign: 'center' }}>
          Produto
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '8',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ width: '150px' }}>
          Dt. Desembolso
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '9',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold" style={{ textAlign: 'center' }}>
          Status
        </TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '10',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

export type TApplicationData = {
  productData?: TProducts;
  qualificationData?: TQualificationRequestApplication;
  statusApproval: TQualificationStatusOption[];
  borrowerDetails?: {
    name?: string;
    taxpayer_id?: string;
  };
} & TApplicationResponse;

const filterItems: TFilterItems[] = [
  {
    name: 'ID',
    parameterName: 'id',
  },
  {
    name: 'CCB',
    parameterName: 'sequential_id',
  },
];

export const PendingApplicationsStatus = [
  EApplicationStatus.PENDING_SIGNATURE,
  EApplicationStatus.PENDING_QUALIFICATION,
  EApplicationStatus.PENDING_CESSION_QUALIFICATION,
  EApplicationStatus.AWAITING_APPROVAL_DISBURSEMENT,
];

type ApplicationContentProps = {
  pending?: boolean;
};
const ApplicationContent = ({ pending }: ApplicationContentProps) => {
  const theme = useTheme();
  const {
    state: { borrowerStatusOptions },
  } = useQualificationsContext();
  const {
    handleUpdateQualificationStatus,
    handleUpdateQualificationStatusLoading,
  } = useQualificationChangeContext();
  const { showSnackbarCopy } = useSnackbar();
  const { originators } = useOriginatorContext();
  const [searchInputValue, setSearchInputValue] = useState('');
  const { getBusiness } = useCustomerService();
  const { getPerson } = usePersonService();
  const [isFiltersOpened, toggleFiltersOpened] = useState<boolean>(false);
  const [filters, updateFilters] = useState<TFilters>(
    initalFilters as TFilters,
  );
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );
  const { setSelectedOriginator } = useOriginatorContext();
  const { getQualificationStatusOptions, getQualificationRequestApplication } =
    useApplicationsService();
  const { getApplications, getApplication, applicationLoading } =
    useApplicationsService();
  const { getProduct } = useProductsService();
  const [isLoading, setIsLoading] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [applicationState, setApplicationState] = useState<
    TApplicationData[] | undefined
  >([]);
  const [applicationDetail, setApplicationDetail] =
    useState<TApplicationData | null>(null);
  const { isProfileAuthorized } = usePermify();
  const { userInfo } = useAuthContext();
  const { is_issuer_app } = useAppContext();

  const getStatusFilter = () => {
    if (pending) {
      return PendingApplicationsStatus;
    }
    return filters.status &&
      filters.status.length === initalFilters.status.length
      ? undefined
      : filters.status;
  };

  const handleGetApplications = async (
    page: number,
    itemsPerPage?: number,
    searchApplicationId?: string,
  ) => {
    setIsLoading(true);
    setApplicationState([]);
    try {
      const applicationData: IList<TApplicationResponse> | null =
        typeof searchApplicationId === 'string' &&
        searchApplicationId.length > 0 &&
        selectedFilter?.parameterName === 'id'
          ? await getApplication(searchApplicationId).then(
              (application) =>
                ({
                  total_elements: 1,
                  content: [application],
                  has_next: false,
                  total_pages: 1,
                } as IList<TApplicationResponse>),
            )
          : await getApplications(
              page,
              itemsPerPage,
              {
                product_id: filters?.productId,
                borrower_id: filters?.borrower,
                created_date_from: formatDateForBackendString(
                  filters?.minCreatedAtDate,
                ),
                created_date_to: formatDateForBackendString(
                  filters?.maxCreatedAtDate,
                ),
                disbursement_date_from: formatDateForBackendString(
                  filters?.minDisbursementDate,
                ),
                disbursement_date_to: formatDateForBackendString(
                  filters?.maxDisbursementDate,
                ),
                disbursement_processed_date_from: formatDateForBackendString(
                  filters.minDisbursementProcessedAtDate,
                ),
                disbursement_processed_date_to: formatDateForBackendString(
                  filters.maxDisbursementProcessedAtDate,
                ),
                funding_id: filters?.fundingId,
                ...(selectedFilter?.parameterName &&
                searchApplicationId &&
                searchApplicationId.length > 0
                  ? { [selectedFilter.parameterName]: searchApplicationId }
                  : {}),
              },
              getStatusFilter(),
            );
      setTotalItemsQty(applicationData?.total_elements || 0);
      if (
        applicationData?.content !== undefined &&
        Array.isArray(applicationData?.content)
      ) {
        const applicationDataWithProduct = await Promise.all<TApplicationData>(
          applicationData?.content.map(async (application) => {
            const originator = is_issuer_app
              ? application?.originator?.id
              : undefined;
            const productId = application.product.id;
            const statusApproval = await getQualificationStatusOptions(
              productId,
              originator,
              application.status as EApplicationStatus,
            );
            const qualificationDataArr =
              await getQualificationRequestApplication(
                productId,
                0,
                application.borrower.id,
                application.id,
                originator,
                application.status as EApplicationStatus,
              );
            try {
              const productData = await getProduct(productId);
              const borrowerDetails =
                productData?.data?.borrower_type === 'PERSON'
                  ? await getPerson(application.borrower.id, false, originator)
                  : await getBusiness(
                      application.borrower.id,
                      false,
                      originator,
                    );

              return {
                ...application,
                qualificationData: qualificationDataArr
                  ? qualificationDataArr?.content?.[0]
                  : undefined,
                statusApproval: statusApproval,
                borrowerDetails:
                  productData?.data?.borrower_type === 'PERSON'
                    ? {
                        name: (
                          borrowerDetails as unknown as TRegisterPersonData
                        )?.full_name,
                        taxpayer_id: (
                          borrowerDetails as unknown as TRegisterPersonData
                        )?.taxpayer_id,
                      }
                    : {
                        name: (
                          borrowerDetails as unknown as TRegisterBusinessData
                        )?.legal_name,
                        taxpayer_id: (
                          borrowerDetails as unknown as TRegisterBusinessData
                        )?.taxpayer_id,
                      },
                productData: productData?.data,
              };
            } catch {
              return {
                ...application,
                qualificationData: qualificationDataArr
                  ? qualificationDataArr?.content?.[0]
                  : undefined,
                statusApproval: statusApproval,
                productData: undefined,
              };
            }
          }),
        );
        setApplicationState((state) =>
          state?.concat(
            applicationDataWithProduct.filter((content) => {
              const inx = state?.findIndex(
                (stateContent) => stateContent?.id === content?.id,
              );
              return inx < 0;
            }),
          ),
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedOriginator(null);
    setTimeout(() => handleGetApplications(currentPage, linesPerPage));
  }, [linesPerPage, currentPage]);

  useEffect(() => {
    setSelectedOriginator(null);
    setTimeout(() => {
      if (currentPage === 0) {
        handleGetApplications(0, linesPerPage);
      } else {
        setCurrentPage(0);
      }
    });
  }, [filters, pending]);

  const handleOpenApplicationDetail = (
    application: TApplicationData | undefined,
  ) => {
    if (application) setApplicationDetail(application);
  };

  const handleCloseApplicationDetail = () => {
    setApplicationDetail(null);
  };

  const handleUpdateQualificationStatusAction = (
    application: TApplicationData | null,
    identifier: string,
  ) => {
    handleUpdateQualificationStatus(
      application as TApplicationResponse,
      identifier,
      application?.qualificationData,
    ).then((retn) => {
      if (retn) {
        setApplicationState((state) =>
          state?.map((applicationFromState) => {
            if (applicationFromState?.id === application?.id) {
              if (retn.isQualification) {
                return {
                  ...applicationFromState,
                  qualificationData:
                    retn.newStatus as TQualificationRequestApplication,
                };
              }
              return {
                ...applicationFromState,
                status: retn.newStatus as string,
              };
            }
            return applicationFromState;
          }),
        );
        if (applicationDetail) {
          setApplicationDetail(() => {
            const updatedApplicationDetail = { ...applicationDetail };
            if (retn.isQualification) {
              return {
                ...updatedApplicationDetail,
                qualificationData:
                  retn.newStatus as TQualificationRequestApplication,
              };
            }
            return {
              ...updatedApplicationDetail,
              status: retn.newStatus as string,
            };
          });
        }
      }
    });
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    handleGetApplications(0, linesPerPage, searchInputValue);
  };

  const getStatusList = useCallback((application: TApplicationData) => {
    const { statusApproval } = application;
    if (
      [
        EApplicationStatus.PENDING_QUALIFICATION,
        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
      ].includes(application.status as EApplicationStatus) &&
      statusApproval
    ) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ]
        .concat(
          statusApproval && statusApproval?.length > 0
            ? statusApproval.map((status) => ({
                label: status.description,
                value: status.identifier,
              }))
            : [],
        )
        .concat({
          label: 'Negado',
          value: EQualificationStatus.DENIED as string,
        });
    } else if (
      [
        EApplicationStatus.PENDING_QUALIFICATION,
        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
      ].includes(application.status as EApplicationStatus) &&
      !statusApproval
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[EApplicationStatus.PENDING_RANDOM],
          value: EQualificationStatus.PENDING_RANDOM as string,
        },
        {
          label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
          value: EQualificationStatus.CANCELED as string,
        },
      ];
    }

    return handleGetStaticStatusList(application.status);
  }, []);

  const filterCount = useMemo(() => {
    let counter = 0;
    if (filters.borrower) counter++;
    if (filters.originator) counter++;
    if (filters.minCreatedAtDate || filters.maxCreatedAtDate) counter++;
    if (filters.minDisbursementDate || filters.maxDisbursementDate) counter++;
    if (
      filters.minDisbursementProcessedAtDate ||
      filters.maxDisbursementProcessedAtDate
    )
      counter++;
    if (filters.productId) counter++;
    if (filters.fundingId) counter++;

    if (filters.status?.length !== 6) counter++;
    return counter;
  }, [filters]);

  const onApplyFilters = useCallback((f: TFilters) => {
    updateFilters(f);
    toggleFiltersOpened(false);
  }, []);

  const handleCopyId = useCallback((id: string) => {
    navigator.clipboard.writeText(id);
    showSnackbarCopy();
  }, []);

  return (
    <BoxSection>
      <ButtonContainer>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <FilterArea>
            <SearchFilter
              inputValue={searchInputValue}
              handleClearInput={() => {
                setCurrentPage(0);
                setLinesPerPage(10);
                setSearchInputValue(() => '');
                setTimeout(() => handleGetApplications(0, linesPerPage));
              }}
              handleInputChange={(evt) =>
                setSearchInputValue(() => evt.target.value)
              }
              filterItems={filterItems}
              handleSelectFilterItem={(selectedFilterValue) => {
                setSelectedFilter(() => selectedFilterValue);
              }}
              selectedFilter={selectedFilter?.name}
              handleSearch={handleSearch}
            />
          </FilterArea>
          <ButtonPrimary
            style={{ maxWidth: filterCount > 0 ? '202px' : '170px' }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <InstallmentsFiltersIcon
                color={theme.palette.brand.primary.base as string}
              />
            }
            onClick={() => toggleFiltersOpened(() => true)}
          >
            <TextM
              weight="Bold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base as string,
              }}
            >
              Mais filtros
            </TextM>
            {filterCount > 0 && (
              <ApplicationsContentFiltersCount>
                {filterCount}
              </ApplicationsContentFiltersCount>
            )}
          </ButtonPrimary>
        </div>
      </ButtonContainer>
      {borrowerStatusOptions &&
      applicationState &&
      applicationState.length > 0 ? (
        <>
          <Container>
            <TableArea>
              <TableWrapper>
                <Table
                  showPagination
                  handlePageChange={(selectedPage) => {
                    setCurrentPage(selectedPage - 1);
                    handleGetApplications(selectedPage - 1, linesPerPage);
                  }}
                  handleLinesPerPage={(selectedLinesPerPage) => {
                    setCurrentPage(0);
                    setLinesPerPage(
                      selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                    );
                  }}
                  overflowVisible={true}
                  totalElementsQty={totalItemsQty}
                  currentPage={currentPage + 1}
                  linesPerPage={linesPerPage}
                  tableBodyContent={applicationState?.map(
                    (application, index) => {
                      const originator = originators?.find(
                        (o) => o.id === application?.originator?.id,
                      );
                      return {
                        id: application?.id || String(index),
                        cells: [
                          {
                            id: '1',
                            content: (
                              <TableBodyCellContainer
                                style={{ maxWidth: '150px', display: 'flex' }}
                              >
                                <TextS
                                  weight="Regular"
                                  style={{
                                    width: '90%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {application?.id}
                                </TextS>
                                <ButtonPrimary
                                  typeVariant="ghost"
                                  style={{
                                    padding: '0px',
                                    width: 'fit-content',
                                    marginRight: '5px',
                                  }}
                                  onClick={() => handleCopyId(application.id)}
                                >
                                  <CopyIcon
                                    color={
                                      theme.palette.brand.primary.base as string
                                    }
                                  />
                                </ButtonPrimary>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '2',
                            content: (
                              <TableBodyCellContainer>
                                <TextS weight="Regular">
                                  {application.productData
                                    ?.type_product_emission
                                    ? ProductType[
                                        application.productData
                                          .type_product_emission as TypeProductEmission
                                      ]
                                    : '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '3',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {application?.sequential_id || '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '4',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {formatDateFromBackend(
                                    application?.created_at || '',
                                  ).toLocaleDateString('pt-BR')}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '5',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>{originator?.legal_name || '-'}</TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '6',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {application.borrowerDetails?.taxpayer_id
                                    ? normalizeCpfCnpj(
                                        application.borrowerDetails.taxpayer_id,
                                      )
                                    : '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '7',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {application.productData?.name || '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '8',
                            content: (
                              <TableBodyCellContainer>
                                <TextS style={{ paddingRight: '5px' }}>
                                  {application?.status ===
                                    EApplicationStatus.ISSUED &&
                                  application?.disbursement?.processed_at
                                    ? formatDateFromBackend(
                                        application?.disbursement?.processed_at,
                                      ).toLocaleDateString('pt-BR')
                                    : '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '9',
                            content: (
                              <TableBodyCellContainer>
                                <CustomTableSelect
                                  info={application.status_description}
                                  selectItems={getStatusList(
                                    application,
                                  ).filter(
                                    handleStatusListPermissions(
                                      application,
                                      [
                                        EApplicationStatus.PENDING_QUALIFICATION,
                                        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
                                      ].includes(
                                        application?.status as EApplicationStatus,
                                      )
                                        ? application?.qualificationData?.status
                                        : application?.status,
                                      userInfo,
                                    ),
                                  )}
                                  disabled={
                                    !isProfileAuthorized(
                                      EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                                    ) || !application.productData
                                  }
                                  isLoading={
                                    handleUpdateQualificationStatusLoading ===
                                    application?.id
                                  }
                                  handleChange={(identifier) =>
                                    handleUpdateQualificationStatusAction(
                                      application,
                                      identifier,
                                    )
                                  }
                                  value={
                                    ([
                                      EApplicationStatus.PENDING_QUALIFICATION,
                                      EApplicationStatus.PENDING_CESSION_QUALIFICATION,
                                    ].includes(
                                      application?.status as EApplicationStatus,
                                    )
                                      ? application?.qualificationData?.status
                                      : application?.status) || ''
                                  }
                                />
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '10',
                            content: (
                              <TableBodyCellContainer
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  margin: '0px',
                                }}
                              >
                                <ButtonPrimary
                                  typeVariant="ghost"
                                  style={{ width: 'fit-content' }}
                                  onClick={() =>
                                    handleOpenApplicationDetail(application)
                                  }
                                >
                                  <EyeIcon
                                    color={
                                      theme.palette.brand.primary.base as string
                                    }
                                  />
                                </ButtonPrimary>
                              </TableBodyCellContainer>
                            ),
                          },
                        ].filter((item) => is_issuer_app || item.id !== '5'),
                      };
                    },
                  )}
                  tableHeadCell={tableHeader.filter(
                    (item) => is_issuer_app || item.id !== '5',
                  )}
                />
              </TableWrapper>
            </TableArea>
          </Container>
        </>
      ) : (
        <EmptyContainer>
          {applicationLoading || isLoading ? (
            <Spinner />
          ) : (
            <>
              <img src={EmptyApplications} />
              <Heading4
                style={{
                  marginTop: '30px',
                  color: theme.palette.brand.secondary.base,
                }}
                fontWeight="B"
              >
                Nenhum empréstimo solicitado
              </Heading4>
            </>
          )}
        </EmptyContainer>
      )}
      <ApplicationDetailsSideSheet
        application={applicationDetail ?? undefined}
        productInfo={applicationDetail?.productData}
        isOpen={applicationDetail !== null}
        handleClose={handleCloseApplicationDetail}
        handleUpdateQualificationStatus={handleUpdateQualificationStatusAction}
      />
      <ApplicationsFilters
        filters={filters}
        handleApply={onApplyFilters}
        handleClose={() => toggleFiltersOpened(false)}
        isLoading={applicationLoading}
        hideApplication={true}
        isOpen={isFiltersOpened}
      />
    </BoxSection>
  );
};

export default ApplicationContent;
